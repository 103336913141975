import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Tooltip } from "@mui/material";

const OutstandingPayment = () => {
  return (
    <Tooltip title="Payment Outstanding">
      <AttachMoneyIcon color="secondary" variant="text" />
    </Tooltip>
  );
};

export default OutstandingPayment;
