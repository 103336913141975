import React from "react";
import { Link } from "react-router-dom";
import CurrentlyOnlineFooter from "../User/CurrentlyOnlineFooter";

const Footer = () => {
  return (
    <footer
      className={`p-2 mt-8 bg-gradient-to-r dark:from-gray-500/20 dark:to-gray-700/20 from-gray-300/50 to-gray-400/50 border-t border-black -z-20 `}
    >
      <div className="flex sm:flex sm:items-end sm:justify-between">
        <div className="flex">
          <img
            className="w-32 float-left"
            src={
              window.document.documentElement.classList[0] === "dark"
                ? require("../../assets/BigCWhite.png")
                : require("../../assets/BigC.png")
            }
            alt="Big C Lumber"
          />
        </div>

        <div>
          <CurrentlyOnlineFooter />
          <span className="text-sm text-black dark:text-white sm:text-center dark:text-gray-200">
            © 2023
            <a href="https://bigclumber.com/" className="hover:underline">
              {" "}
              Big C Lumber™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
