import React, { useEffect, useState } from "react";
import { selectUser } from "../../../features/userSlice";
import { useSelector } from "react-redux";
import {
  Divider,
  MenuItem,
  Select,
  TextField,
  Button,
  Dialog,
} from "@mui/material";
import DatePicker from "react-datepicker";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  default_door,
  door_size,
  grille_type,
  handing_fields,
  hardware_color,
  yes_no,
} from "../../../components/Forms/SelectValues/values";
import { formAutoSave } from "../../../components/Forms/Misc/utilities";
import { Link, useNavigate } from "react-router-dom";

const ExteriorDoor = (props) => {
  //This will have data or be null either is fine.
  const [formData, setFormData] = useState(props.formData);
  const [formID, setFormID] = useState(null);
  const [autoSaveInterval, setASInterval] = useState(60);
  const [submitStatus, setStatus] = useState(false);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  //Using existing door data if it exists, if not use a blank row.
  const [doors, setDoors] = useState(props.formData.doorData || default_door);

  // Auto Save Counter
  const [currentCount, setCount] = useState(1);

  useEffect(() => {
    if (currentCount <= 0) {
      return;
    }

    if (currentCount >= autoSaveInterval) {
      formAutoSave(formData);
      setCount(1);
    }

    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [currentCount]);

  const timer = () => setCount(currentCount + 1);

  // Handle door info entries, update formData everytime a change is made to the door information
  const handleDoor = (e, obj, index) => {
    var array = [...doors]; // make a separate copy of the array
    array[index][`${obj}`] = e.target.value;
    setDoors(array);
  };

  const handleCopy = (index) => {
    var array = [...doors]; // make a separate copy of the array

    var newDoor = {
      door_size: array[index].door_size,
      description: array[index].description,
      quantity: array[index].quantity,
      size: array[index].size,
      handing: array[index].handing,
      inswing: array[index].inswing,
      jamb_thickness: array[index].jamb_thickness,
      deadbolt: array[index].deadbolt,
      peep_site: array[index].peep_site,
      sill_cover: array[index].sill_cover,
      outswing: array[index].outswing,
      quoted_price: array[index].quoted_price,
    };
    array.push(newDoor);
    setDoors(array);
  };

  const handleSubmit = async () => {
    //Once the form is submitted, stop the auto save.
    setASInterval(9999);
    var data = formData;

    data.entry_date = new Date();
    data.submit_user = user;
    data.form_type = formData.form_type.toUpperCase();

    submitForm(data);
  };

  const submitForm = async (data) => {
    const rawResponse = await fetch("/api/forms/submit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const content = await rawResponse.json();

    if (content.success === 1) {
      setStatus(true);
      setFormID(content.form_id);
      return;
    } else {
      setStatus(false);
      return;
    }
  };

  useEffect(() => {
    var array = [...doors]; // make a separate copy of the array
    formData.doorData = array;
    setFormData({ ...formData });
  }, [doors]);

  //Get Valid Date for Date Picker
  const validDate = () => {
    if (formData.shipping_date) {
      //console.log(formData.shipping_date);

      return new Date(formData.shipping_date);
    } else {
      setFormData({ ...formData, shipping_date: new Date() });
      return new Date();
    }
  };

  return (
    <div className="m-4 items-center p-4 dark:text-white mb-4">
      <Dialog
        open={submitStatus}
        onClose={() => {
          setStatus(false);
          navigate("/forms/viewall");
        }}
        maxWidth="lg"
      >
        <div className="bg-gradient-to-r from-gray-800 to-gray-900 border border-green-500 p-4 dark:text-white w-full">
          <p className="text-2xl">
            {formData.form_type.toUpperCase()} Submitted Successfully
          </p>
          <div>
            <div className="flex flex-col justify-center items-center m-2">
              <p className="text-xl">Notification has been sent!</p>
              <Link to={`/api/forms/pdf?id=${formID}`} target="_blank">
                [Click to download]
              </Link>
            </div>

            <div className="flex flex-col justify-center items-center m-2">
              <Button
                variant="outlined"
                color="success"
                onClick={() => {
                  setStatus(false);
                  navigate("/forms/viewall");
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="border rounded-md bg-white dark:bg-white/5 border-black dark:border-white">
        <p
          className="flex justify-center font-bold
      text-xl"
        >
          Exterior Door {formData.form_type.toUpperCase()}
        </p>
        <p
          className="flex justify-center font-medium
      text-xl"
        >
          {formData.customer} | Job #{formData.job}
        </p>

        <p
          className="flex justify-center font-medium
      text-sm"
        >
          Autosaving in ({autoSaveInterval - currentCount})
        </p>
      </div>

      {/* Delivery Date and Instrutions (2) */}

      <div className="flex flex-col justify-center border rounded-md p-2 my-2 bg-white dark:bg-white/5 border-black dark:border-white">
        <div>
          <p
            className="flex justify-center font-bold
      text-xl"
          >
            General Information
          </p>
        </div>

        <div className="flex flex-wrap justify-center">
          <div className="w-1/5 m-1">
            <p className="dark:text-white font-medium">Shipping Date</p>
            <DatePicker
              selected={validDate()}
              minDate={new Date()}
              onChange={(date) =>
                setFormData({ ...formData, shipping_date: date })
              }
              className="w-full border p-2 rounded-sm text-black bg-slate-200"
            />
          </div>
          <div className="w-3/5 m-1">
            <p className="dark:text-white font-medium">Special Instructions</p>
            <TextField
              onChange={(e) => {
                setFormData({
                  ...formData,
                  instructions: e.target.value,
                });
              }}
              className="w-full border rounded-sm bg-slate-200"
              multiline={true}
              rows={2}
              value={formData.instructions || ""}
              variant="outlined"
            />
          </div>
          <Divider />
          {/* Kit Information (8 Inputs) */}
          <div className="w-1/4 m-2">
            <p className="dark:text-white font-medium">
              Will This Order Accompany a Window Order?
            </p>
            <Select
              className="w-full bg-slate-200"
              value={formData.accompany_window_order || ""}
              onChange={(e) => {
                //console.log(e.target.value);
                setFormData({
                  ...formData,
                  accompany_window_order: e.target.value,
                });
              }}
            >
              {yes_no.map((item, index) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </div>
          <div className="w-1/4 m-2">
            <p className="dark:text-white font-medium">Grille Type</p>
            <Select
              className="w-full bg-slate-200"
              value={formData.grille_type || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  grille_type: e.target.value,
                })
              }
            >
              {grille_type.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="w-1/4 m-2">
            <p className="dark:text-white font-medium">Hardware Color</p>
            <Select
              className="w-full bg-slate-200"
              value={formData.hardware_color || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  hardware_color: e.target.value,
                })
              }
            >
              {hardware_color.map((item, index) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </div>
        </div>
      </div>
      {/* Door Configurator */}

      <div className="flex flex-col justify-center border rounded-md mt-2 mb-8 bg-white dark:bg-white/5 border-black dark:border-white">
        <div>
          <p
            className="flex justify-center font-bold
      text-xl"
          >
            Door Information
          </p>
        </div>
        {doors.map((item, index) => {
          return (
            <div className="flex flex-wrap justify-center m-2 border-b p-2">
              <div className="w-1/12 m-1">
                <p className="dark:text-white font-medium">QTY</p>
                <TextField
                  onChange={(e) => handleDoor(e, "quantity", index)}
                  className="w-full p-2 border rounded-sm bg-slate-200"
                  multiline={true}
                  value={doors[index].quantity || ""}
                  variant="outlined"
                />
              </div>
              <div className="w-1/4 m-1">
                <p className="dark:text-white font-medium">Description</p>
                <TextField
                  onChange={(e) => handleDoor(e, "description", index)}
                  className="w-full p-2 border rounded-sm bg-slate-200"
                  multiline={true}
                  value={doors[index].description || ""}
                  variant="outlined"
                />
              </div>
              <div className="w-1/5 m-1">
                <p className="dark:text-white font-medium">Door Size</p>
                <Select
                  className="w-full bg-slate-200"
                  value={doors[index].door_size || ""}
                  onChange={(e) => handleDoor(e, "door_size", index)}
                >
                  {door_size.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </div>
              <div className="w-1/5 m-1">
                <p className="dark:text-white font-medium">Handing</p>
                <Select
                  className="w-full bg-slate-200"
                  value={doors[index].handing || ""}
                  onChange={(e) => handleDoor(e, "handing", index)}
                >
                  {handing_fields.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </div>
              <div className="w-1/12 m-1">
                <p className="dark:text-white font-medium">Inswing</p>
                <TextField
                  onChange={(e) => handleDoor(e, "inswing", index)}
                  className="w-full p-2 border rounded-sm bg-slate-200"
                  multiline={true}
                  value={doors[index].inswing || ""}
                  variant="outlined"
                />
              </div>
              <div className="w-1/12 m-1">
                <p className="dark:text-white font-medium">Outswing</p>
                <TextField
                  onChange={(e) => handleDoor(e, "outswing", index)}
                  className="w-full p-2 border rounded-sm bg-slate-200"
                  multiline={true}
                  value={doors[index].outswing || ""}
                  variant="outlined"
                />
              </div>
              <div className="w-1/6 m-1">
                <p className="dark:text-white font-xs">Jamb Thickness</p>
                <TextField
                  onChange={(e) => handleDoor(e, "jamb_thickness", index)}
                  className="w-full p-2 border rounded-sm bg-slate-200"
                  multiline={true}
                  value={doors[index].jamb_thickness || ""}
                  variant="outlined"
                />
              </div>
              <div className="w-1/6 m-1">
                <p className="dark:text-white font-medium">Dead Bolt</p>
                <Select
                  className="w-full bg-slate-200"
                  value={doors[index].deadbolt || ""}
                  onChange={(e) => handleDoor(e, "deadbolt", index)}
                >
                  {yes_no.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </div>
              <div className="w-1/6 m-1">
                <p className="dark:text-white font-medium">Peep Site</p>
                <Select
                  className="w-full bg-slate-200"
                  value={doors[index].peep_site || ""}
                  onChange={(e) => handleDoor(e, "peep_site", index)}
                >
                  {yes_no.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </div>
              <div className="w-1/6 m-1">
                <p className="dark:text-white font-medium">Sill Cover</p>
                <Select
                  className="w-full bg-slate-200"
                  value={doors[index].sill_cover || ""}
                  onChange={(e) => handleDoor(e, "sill_cover", index)}
                >
                  {yes_no.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </div>
              <div className="w-1/6 m-1">
                <p className="dark:text-white font-medium">Quoted Price</p>
                <TextField
                  onChange={(e) => handleDoor(e, "quoted_price", index)}
                  className="w-full p-2 border rounded-sm bg-slate-200"
                  value={doors[index].quoted_price || ""}
                  variant="outlined"
                />
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    var array = [...doors]; // make a separate copy of the array
                    if (index !== -1) {
                      array.push({
                        door_type: "",
                        french: "",
                        quantity: "",
                        size: "",
                        handing: "",
                        other_information: "",
                        quoted_price: "",
                      });
                    }
                    setDoors(array);
                  }}
                >
                  <ControlPointIcon />
                </button>
                {index !== 0 && (
                  <button
                    onClick={() => {
                      var array = [...doors]; // make a separate copy of the array
                      if (index !== -1) {
                        array.splice(index, 1);
                        setDoors(array);
                      }
                    }}
                  >
                    <RemoveCircleOutlineIcon />
                  </button>
                )}
                <button onClick={() => handleCopy(index)}>
                  <ContentCopyIcon />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center mb-10">
        <Button onClick={handleSubmit} variant="outlined" color="success">
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ExteriorDoor;
