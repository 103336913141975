import { Button, Dialog, DialogTitle, Tooltip } from "@mui/material";
import React, { useState } from "react";

const NoType = () => {
  return (
    <div className="flex justify-center">
      <p className="text-lg font-black text-red-500">
        Select Form Type Before Selecting Form
      </p>
    </div>
  );
};

const Type = () => {
  return (
    <div className="flex justify-center dark:text-white font-black">
      <p>Select Form</p>
    </div>
  );
};

const FRM_FormSelector = (props) => {
  const [doorModal, setDoorModal] = useState(false);
  const [windowModal, setWindowModal] = useState(false);

  const handleFormSelected = (form_id, form_name) => {
    var array = props.formData; // make a separate copy of the array
    array.form_id = form_id;
    array.form_name = form_name;
    props.setFormData(array);
  };

  return (
    <>
      <Dialog
        open={doorModal}
        onClose={() => {
          setDoorModal(false);
        }}
      >
        <div className="dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 bg-white text-black dark:dark:text-white border border-gray-500">
          <div className="flex justify-center">
            <p className="p-2 dark:text-white font-black text-lgs">
              Door Forms
            </p>
          </div>

          <div className="flex justify-center items-center">
            <div>
              <Button
                onClick={() => {
                  handleFormSelected(6, "Interior Door");
                  props.setFormSelected("interior-door");
                }}
              >
                <img
                  className="w-36 h-36 m-4 rounded-md"
                  src={require("../../../assets/IntDoor.png")}
                />
              </Button>
            </div>
            {/* <div>
              <Button
                disabled={true}
                onClick={() => {
                  handleFormSelected(11, "Exterior Door");
                  props.setFormSelected("exterior-door");
                }}
              >
                <img
                  className="w-36 h-36 m-4 rounded-md"
                  src={require("../../../assets/ExtDoor.png")}
                />
              </Button>
            </div> */}
          </div>
        </div>
      </Dialog>

      <Dialog
        open={windowModal}
        onClose={() => {
          setWindowModal(false);
        }}
      >
        <div className="dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 bg-white text-black dark:text-white border border-gray-500">
          <div className="flex justify-center">
            <p className="p-2 dark:text-white font-black text-lgs">
              Window Forms
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="flex">
              <div className="m-2">
                <Button
                  onClick={() => {
                    handleFormSelected(4, "Andersen 100 Series");
                    props.setFormSelected("andersen-100-series");
                  }}
                >
                  <img
                    className="w-36 h-42 m-4 rounded-md"
                    src={require("../../../assets/Andersen100.jpg")}
                  />
                </Button>
              </div>
              <div className="m-2">
                <Button
                  onClick={() => {
                    handleFormSelected(10, "Andersen 200/400 Series");
                    props.setFormSelected("andersen-200-400-series");
                  }}
                >
                  <img
                    className="w-36 h-42 m-4 rounded-md"
                    src={require("../../../assets/Andersen200400.jpg")}
                  />
                </Button>
              </div>
              <div className="m-2">
                <Button
                  onClick={() => {
                    handleFormSelected(5, "Andersen A Series");
                    props.setFormSelected("andersen-a-series");
                  }}
                >
                  <img
                    className="w-36 h-42 m-4 rounded-md"
                    src={require("../../../assets/AndersenA.jpg")}
                  />
                </Button>
              </div>
            </div>
            <div className="flex">
              <div className="m-2">
                <Button
                  onClick={() => {
                    handleFormSelected(2, "Marvin Window");
                    props.setFormSelected("marvin-window");
                  }}
                >
                  <img
                    className="w-36 h-42 m-4 rounded-md"
                    src={require("../../../assets/Marvin.png")}
                  />
                </Button>
              </div>
              <div className="m-2">
                <Button
                  onClick={() => {
                    handleFormSelected(8, "Integrity Window");
                    props.setFormSelected("integrity-window");
                  }}
                >
                  <img
                    className="w-36 h-42 m-4 rounded-md"
                    src={require("../../../assets/Integrity.jpeg")}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {props.formData.form_type ? <Type /> : <NoType />}

      <>
        <div className="flex justify-center">
          <div className="m-2">
            <Button
              disabled={props.formData.form_type ? false : true}
              onClick={() => {
                setDoorModal(true);
              }}
              className="flex flex-col items-center m-2 bg-slate-100 p-4 rounded-md hover:drop-shadow-md"
            >
              <img
                className="w-36 h-36"
                src={require("../../../assets/DoorSelector.png")}
              />
              <p className="font-black dark:dark:text-white text-black text-xl p-2 items-center border border-black dark:border-gray-500 dark:bg-white/5 bg-white rounded-md m-1 w-full">
                Doors
              </p>
            </Button>
          </div>

          {/* <div className="m-2">
            <Button
              className="flex flex-col items-center m-2 bg-slate-100 p-4 rounded-md hover:drop-shadow-md"
              disabled={true}
              onClick={() => {
                setWindowModal(true);
              }}
            >
              <img
                className="w-36 h-36"
                src={require("../../../assets/WindowSelector.png")}
              />
              <p className="font-black dark:dark:text-white text-black text-xl p-2 items-center border border-black dark:border-gray-500 dark:bg-white/5 bg-white rounded-md m-1 w-full">
                Windows
              </p>
            </Button>
          </div> */}
        </div>
      </>
    </>
  );
};

export default FRM_FormSelector;
