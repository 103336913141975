export const slab_styles = (core) => {
  if (core === "Pine") {
    return {
      label: "Slab style",
      dataLabel: "slab_style",
      tabValue: 3,
      description: "What type of slab style will the door have?",
      optionType: "radio",
      options: ["6 Panel", "Primed Lite", "Wood Lite"],
      images: ["pine.png", "primed-lite.png", "pine-div-lite.png"],
      required: true,
    };
  } else {
    return {
      label: "Slab style",
      dataLabel: "slab_style",
      tabValue: 3,
      description: "What type of slab style will the door have?",
      optionType: "radio",
      options: [
        "Birch",
        "Carrara",
        "Caiman",
        "Craftsman",
        "Rockport",
        "Santa Fe",
        "Smooth Colonist",
        "Textured Colonist",
      ],
      images: [
        "birch.png",
        "cambridge.png",
        "continental.png",
        "craftsman.png",
        "rockport.png",
        "santa_fe.png",
        "textured_colonist.png",
        "textured_colonist.png",
      ],
      required: true,
    };
  }
};

export const getSteps = (size_jamb_type) => {
  return [
    {
      label: "Handing",
      dataLabel: "handing",
      tabValue: 0,
      description: `Select the handing of the door.`,
      optionType: "radio",
      options: ["Left Hand", "Right Hand"],
      images: ["handing_left.jpg", "handing_right.jpg"],
      required: true,
    },
    {
      label: "Casing",
      dataLabel: "casing_type",
      tabValue: 1,
      description: "Will the door have casing?",
      optionType: "select",
      options: [
        "2-1/4 Clr Colonial",
        "2-1/4 Oak Colonial",
        "2-1/4 Clr Ranch",
        "2-1/4 F/J Clay Coat Colonial",
        "2-1/4 Imp Oak Colonial",
        "2-1/4 Meadow Colonial",
        "2-3/4 Prim. Poplar Victorian",
        "2-3/4 Clr Poplar Victorian",
        "3-1/4 Primed Colonial",
        "3-1/4 Clr Colonial",
        "3-1/4 Primed MDF",
        "3-1/2 Prim. Poplar Victorian",
        "2-1/4 Clr Poplar",
        "N/A",
      ],
      thumbnails: [
        "Colonial.jpg",
        "Colonial.jpg",
        "ClearPineRanch.jpg",
        "Colonial.jpg",
        "Colonial.jpg",
        "Colonial.jpg",
        "Victorian.jpg",
        "Victorian.jpg",
        "PineColonial.jpg",
        "Colonial.jpg",
        "Colonial.jpg",
        "ClearPineRanch.jpg",
        "Transparent.png",
        "Transparent.png",
      ],
      doorCasingURL: [""],
      required: true,
    },
    {
      label: "Core",
      dataLabel: "core",
      tabValue: 2,
      description: "What type of core will the door have?",
      optionType: "radio",
      options: ["Hollow", "Solid", "Pine"],
      required: true,
    },
    {
      label: "Slab style",
      dataLabel: "slab_style",
      tabValue: 3,
      description: "What type of slab style will the door have?",
      optionType: "radio",
      options: [
        "Birch",
        "Carrara",
        "Caiman",
        "Craftsman",
        "Rockport",
        "Santa Fe",
        "Smooth Colonist",
        "Textured Colonist",
      ],
      images: [
        "birch.png",
        "cambridge.png",
        "continental.png",
        "craftsman.png",
        "rockport.png",
        "santa_fe.png",
        "smooth_colonist.png",
        "textured_colonist.png",
      ],
      required: true,
    },
    {
      label: "Width",
      dataLabel: "width",
      tabValue: 4,
      description: "What is the width of the door?",
      optionType: "select",
      options: [
        `1'0"`,
        `1'2"`,
        `1'4"`,
        `1'6"`,
        `1'8"`,
        `1'10"`,
        `2'0"`,
        `2'2"`,
        `2'4"`,
        `2'6"`,
        `2'8"`,
        `2'10"`,
        `3'0"`,
      ],
      required: true,
    },
    {
      label: "Jamb Size and Type",
      dataLabel: "size_jamb_type",
      tabValue: 6,
      description: "What is the jamb of the door",
      optionType: "select",
      options: [
        "Door Slab Only",
        "4-3/4 Imp Oak Split",
        "4-9/16 Primed Flat",
        "4-9/16 Clr Veneer Flat",
        "4-9/16 Clr Split",
        "4-9/16 Clr Solid Flat",
        "4-9/16 Oak Veneer Flat",
        "4-9/16 Primed Split",
        "4-9/16 Meadow Flat",
        "4-9/16 Meadow Split",
        "5-1/4 Clr Split",
        "6-9/16 Clr Flat Solid Pine",
        "6-9/16 Primed Flat",
      ],
      required: true,
    },
    {
      label: "Hardware Finish",
      dataLabel: "hardware_finish",
      tabValue: 7,
      description: "What is the Hardware Finish of the door",
      optionType: "select",
      options: [
        "N/A",
        "3-1/2 Bright Brass",
        "3-1/2 Bright Chrome",
        "3-1/2 Brushed Satin Chrome",
        "3-1/2 Antique Brass",
        "3-1/2 Antique Nickel",
        "3-1/2 Antq. Bronze Lacq.",
        "3-1/2 Satin Nickel",
        "3-1/2 Satin Brass",
        "3-1/2 Oil Rubbed Bronze",
        "3-1/2 Black Hinge",
        '4" Satin Brass',
        '4" Brushed Chrome',
        '4" Bright Brass',
        '4" Satin Nickel',
        '4" Oil Rubbed Bronze',
      ],
      required: true,
    },
    {
      label: "Stop",
      dataLabel: "stop",
      tabValue: 8,
      description: "What is the stop of the door",
      optionType: "select",
      options: getStopOptions(size_jamb_type),
      required: true,
    },
  ];
};

const getStopOptions = (sizeJambType) => {
  if (sizeJambType?.includes("Split")) {
    // Return updated options array
    return ["N/A"];
  } else {
    // Return default options array
    return [
      "",
      "Clear Colonial",
      "Clear Round Edge",
      "Clear Ranch",
      "Primed Colonial",
      "Primed Round Edge",
      "Oak Colonial",
    ];
  }
};

export const getSlabStyleImage = (slab_style) => {
  switch (slab_style) {
    case "Birch":
      return "birch.png";
    case "Carrara":
      return "cambridge.png";
    case "Caiman":
      return "continental.png";
    case "Craftsman":
      return "craftsman.png";
    case "Rockport":
      return "rockport.png";
    case "Santa Fe":
      return "santa_fe.png";
    case "Smooth Colonist":
      return "smooth_colonist.png";
    case "Textured Colonist":
      return "textured_colonist.png";
    default:
      return "continental.png";
  }
};

export const TabPanel = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

export const createDoor = (data) => {
  return {
    handing: data?.handing,
    casing_type: data?.casing_type,
    core: data?.core,
    slab_style: data?.slab_style,
    slab_type: data?.slab_type,
    width: data?.width,
    height: data?.height,
    size_jamb_type: data?.size_jamb_type,
    hinge_finish: data?.hinge_finish,
    stop: data?.stop,
    stop_applied: data?.stop_applied,
    applied: data?.applied,
    quantity: data?.qty,
    special_instructions: data?.special_instructions,
    estimated_price: data?.estimated_price,
    margin: data?.margin,
  };
};

export const extractNumbers = (data) => {
  const productIDs = [];
  const regex = /\((\d+)\)/g;

  const processString = (inputString) => {
    const matches = inputString.match(regex);
    if (matches) {
      matches.forEach((match) => {
        const number = parseInt(match.replace(/\D/g, ""), 10);
        if (!isNaN(number)) {
          productIDs.push(number);
        }
      });
    }
  };

  const processValue = (value) => {
    if (typeof value === "string") {
      processString(value);
    }
  };

  if (Array.isArray(data)) {
    data.forEach(processValue);
  } else if (typeof data === "object") {
    Object.values(data).forEach(processValue);
  }

  return productIDs;
};
