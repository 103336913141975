export const roles = [
  "Admin",
  "Manager",
  "General Manager",
  "Operations Manager",
  "IT",
  "Salesperson",
  "Window Door Manager",
  "Fleet Manager",
  "Driver",
  "Inside Sales",
  "Outside Sales",
  "Executive Team",
  "Area Manager",
  "Office",
  "Web Lead Manager",
  "SBCF Admin",
  "RFQ Tester",
];
