import React, { useEffect, useState } from "react";
import data from "../../assets/CustomerKPIData/avg-amount-per-ticket-json";
import {
  Dialog,
  DialogContent,
  Typography,
  Tooltip as ToolTIP,
} from "@mui/material";
import Chart from "chart.js/auto";
import { Bar, Doughnut, Line, Pie, PolarArea, Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRight,
  ArrowCircleRightOutlined,
  ArrowLeft,
} from "@mui/icons-material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DataTable = ({ data }) => {
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [fetchedData, setFetchedData] = useState({ Results: [] });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSummaryDialog, setSummaryOpenDialog] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10); // New state for items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedComparison, setSelectedComparison] = useState("Ticket Count");
  const comparisonOptions = ["Ticket Count", "Avg $ per Ticket", "Total Sales"];

  const handleComparisonChange = (event) => {
    setSelectedComparison(event.target.value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const visibleData = fetchedData?.Results?.slice(startIndex, endIndex) || [];

  const totalPages = Math.ceil(
    (fetchedData?.Results?.length || 0) / itemsPerPage
  );

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    const maxPage = Math.ceil(
      (searchResults?.length || fetchedData?.Results?.length || 0) /
        itemsPerPage
    );
    setCurrentPage(Math.min(nextPage, maxPage));
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    setCurrentPage(Math.max(prevPage, 1));
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const renderArrow = (column) => {
    if (sortedColumn === column) {
      return sortOrder === "asc" ? "↑" : "↓";
    }
    return null;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const columns = [
    "Customer",
    "CustomerID",
    "Branch",
    "Sales Rep",
    "LY Ticket Count",
    "YTD Ticket Count",
    "LY Avg $ per Ticket",
    "Avg $ per Ticket",
    "TotalSalesLY",
    "TotalSalesYTD",
  ];

  useEffect(() => {
    setFetchedData(data || []);
  }, [data]);

  const sortData = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    const sortedData = fetchedData.Results.slice().sort((a, b) => {
      const aValue = String(a[column]).toLowerCase();
      const bValue = String(b[column]).toLowerCase();

      return isAsc
        ? aValue.localeCompare(bValue, undefined, { numeric: true })
        : bValue.localeCompare(aValue, undefined, { numeric: true });
    });

    setSortedColumn(column);
    setSortOrder(isAsc ? "desc" : "asc");
    setFetchedData({ Results: sortedData });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClickRow = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleSummaryClick = () => {
    setSummaryOpenDialog(true);
  };

  const handleSummaryCloseDialog = () => {
    setSummaryOpenDialog(false);
  };

  useEffect(() => {
    // Filter data based on the search term
    const results = fetchedData?.Results?.filter((row) =>
      row.Customer.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, fetchedData]);

  const chartData = {
    labels: ["LY Ticket Count", "YTD Ticket Count"],
    datasets: [
      {
        label: "Ticket Count",
        data: [
          selectedRow ? String(selectedRow["LY Ticket Count"]) : "0",
          selectedRow ? String(selectedRow["YTD Ticket Count"]) : "0",
        ],
        backgroundColor: ["rgba(255,99,132,0.6)", "rgba(75,192,192,0.6)"],
        borderColor: ["rgba(255,99,132,1)", "rgba(75,192,192,1)"],
        borderWidth: 1,
      },
    ],
  };

  const avgPriceData = {
    labels: ["LY Avg $ per Ticket", "Avg $ per Ticket"],
    datasets: [
      {
        label: "Avg $ per Ticket Comparison",
        data: [
          selectedRow ? selectedRow["LY Avg $ per Ticket"] : 0,
          selectedRow ? selectedRow["Avg $ per Ticket"] : 0,
        ],
        backgroundColor: ["rgba(255,99,132,0.6)", "rgba(75,192,192,0.6)"],
        borderColor: ["rgba(255,99,132,1)", "rgba(75,192,192,1)"],
        borderWidth: 1,
        formatter: formatCurrency,
      },
    ],
  };

  const totalSalesData = {
    labels: ["Total Sales LY", "Total Sales YTD"],
    datasets: [
      {
        label: "Total Sales Comparison",
        data: [
          selectedRow ? selectedRow["TotalSalesLY"] : 0,
          selectedRow ? selectedRow["TotalSalesYTD"] : 0,
        ],
        backgroundColor: ["rgba(255,99,132,0.6)", "rgba(75,192,192,0.6)"],
        borderColor: ["rgba(255,99,132,1)", "rgba(75,192,192,1)"],
        borderWidth: 1,
        formatter: formatCurrency,
      },
    ],
  };

  const ticketSummaryChartData = {
    labels: visibleData.map((data) => data?.Customer || []),
    datasets: [
      {
        label: "LY Ticket Count",
        data: visibleData.map((data) => data["LY Ticket Count"] || 0),
        backgroundColor: "rgba(255,99,132,0.6)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
      },
      {
        label: "YTD Ticket Count",
        data: visibleData.map((data) => data["YTD Ticket Count"] || 0),
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const avg$SummaryChartData = {
    labels: visibleData.map((data) => data?.Customer || []),
    datasets: [
      {
        label: "LY Avg $ per Ticket",
        data: visibleData.map((data) => data["LY Avg $ per Ticket"] || 0),
        backgroundColor: "rgba(255,99,132,0.6)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        formatter: formatCurrency,
      },
      {
        label: "Avg $ per Ticket",
        data: visibleData.map((data) => data["Avg $ per Ticket"] || 0),
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        formatter: formatCurrency,
      },
    ],
  };

  const totalSalesSummaryChartData = {
    labels: visibleData.map((data) => data?.Customer || []),
    datasets: [
      {
        label: "Total Sales LY",
        data: visibleData.map((data) => data["TotalSalesLY"] || 0),
        backgroundColor: "rgba(255,99,132,0.6)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        formatter: formatCurrency,
      },
      {
        label: "Total Sales YTD",
        data: visibleData.map((data) => data["TotalSalesYTD"] || 0),
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        formatter: formatCurrency,
      },
    ],
  };

  const comparisonData = () => {
    switch (selectedComparison) {
      case "Ticket Count":
        return ticketSummaryChartData;
      case "Avg $ per Ticket":
        return avg$SummaryChartData;
      case "Total Sales":
        return totalSalesSummaryChartData;
      default:
        return {};
    }
  };

  return (
    <div className="m-2 dark:text-white">
      <input
        type="text"
        placeholder="Search by customer"
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: "10px", marginTop: "10px", marginLeft: "10px" }}
      />
      {fetchedData && (
        <button
          style={{
            margin: "10px",
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={handleSummaryClick}
        >
          Full Summary
        </button>
      )}
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr className="bg-gray-300d">
            {columns.map((column) => (
              <th
                key={column}
                className="border px-4 py-2 border-black dark:border-white dark:bg-blue-200/5"
                onClick={() => sortData(column)}
              >
                {column} {renderArrow(column)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(searchResults) && fetchedData ? (
            searchResults?.map((row) => (
              <tr
                key={row.CustomerID}
                onClick={() => handleClickRow(row)}
                style={{ cursor: "pointer" }}
                className="hover:bg-gray-400"
              >
                {columns.map((column) => (
                  <td
                    key={column}
                    className="border px-4 py-2 border-black dark:border-white dark:bg-blue-200/10"
                  >
                    {/* Check if the column needs currency formatting */}
                    {[
                      "Avg $ per Ticket",
                      "TotalSalesYTD",
                      "LY Avg $ per Ticket",
                      "TotalSalesLY",
                    ].includes(column)
                      ? formatCurrency(row[column])
                      : row[column]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>Loading...</td>
            </tr>
          )}
        </tbody>
      </table>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{
          "& .MuiDialog-paper": {
            width: "25%",
            maxWidth: "none",
            maxHeight: "100%",
          },
        }}
      >
        <DialogContent>
          <div style={{ textAlign: "center", color: "black" }}>
            <Typography variant="h6" gutterBottom style={{ color: "black" }}>
              {selectedRow ? `Customer: ${selectedRow.Customer}` : ""}
            </Typography>

            <Typography variant="h6" gutterBottom style={{ color: "black" }}>
              Last Year / Current YTD Comparison
            </Typography>
          </div>

          <Bar
            data={chartData}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            className="dark:text-black"
          />
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            Average $ Per Ticket
          </p>
          <Bar
            data={avgPriceData}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: (value) =>
                      avgPriceData.datasets[0].formatter(value),
                  },
                },
              },
            }}
          />
          <p style={{ textAlign: "center", fontWeight: "bold" }}>Total Sales</p>
          <Bar
            data={totalSalesData}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: (value) =>
                      totalSalesData.datasets[0].formatter(value),
                  },
                },
              },
            }}
          />
        </DialogContent>
      </Dialog>

      {fetchedData && (
        <Dialog
          open={openSummaryDialog}
          onClose={handleSummaryCloseDialog}
          sx={{
            "& .MuiDialog-paper": {
              width: "85%",
              maxWidth: "none",
              maxHeight: "100%",
            },
          }}
        >
          <DialogContent>
            <div className="flex justify-between">
              <div className="flex justify-start items-center">
                <p className="m-2">Customer Per Page:</p>
                <select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className="w-20"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                </select>
              </div>
              <div className="flex justify-end items-center">
                <p className="m-2">Chart Data:</p>
                <select
                  value={selectedComparison}
                  onChange={handleComparisonChange}
                  className="w-64"
                >
                  {comparisonOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="text-center ">
              <Typography variant="h6" gutterBottom style={{ color: "black" }}>
                Last Year / Current YTD Comparison:
              </Typography>
            </div>
            <p className="text-center font-bold text-2xl dark:text-black">
              {selectedComparison === "Ticket Count"
                ? "Ticket Count"
                : selectedComparison === "Avg $ per Ticket"
                ? "Average $ per Ticket"
                : selectedComparison === "Total Sales"
                ? "Total Sales"
                : ""}
            </p>

            <Bar
              data={comparisonData()}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      callback: (value) => {
                        if (selectedComparison !== "Ticket Count") {
                          return avgPriceData.datasets[0].formatter(value);
                        } else {
                          return value;
                        }
                      },
                    },
                  },
                },
              }}
            />
          </DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              style={{
                padding: "5px",
                margin: "5px",
              }}
            >
              <ArrowCircleLeftOutlined style={{ fontSize: "30px" }} />
            </button>
            <p>
              Page {currentPage} of {totalPages}
            </p>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{
                padding: "5px",
                margin: "5px",
              }}
            >
              <ArrowCircleRightOutlined style={{ fontSize: "30px" }} />
            </button>
          </div>
        </Dialog>
      )}
    </div>
  );
};

const CustomerKPIs = () => {
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    setFetchedData(data || []);
  }, []);

  return (
    <div>
      <DataTable data={fetchedData} />
    </div>
  );
};

export default CustomerKPIs;
