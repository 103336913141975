import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { addFeedback } from "../Admin/Utilities/api";
import { useNavigate } from "react-router-dom";
import { sendUserNotification } from "../../utilities";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color:
      window.document.documentElement.classList[0] === "dark"
        ? "white"
        : "black",
  },
}));

const customIcons = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon
        sx={{ color: "rgba(230, 5, 12,1)" }}
        fontSize="large"
      />
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: (
      <SentimentDissatisfiedIcon
        sx={{ color: "rgba(222, 91, 95,1)" }}
        fontSize="large"
      />
    ),
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" fontSize="large" />,
    label: "Neutral",
  },
  4: {
    icon: (
      <SentimentSatisfiedAltIcon
        sx={{ color: "rgba(79, 219, 95,1)" }}
        fontSize="large"
      />
    ),
    label: "Satisfied",
  },
  5: {
    icon: (
      <SentimentVerySatisfiedIcon
        sx={{ color: "rgba(3, 163, 22,1)" }}
        fontSize="large"
      />
    ),
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const Feedback = () => {
  const [feedback, setFeedback] = useState("");
  const [feedbackValue, setFeedbackValue] = useState(0);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSmileChange = (event) => {
    if (event.target.value) {
      setFeedbackValue(event.target.value);
    }
  };

  const handleSubmit = () => {
    const data = {
      feedback: feedback,
      feedbackValue: feedbackValue,
      submittedBy: user,
      date: new Date(),
    };
    addFeedback(data);
    sendUserNotification(
      "nathan.bilancio@bigclumber.com",
      "System",
      `${user.name}: ${feedback}`,
      "Feedback",
      "/admin/displayfeedback"
    );

    sendUserNotification(
      "blake.upham@bigclumber.com",
      "System",
      `${user.name}: ${feedback}`,
      "Feedback",
      "/admin/displayfeedback"
    );

    navigate("/");
  };

  return (
    <div className="flex flex-col items-center">
      <p className="dark:text-white text-2xl mb-4 text-center">
        We value your input! Please share your suggestions and ideas with us to
        help us improve and bring new innovations to Big C Node.
      </p>
      <textarea
        className="text-black p-2 rounded"
        rows="4"
        cols="50"
        value={feedback}
        onChange={handleFeedbackChange}
        placeholder="Enter your suggestions here..."
      ></textarea>

      <div className="m-4">
        <StyledRating
          name="highlight-selected-only"
          IconContainerComponent={IconContainer}
          getLabelText={(value) => customIcons[value].label}
          onClick={(e) => {
            handleSmileChange(e);
          }}
          highlightSelectedOnly
        />
      </div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-4 rounded"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default Feedback;
