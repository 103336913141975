import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  ThemeProvider,
  createTheme,
  Alert,
  AlertTitle,
  Divider,
  Button,
} from "@mui/material";
import {
  countInvalidAll,
  countValidAll,
  getCheckedAddress,
} from "./Utilities/api";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const AddressValid = () => {
  const defaultMaterialTheme = createTheme();
  const [dataStore, setDataStore] = useState([]);
  const [userSelected, setUser] = useState("ALL");

  const [countInvalid, setInvalid] = useState(0);
  const [countValid, setValid] = useState(0);

  useEffect(() => {
    getCheckedAddress(setDataStore, userSelected);
    countInvalidAll(setInvalid, userSelected);
    countValidAll(setValid, userSelected);
  }, [userSelected]);

  const data = {
    labels: [`Invalid (${countInvalid})`, `Valid (${countValid})`],
    datasets: [
      {
        label: "# of Votes",
        data: [countInvalid, countValid],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="mt-2 md:mt-0 md:col-span-2 w-full">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="space-y-2 sm:p-6">
              <div className="grid grid-cols-3 gap-2">
                <div className="col-span-2">
                  <div>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <MaterialTable
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.05)",
                          color: "white",
                        }}
                        options={{
                          pageSize: 10, // make initial page size
                          emptyRowsWhenPaging: false, // To avoid of having empty rows
                          pageSizeOptions: [5, 10, 15, 25],
                          headerStyle: {
                            backgroundColor: "rgba(255, 255, 255, 0.05)",
                            color: "white",
                          },
                          searchFieldStyle: {
                            color: "white",
                            borderBottom: "1px solid white",
                          },
                          rowStyle: (rowData) => {
                            return {
                              fontSize: 14,
                            };
                          },
                        }}
                        columns={[
                          {
                            title: "Order#",
                            field: "orderNumber",
                          },
                          {
                            title: "Status",
                            field: "status",
                          },
                          {
                            title: "Match",
                            field: "match_type",
                          },
                          {
                            title: "Address",
                            field: "address",
                          },
                          {
                            title: "Salesperson",
                            field: "user",
                            render: (rowData) => {
                              return (
                                <Button
                                  onClick={() => {
                                    setUser(rowData.user);
                                  }}
                                >
                                  {rowData.user}
                                </Button>
                              );
                            },
                          },
                          {
                            title: "Branch",
                            field: "branch",
                          },
                        ]}
                        data={dataStore}
                        title={
                          userSelected === "ALL" ? (
                            `Address Validation - ${userSelected}`
                          ) : (
                            <div className="flex items-center">
                              <p>Address Validation - {userSelected} </p>
                              <Button
                                onClick={() => {
                                  setUser("ALL");
                                }}
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </Button>
                            </div>
                          )
                        }
                        icons={{
                          Clear: (props) => (
                            <DeleteIcon className="text-white" />
                          ),
                          Search: (props) => (
                            <SearchIcon className="text-white" />
                          ),
                          ResetSearch: (props) => (
                            <DeleteIcon className="text-white" />
                          ),
                          NextPage: (props) => (
                            <NavigateNextIcon className="text-white" />
                          ),
                          PreviousPage: (props) => (
                            <NavigateBeforeIcon className="text-white" />
                          ),
                          FirstPage: (props) => (
                            <SkipPreviousIcon className="text-white" />
                          ),
                          LastPage: (props) => (
                            <SkipNextIcon className="text-white" />
                          ),
                          SortArrow: (props) => (
                            <ArrowDropDownIcon className="text-white" />
                          ),
                        }}
                        localization={{
                          body: {
                            emptyDataSourceMessage: (
                              <div className="flex justify-center">
                                <Alert severity="success">
                                  <div>
                                    <AlertTitle>No Data</AlertTitle>
                                    If you think this is a mistake please
                                    contact <strong>Help Desk</strong>.
                                  </div>
                                  <Divider />
                                </Alert>
                              </div>
                            ),
                          },
                        }}
                      />
                    </ThemeProvider>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="border border-gray-500 bg-white/5 rounded-md">
                    <Pie style={{ height: 100 }} data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressValid;
