import React, { useState } from "react";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import DisplayUserRoles from "../../components/User/DisplayUserRoles";
import { selectRoles } from "../../features/roleSlice";
import EditIcon from "@mui/icons-material/Edit";
import LogoutButton from "../../auth/logout-button";

const Profile = () => {
  const user = useSelector(selectUser);
  const role = useSelector(selectRoles);
  const [pickedImage, setPickedImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [image, setImage] = useState(null);
  const [edit, setEdit] = useState(false);

  const handleFileInputChange = (e) => {
    var file = e.target.files[0];
    if (file) {
      var element = document.getElementById("file");
      var image = element.files[0];
      var blob = image.slice(0, image.size);
      file = new File([blob], user.id, {
        type: `${image.type}`,
      });

      const img = {
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      };
      setImage(img);

      setPickedImage(file);
      setUploadStatus(null);
    }
  };

  const handleUpload = async () => {
    if (pickedImage) {
      const formData = new FormData();
      formData.append("file", pickedImage);

      try {
        const response = await fetch("/api/profile/image/upload", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          console.log("Image uploaded successfully");
          setUploadStatus("success");
          setEdit(false);
          // Perform any additional actions after successful upload
        } else {
          console.error("Image upload failed");
          setUploadStatus("failure");
        }
      } catch (error) {
        console.error("Error occurred during image upload:", error);
        setUploadStatus("failure");
      }
    }
  };

  return (
    <>
      <div className="flex justify-center items-start m-2">
        <div className="flex flex-col items-center border border-gray-950 bg-white dark:border-gray-500 dark:bg-white/5 p-4 text-black dark:text-white rounded-md">
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center border-b p-2">
              <div className="text-black dark:text-white m-2 flex flex-col items-center">
                <div className="relative">
                  {!edit && (
                    <Tooltip title="Edit Image">
                      <EditIcon
                        onClick={() => setEdit(true)}
                        className="absolute translate-x-12 -translate-y-2"
                      />
                    </Tooltip>
                  )}
                  {edit && (
                    <input
                      type="file"
                      name="file"
                      id="file"
                      accept="image/*"
                      onChange={handleFileInputChange}
                    />
                  )}
                </div>
                <div className="w-32 h-32 bg-gray-300 rounded-full overflow-hidden">
                  {image ? (
                    <img
                      src={image.preview}
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <img
                      src={`/api/profile/image?id=${user?.id}`}
                      className="object-cover w-full h-full"
                    />
                  )}
                </div>
                {pickedImage && !uploadStatus && (
                  <div className="flex justify-center">
                    <button
                      className="bg-green-500 hover:bg-green-700 text-white p-1 m-2 rounded"
                      onClick={handleUpload}
                    >
                      Submit
                    </button>
                  </div>
                )}
                {uploadStatus === "success" && (
                  <p className="text-green-500 mt-2">Upload successful!</p>
                )}
                {uploadStatus === "failure" && (
                  <p className="text-red-500 mt-2">
                    Upload failed. Please try again.
                  </p>
                )}
              </div>

              <p className="text-xl font-black">{user?.name}</p>
              <p className="text-xl ">{user?.title}</p>
            </div>

            <p className="text-l font-bold text-xl">Roles</p>
            <p className="text-l font-bold text-xl">
              <DisplayUserRoles user={user} role={role} />
            </p>
          </div>
          <div className="m-2">
            <LogoutButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
