import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const ExternalAuth = (props) => {
  const { key: routeKey } = useParams();

  useEffect(() => {
    if (props.externalKey === routeKey) {
      console.log("External Access Granted");
    } else {
      window.location = "https://www.bigclumber.com";
    }
  }, [props.externalKey, routeKey]);

  return <></>;
};

export default ExternalAuth;
