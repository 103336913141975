import React, { useContext, useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { SocketContext } from "./Utilities/Context";
import { Dialog, Tooltip } from "@mui/material";
import { sendShareCode } from "./Utilities/api";

const Notifications = () => {
  const { answerCall, call, callAccepted } = useContext(SocketContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Set open to true when receiving a call and call is not accepted
    if (call.isReceivingCall && !callAccepted) {
      setOpen(true);
    }

    if (callAccepted) {
      setOpen(false);
    }
  }, [call.isReceivingCall, callAccepted]);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <div className="flex flex-col w-full justify-center items-center p-4">
          <p className="text-xl">
            {call.name} is requesting to view your screen share!
          </p>
          <div className="flex justify-center">
            <button className="m-2 p-2" onClick={answerCall}>
              <CheckCircleIcon className="text-green-300 hover:text-green-400" />
            </button>
            <button className="m-2 p-2" onClick={() => setOpen(false)}>
              <CancelIcon className="text-red-300 hover:text-red-400" />
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const ScreenShareNavBar = () => {
  const {
    me,
    callAccepted,
    stream,
    startScreenShare,
    stopScreenShare,
    myVideo,
    userVideo,
    isScreenSharing,
  } = useContext(SocketContext);

  // Check if `me` is truthy, otherwise render a loading state or nothing
  if (!me) {
    return <></>;
  }

  return (
    <div className="flex">
      {/* my video */}
      {stream ? (
        <div className="flex">
          {isScreenSharing && (
            <div className="flex flex-col items-center">
              <video playsInline muted ref={myVideo} autoPlay width="60" />
              <video playsInline muted ref={userVideo} autoPlay width="0" />
              <p className="text-xs">Sharing Screen</p>
              {/* <p>ID: {me}</p>

              <ContentCopyIcon
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(me);
                }}
              >
                <button leftIcon={<ContentCopyIcon />}>Copy ID</button>
              </ContentCopyIcon> */}
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center">
          <button
            onClick={() => {
              startScreenShare();
            }}
          >
            <ScreenShareIcon />
          </button>
        </div>
      )}

      <Notifications />

      {callAccepted && (
        <>
          <Tooltip title={"Stop Screen Share"}>
            <CancelPresentationIcon
              className="cursor-pointer m-1 text-red-300 hover:text-red-400"
              onClick={() => stopScreenShare()}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default ScreenShareNavBar;
