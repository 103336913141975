import React, { useEffect, useState } from "react";
import { getMSAuthToken } from "../../utils/MsGraphApiCall";
import { GraphFileBrowser } from "@microsoft/file-browser";

const CommonStock = () => {
  const [token, setToken] = useState(null);

  const getAuthenticationToken = () => {
    if (token) {
      return new Promise((resolve) => {
        resolve(token);
      });
    }
  };

  useEffect(() => {
    getMSAuthToken(setToken);
  }, []);

  useEffect(() => {
    // Find the element with the data-automationid attribute
    const elementToHide = document.querySelector(
      '[data-automationid="malwareDownloadCommand"]'
    );

    // Check if the element exists before attempting to hide it
    if (elementToHide) {
      elementToHide.style.display = "none";
    }
  }, [token]);

  return (
    <div>
      {token && (
        <GraphFileBrowser
          getAuthenticationToken={getAuthenticationToken}
          endpoint="https://graph.microsoft.com/v1.0/sites/bigclumberinc.sharepoint.com,1d5cd21c-1bab-4e4d-91d1-97308132fcb4"
          itemId="01EJATJ6KOT573DZ4OWRDKXQNDBUTAVCC2"
        />
      )}
    </div>
  );
};

export default CommonStock;
