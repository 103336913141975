import React, { useEffect, useState } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import {
  destroyFavorites,
  selectFavorites,
  storeFavorites,
} from "../../features/favoriteSlice";
import { useDispatch } from "react-redux";

function FavoriteButton(props) {
  const [isFavorited, setIsFavorited] = useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const favorites = useSelector(selectFavorites);

  const saveFavorite = async () => {
    var data = JSON.stringify({
      id: user.id,
      item: props.item,
      isFavorited: !isFavorited,
    });

    const rawResponse = await fetch("/api/favorites", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const favorites = await rawResponse.json();

    dispatch(storeFavorites(favorites));
  };
  const toggleFavorite = () => {
    //dispatch(destroyFavorites());
    saveFavorite();
    setIsFavorited((prevState) => !prevState);
  };

  const darkIconStyle = {
    color: props.color || "rgba(234, 237, 133, 1)",
  };

  const iconStyle = {
    color: props.color || "rgba(227, 171, 16, 1)",
  };

  useEffect(() => {
    if (favorites) {
      //console.log(favorites);
      favorites?.favorite?.forEach((favorite) => {
        if (favorite === props.item) {
          setIsFavorited(true);
        }
      });
    }
  }, []);

  return (
    <div onClick={toggleFavorite}>
      {isFavorited ? (
        <StarIcon
          style={
            window.document.documentElement.classList[0] === "dark"
              ? darkIconStyle
              : iconStyle
          }
        />
      ) : (
        <StarBorderIcon
          style={
            window.document.documentElement.classList[0] === "dark"
              ? darkIconStyle
              : iconStyle
          }
        />
      )}
    </div>
  );
}

export default FavoriteButton;
