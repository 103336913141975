import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { sendUserNotification } from "../../utilities";
import LoadingSpinner from "../../components/Other/LoadingSpinner";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CheckUserAdmin from "../../components/User/CheckUserAdmin";

const CreateNotification = () => {
  const user = useSelector(selectUser);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [link, setLink] = useState("");
  const [notificationSent, setNotificationSent] = useState(false);

  const getAllUsers = () => {
    return fetch("/api/getUserID")
      .then((response) => response.json())
      .then((responseJson) => {
        setUsers(responseJson.recordset);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleBodyChange = (event) => {
    setBody(event.target.value);
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleChange = (event, value) => {
    setSelectedUser(value);
  };

  const handleSendNotification = () => {
    if (subject.trim() === "") {
      <Alert variant="filled" severity="error">
        <AlertTitle>
          <p className="text-white">Subject is empty!</p>
        </AlertTitle>
      </Alert>;
      return;
    }

    if (body.trim() === "") {
      <Alert variant="filled" severity="error">
        <AlertTitle>
          <p className="text-white">Body is empty!</p>
        </AlertTitle>
      </Alert>;

      return;
    }

    const emailAddress = selectedUser ? selectedUser.EmailAddress : "";
    sendUserNotification(emailAddress, user.name, body, subject, link);
    setBody("");
    setSubject("");
    setLink("");
    setNotificationSent(true);
    setTimeout(() => {
      setNotificationSent(false);
    }, 3000);
  };

  return (
    <>
      <CheckUserAdmin />
      {users.length > 0 ? (
        <div className="flex flex-col items-center w-full">
          <div className="flex">
            <div className="w-1/3 m-2">
              <FormControl className="p-2 m-2 rounded-md w-full">
                <p className="font-black text-white">Select User</p>
                <Autocomplete
                  className="text-black bg-white"
                  value={selectedUser}
                  options={users}
                  getOptionLabel={(item) => item.Name}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} value={""} />}
                />
              </FormControl>
            </div>
            <div className="m-2 rounded-md">
              <p className="font-black text-white">Subject</p>

              <TextField
                className="bg-white rounded-md"
                id="subject"
                placeholder="Subject"
                variant="outlined"
                value={subject}
                onChange={handleSubjectChange}
              />
            </div>
            <div className="m-2 rounded-md">
              <p className="font-black text-white">Body</p>

              <TextField
                className="bg-white rounded-md"
                id="body"
                placeholder="Body"
                variant="outlined"
                multiline={true}
                value={body}
                onChange={handleBodyChange}
              />
            </div>
            <div className="m-2 rounded-md">
              <p className="font-black text-white">Link</p>

              <TextField
                className="bg-white rounded-md"
                id="link"
                placeholder="Link"
                variant="outlined"
                value={link}
                onChange={handleLinkChange}
              />
            </div>
          </div>

          <div className="flex justify-center">
            <button
              className="bg-white rounded-md py-2 px-4"
              onClick={handleSendNotification}
            >
              Send Notification!
            </button>
          </div>

          <div className="flex w-full m-2">
            <a
              className="m-2"
              href="https://data.typeracer.com/pit/profile?user=nathankvb&ref=badge"
              target="_top"
            >
              <img
                src="https://data.typeracer.com/misc/badge?user=nathankvb"
                border="0"
                alt="TypeRacer.com scorecard for user nathankvb"
              />
            </a>

            <a
              className="m-2"
              href="https://data.typeracer.com/pit/profile?user=blake243&ref=badge"
              target="_top"
            >
              <img
                src="https://data.typeracer.com/misc/badge?user=blake243"
                border="0"
                alt="TypeRacer.com scorecard for user blake243"
              />
            </a>
          </div>

          {notificationSent && (
            <div className="flex justify-center mt-4">
              <Alert variant="filled" severity="success">
                <AlertTitle>
                  <p className="text-white">Notification Sent!</p>
                </AlertTitle>
              </Alert>
            </div>
          )}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default CreateNotification;
