import React from "react";
import NavItem from "../../Home/NavItem";

const LocationNavigation = (props) => {
  return (
    <div className="flex flex-col m-2">
      <div className="flex justify-center">View Forklifts</div>

      <div className="flex flex-wrap justify-center">
        <NavItem title={"All"} nav={`fleet/forks/view/All`} />
        {props.locationData.map((item, index) => {
          return (
            <NavItem
              key={index}
              title={item.location}
              nav={`fleet/forks/view/${item.location}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LocationNavigation;
