import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";

const FRM_QuoteOrder = (props) => {
  const [customMargin, setCustomMargin] = useState(false);

  const handleChange = (e) => {
    props.setFormData({ ...props.formData, margin: e.target.value });
  };

  return (
    <div className="m-2">
      <div className="flex justify-center dark:text-white">
        <p className="font-black">Adjust Default Margin?</p>
      </div>

      <div className="flex justify-center m-2">
        <button
          onClick={() => setCustomMargin(false)}
          className={
            !customMargin
              ? "p-1 m-1 rounded bg-gray-200/80 w-14 border border-green-600"
              : "p-1 m-1 rounded bg-gray-200/80 w-14"
          }
        >
          No
        </button>
        <button
          onClick={() => setCustomMargin(true)}
          className={
            customMargin
              ? "p-1 m-1 rounded bg-gray-200/80 w-14 border border-green-600"
              : "p-1 m-1 rounded bg-gray-200/80 w-14"
          }
        >
          Yes
        </button>
      </div>
      {customMargin && (
        <div className="flex justify-center">
          <TextField
            onChange={handleChange}
            type={"text"}
            className="shadow-sm border border-gray-500 bg-white dark:text-white border-gray-300 rounded-md w-1/12"
            value={props.formData.margin}
            placeholder="Margin"
            InputProps={{
              endAdornment: <PercentIcon />,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FRM_QuoteOrder;
