import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { Turnstile } from "@marsidev/react-turnstile";

const RequestAccess = () => {
  const [loginName, setLoginName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState(false);
  const formRef = React.useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    let token = formData.get("cf-turnstile-response");

    if (
      loginName &&
      accountNumber &&
      customerName &&
      firstName &&
      lastName &&
      emailAddress
    ) {
      requestAccess(token);
    }
  };

  document.body.style = "";

  const requestAccess = async (token) => {
    var data = JSON.stringify({
      loginName: loginName,
      accountNumber: accountNumber,
      customerName: customerName,
      firstName: firstName,
      lastName: lastName,
      contactNumber: contactNumber,
      emailAddress: emailAddress,
      token: token,
    });

    const rawResponse = await fetch("/api/misc/webtrack", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    setContent(await rawResponse.json());

    setModal(true);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      {!modal ? (
        <form
          ref={formRef}
          className="flex flex-col w-1/4"
          onSubmit={handleSubmit}
        >
          <div className="m-1 flex justify-center flex-col">
            <label>
              <strong className="text-red-900">*</strong>Requested Login name:
            </label>
            <input
              type="text"
              value={loginName}
              onChange={(e) => setLoginName(e.target.value)}
              required
            />
          </div>
          <div className="m-1 flex justify-center flex-col">
            <label>
              <strong className="text-red-900">*</strong>Account number or
              Customer code:
            </label>
            <input
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              required
            />
          </div>
          <div className="m-1 flex justify-center flex-col">
            <label>
              <strong className="text-red-900">*</strong>Company name:
            </label>
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              required
            />
          </div>
          <div className="m-1 flex justify-center flex-col">
            <label>
              <strong className="text-red-900">*</strong>First name:
            </label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="m-1 flex justify-center flex-col">
            <label>
              <strong className="text-red-900">*</strong>Last name:
            </label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="m-1 flex justify-center flex-col">
            <label>
              <strong className="text-red-900">*</strong>Contact number:
            </label>
            <input
              type="text"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              required
            />
          </div>
          <div className="m-1 flex justify-center flex-col">
            <label>
              <strong className="text-red-900">*</strong>Email address:
            </label>
            <input
              type="email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              required
            />
          </div>
          <div className="m-1 flex flex-col justify-center items-center">
            <button
              className="bg-blue-600 m-1 p-2 rounded-md text-white"
              type="submit"
            >
              Request Access
            </button>

            <Turnstile
              options={{
                theme: "light",
              }}
              siteKey="0x4AAAAAAAOMyELVWdkY7Bls"
            />
          </div>
        </form>
      ) : (
        <p>
          {content.success ? (
            <p>
              All set! Your request has been sent. Account creation can take
              24-48 hours. If you do not receive a welcome email within that
              time frame, please reach out to us at 574-277-4550.
            </p>
          ) : (
            <p>Error: Please call 574-277-4550 for assistance.</p>
          )}
        </p>
      )}
    </div>
  );
};

export default RequestAccess;
