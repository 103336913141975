import { Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import DisplayAllUsers from "../../components/User/DisplayAllUsers";
import { getAllUsers } from "./Utilities/api";
import CurrentlyOnline from "../../components/User/CurrentlyOnline";

const UserManagement = () => {
  return (
    <div className="flex flex-col m-2">
      <CurrentlyOnline />

      <DisplayAllUsers />
    </div>
  );
};

export default UserManagement;
