import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUserID, updateUser } from "../../utilities";
import { useDispatch } from "react-redux";
import { login } from "../../features/userSlice";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import {
  callMsCalendar,
  callMsGraph,
  callMsGroups,
} from "../../utils/MsGraphApiCall";

// Msal imports
import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { loginRequest } from "../../authConfig.js";
import { getUser } from "../../views/Admin/Utilities/api";
import { add } from "../../features/favoriteSlice";
import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";

const InitUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(1);
  const [buffer, setBuffer] = useState(10);
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [userI, setUser] = useState(null);
  const [userID, setUserID] = useState(null);

  var formattedBody = `Debug Details \n ${userI} \n ${userID}`;

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then((response) => {
          if (response.mail) {
            setGraphData(response);
            setProgress(10);
            getUserID(response.mail, setUserID);
            setProgress(20);
            getUser(setUser, response.id);
            setProgress(65);
          } else {
            setProgress(100);
            dispatch(
              login({
                name: response.displayName,
                email: response.userPrincipalName,
                id: response.id,
                mobile: response.mobilePhone,
                title: response.jobTitle,
                userID: "1",
                roles: [],
                branch: response.displayName.split(" ")[0],
              })
            );
            navigate("/");
          }
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
  }, [inProgress, instance]);

  useEffect(() => {
    if (graphData && userI && userID) {
      const user = {
        name: graphData?.displayName,
        email: graphData?.mail,
        id: graphData?.id,
        mobile: graphData?.mobilePhone,
        title: graphData?.jobTitle,
        userID: userID?.UserID,
        roles: userI?.roles,
        branch: userID?.Branch,
        salesRepCode: userID?.SalesRepCode,
      };
      setProgress(80);
      updateUser(user);
      dispatch(login(user));
      setProgress(100);
      navigate("/");
    } else if (graphData && !userI && userID) {
      const user = {
        name: graphData?.displayName,
        email: graphData?.mail,
        id: graphData?.id,
        mobile: graphData?.mobilePhone,
        title: graphData?.jobTitle,
        userID: userID?.UserID,
        salesRepCode: userID?.SalesRepCode,
      };
      setProgress(80);
      updateUser(user);
      getUser(setUser, user.id);
      setProgress(100);
      navigate("/");
    }
  }, [userI, graphData, userID]);

  return (
    <div className="flex flex-col justify-center h-screen items-center bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 dark:from-gray-800 from-gray-400/50 to-gray-300/50">
      <div className="flex flex-col items-center mt-8 h-1/2">
        <img
          src={
            window.document.documentElement.classList[0] === "dark"
              ? require("../../assets/NodeLogo2.png")
              : require("../../assets/NodeLogoDark.png")
          }
          style={{ width: "150px" }}
        />
        <p>Welcome to Node! Please wait while we authenticate you.</p>
        <div className="flex mt-10">
          <CircularProgressWithLabel value={progress} />
        </div>
      </div>
      <div>Stuck on this page? Contact HelpDesk!</div>
      <div>
        <a
          className="p-2 border border-black dark:border-white bg-cyan-600/20 dark:bg-white/5 hover:bg-slate-200 flex justify-between items-center rounded-md"
          href={`mailto:helpdesk@bigclumber.com?subject=${encodeURIComponent(
            "Node Help | Stuck on INIT USER"
          )}&body=${encodeURIComponent(formattedBody)}`}
        >
          <div className="flex">
            <div className="w-11/12 flex flex-col">
              <p className="font-black dark:text-white">Submit a Ticket</p>
            </div>
            <div className="w-1/12 flex justify-center items-center">
              <ArrowOutwardIcon />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        className="w-20 h-20"
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default InitUser;
