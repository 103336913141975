import React, { useEffect, useState } from "react";
import { getPricing } from "../../Forms/Misc/utilities";
import { extractNumbers } from "../utilities";
import { Dialog } from "@mui/material";

const DoorSpecifications = (props) => {
  const [productIDs, setProductIDs] = useState([]);
  const [pricing, setPricing] = useState(0);
  const [margin, setMargin] = useState(props.data.margin);
  const [marginDialog, setDialog] = useState(false);

  useEffect(() => {
    const extractedNumbers = extractNumbers(props.data);
    setProductIDs((prevProductIDs) => [...prevProductIDs, ...extractedNumbers]);

    // Clean up function (runs on component unmount)
    return () => {
      setProductIDs([]);
    };
  }, [props.data]);

  useEffect(() => {
    if (props.data.isDouble) {
      getPricing(productIDs, 1, props.data.size_jamb_type, setPricing);
    } else {
      getPricing(productIDs, 0, props.data.size_jamb_type, setPricing);
    }
  }, [productIDs]);

  useEffect(() => {
    // Check if the core type has changed
    if (props.data.core && props.data.core !== "Bifold") {
      // Set slab_style and width to null when the core type changes
      props.data.slab_style = null;
      props.data.width = null;
    }
  }, [props.data.core]);

  useEffect(() => {
    if (props.data.door_type === "double") {
      // Set slab_style and width to null when the core type changes
      props.data.handing = "Double";
    } else if (props.data.size_jamb_type?.includes("Door Slab Only")) {
      props.data.handing = "N/A";
    } else if (props.data.door_type === "bifold") {
      props.data.handing = "Bifold";
      props.data.core = "Bifold";
    }
  }, [props.data]);

  useEffect(() => {
    // Pricing is on the original Door Builder page, this pricing is only used to send pricing back to Door Builder to add to the door table when added to quote?
    props.setEstimatedPrice(
      Math.round(
        (pricing * props.data.qty * (100 / (100 - parseFloat(margin))) +
          Number.EPSILON) *
          100
      ) / 100
    );
  }, [pricing, props.data.qty, margin, props.data.margin]);

  useEffect(() => {
    // Pricing is on the original Door Builder page, this pricing is only used to send pricing back to Door Builder to add to the door table when added to quote?
    setMargin(props.data.margin);
  }, [props.marginFlag]);

  return (
    <>
      <Dialog
        open={marginDialog}
        onClose={() => setDialog(false)}
        maxWidth="sm"
      >
        <div className="flex flex-col items-center m-2 ">
          <p className="text-black dark:text-white">Margin(%)</p>
          <input
            type="text"
            name="margin"
            className="border border-black dark:text-black rounded w-full"
            value={margin}
            onChange={(e) => setMargin(e.target.value)}
            max={99}
            onInput={(e) => {
              // Allow only numbers and a single decimal point
              e.target.value = e.target.value.replace(/[^0-9.]/g, "");

              // Ensure only one decimal point is present
              const parts = e.target.value.split(".");
              if (parts.length > 2) {
                parts.pop();
                e.target.value = parts.join(".");
              }

              // Limit to two digits before decimal
              const integerPart = parts[0];
              if (integerPart.length > 2) {
                e.target.value =
                  integerPart.substring(0, 2) +
                  (parts[1] ? "." + parts[1] : "");
              }

              // Limit decimal to two numbers after decimal point
              if (parts[1]) {
                const decimalPart = parts[1];
                if (decimalPart.length > 2) {
                  e.target.value =
                    integerPart + "." + decimalPart.substring(0, 2);
                }
              }
            }}
          />
          <button
            className="m-2 p-2 w-full bg-green-200 rounded hover:bg-green-300"
            onClick={() => setDialog(false)}
          >
            Close
          </button>
        </div>
      </Dialog>
      <div className="flex flex-col items-center border-b">
        <p className="text-black dark:text-white">Specifications</p>
      </div>
      <div className="flex flex-col max-h-48 overflow-auto">
        {props.data.core && props.data.slab_style && props.data.width && (
          <p className="flex justify-center font-black text-lg">
            {props.data.core} {props.data.slab_style} {props.data.width}
          </p>
        )}
        {Object.keys(props.data).map((key) => {
          if (
            key !== "core" &&
            key !== "slab_style" &&
            key !== "width" &&
            key !== "margin" &&
            props.data[key] !== null
          ) {
            return (
              <div className="flex flex-col" key={key}>
                <div className="flex text-black dark:text-white hover:text-slate-500 dark:hover:text-slate-500">
                  <p className="flex">{key.toUpperCase()}:</p>
                  {key === "isDouble" ? (
                    <p className="ml-1">{props.data[key] ? "True" : "False"}</p>
                  ) : (
                    <p className="ml-1">{props.data[key]}</p>
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
      <button onClick={() => setDialog(true)}>
        <div className="flex items-center justify-center text-2xl font-bold">
          Estimated Price:{" "}
          <p className="text-green-500">
            $
            {(
              Math.round(
                (pricing * props.data.qty * (100 / (100 - parseFloat(margin))) +
                  Number.EPSILON) *
                  100
              ) / 100
            ).toFixed(2)}
          </p>
        </div>
      </button>
    </>
  );
};

export default DoorSpecifications;
