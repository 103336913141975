import React from "react";

const GrangerYardMap = () => {
  return (
    <>
      <div className="flex flex-col w-full mt-4 rounded-2xl items-center p-2 border dark:border-gray-500 dark:bg-white/5 bg-white/95 border-black dark:border-white">
        <p className="font-black text-2xl text-black dark:text-white">
          Granger Lumber Yard Map
        </p>
      </div>
      {/* Sample image for now */}
      <div className="flex justify-center m-4">
        <img
          src={require("../../assets/Granger_Lumber_Yard.png")}
          width="850"
        ></img>
      </div>
    </>
  );
};

export default GrangerYardMap;
