import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectPins, storePins } from "../../features/pinnedSlice";
import ExteriorOrders from "../../views/Dashboard/WindowDoor/ExteriorOrders";
import DashboardHome from "../../views/Dashboard/WindowDoor/RequestList";
import Summary from "../../views/Webleads/Summary";
import SummaryBranchSpecific from "../../views/Webleads/SummaryBranchSpecific";
import WebLead from "../../views/Webleads/WebLead";
import ManagerLeads from "../../views/Webleads/ManagerLeads";
import SalesLeads from "../../views/Webleads/SalesLeads";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Dialog from "@mui/material/Dialog";
import { canPin } from "../Navigation/utility";
import { selectRoles } from "../../features/roleSlice";
import { useDispatch } from "react-redux";
import { selectUser } from "../../features/userSlice";
import Weather from "../../views/Dashboard/Weather";
import PinnedPageHandler from "./PinnedPageHandler";

const PinnedPages = () => {
  const pins = useSelector(selectPins);
  const [open, setOpen] = useState(false);
  const [openPins, setOpenPins] = useState(false);
  const role = useSelector(selectRoles);
  const [size, setSize] = useState(null);
  const [pin, setPin] = useState(null);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPins(false);
  };

  const handleSize = (size) => {
    setSize(size);
    setOpen(false);
    setOpenPins(true);
  };
  const handlePins = (item) => {
    setPin(item.name);
    setOpenPins(false);
  };

  useEffect(() => {
    if (pin !== null) {
      savePinned();
      setPin(null);
    }
  }, [pin]);

  const savePinned = async () => {
    var data = JSON.stringify({
      id: user.id,
      item: pin,
      size: size,
    });

    const rawResponse = await fetch("/api/favorites/pin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const pinData = await rawResponse.json();
    window.location.reload(false);

    dispatch(storePins(pinData));
  };

  const checkRoles = (neededRoles, myRoles) => {
    if (neededRoles[0] === "*") {
      return true;
    }

    // Convert both arrays to sets for easier comparison
    const neededRolesSet = new Set(neededRoles);
    const myRolesSet = new Set(myRoles);

    // Check if any role in neededRolesSet is present in myRolesSet
    for (const role of neededRolesSet) {
      if (myRolesSet.has(role)) {
        return true; // Role found in myRoles
      }
    }

    return false; // No role found in myRoles
  };

  return (
    <div className="flex w-full flex-col flex-wrap items-center">
      <div className="bg-white dark:bg-white/10 p-2 justify-center dark:text-white border border-black dark:border-white w-full">
        <h2 className="text-center font-black text-xl">Pinned Pages</h2>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        className="w-full"
        open={open}
        onClose={handleClose}
      >
        <p className="flex justify-center ml-4 mr-4 border-b border-black ">
          Select Pin Size
        </p>

        <button
          className="m-1 border border-black rounded bg-black/20 hover:bg-black/50"
          onClick={() => {
            handleSize("full");
          }}
        >
          Full Screen
        </button>
        <div className="flex justify-center">
          <button
            className="m-1 mb-8 border border-black rounded bg-black/20 hover:bg-black/50 w-1/2"
            onClick={() => {
              handleSize("half");
            }}
          >
            Half Screen
          </button>
        </div>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        className="w-full"
        open={openPins}
        onClose={handleClose}
      >
        <p className="flex justify-center ml-4 mr-4 border-b border-black ">
          Select Page
        </p>
        {canPin.map((item, index) => {
          if (checkRoles(item.roles, role?.roles)) {
            return (
              <button
                key={item.name}
                className="m-1 border border-black rounded bg-black/20 hover:bg-black/50"
                onClick={() => {
                  handlePins(item);
                }}
              >
                {item.name}
              </button>
            );
          }
        })}
      </Dialog>
      <div className="flex flex-wrap justify-center w-full">
        {pins?.pins?.map((item, index) => {
          var size = item.size;
          var item = item.item;

          switch (item) {
            case "My Exterior Orders":
              return (
                <PinnedPageHandler
                  item={item}
                  size={size}
                  key={item.item}
                  component={<ExteriorOrders />}
                  link={"/windowdoor/exteriororders"}
                />
              );
            case "Request Manager":
              return (
                <PinnedPageHandler
                  item={item}
                  size={size}
                  key={item.item}
                  component={<DashboardHome />}
                  link={"/windowdoor/admin/requestlist"}
                />
              );

            case "Weather":
              return (
                <PinnedPageHandler
                  item={item}
                  size={size}
                  key={item.item}
                  component={<Weather />}
                  link={"/dashboard/weather"}
                />
              );

            default:
              return null;
          }
        })}
      </div>
      <button
        className="bg-white dark:bg-white/10 p-2 border border-black dark:border-white mt-4 dark:text-white"
        onClick={handleClick}
      >
        <p className="dark:text-white ">Add Pinned Page</p>
        <AddBoxIcon style={{ fontSize: "10rem" }} />
      </button>
    </div>
  );
};

export default PinnedPages;
