import { createSlice } from "@reduxjs/toolkit";

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState: {
    favorite: [],
  },
  reducers: {
    storeFavorites: (state, action) => {
      //console.log(state);
      state.favorite = action.payload;
    },
    destroyFavorites: (state, action) => {
      //console.log(state);
      state.favorite = null;
    },
  },
});

export const { storeFavorites, destroyFavorites } = favoriteSlice.actions;

export const selectFavorites = (state) => state.favorite.favorite;

export default favoriteSlice.reducer;
