import { Button, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { updateLocationManager } from "./Utilities/api";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";

const UpdateLocationInfoModal = (props) => {
  const user = useSelector(selectUser);

  const [managerName, setManagerName] = useState(props.item.managerName);
  const [managerEmail, setManagerEmail] = useState(props.item.managerEmail);
  const [managerPhone, setManagerPhone] = useState(props.item.managerPhone);
  const [generalName, setgeneralName] = useState(props.item.generalName);
  const [generalEmail, setgeneralEmail] = useState(props.item.generalEmail);
  const [generalPhone, setgeneralPhone] = useState(props.item.generalPhone);
  const [emailAlert, setEmailAlert] = useState(props.item.emailAlert);
  const [textAlert, setTextAlert] = useState(props.item.textAlert);

  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-2 ">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            <p className="text-xl font-black">
              Update {props.item.location} Information
            </p>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-1">
                <div className="bg-gray-50 p-2 border-2 rounded-md">
                  <TextField
                    onChange={(e) => {
                      setManagerName(e.target.value);
                    }}
                    value={managerName}
                    id="outlined-basic"
                    label="Manager"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div className="bg-gray-50 p-2 border-2 rounded-md">
                  <TextField
                    className="w-full"
                    onChange={(e) => {
                      setManagerEmail(e.target.value);
                    }}
                    value={managerEmail}
                    id="outlined-basic"
                    label="Manager Email"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-gray-50 p-2 border-2 rounded-md">
                  <TextField
                    onChange={(e) => {
                      setManagerPhone(e.target.value);
                    }}
                    value={managerPhone}
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-gray-50 p-2 border-2 rounded-md">
                  <TextField
                    onChange={(e) => {
                      setEmailAlert(e.target.value);
                    }}
                    value={emailAlert}
                    id="outlined-basic"
                    label="Days Before Email Alert"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-gray-50 p-2 border-2 rounded-md">
                  <TextField
                    onChange={(e) => {
                      setTextAlert(e.target.value);
                    }}
                    value={textAlert}
                    id="outlined-basic"
                    label="Days Before Text Alert"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-gray-50 p-2 border-2 rounded-md">
                  <TextField
                    onChange={(e) => {
                      setgeneralName(e.target.value);
                    }}
                    value={generalName}
                    id="outlined-basic"
                    label="GM Name"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div className="bg-gray-50 p-2 border-2 rounded-md">
                  <TextField
                    className="w-full"
                    onChange={(e) => {
                      setgeneralEmail(e.target.value);
                    }}
                    value={generalEmail}
                    id="outlined-basic"
                    label="GM Email"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-gray-50 p-2 border-2 rounded-md">
                  <TextField
                    onChange={(e) => {
                      setgeneralPhone(e.target.value);
                    }}
                    value={generalPhone}
                    id="outlined-basic"
                    label="GM Phone"
                    variant="outlined"
                  />
                </div>
              </div>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  props.setOpen(false);
                  props.setFlag(!props.flag);
                  props.setIndex(null);
                  updateLocationManager(
                    props.item.location,
                    user,
                    managerName,
                    managerEmail,
                    managerPhone,
                    generalEmail,
                    generalName,
                    generalPhone,
                    emailAlert,
                    textAlert
                  );
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  props.setOpen(false);
                  props.setIndex(null);

                  //console.log(props.item);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateLocationInfoModal;
