import { useState, useEffect } from "react";

export default function useDarkSide() {
  const [theme, setTheme] = useState(localStorage.theme);
  const colorTheme = theme === "dark" ? "dark" : "light";

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(theme);

    localStorage.setItem("theme", theme);
  }, [theme, colorTheme]);

  return [colorTheme, setTheme];
}

export const canPin = [
  {
    name: "My Exterior Orders",
    roles: ["*"],
  },
  {
    name: "Request Manager",
    roles: ["Window Door Manager", "Admin"],
  },
  {
    name: "Door Builder",
    roles: ["Admin"],
  },
  {
    name: "Web Lead Summary",
    roles: [
      "Admin",
      "Executive Team",
      "Web Lead Manager",
      "Area Manager",
      "Manager",
    ],
  },
  {
    name: "Web Lead Branch Summary",
    roles: ["General Manager", "Admin"],
  },
  {
    name: "Web Lead Manager",
    roles: ["Web Lead Manager", "Admin"],
  },
  {
    name: "My Web Leads (Manager)",
    roles: ["Manager", "Admin"],
  },
  {
    name: "My Web Leads (Sales)",
    roles: ["*"],
  },
  {
    name: "Weather",
    roles: ["*"],
  },
];
