import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getForkliftManager,
  getForklifts,
} from "../../../components/Fleet/Forklifts/Utilities/api";
import { capitalizeFirstLetter, normalizeDate } from "../../../utilities";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Dialog, Tooltip } from "@mui/material";
import LoadingSpinner from "../../../components/Other/LoadingSpinner";

const ForkDisplay = () => {
  const { branch } = useParams();
  const [forkliftData, setForkliftData] = useState([]);
  const [managerData, setManagerData] = useState(null);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({});

  useEffect(() => {
    getForklifts(setForkliftData, branch);
  }, [flag]);

  useEffect(() => {
    getForkliftManager(setManagerData, capitalizeFirstLetter(branch));
  }, []);

  useEffect(() => {
    setFlag(!flag);
  }, [open]);

  const getDaysSinceCheckin = (last_checked) => {
    if (last_checked !== "" || last_checked) {
      const today = new Date();
      var lastChecked = new Date(last_checked);
      var diff = Math.abs(lastChecked.getTime() - today.getTime()) / 3600000;
      if (diff <= 12) {
        return "bg-green-200";
      } else if (diff > 12) {
        return "bg-yellow-200";
      } else if (diff > 36) {
        return "bg-red-200";
      } else {
        return "bg-red-200";
      }
    } else {
      return "bg-red-200";
    }
  };

  const handleServiceUpdate = (item) => {
    return (
      <>
        {item.inService ? (
          <div className="flex flex-col items-center p-4">
            <p className="m-2">
              Are you sure you want to mark unit <strong>#{item.unit}</strong>{" "}
              out of service?
            </p>
            <button
              onClick={() => {
                fetch("/api/fleet/forklift/updateService", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    location: capitalizeFirstLetter(branch),
                    unit: item.unit,
                    inService: false,
                  }),
                });

                setOpen(false);
              }}
              className="bg-green-500 p-1 pl-2 pr-2 m-2 border border-black rounded-md"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="flex flex-col">
            <p>
              Are you sure you want to mark unit <strong>#{item.unit}</strong>{" "}
              in service?
            </p>
            <button
              onClick={() => {
                fetch("/api/fleet/forklift/updateService", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    location: capitalizeFirstLetter(branch),
                    unit: item.unit,
                    inService: true,
                  }),
                });

                setOpen(false);
              }}
              className="bg-green-600 p-2 m-2 border border-black rounded-md"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col items-center">
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={() => setOpen(false)}
      >
        {handleServiceUpdate(item)}
      </Dialog>

      <div className="flex flex-col items-center justify-center">
        <h1 className="text-xl font-black">
          {capitalizeFirstLetter(branch)} Forklifts
        </h1>
      </div>
      <div className="flex w-full flex-wrap justify-center">
        <div className="flex flex-col w-1/4 bg-white border rounded-md border-black m-2 p-4 items-center justify-center w-64 m-2">
          <p className="text-xl font-black">Manager Information</p>

          {managerData ? (
            <div className="flex flex-col items-center">
              <div className="m-1 p-1 bg-blue-200/50">
                <p className="font-black">{managerData[0]?.managerName}</p>
                <p>{managerData[0]?.managerEmail}</p>
                <p>{managerData[0]?.managerPhone}</p>
              </div>
              <div className="m-1 p-1 bg-blue-200/50">
                <p className="font-black">{managerData[0]?.generalName}</p>
                <p>{managerData[0]?.generalEmail}</p>
                <p>{managerData[0]?.generalPhone}</p>
              </div>
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </div>
        {forkliftData.map((item, index) => {
          // Display a card for each forklift
          if (branch.toLowerCase() === item.location.toLowerCase()) {
            return (
              <div className="flex flex-col w-1/4 bg-white border rounded-md border-black m-2 p-4 items-center w-64">
                <p className="text-xl font-black">Unit #{item.unit}</p>
                <Link to={`/fleet/forks/entries/${item.unit}`}>
                  <div
                    className={
                      "flex flex-col items-center p-2 rounded-md " +
                      getDaysSinceCheckin(item.lastChecked)
                    }
                  >
                    <p className="font-black">Last Check-In</p>
                    <p>{normalizeDate(item.lastChecked)}</p>

                    <ArrowOutwardIcon className="text-black " />
                  </div>
                </Link>

                <div className="flex justify-center mt-4">
                  {item.inService ? (
                    <>
                      <Link
                        to={`/fleet/forks/forkliftform/${branch}/${item.unit}?m=${item.moffett}`}
                      >
                        <button className="bg-blue-200 p-2 m-2 border border-black rounded-md">
                          Check In
                        </button>
                      </Link>
                      <button
                        onClick={() => {
                          setItem(item);
                          setOpen(true);
                        }}
                        className="bg-green-600 p-2 m-2 border border-black rounded-md"
                      >
                        In Service
                      </button>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Not Currently In Service">
                        <button className="bg-gray-200 p-2 m-2 border border-black rounded-md cursor-not-allowed">
                          Check In
                        </button>
                      </Tooltip>
                      <button
                        onClick={() => {
                          setItem(item);
                          setOpen(true);
                        }}
                        className="bg-red-500 p-2 m-2 border border-black rounded-md"
                      >
                        Out of Service
                      </button>
                    </>
                  )}
                </div>
                {item.moffett && <p>Moffett</p>}
              </div>
            );
          }

          return;
        })}
      </div>
    </div>
  );
};

export default ForkDisplay;
