import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  Button,
  Tooltip,
  Dialog,
  Switch,
} from "@mui/material";
import MaterialTable from "material-table";
import { getCertData, normalizeDate_notime } from "../../utilities";
import { tableIcons } from "../../components/Utilities/UtilitiesWD";
import LoadingSpinner from "../../components/Other/LoadingSpinner";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { useNavigate } from "react-router-dom";

const TaxCert = (props) => {
  const defaultMaterialTheme = createTheme();
  const [certData, setCertData] = useState(null);
  const [image, setImage] = useState(null);
  const [imageOpen, setImageOpen] = useState(false);
  const [showExpired, setExpired] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getCertData(setCertData);
  }, []);

  function displayCertData() {
    if (showExpired) {
      return certData.filter((obj) => obj.Status === 1);
    } else {
      return certData;
    }
  }

  return (
    <div className="m-2">
      {image && (
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={imageOpen}
          onClose={() => setImageOpen(false)}
        >
          <div className="flex w-full justify-center m-4">
            <img className="rounded-md w-100 h-80" src={"file://" + image} />
          </div>
        </Dialog>
      )}
      {certData ? (
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            style={{
              backgroundColor:
                window.document.documentElement.classList[0] === "dark"
                  ? "rgba(255, 255, 255, 0.05)"
                  : "rgba(255, 255, 255, 1)",
              color:
                window.document.documentElement.classList[0] === "dark"
                  ? "white"
                  : "black",
            }}
            columns={[
              {
                title: "State",
                field: "State",
              },
              {
                title: "Customer Code",
                field: "Customer Code",
              },
              {
                title: "Name",
                field: "Name",
              },
              {
                title: "Job Code",
                field: "Job Code",
              },
              {
                title: "Job Ref",
                field: "Job Ref",
              },
              {
                title: "Certificate Type",
                field: "Certificate Type",
              },
              {
                title: "Reason",
                field: "Reason IN",
                render: (rowData) => {
                  return (
                    <div className="flex">
                      {rowData["Reason IN"] && <p>{rowData["Reason IN"]}</p>}
                      {rowData["Reason MI"] && <p>{rowData["Reason MI"]}</p>}
                      {rowData["Reason OH"] && <p>{rowData["Reason OH"]}</p>}
                    </div>
                  );
                },
              },
              {
                title: "Form Received",
                field: "Form Received",
                render: (rowData) => {
                  return (
                    <p>{normalizeDate_notime(rowData["Form Received"])}</p>
                  );
                },
              },
              {
                title: "Expiration Date",
                field: "Expiration Date",
                render: (rowData) => {
                  if (rowData["Expiration Date"]) {
                    return (
                      <p>{normalizeDate_notime(rowData["Expiration Date"])}</p>
                    );
                  } else {
                    return <p>Never</p>;
                  }
                },
              },
              {
                title: "Notes",
                field: "Notes",
              },
              {
                title: "CERT",
                field: "Cert",
                render: (rowData) => {
                  return (
                    <div className="flex ">
                      <Tooltip title="View Certificate">
                        <a
                          href={`https://cloud.bigclumber.com:3001/getcustomertaxexemptcerts/image?file=${rowData["Cert"]}`}
                          target="_blank"
                          className="flex justify-center"
                        >
                          <VisibilityIcon />
                        </a>
                      </Tooltip>
                      {rowData["Status"] == 1 && (
                        <Tooltip title="Certificate Expired">
                          <DangerousIcon className="text-red-800" />
                        </Tooltip>
                      )}
                    </div>
                  );
                },
              },
            ]}
            data={displayCertData()}
            title={
              <div className="flex justify-center items-center w-full">
                <p className="m-2">Customer Certificates</p>
                <div className="flex flex-col items-center absolute top-2 left-72">
                  <Switch
                    checked={showExpired}
                    onChange={() => setExpired(!showExpired)}
                  />
                  <p className="text-xs">Expired Only</p>
                </div>
              </div>
            }
            options={{
              exportButton: true,
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              pageSizeOptions: [5, 10, 15, 25],
              search: true,
              sorting: true,
              headerStyle: {
                search: true,
                backgroundColor:
                  window.document.documentElement.classList[0] === "dark"
                    ? "rgba(255, 255, 255, 0.05)"
                    : "rgba(255, 255, 255, 1)",
                color:
                  window.document.documentElement.classList[0] === "dark"
                    ? "white"
                    : "black",
              },
              searchFieldStyle: {
                color:
                  window.document.documentElement.classList[0] === "dark"
                    ? "white"
                    : "black",
                borderBottom:
                  window.document.documentElement.classList[0] === "dark"
                    ? "1px solid white"
                    : "1px solid black",
              },

              rowStyle: (rowData) => {
                return {
                  fontSize: 14,
                };
              },
              sortIcon: "show",
            }}
            icons={tableIcons}
            localization={{
              body: {
                emptyDataSourceMessage: "No Leads Were Found",
              },
            }}
          />
        </ThemeProvider>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default TaxCert;
