export const insertContractForm = async (
  user,
  estimateHeader,
  estimateInfo
) => {
  var data = JSON.stringify({
    docID: makeid(5),
    latestVersion: 1,
    formID: 1,
    userID: user.userID,
    submitDate: new Date(),
    header: estimateHeader,
    info: estimateInfo,
    user: user,
  });

  const rawResponse = await fetch("/api/forms/misc/addContract", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const insertEditedContractForm = async (
  user,
  estimateHeader,
  estimateInfo,
  docID
) => {
  var data = JSON.stringify({
    docID: docID,
    latestVersion: 1,
    formID: 1,
    userID: user.userID,
    submitDate: new Date(),
    header: estimateHeader,
    info: estimateInfo,
    user: user,
  });

  const rawResponse = await fetch("/api/forms/misc/editContract", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const sendContractPDF = async (emailData) => {
  //console.log(emailData[0]);

  var data = JSON.stringify(emailData);
  const rawResponse = await fetch("/api/forms/misc/sendContract", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const getContract = async (setter, docID) => {
  return fetch(`/api/forms/misc/getContract?docID=${docID}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson[0]);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const importQuote = async (setter, quote) => {
  return fetch(`/api/forms/misc/importQuote?QuoteNumber=${quote}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson.recordsets[0]);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllContracts = async (setter, user) => {
  return fetch(`/api/forms/misc/getAllContracts?user=${user.userID}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const makeid = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getDrafts = async (setter, user) => {
  return fetch(`/api/forms/misc/getDrafts?user=${user.userID}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllInteriorDoorEntries = async (setter) => {
  return fetch(`/api/forms/misc/getForms/interiordoororders`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllRecognitions = async (setter) => {
  return fetch(`/api/forms/misc/getForms/recognition`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getForms = async (setter, user) => {
  return fetch(`/api/forms/misc/getForms?user=${user.userID}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};
