import React, { useEffect, useState } from "react";
import { getAllOnlineUsers } from "../../views/Admin/Utilities/api";
import { Tooltip } from "@mui/material";
import LoadingSpinner from "../Other/LoadingSpinner";

const CurrentlyOnlineFooter = () => {
  const [onlineUsers, setOnlineUsers] = useState(null);

  useEffect(() => {
    //Get all users that are currently online
    getAllOnlineUsers(setOnlineUsers);
  }, []);

  //Display all users that are currently online
  return (
    <div>
      {onlineUsers ? (
        <div className="flex flex-col items-center">
          <p className="items-center dark:text-white">
            Currently Online ({onlineUsers?.length})
          </p>
          <div className="flex flex-col items-center w-64 dark:text-white overflow-auto">
            <div className="flex ml-8">
              {onlineUsers &&
                onlineUsers.map((user) => (
                  <Tooltip
                    key={user?.name}
                    title={`${user?.name} (${user?.title})`}
                    arrow
                  >
                    <div className="w-8 h-8 bg-gray-300 rounded-full overflow-hidden border-green-400 border m-1">
                      <img
                        src={`/api/profile/image?id=${user?.id}`}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  </Tooltip>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default CurrentlyOnlineFooter;
