import React from "react";
import { Link } from "react-router-dom";
import NotFoundSVG from "./NotFoundSVG";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        <NotFoundSVG />
      </div>

      <div className="flex flex-col justify-center">
        <h1 className="text-9xl font-bold">404</h1>
        <p className="text-2xl font-bold">Page Not Found</p>
        <Link to="/" className="text-xl font-bold hover:underline">
          <div className="flex justify-center m-2 bg-blue-200/50 p-2 rounded border-black border">
            Go to Home
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
