import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  acceptRequest,
  addToJourney,
  getOrderInfo,
  getRequestInfo,
  sendUserNotification,
} from "../../../../utilities";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Tooltip } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import NumbersIcon from "@mui/icons-material/Numbers";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ErrorID from "../../../../components/Other/ErrorID";

const AcceptRequest = () => {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [requestData, setReqData] = useState("");
  const [notes, setNotes] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getOrderInfo(id, setData);
    getRequestInfo(id, setReqData);
  }, []);

  useEffect(() => {
    //console.log(requestData);
  }, [requestData]);

  return (
    <>
      {requestData.error && (
        <div>
          <ErrorID id={id} />
        </div>
      )}
      <div className="w-full flex justify-center p-8">
        <div className="m-2 w-full md:mt-0 md:col-span-2">
          <div className="shadow sm:rounded-md sm:overflow-hidden border border-gray-950 bg-white text-black dark:border-gray-500 dark:bg-white/5 dark:text-white">
            <div className="px-4 py-5 space-y-6 sm:p-6">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <label
                    for="company_website"
                    className="block text-sm font-medium text-black dark:text-white"
                  >
                    Accept Request - ID {id}
                  </label>
                </div>

                <div className="col-span-1">
                  <div className="p-2 border rounded-md text-black dark:text-white">
                    <Tooltip title="Customer">
                      <div>
                        <PersonOutlineIcon />
                        {data.Customer}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="p-2 border rounded-md text-black dark:text-white">
                    <Tooltip title="Order Number">
                      <div>
                        <NumbersIcon />
                        {data.OrderNumber}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="p-2 border rounded-md text-black dark:text-white">
                    <Tooltip title="Job Ref">
                      <div>
                        <WorkIcon />
                        {data["Job Ref"]}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="p-2 border rounded-md text-black dark:text-white">
                    <Tooltip title="Deliver To Address">
                      <div>
                        <HomeIcon />
                        {data["Deliver To"]}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <label
                    for="company_website"
                    className="block text-sm font-medium text-black dark:text-white"
                  >
                    Request Information
                  </label>
                </div>

                <div className="col-span-1">
                  <div className="p-2 border rounded-md">
                    <Tooltip title="Request Made By">
                      <div>
                        <PersonOutlineIcon />
                        {requestData?.requestName}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="p-2 border rounded-md">
                    <Tooltip title="Date Requested">
                      <div>
                        <CalendarMonthIcon />
                        {requestData?.requestDate}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="p-2 border rounded-md">
                    <Tooltip title="Notes">
                      <div>
                        <NoteAltIcon />
                        {requestData?.notes !== ""
                          ? requestData?.notes
                          : "No Provided Notes"}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div>
                <label
                  for="notes"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  Notes
                </label>
                <div className="mt-1">
                  <textarea
                    id="notes"
                    name="notes"
                    rows="3"
                    className="shadow-sm border border-gray-950 bg-white text-black dark:border-gray-500 dark:bg-white/5 dark:text-white p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="enter optional notes"
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                    value={notes}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="flex justify-center px-4 py-3 text-right sm:px-6">
              {requestData.requestStatus === 0 && (
                <Button
                  color="success"
                  variant="outlined"
                  onClick={() => {
                    sendUserNotification(
                      requestData?.requestEmail,
                      "System",
                      `Your Request For Order #${requestData.salesOrder} Has Been Accepted`,
                      "Delivery Request Accepted"
                    );
                    acceptRequest(
                      id,
                      notes,
                      requestData.requestDate.replaceAll("/", "-"),
                      requestData.orderID
                    );
                    addToJourney(
                      requestData.requestDate.replaceAll("/", "-"),
                      requestData.orderID
                    );
                    navigate("/windowdoor/admin/requestlist");
                  }}
                >
                  Accept
                </Button>
              )}
              {requestData.requestStatus !== 0 && (
                <Tooltip title="This Request Was Already Accepted/Rejected Mistake? Please Contact HelpDesk.">
                  <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black dark:text-white bg-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled">
                    Accept
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptRequest;
