import * as React from "react";
const HazeSVG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 0 64 64" {...props}>
    <defs>
      <filter id="blur">
        <feGaussianBlur in="SourceAlpha" stdDeviation={3} />
        <feOffset dx={0} dy={4} result="offsetblur" />
        <feComponentTransfer>
          <feFuncA slope={0.05} type="linear" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <style>
        {
          "@keyframes am-weather-haze-1{0%,to{transform:translate(0,0)}50%{transform:translate(7px,0)}}@keyframes am-weather-haze-2{0%,to{transform:translate(0,0)}21.05%{transform:translate(-6px,0)}78.95%{transform:translate(9px,0)}}@keyframes am-weather-haze-3{0%,to{transform:translate(0,0)}25%{transform:translate(4px,0)}75%{transform:translate(-4px,0)}}@keyframes am-weather-haze-4{0%,to{transform:translate(0,0)}50%{transform:translate(-4px,0)}}"
        }
      </style>
    </defs>
    <g transform="translate(16,-2)" filter="url(#blur)">
      <g
        className="am-weather-haze"
        transform="translate(-10,20)"
        fill="none"
        stroke="#cd9e73"
        strokeLinecap="round"
        strokeWidth={2}
      >
        <line
          className="am-weather-haze-1"
          y1={0}
          y2={0}
          x1={1}
          x2={37}
          strokeDasharray="3, 5, 17, 5, 7"
          style={{
            WebkitAnimationName: "am-weather-haze-1",
            MozAnimationName: "am-weather-haze-1",
            msAnimationName: "am-weather-haze-1",
            animationName: "am-weather-haze-1",
            WebkitAnimationDuration: "8s",
            MozAnimationDuration: "8s",
            msAnimationDuration: "8s",
            animationDuration: "8s",
            WebkitAnimationTimingFunction: "linear",
            MozAnimationTimingFunction: "linear",
            msAnimationTimingFunction: "linear",
            animationTimingFunction: "linear",
            WebkitAnimationIterationCount: "infinite",
            MozAnimationIterationCount: "infinite",
            msAnimationIterationCount: "infinite",
            animationIterationCount: "infinite",
          }}
        />
        <line
          className="am-weather-haze-2"
          y1={5}
          y2={5}
          x1={9}
          x2={33}
          strokeDasharray="11, 7, 15"
          style={{
            WebkitAnimationName: "am-weather-haze-2",
            MozAnimationName: "am-weather-haze-2",
            msAnimationName: "am-weather-haze-2",
            animationName: "am-weather-haze-2",
            WebkitAnimationDuration: "20s",
            MozAnimationDuration: "20s",
            msAnimationDuration: "20s",
            animationDuration: "20s",
            WebkitAnimationTimingFunction: "linear",
            MozAnimationTimingFunction: "linear",
            msAnimationTimingFunction: "linear",
            animationTimingFunction: "linear",
            WebkitAnimationIterationCount: "infinite",
            MozAnimationIterationCount: "infinite",
            msAnimationIterationCount: "infinite",
            animationIterationCount: "infinite",
          }}
        />
        <line
          className="am-weather-haze-3"
          y1={10}
          y2={10}
          x1={5}
          x2={40}
          strokeDasharray="11, 7, 3, 5, 9"
          style={{
            WebkitAnimationName: "am-weather-haze-3",
            MozAnimationName: "am-weather-haze-3",
            msAnimationName: "am-weather-haze-3",
            animationName: "am-weather-haze-3",
            WebkitAnimationDuration: "6s",
            MozAnimationDuration: "6s",
            msAnimationDuration: "6s",
            animationDuration: "6s",
            WebkitAnimationTimingFunction: "linear",
            MozAnimationTimingFunction: "linear",
            msAnimationTimingFunction: "linear",
            animationTimingFunction: "linear",
            WebkitAnimationIterationCount: "infinite",
            MozAnimationIterationCount: "infinite",
            msAnimationIterationCount: "infinite",
            animationIterationCount: "infinite",
          }}
        />
        <line
          className="am-weather-haze-4"
          y1={15}
          y2={15}
          x1={7}
          x2={42}
          strokeDasharray="13, 5, 9, 5, 3"
          style={{
            WebkitAnimationName: "am-weather-haze-4",
            MozAnimationName: "am-weather-haze-4",
            msAnimationName: "am-weather-haze-4",
            animationName: "am-weather-haze-4",
            WebkitAnimationDuration: "6s",
            MozAnimationDuration: "6s",
            msAnimationDuration: "6s",
            animationDuration: "6s",
            WebkitAnimationTimingFunction: "linear",
            MozAnimationTimingFunction: "linear",
            msAnimationTimingFunction: "linear",
            animationTimingFunction: "linear",
            WebkitAnimationIterationCount: "infinite",
            MozAnimationIterationCount: "infinite",
            msAnimationIterationCount: "infinite",
            animationIterationCount: "infinite",
          }}
        />
      </g>
    </g>
  </svg>
);
export default HazeSVG;
