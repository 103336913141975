import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  acceptAltDate,
  addToJourney,
  getOrderInfo,
  getRequestInfo,
} from "../../../../utilities";
import { useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import NumbersIcon from "@mui/icons-material/Numbers";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import { Button, Tooltip } from "@mui/material";
import ErrorID from "../../../../components/Other/ErrorID";

const AcceptAlt = () => {
  const { ref } = useParams();
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestData, setReqData] = useState("");

  useEffect(() => {
    getOrderInfo(ref, setData);
    getRequestInfo(ref, setReqData);
  }, []);

  return (
    <div className="w-full flex justify-center p-8">
      <div className="m-2 w-full md:mt-0 md:col-span-2">
        <div className="shadow sm:rounded-md sm:overflow-hidden border border-gray-950 dark:border-gray-500 bg-white dark:bg-white/5 text-black dark:text-white">
          <div className="px-4 py-5 space-y-6 sm:p-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3">
                <div className="flex">
                  <p className="m-2 font-black text-green-500">
                    Accept Alternative Date - {searchParams.get("date")}
                  </p>
                </div>
              </div>

              <div className="col-span-1">
                <div className="p-2 border rounded-md text-black dark:text-white">
                  <Tooltip title="Customer">
                    <div>
                      <PersonOutlineIcon />
                      {data.Customer}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="col-span-1">
                <div className="p-2 border rounded-md text-black dark:text-white">
                  <Tooltip title="Order Number">
                    <div>
                      <NumbersIcon />
                      {data.OrderNumber}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="col-span-1">
                <div className="p-2 border rounded-md text-black dark:text-white">
                  <Tooltip title="Job Ref">
                    <div>
                      <WorkIcon />
                      {data["Job Ref"]}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="col-span-3">
                <div className="p-2 border rounded-md text-black dark:text-white">
                  <Tooltip title="Deliver To Address">
                    <div>
                      <HomeIcon />
                      {data["Deliver To"]}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex justify-center px-4 py-3">
              <Button
                variant="outlined"
                color="success"
                onClick={() => {
                  acceptAltDate(ref, searchParams.get("date"));
                  addToJourney(
                    searchParams.get("date").replaceAll("/", "-"),
                    requestData.orderID
                  );
                  navigate("/");
                }}
              >
                Accept
              </Button>
              {requestData.error && (
                <div>
                  <ErrorID id={ref} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptAlt;
