import React, { useEffect, useState } from "react";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MaterialTable from "material-table";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  ThemeProvider,
  createTheme,
  Alert,
  AlertTitle,
  Divider,
} from "@mui/material";
import { getAllDeliveryRequest } from "../../utilities";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { icons } from "../Utilities/UtilitiesWD";

const RequestDataTable = () => {
  const defaultMaterialTheme = createTheme();
  const [dataStore, setDataStore] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    getAllDeliveryRequest(setDataStore);
  }, []);

  useEffect(() => {
    getAllDeliveryRequest(setDataStore);
  }, [flag]);

  const deleteRequest = async (id) => {
    const data = {
      id: id,
    };

    const rawResponse = await fetch("/api/getAllRequests/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const content = await rawResponse.json();
  };

  return (
    <>
      <div style={{ margin: "10px" }} className="w-full">
        <div style={{ maxWidth: "100%", paddingTop: "12px", margin: "10px" }}>
          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              style={{
                backgroundColor:
                  window.document.documentElement.classList[0] === "dark"
                    ? "rgba(255, 255, 255, 0.05)"
                    : "rgba(255, 255, 255, 1)",
                color:
                  window.document.documentElement.classList[0] === "dark"
                    ? "white"
                    : "black",
              }}
              columns={[
                {
                  title: "Order#",
                  field: "salesOrder",
                },
                {
                  title: "From",
                  field: "requestName",
                },
                {
                  title: "Submit Date",
                  field: "requestSubmitted",
                },
                {
                  title: "Request Date",
                  field: "requestDate",
                  render: (rowData) => {
                    if (rowData["earliestAvailable"]) {
                      return <p>Earliest Available</p>;
                    } else {
                      return <p>{rowData.requestDate}</p>;
                    }
                  },
                },
                {
                  title: "Customer",
                  field: "customer",
                },
                {
                  title: "Job",
                  field: "job",
                },
                {
                  title: "Action",
                  field: "Action",
                  render: (rowData) => {
                    if (rowData["earliestAvailable"]) {
                      return (
                        <>
                          <Tooltip
                            className="text-red-400"
                            title="Delete Request"
                          >
                            <button
                              onClick={() => {
                                deleteRequest(rowData._id);
                                setFlag(!flag);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title="Schedule Request">
                            <Link
                              to={`/schEarliest/${rowData._id}`}
                              className="text-sm font-bold uppercase text-white m-2"
                            >
                              <CalendarMonthIcon color="secondary" />
                            </Link>
                          </Tooltip>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Tooltip
                            className="text-red-400"
                            title="Delete Request"
                          >
                            <button
                              onClick={() => {
                                deleteRequest(rowData._id);
                                setFlag(!flag);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title="Accept Request">
                            <Link
                              to={`/acceptRequest/${rowData._id}`}
                              className="text-sm font-bold uppercase text-white m-2"
                            >
                              <CheckBoxIcon color="success" />
                            </Link>
                          </Tooltip>
                          <Tooltip title="Reject Request">
                            <Link
                              to={`/rejectRequest/${rowData._id}`}
                              className="text-sm font-bold uppercase text-white m-2"
                            >
                              <CancelPresentationIcon sx={{ color: "red" }} />
                            </Link>
                          </Tooltip>
                        </>
                      );
                    }
                  },
                },
              ]}
              data={dataStore.slice().reverse()}
              title={`Open Requests`}
              icons={icons}
              options={{
                pageSize: 10, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [5, 10, 15, 25],
                headerStyle: {
                  search: true,
                  backgroundColor:
                    window.document.documentElement.classList[0] === "dark"
                      ? "rgba(255, 255, 255, 0.05)"
                      : "rgba(255, 255, 255, 1)",
                  color:
                    window.document.documentElement.classList[0] === "dark"
                      ? "white"
                      : "black",
                },
                searchFieldStyle: {
                  color:
                    window.document.documentElement.classList[0] === "dark"
                      ? "white"
                      : "black",
                  borderBottom:
                    window.document.documentElement.classList[0] === "dark"
                      ? "1px solid white"
                      : "1px solid black",
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: 14,
                  };
                },
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <div className="flex justify-center">
                      <Alert severity="success">
                        <div>
                          <AlertTitle>No Open Requests</AlertTitle>
                          If you think this is a mistake please contact{" "}
                          <strong>Help Desk</strong>.
                        </div>
                        <Divider />
                      </Alert>
                    </div>
                  ),
                },
              }}
            />
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default RequestDataTable;
