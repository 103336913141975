import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { Dialog, Tooltip } from "@mui/material";
import { branches } from "../../WebleadsV2/utilities";

const DeliveryIssueForm = () => {
  const [formData, setFormData] = useState({ errors: [] });
  const [status, setStatus] = useState(false);
  const [team, setTeam] = useState(null);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      setFormData({ ...formData, submit_user: user.name });
    }
  }, [user]);

  const submitForm = async (data) => {
    const rawResponse = await fetch("/api/forms/submit/deliveryIssue", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const content = await rawResponse.json();

    if (content.success === 1) {
      setStatus(true);
      return;
    } else {
      setStatus(false);
      return;
    }
  };

  const removeItem = (indexToRemove) => {
    setFormData((prevState) => ({
      errors: prevState.errors.filter((_, index) => index !== indexToRemove),
    }));
  };

  return (
    <div className="flex flex-col w-full dark:text-white items-center justify-center">
      {user && (
        <div className="flex flex-col w-3/4">
          <div className="flex w-full justify-center m-2">
            <h1 className="font-black text-xl">Delivery Error Tracking</h1>
          </div>
          <div>
            <div className="flex flex-col w-1/2">
              <p className="font-black">Sales Order Number</p>
              <input
                type="text"
                className="w-full"
                placeholder="Sales Order #"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    salesorder: e.target.value,
                  });
                }}
              />
            </div>
            <div className="flex flex-col w-full mt-1">
              <p className="font-black">Branch</p>
              <select
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    branch: e.target.value,
                  });
                }}
                className="w-3/4"
              >
                <option></option>
                {branches.map((item) => {
                  if (item !== "All Locations") {
                    return <option value={item}>{item}</option>;
                  }
                })}
              </select>
            </div>
            <div className="flex flex-col mt-1">
              <p className="font-black">Delivery Error Type</p>
              <select
                onChange={(e) => {
                  if (e.target.value !== "Other") {
                    setFormData({
                      ...formData,
                      errors: [...formData.errors, e.target.value],
                    });
                  }
                }}
                className="w-3/4"
              >
                <option></option>
                <option value="Missing contact information for homeowner">
                  Missing contact information for homeowner
                </option>
                <option value="Incorrect address">Incorrect address</option>
                <option value="Lack of placement instructions">
                  Lack of placement instructions
                </option>
                <option value="Wrong material delivered">
                  Wrong material delivered
                </option>
                <option value="Inefficient material transfer/pickup">
                  Inefficient material transfer/pickup
                </option>
                <option value="QR codes not utilized for returns; no attached pictures">
                  QR codes not utilized for returns; no attached pictures
                </option>
                <option value="Inadequate load checks">
                  Inadequate load checks
                </option>
                <option value="Misunderstood pickup instructions">
                  Misunderstood pickup instructions
                </option>
                <option value="Unclear salesman identification">
                  Unclear salesman identification
                </option>
                <option value="Failure to contact operations manager for add-ons">
                  Failure to contact operations manager for add-ons
                </option>
                <option value="Account payment issue">
                  Account payment issue
                </option>
                <option value="Double billing for shipment">
                  Double billing for shipment
                </option>
                <option value="Poor communication between salesman and contractor">
                  Poor communication between salesman and contractor
                </option>
              </select>
            </div>
            <div className="flex flex-wrap w-full">
              {formData.errors.map((item, index) => {
                return (
                  <Tooltip title="Click to remove.">
                    <div
                      className="flex cursor-pointer border p-1 m-1 rounded bg-slate-400/20 hover:bg-slate-500/30"
                      onClick={() => removeItem(index)}
                    >
                      <p>{item}</p>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
          <div className="flex w-full flex-col">
            <p className="font-black">Additional Comments</p>
            <p className="text-sm italic">
              Please provide any other information you feel would be helpful in
              resolving this issue for our customer.
            </p>

            <textarea
              onChange={(e) => {
                setFormData({ ...formData, comments: e.target.value });
              }}
              placeholder="Explain Here..."
              className="w-3/4 h-40 p-2"
              style={{ resize: "none", overflow: "auto" }}
            />
          </div>

          <div className="flex w-full mt-1">
            <button
              onClick={() => {
                submitForm(formData);
              }}
              className="p-1 bg-sky-900/80 hover:bg-sky-900 rounded text-white"
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <Dialog
        maxWidth="md"
        className="flex w-full justify-center"
        open={status}
        onClose={() => {
          window.location.reload();
        }}
      >
        <div className="p-6 flex justify-center flex-col items-center">
          <p className="text-xl m-2">Form successfully submitted!</p>
          <button
            className="m-1 p-1 bg-blue-500/80 hover:bg-blue-500 rounded text-white"
            onClick={() => {
              window.location.reload();
            }}
          >
            Close
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default DeliveryIssueForm;
