import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  addToJourney,
  getOrderInfo,
  getRequestInfo,
  scheduleEarliestDate,
  sendUserNotification,
} from "../../../../utilities";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button, Tooltip } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import NumbersIcon from "@mui/icons-material/Numbers";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ErrorID from "../../../../components/Other/ErrorID";

const ScheduleEarliest = () => {
  const { ref } = useParams();
  const [notes, setNotes] = useState("");
  const navigate = useNavigate();
  const [requestData, setReqData] = useState("");
  const [dateSelected, setDate] = useState(new Date());
  const [dateFormatted, setFormattedDate] = useState(
    dateSelected.toLocaleDateString("en-US")
  );

  const [data, setData] = useState("");

  useEffect(() => {
    getOrderInfo(ref, setData);
    getRequestInfo(ref, setReqData);
  }, []);

  return (
    <>
      {requestData.error && (
        <div>
          <ErrorID id={ref} />
        </div>
      )}

      <div className="w-full h-full flex justify-center">
        <div className="flex justify-center items-stretch m-4 w-full">
          <div className="mt-5 md:mt-0 md:col-span-2 w-full">
            <div className="shadow sm:rounded-md sm:overflow-hidden border border-gray-950 dark:border-gray-500 bg-white dark:bg-white/5 text-black dark:text-white">
              <div className="px-4 py-5 space-y-6 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <label className="block text-sm font-medium text-green-400">
                      Earliest Request - ID {ref}
                    </label>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 border-2 rounded-md text-black dark:text-white">
                      <Tooltip title="Customer">
                        <div>
                          <PersonOutlineIcon />
                          {data.Customer}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 border-2 rounded-md text-black dark:text-white">
                      <Tooltip title="Order Number">
                        <div>
                          <NumbersIcon />
                          {data.OrderNumber}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 border-2 rounded-md text-black dark:text-white">
                      <Tooltip title="Job Ref">
                        <div>
                          <WorkIcon />
                          {data["Job Ref"]}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="p-2 border-2 rounded-md text-black dark:text-white">
                      <Tooltip title="Deliver To Address">
                        <div>
                          <HomeIcon />
                          {data["Deliver To"]}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <label className="block text-sm font-medium text-black dark:text-white">
                      Request Information
                    </label>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 border-2 rounded-md text-black dark:text-white">
                      <Tooltip title="Request Made By">
                        <div>
                          <PersonOutlineIcon />
                          {requestData?.requestName}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 border-2 rounded-md text-black dark:text-white">
                      <Tooltip title="Date Requested">
                        <div>
                          <CalendarMonthIcon />
                          {requestData?.requestDate}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="p-2 border-2 rounded-md text-black dark:text-white">
                      <Tooltip title="Notes">
                        <div>
                          <NoteAltIcon />
                          {requestData?.notes !== ""
                            ? requestData?.notes
                            : "No Provided Notes"}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <label className="block text-sm font-medium text-black dark:text-white m-2">
                      Schedule Date
                    </label>

                    <div className="flex">
                      <DatePicker
                        selected={dateSelected}
                        minDate={new Date()}
                        onChange={(date) => {
                          setDate(date);
                          setFormattedDate(date.toLocaleDateString("en-US"));
                        }}
                        className="border border-gray-500 bg-white/5 dark:text-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black dark:text-white">
                    Notes
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="notes"
                      name="notes"
                      rows="3"
                      className="shadow-sm border border-gray-500 bg-white/5 text-black dark:text-white p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="enter optional notes"
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                      value={notes}
                    ></textarea>
                  </div>
                </div>
                <div className="flex justify-center text-right sm:px-6">
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => {
                      scheduleEarliestDate(ref, notes, dateFormatted);
                      sendUserNotification(
                        requestData?.requestEmail,
                        "System",
                        `Your Request For Order #${requestData.salesOrder} Has Been Scheduled For ${dateFormatted}`,
                        "Earliest Request Scheduled"
                      );
                      addToJourney(
                        dateFormatted.replaceAll("/", "-"),
                        requestData.orderID
                      );
                      navigate("/windowdoor/admin/requestlist");
                    }}
                  >
                    Schedule Delivery
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleEarliest;
