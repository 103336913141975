import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  Circle,
  useJsApiLoader,
} from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  center,
  containerStyle,
  getAllWebLeads,
  storeCoords,
} from "../../components/WebLeads/utilities";
import LoadingSpinner from "../../components/Other/LoadingSpinner";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import GreenX from "../../assets/PinImages/green-dotX.png";
import GreenCheck from "../../assets/PinImages/green-dotCheck.png";
import YellowX from "../../assets/PinImages/yellow-dotX.png";
import YellowCheck from "../../assets/PinImages/yellow-dotCheck.png";
import RedX from "../../assets/PinImages/red-dotX.png";
import RedCheck from "../../assets/PinImages/red-dotCheck.png";
import BlueX from "../../assets/PinImages/blue-dotX.png";
import BlueCheck from "../../assets/PinImages/blue-dotCheck.png";
import PurpleX from "../../assets/PinImages/purple-dotX.png";
import PurpleCheck from "../../assets/PinImages/purple-dotCheck.png";
import OrangeX from "../../assets/PinImages/orange-dotX.png";
import OrangeCheck from "../../assets/PinImages/orange-dotCheck.png";
import PinkX from "../../assets/PinImages/pink-dotX.png";
import PinkCheck from "../../assets/PinImages/pink-dotCheck.png";
import LightBlueX from "../../assets/PinImages/ltblue-dotX.png";
import LightBlueCheck from "../../assets/PinImages/ltblue-dotCheck.png";
import { getLeadsV2 } from "../WebleadsV2/utilities";

const WebLeadMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBTcKskEQngUy4qAvParxaR87TYmt-cwvE",
  });

  const [map, setMap] = useState(null);
  const [customers, setCustomers] = useState(null);

  const [active, setActive] = useState(null);
  const [completed, setCompleted] = useState(null);
  const [deleted, setDeleted] = useState(null);

  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedStoreName, setSelectedStoreName] = useState(null);
  const [datePickerShown, setDatePickerShown] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [showDeleted, setShowDeleted] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleStoreClick = (storeName) => {
    setSelectedStoreName((prevStoreName) =>
      prevStoreName === storeName ? null : storeName
    );
  };

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const handleMarkerClick = (customer) => {
    setSelectedCustomer(customer);
    setInfoWindowOpen(true);
  };

  const handleCloseInfoWindow = () => {
    setSelectedCustomer(null);
    setInfoWindowOpen(false);
  };

  const getColoredMarker = (customer, deleted, completed) => {
    if (deleted) {
      switch (customer.customerWorkingOn) {
        case "Siding / Roofing":
          return GreenX;
        case "Lumber / Building Materials":
          return YellowX;
        case "Deck Materials":
          return RedX;
        case "Trusses / Panels":
          return BlueX;
        case "Doors / Windows":
          return PurpleX;
        case "Cabinetry":
          return OrangeX;
        case "Insulation":
          return PinkX;
        default:
          return LightBlueX;
      }
    } else if (completed) {
      switch (customer.customerWorkingOn) {
        case "Siding / Roofing":
          return GreenCheck;
        case "Lumber / Building Materials":
          return YellowCheck;
        case "Deck Materials":
          return RedCheck;
        case "Trusses / Panels":
          return BlueCheck;
        case "Doors / Windows":
          return PurpleCheck;
        case "Cabinetry":
          return OrangeCheck;
        case "Insulation":
          return PinkCheck;
        default:
          return LightBlueCheck;
      }
    } else {
      switch (customer.customerWorkingOn) {
        case "Siding / Roofing":
          return "https://maps.google.com/mapfiles/ms/icons/green-dot.png";
        case "Lumber / Building Materials":
          return "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
        case "Deck Materials":
          return "https://maps.google.com/mapfiles/ms/icons/red-dot.png";
        case "Trusses / Panels":
          return "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
        case "Doors / Windows":
          return "https://maps.google.com/mapfiles/ms/icons/purple-dot.png";
        case "Cabinetry":
          return "https://maps.google.com/mapfiles/ms/icons/orange-dot.png";
        case "Insulation":
          return "https://maps.google.com/mapfiles/ms/icons/pink-dot.png";
        default:
          return "http://maps.gstatic.com/mapfiles/ms2/micons/ltblue-dot.png";
      }
    }
  };

  const getCircleOptions = () => ({
    strokeColor: "red",
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: "red",
    fillOpacity: 0.05,
  });

  useEffect(() => {
    getLeadsV2(setActive, "Active", null, "ALL");
    getLeadsV2(setCompleted, "Completed", null, "ALL");
    getLeadsV2(setDeleted, "Deleted", null, "ALL");
  }, []);

  useEffect(() => {
    if (completed && deleted && active) {
      setCustomers([...active, ...completed, ...deleted]);
    }
  }, [active, completed, deleted]);

  const handleFilterChange = (filterType, filterValue) => {
    switch (filterType) {
      case "completed":
        setShowCompleted((prevShowCompleted) => !prevShowCompleted);
        break;
      case "deleted":
        setShowDeleted((prevShowDeleted) => !prevShowDeleted);
        break;
      case "lookingFor":
        setSelectedFilters((prevFilters) => {
          if (prevFilters.includes(filterValue)) {
            return prevFilters.filter((filter) => filter !== filterValue);
          } else {
            return [...prevFilters, filterValue];
          }
        });
        break;
      default:
        break;
    }
  };

  const getFilteredCustomersCount = () => {
    const filteredCustomers = customers.filter((customer) => {
      const filterByLookingFor =
        selectedFilters.length === 0 ||
        selectedFilters.includes(customer?.webleadEntry.customerWorkingOn);

      const filterByDeleted =
        showDeleted || customer?.leadProgress.leadStatus.deleted.status !== 1;

      const filterByCompleted =
        showCompleted ||
        customer?.leadProgress.leadStatus.completed.status !== 1;

      const filterByDateRange =
        (!startDate ||
          new Date(customer?.webleadEntry.entryDate) >= startDate) &&
        (!endDate || new Date(customer?.webleadEntry.entryDate) <= endDate);

      return (
        filterByLookingFor &&
        filterByDeleted &&
        filterByCompleted &&
        (!startDate || !endDate || filterByDateRange)
      );
    });

    return filteredCustomers.length;
  };

  return isLoaded && customers ? (
    <div className="flex flex-col dark:text-white">
      <div className="w-full m-2">
        {customers?.length} Customers Loaded | {getFilteredCustomersCount()}{" "}
        Pins Showing
      </div>
      <div className="flex flex-col items-center">
        <h3 className="text-lg font-semibold">Filter Options (Looking For):</h3>
        <div className="flex">
          <label className="m-2">
            <input
              className="m-1"
              type="checkbox"
              name="nameOption"
              value="Lumber / Building Materials"
              onChange={() =>
                handleFilterChange("lookingFor", "Lumber / Building Materials")
              }
            />
            Lumber / Building Materials
          </label>
          <label className="m-2">
            <input
              className="m-1"
              type="checkbox"
              name="nameOption"
              value="Deck Materials"
              onChange={() =>
                handleFilterChange("lookingFor", "Deck Materials")
              }
            />
            Deck Materials
          </label>
          <label className="m-2">
            <input
              className="m-1"
              type="checkbox"
              name="nameOption"
              value="Insulation"
              onChange={() => handleFilterChange("lookingFor", "Insulation")}
            />
            Insulation
          </label>
          <label className="m-2">
            <input
              className="m-1"
              type="checkbox"
              name="nameOption"
              value="Doors / Windows"
              onChange={() =>
                handleFilterChange("lookingFor", "Doors / Windows")
              }
            />
            Doors / Windows
          </label>
          <label className="m-2">
            <input
              className="m-1"
              type="checkbox"
              name="nameOption"
              value="Siding / Roofing"
              onChange={() =>
                handleFilterChange("lookingFor", "Siding / Roofing")
              }
            />
            Siding / Roofing
          </label>
          <label className="m-2">
            <input
              className="m-1"
              type="checkbox"
              name="nameOption"
              value="Cabinetry"
              onChange={() => handleFilterChange("lookingFor", "Cabinetry")}
            />
            Cabinetry
          </label>
          <label className="m-2">
            <input
              className="m-1"
              type="checkbox"
              name="nameOption"
              value="Post Frame Buildings"
              onChange={() =>
                handleFilterChange("lookingFor", "Post Frame Buildings")
              }
            />
            Post Frame Buildings
          </label>
          <label className="m-2">
            <input
              className="m-1"
              type="checkbox"
              name="nameOption"
              value="Trusses / Panels"
              onChange={() =>
                handleFilterChange("lookingFor", "Trusses / Panels")
              }
            />
            Trusses / Panels
          </label>
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-semibold">
            Filter Options (Lead Status):
          </h3>
          <div className="flex">
            <label className="m-2">
              <input
                className="m-1"
                type="checkbox"
                name="completedOption"
                checked={showCompleted}
                onChange={() => handleFilterChange("completed")}
              />
              Completed
            </label>
            <label className="m-2">
              <input
                className="m-1"
                type="checkbox"
                name="deletedOption"
                checked={showDeleted}
                onChange={() => handleFilterChange("deleted")}
              />
              Deleted
            </label>
          </div>
        </div>
        <div className="absolute z-40 bottom-0 left-10">
          <div className="flex flex-col items-center bg-white p-1 rounded">
            <div className="flex">
              <p className="font-black mr-2 dark:text-black">Date Range</p>
              {datePickerShown ? (
                <button
                  onClick={() => {
                    setDatePickerShown(false);
                  }}
                >
                  <ArrowDropUpIcon className="dark:text-black" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setDatePickerShown(true);
                  }}
                >
                  <ArrowDropDownIcon className="dark:text-black" />
                </button>
              )}
            </div>
            {datePickerShown && (
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        {customers ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={8}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {Object.entries(storeCoords).map(([storeName, coords]) => (
              <React.Fragment key={storeName}>
                <Marker
                  position={coords}
                  icon={{
                    url: "https://forms.bigclumber.com/wp-content/uploads/2023/08/bigcstoresm.png",
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                  onClick={() => handleStoreClick(storeName)}
                />
                {selectedStoreName === storeName && (
                  <Circle
                    center={coords}
                    radius={32187.4} // 20 miles in meters
                    options={getCircleOptions()}
                  />
                )}
              </React.Fragment>
            ))}

            {customers
              .filter((customer) => {
                const filterByLookingFor =
                  selectedFilters.length === 0 ||
                  selectedFilters.includes(
                    customer.webleadEntry.customerWorkingOn
                  );

                const filterByDeleted =
                  showDeleted ||
                  customer.leadProgress.leadStatus.deleted.status !== 1;

                const filterByCompleted =
                  showCompleted ||
                  customer.leadProgress.leadStatus.completed.status !== 1;

                const filterByDateRange =
                  (!startDate ||
                    new Date(customer.webleadEntry.entryDate) >= startDate) &&
                  (!endDate ||
                    new Date(customer.webleadEntry.entryDate) <= endDate);

                return (
                  filterByLookingFor &&
                  filterByDeleted &&
                  filterByCompleted &&
                  (!startDate || !endDate || filterByDateRange)
                );
              })
              .map((customer, index) => {
                const markerIcon = getColoredMarker(
                  customer.webleadEntry,
                  customer.leadProgress.leadStatus.deleted.status,
                  customer.leadProgress.leadStatus.completed.status
                );

                return (
                  <Marker
                    key={index}
                    position={{
                      lat: parseFloat(customer.locationData.lat),
                      lng: parseFloat(customer.locationData.lon),
                    }}
                    icon={{
                      url: markerIcon,
                      scaledSize: new window.google.maps.Size(30, 30),
                    }}
                    onClick={() => handleMarkerClick(customer.webleadEntry)}
                  />
                );
              })}

            {infoWindowOpen && selectedCustomer && (
              <InfoWindow
                position={{
                  lat: parseFloat(selectedCustomer.lat),
                  lng: parseFloat(selectedCustomer.lon),
                }}
                onCloseClick={handleCloseInfoWindow}
              >
                <div className="dark:text-black">
                  <h3>Name: {selectedCustomer.customerName}</h3>
                  <p>Working On: {selectedCustomer.customerWorkingOn}</p>
                  <p>Address: {selectedCustomer.customerAddress}</p>
                  {/* Display other customer details as needed */}
                  <a href={`mailto:${selectedCustomer.customerEmail}`}>
                    {selectedCustomer.customerEmail} |{" "}
                    {selectedCustomer.customerPhone}
                  </a>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default WebLeadMap;
