import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { selectUser } from "../../features/userSlice";
import { selectRoles } from "../../features/roleSlice";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Other/LoadingSpinner";

const MfaHandler = (props) => {
  const user = useSelector(selectUser);
  const role = useSelector(selectRoles);
  const [enableURL, setURL] = useState(null);
  const [mfaStatus, setMFAStatus] = useState(true);
  const [loading, setLoading] = useState(true);
  const [mfaCode, setMFACode] = useState("");
  const [selectedLength, setSelectedLength] = useState("30day");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setMFACode(e.target.value);
  };

  const handleVerifyClick = () => {
    // Send the entered MFA code to the backend for verification
    verifyMFA(mfaCode, selectedLength);
  };
  const handleLengthButtonClick = (length) => {
    setSelectedLength(length);
  };

  useEffect(() => {
    if (user) {
      checkMfaStatus(user.id);
    }
  }, [user]);

  const getURL = async () => {
    var data = JSON.stringify({
      id: user.id,
    });

    const rawResponse = await fetch("/api/profile/enableMFA", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const content = await rawResponse.json();

    setURL(content.otpAuthUrl);
  };

  const verifyMFA = async () => {
    var data = JSON.stringify({
      id: user.id,
      token: mfaCode,
      length: selectedLength,
    });

    const rawResponse = await fetch("/api/profile/verifyMFA", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const content = await rawResponse.json();

    if (content.status === 200) {
      props.setVerify(true);
    } else {
      props.setVerify(false);
    }
  };

  const checkMfaStatus = async (id) => {
    return fetch(`/api/profile/mfaStatus?id=${id}`)
      .then((response) => response.json())
      .then((responseJson) => {
        setMFAStatus(responseJson.enabled);
        props.setPrevAuth(responseJson.prevAuth);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const enableMfaQR = () => {
    getURL();
  };

  const NonAdminRedirect = () => {
    if (!role.roles?.includes("Admin") && !role.roles?.includes("IT")) {
      navigate("/");
    } else {
      return <></>;
    }
  };

  return (
    <div>
      {role.roles?.includes("Admin") &&
      role.roles?.includes("IT") &&
      !mfaStatus ? (
        <div className="max-w-md mx-auto my-10 p-4 bg-white rounded shadow-md flex justify-center">
          {!enableURL && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={enableMfaQR}
            >
              Enable MFA
            </button>
          )}

          {enableURL && (
            <div className="mt-4 flex flex-col">
              <QRCode value={enableURL} />
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-2 rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  setMFAStatus(true);
                }}
              >
                Done
              </button>
            </div>
          )}
        </div>
      ) : (
        <NonAdminRedirect />
      )}

      {!props.mfaVerify && mfaStatus && !props.prevAuth && !loading ? (
        <div className="max-w-md mx-auto my-10">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="mfaCode"
          >
            Enter MFA Code
          </label>
          <div className="flex items-center">
            <input
              type="text"
              id="mfaCode"
              className="shadow appearance-none border rounded w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="123456"
              value={mfaCode}
              onChange={handleInputChange}
            />
            <button
              className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={handleVerifyClick}
            >
              Verify
            </button>
          </div>
          {/* Buttons for verification length */}
          <div className="mt-4 flex items-center">
            <span className="text-gray-700 text-sm font-bold mr-2">
              Verification Length:
            </span>
            <button
              className={`${
                selectedLength === "1day"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-700"
              } hover:bg-gray-400 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
              onClick={() => handleLengthButtonClick("1day")}
            >
              1 Day
            </button>
            <button
              className={`${
                selectedLength === "3day"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-700"
              } hover:bg-gray-400 font-bold py-2 px-4 rounded ml-2 focus:outline-none focus:shadow-outline`}
              onClick={() => handleLengthButtonClick("3day")}
            >
              3 Days
            </button>
            <button
              className={`${
                selectedLength === "7day"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-700"
              } hover:bg-gray-400 font-bold py-2 px-4 rounded ml-2 focus:outline-none focus:shadow-outline`}
              onClick={() => handleLengthButtonClick("7day")}
            >
              7 Days
            </button>
            <button
              className={`${
                selectedLength === "30day"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-700"
              } hover:bg-gray-400 font-bold py-2 px-4 rounded ml-2 focus:outline-none focus:shadow-outline`}
              onClick={() => handleLengthButtonClick("30day")}
            >
              30 Days
            </button>
          </div>
        </div>
      ) : (
        loading && <LoadingSpinner />
      )}
    </div>
  );
};

export default MfaHandler;
