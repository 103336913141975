export const getLeadsV2 = (set, type, branch, user) => {
  if (user) {
    return fetch(
      `/api/leads/v2/getLeads?user=${user}&type=${type}${
        branch ? `&branch=${branch}` : ""
      }`
    )
      .then((response) => response.json())
      .then((responseJson) => {
        set(responseJson);
        return;
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const getBranchLeadsV2 = (set, type, branch) => {
  return fetch(`/api/leads/v2/getLeads?type=${type}&branch=${branch}`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllUsers = (setter) => {
  return fetch("/api/getUserID")
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson.recordset);
    })
    .catch((error) => {
      //console.error(error);
    });
};

// Define a function to map text to colors
export const mapTypeToColor = (type) => {
  switch (type) {
    //Form Types
    case "Quick Contact":
      return "bg-blue-200/50 border-blue-500 text-blue-900";

    //Social Types
    case "Search Engine":
      return "bg-fuchsia-200/50 border-fuchsia-500 text-fuchsia-900";
    case "Social (Facebook, Instagram, LinkedIn)":
      return "bg-orange-200/50 border-orange-500 text-orange-900";
    case "Radio Advertisement":
      return "bg-yellow-200/50 border-yellow-500 text-yellow-900";
    case "TV Advertisement":
      return "bg-green-200/50 border-green-500 text-green-900";
    case "Friend":
      return "bg-purple-200/50 border-purple-500 text-purple-900";
    case "News":
      return "bg-amber-200/50 border-amber-500 text-amber-900";

    //Working On Types
    case "Lumber / Building Materials":
      return "bg-fuchsia-100/50 border-fuchsia-300 text-fuchsia-900";
    case "Deck Materials":
      return "bg-orange-100/50 border-orange-300 text-orange-900";
    case "Insulation":
      return "bg-yellow-100/50 border-yellow-300 text-yellow-900";
    case "Doors / Windows":
      return "bg-green-100/50 border-green-300 text-green-900";
    case "Siding / Roofing":
      return "bg-purple-100/50 border-purple-300 text-purple-900";
    case "Cabinetry":
      return "bg-slate-100/50 border-slate-300 text-slate-900";
    case "Post Frame Buildings":
      return "bg-pink-100/50 border-pink-300 text-pink-900";
    case "Trusses / Panels":
      return "bg-rose-100/50 border-rose-300 text-rose-900";

    case "Other":
      return "bg-cyan-200/50 border-cyan-500 text-cyan-900";
    default:
      return "bg-red-200/50 border-red-500 text-red-900";
  }
};

export const branches = [
  "All Locations",
  "Granger",
  "Mishawaka",
  "Elkhart",
  "Coloma",
  "Fort Wayne - Store",
  "Three Oaks",
  "Edgerton",
  "Kendallville",
  "Cromwell",
  "Delton",
  "LaPorte",
  "Roseland",
  "Adrian",
  "Shipshewana",
  "Eau Claire",
  "Schoolcraft",
];
