import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import { pages } from "./navItems";
import { selectRoles } from "../../features/roleSlice";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CloseIcon from "@mui/icons-material/Close";

export const PageSearch = () => {
  const role = useSelector(selectRoles);
  const [query, setQuery] = useState("");
  const [searchBarShown, setShown] = useState(false);

  const checkForRole = (page) => {
    //check for global access
    if (page?.roles.includes("*")) {
      return true;
    }

    //loop through user roles array
    for (let i = 0; i < role.roles.length; i++) {
      if (page.roles.includes(role.roles[i])) {
        return true;
      } else {
        return false;
      }
    }
  };

  const filteredPages = pages.filter((page) => {
    if (query === "") {
      return null;
    } else {
      //Check if user has access to page
      if (checkForRole(page)) {
        //Check if page name contains query
        if (
          page.name.toLowerCase().includes(query.toLowerCase()) ||
          page.description.toLowerCase().includes(query.toLowerCase())
        ) {
          return page;
        }
      }
    }
  });

  return (
    <>
      {searchBarShown ? (
        <>
          <TextField
            className="w-64 text-white bg-white rounded-md"
            focused={false}
            placeholder="Search"
            variant="standard"
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <div className="flex justify-center items-center">
                  <button>
                    <CloseIcon
                      sx={{ color: "red" }}
                      onClick={() => setShown(false)}
                    />
                  </button>
                </div>
              ),
            }}
          />
          <div className="bg-white absolute flex flex-col rounded-md z-40 max-h-72 overflow-auto">
            {filteredPages.map((page) => {
              switch (page.path) {
                case "ticket":
                  return (
                    <a
                      className="p-2 border bg-white hover:bg-slate-200 flex justify-between items-center"
                      href={`mailto:helpdesk@bigclumber.com?subject=Node%20Web%20Help%20Desk%20Ticket%20`}
                    >
                      <div className="flex">
                        <div className="w-11/12 flex flex-col">
                          {page.name}
                          <span className="text-xs text-gray-400">
                            {page.description}
                          </span>
                        </div>
                        <div className="w-1/12 flex justify-center items-center">
                          <ArrowOutwardIcon />
                        </div>
                      </div>
                    </a>
                  );

                default:
                  return (
                    <Link
                      to={page.path}
                      className="p-2 border bg-white hover:bg-slate-200 flex justify-between items-center"
                      onClick={() => {
                        setShown(false);
                        setQuery("");
                      }}
                    >
                      <div className="flex flex-col">
                        {page.name}
                        <span className="text-xs text-gray-400">
                          {page.description}
                        </span>
                      </div>
                    </Link>
                  );
              }
            })}
          </div>
        </>
      ) : (
        <div className="flex justify-end items-center">
          <button onClick={() => setShown(true)}>
            <SearchIcon
              style={{ fontSize: "2rem" }}
              className="text-black dark:text-white hover:text-blue-500"
            />
          </button>
        </div>
      )}
    </>
  );
};
