import React, { useState, useEffect } from "react";
import { getNodeProfileSalesTeamForLocation } from "../Admin/Utilities/api";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { Tooltip } from "@mui/material";
import DatePicker from "react-datepicker";

import { Bar, Pie } from "react-chartjs-2";
import WebleadManager from "../WebleadsV2/WebleadManager.js";
import {
  branches,
  getBranchLeadsV2,
  getLeadsV2,
} from "../WebleadsV2/utilities";
import { selectRoles } from "../../features/roleSlice.js";

const SummaryBranchSpecificNew = () => {
  const [openLeads, setOpenLeads] = useState([]);
  const [completedLeads, setCompletedLeads] = useState([]);
  const [deletedLeads, setDeletedLeads] = useState([]);
  const [branch, setBranch] = useState(null);
  const [salesTeam, setSalesTeam] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeTab, setActiveTab] = useState("activeLeads"); // Default tab
  const [chartData, setChartData] = useState(null);
  const [isManager, setIsManager] = useState(true);
  const [hearaboutlabels, setHearaboutLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);

  const staticColors = [
    "rgba(102, 153, 255, 0.7)", // Blue
    "rgba(102, 204, 102, 0.7)", // Green
    "rgba(255, 102, 102, 0.7)", // Red
    "rgba(255, 153, 51, 0.7)", // Orange
    "rgba(255, 204, 102, 0.7)", // Yellow
    "rgba(153, 102, 255, 0.7)", // Purple
    "rgba(255, 102, 204, 0.7)", // Pink
  ];

  useEffect(() => {
    if (roles.roles.includes("Web Lead Manager")) {
      setActiveTab("unassignedLeads");
    }
  }, [roles]);

  useEffect(() => {
    if (
      !roles.roles.includes("Web Lead Manager") &&
      !roles.roles.includes("Area Manager")
    ) {
      setSelectedUser(user.userID);
      setIsManager(false);
    }

    if (roles.roles.includes("General Manager")) {
      setIsManager(true);
    }
  }, [roles]);

  useEffect(() => {
    if (
      roles.roles.includes("Web Lead Manager") ||
      roles.roles.includes("Area Manager")
    ) {
      setBranch("All Locations");
    }
  }, [roles]);

  useEffect(() => {
    if (branch === "All Locations") {
      setSelectedUser("ALL");
    }
  }, [branch]);

  useEffect(() => {
    if (user.branch) {
      setBranch(user.branch);
      getNodeProfileSalesTeamForLocation(setSalesTeam, user.branch);
    }

    if (user.branch === "Corporate") {
      setSelectedUser("All Locations");
    }
  }, [user]);

  useEffect(() => {
    if (branch && selectedUser !== user.userID) {
      getBranchLeadsV2(setOpenLeads, "Active", branch);
      getBranchLeadsV2(setCompletedLeads, "Completed", branch);
      getBranchLeadsV2(setDeletedLeads, "Deleted", branch);
    }
  }, [branch]);

  useEffect(() => {
    if (selectedUser === "ALL") {
      getBranchLeadsV2(setOpenLeads, "Active", branch);
      getBranchLeadsV2(setCompletedLeads, "Completed", branch);
      getBranchLeadsV2(setDeletedLeads, "Deleted", branch);
    } else {
      if (selectedUser) {
        getLeadsV2(setOpenLeads, "Active", null, selectedUser);
        getLeadsV2(setCompletedLeads, "Completed", null, selectedUser);
        getLeadsV2(setDeletedLeads, "Deleted", null, selectedUser);
        getNodeProfileSalesTeamForLocation(setSalesTeam, branch);
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    console.log(chartData);
  }, [chartData]);

  const handleBranch = async (event, child) => {
    await setBranch(event.target.value);
    await getNodeProfileSalesTeamForLocation(setSalesTeam, event.target.value);
  };

  useEffect(() => {
    if (openLeads && completedLeads && deletedLeads) {
      // Get current month and year
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      // Create an array to hold the data for the last three months
      const lastThreeMonthsData = [];

      const hearaboutstats = {};

      // Define a function to increment counts
      const incrementCounts = (category, subcategory) => {
        if (category && subcategory && category != "Other") {
          // Check if both category and subcategory are not empty
          if (!hearaboutstats[category]) {
            hearaboutstats[category] = {};
          }
          if (!hearaboutstats[category][subcategory]) {
            hearaboutstats[category][subcategory] = 1;
          } else {
            hearaboutstats[category][subcategory]++;
          }
        }
      };

      // Initialize all subcategories with a count of 0 for each category
      const initializeSubcategories = () => {
        const categories = Object.keys(hearaboutstats);
        categories.forEach((category) => {
          const subcategories = [
            "Friend",
            "Search Engine",
            "Other",
            "Social (Facebook, Instagram, LinkedIn)",
            "News",
            "TV Advertisement",
            "Radio Advertisement",
          ];
          subcategories.forEach((subcategory) => {
            if (!hearaboutstats[category][subcategory]) {
              hearaboutstats[category][subcategory] = 0;
            }
          });
        });
      };

      // Loop through each lead and increment counts
      [completedLeads, openLeads, deletedLeads].forEach((leads) => {
        leads.forEach((lead) => {
          const { customerWorkingOn, hearAbout } = lead.webleadEntry;
          incrementCounts(customerWorkingOn, hearAbout);
        });
      });

      // Initialize subcategories with a count of 0 for each category
      initializeSubcategories();

      if (hearaboutstats) {
        const categories = Object.keys(hearaboutstats);
        const subcategories = Array.from(
          new Set(categories.flatMap((cat) => Object.keys(hearaboutstats[cat])))
        );

        let colorIndex = 0;
        // Create datasets for stacked bar chart
        const datasets = subcategories.map((subcategory) => {
          const data = categories.map(
            (category) => hearaboutstats[category][subcategory] || 0
          );
          const color = staticColors[colorIndex % staticColors.length]; // Get color from static colors array
          colorIndex++; // Increment color index
          return {
            label: subcategory,
            data,
            backgroundColor: color,
          };
        });

        // Set the state variables
        setHearaboutLabels(categories);
        setDatasets(datasets);
        console.log(hearaboutstats);
      }

      // Loop through the last three months
      for (let i = 0; i < 12; i++) {
        // Calculate the month and year for the current iteration
        const month = (currentMonth - i + 12) % 12; // Ensure month rolls over correctly for previous year
        let year = currentYear;

        if (currentMonth - i < 0) {
          year--;
        }

        // Filter leads for the current month and year
        const filteredOpenLeads = openLeads.filter((lead) => {
          const leadDate = new Date(lead.webleadEntry.entryDate);
          return (
            leadDate.getMonth() === month && leadDate.getFullYear() === year
          );
        });

        const filteredCompletedLeads = completedLeads.filter((lead) => {
          const leadDate = new Date(lead.webleadEntry.entryDate);
          return (
            leadDate.getMonth() === month && leadDate.getFullYear() === year
          );
        });

        const filteredDeletedLeads = deletedLeads.filter((lead) => {
          const leadDate = new Date(lead.webleadEntry.entryDate);
          return (
            leadDate.getMonth() === month && leadDate.getFullYear() === year
          );
        });

        // Store the counts for the filtered leads
        const openLeadsCount = filteredOpenLeads.length;
        const completedLeadsCount = filteredCompletedLeads.length;
        const deletedLeadsCount = filteredDeletedLeads.length;

        // Store the data for the current month
        lastThreeMonthsData.unshift({
          month: month, // Store the month for labeling
          year: year,
          openLeadsCount: openLeadsCount,
          completedLeadsCount: completedLeadsCount,
          deletedLeadsCount: deletedLeadsCount,
        });
      }

      // Create datasets for each lead category
      const openLeadsData = lastThreeMonthsData.map(
        (data) => data.openLeadsCount
      );
      const completedLeadsData = lastThreeMonthsData.map(
        (data) => data.completedLeadsCount
      );
      const deletedLeadsData = lastThreeMonthsData.map(
        (data) => data.deletedLeadsCount
      );

      // Create labels for the last three months
      const labels = lastThreeMonthsData.map((data) => {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return `${monthNames[data.month]} ${data.year}`;
      });

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: "Active Leads",
            data: openLeadsData,
            backgroundColor: "rgba(255, 206, 86, 0.6)",
            stack: "Stack 0",
          },
          {
            label: "Completed Leads",
            data: completedLeadsData,
            backgroundColor: "rgba(25, 200, 25, 0.6)",
            stack: "Stack 0",
          },
          {
            label: "Deleted Leads",
            data: deletedLeadsData,
            backgroundColor: "rgba(200, 25, 25, 0.6)",
            stack: "Stack 0",
          },
        ],
      };
      setChartData(chartData);
    }
  }, [openLeads, completedLeads, deletedLeads]);

  return (
    <div className="flex flex-col items-center">
      <div
        className={
          isManager ? `flex w-full items-center` : `flex w-1/3 items-center`
        }
      >
        <div
          className={
            isManager
              ? `flex flex-col justify-start items-center w-1/3`
              : `flex justify-center items-center w-full`
          }
        >
          {chartData && (
            <Bar
              className="m-2 p-2"
              data={chartData}
              options={{
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      color:
                        window.document.documentElement.classList[0] === "dark"
                          ? "white"
                          : "black", // Conditionally sets the color of the legend text
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      color:
                        window.document.documentElement.classList[0] === "dark"
                          ? "white"
                          : "black", // Conditionally sets the color of the y-axis ticks
                    },
                    grid: {
                      color:
                        window.document.documentElement.classList[0] === "dark"
                          ? "rgba(255, 255, 255, 0.3)"
                          : "rgba(128, 128, 128, 0.3)", // Conditionally sets the color of the y-axis grid lines
                    },
                  },
                  x: {
                    ticks: {
                      color:
                        window.document.documentElement.classList[0] === "dark"
                          ? "white"
                          : "black", // Conditionally sets the color of the x-axis ticks
                    },
                    grid: {
                      color:
                        window.document.documentElement.classList[0] === "dark"
                          ? "rgba(255, 255, 255, 0.3)"
                          : "rgba(128, 128, 128, 0.3)", // Conditionally sets the color of the x-axis grid lines
                    },
                  },
                },
              }}
            />
          )}
        </div>

        {isManager && (
          <div className="flex flex-col w-1/3">
            <div className="flex flex-col  dark:text-white items-center">
              <p className="mb-2">Location Selection</p>
              <select
                className="bg-gray-50 w-3/4 font-bold"
                value={branch || ""}
                label="Location"
                onChange={handleBranch}
              >
                {branches.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>

            {branch !== "Unassigned Leads" && branch !== "All Locations" && (
              <>
                <div className="flex justify-center">
                  <p className="dark:text-white">{branch} Team</p>
                </div>
                <div className="grid grid-cols-4 gap-2 max-h-40 overflow-y-auto m-1">
                  {salesTeam?.map((item) => {
                    return (
                      <div
                        key={item.userID}
                        className="flex flex-col items-center justify-center"
                      >
                        <Tooltip title={item.name}>
                          <button
                            onClick={() => setSelectedUser(item.userID)}
                            className={
                              item.userID === selectedUser
                                ? "w-24 h-24 p-2 bg-gray-200 hover:bg-gray-300 border-2 border-green-400 rounded"
                                : "w-24 h-24 p-2 bg-gray-200 hover:bg-gray-300 rounded"
                            }
                          >
                            {item.name !== "ALL" && (
                              <div className="flex justify-center">
                                <img
                                  src={`/api/profile/image?id=${item.id}`}
                                  alt={`Profile ${item.name}`}
                                  className="w-8 h-8 bg-gray-300 rounded-full border-blue-400 border m-1"
                                />
                              </div>
                            )}
                            <div className="text-center">
                              <p className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                                {item.name}
                              </p>
                            </div>
                          </button>
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        )}

        {hearaboutlabels &&
          (roles.roles.includes("Web Lead Manager") ||
            roles.roles.includes("Area Manager")) && (
            <div className="flex flex-col items-center pt-2 w-1/3 dark:text-white">
              <div className="flex flex-col items-center w-full">
                <p>Referral Statistics</p>
                <Bar
                  className="m-2 p-2"
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        position: "bottom", // You can adjust the position as needed
                      },
                    },
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                      },
                    },
                  }}
                  data={{
                    labels: hearaboutlabels,
                    datasets: datasets,
                  }}
                />
              </div>
            </div>
          )}
      </div>

      <div className="flex w-full">
        <div className="w-full flex">
          <div className="dark:text-white flex flex-col w-full">
            <div className="flex flex-col m-4">
              <div className="flex flex-col w-full">
                <label id="lead-dropdown">Select Leads</label>
                <div className="flex w-full">
                  <div className="flex w-1/2 justify-start">
                    <select
                      className="dark:text-black"
                      value={activeTab}
                      onChange={(e) => {
                        setActiveTab(e.target.value);
                      }}
                    >
                      {roles.roles.includes("Web Lead Manager") && (
                        <option value="unassignedLeads">
                          Unassigned Leads
                        </option>
                      )}
                      <option value="activeLeads">
                        Active Leads ({openLeads?.length})
                      </option>
                      <option value="completedLeads">
                        Completed Leads ({completedLeads?.length})
                      </option>
                      <option value="deletedLeads">
                        Deleted Leads ({deletedLeads?.length})
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex m-4">
                {activeTab === "unassignedLeads" && (
                  <WebleadManager
                    type="Unassigned"
                    user={"ALL"}
                    selectedUser={"ALL"}
                    branch={branch}
                  />
                )}
                {activeTab === "activeLeads" && (
                  <WebleadManager
                    type="Active"
                    user={selectedUser !== "ALL" ? selectedUser : null}
                    selectedUser={selectedUser}
                    branch={branch}
                  />
                )}
                {activeTab === "completedLeads" && (
                  <WebleadManager
                    type="Completed"
                    user={selectedUser !== "ALL" ? selectedUser : null}
                    selectedUser={selectedUser}
                    branch={branch}
                  />
                )}
                {activeTab === "deletedLeads" && (
                  <WebleadManager
                    type="Deleted"
                    user={selectedUser !== "ALL" ? selectedUser : null}
                    selectedUser={selectedUser}
                    branch={branch}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryBranchSpecificNew;
