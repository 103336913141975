import React, { useEffect, useState } from "react";
import { normalizeDate_time } from "../../utilities";
import { useParams } from "react-router-dom";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";
import renderWeatherIcon from "../../components/Other/GetWeatherIcon";

const Weather = () => {
  const { branchlocation } = useParams();
  const user = useSelector(selectUser);

  //function to call getWeatherData every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      getWeather();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const [weather, setWeather] = useState(null);
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getWeather = async () => {
    var link = "";
    if (branchlocation) {
      link = "/api/weather/getWeatherData?branch=" + branchlocation;
    } else {
      link = "/api/weather/getWeatherData?branch=" + user.branch;
    }
    const res = await fetch(link);
    const data = await res.json();
    setWeather(data[0]);
  };

  useEffect(() => {
    getWeather();
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      {weather && (
        <div className="flex flex-col items-center w-full h-screen">
          <div className="flex flex-col items-center p-1 dark:text-white  min-w-64 w-full">
            <div className="flex flex-col items-center w-full border border-black dark:border-white">
              <div className=" items-center flex flex-col  bg-white dark:border-gray-500 dark:bg-white/5 bg-white/95 border-black dark:border-white dark:text-white w-full">
                <div className="border-b border-black dark:border-white flex w-full flex-col items-center m-1">
                  <div className="flex flex-col items-center">
                    <p className="text-3xl font-bold text-center dark:border-white">
                      {capitalizeFirstLetter(weather.branch)}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center items-center">
                  <p className="font-black">Current Weather</p>
                  <p className="text-center">
                    {capitalizeFirstLetter(
                      weather.current.weather[0].description
                    )}
                  </p>
                </div>
                <div className="flex m-2 items-center justify-center">
                  <div className="flex flex-col m-2 items-center">
                    <div className="flex flex-col items-center justify-center p-2 dark:text-white">
                      <p className="flex text-center text-5xl font-black">
                        {Math.round(weather.current.feels_like)}&deg;F
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    {renderWeatherIcon(
                      weather.current.weather[0].main,
                      32,
                      weather.current.weather[0].description
                    )}
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col items-center  bg-white  dark:border-gray-500 dark:bg-white/5 bg-white/95 border-black dark:border-white dark:text-white">
                <div className="flex justify-center items-center">
                  <p className="font-black">Hourly Forecast</p>
                </div>
                <div className="flex justify-center ">
                  {(() => {
                    const elements = [];

                    for (let i = 1; i < 7; i++) {
                      elements.push(
                        <div
                          className="flex flex-col items-center m-1 bg-gray-200/5 border dark:border-gray-500 dark:bg-white/5 bg-white/95  dark:border-white dark:text-white sc"
                          key={i}
                        >
                          <p className="border-b border-black dark:border-white text-xl text-center">
                            {normalizeDate_time(weather.hourly[i].dt * 1000)}
                          </p>
                          <p className="text-center m-1 p-1 font-black">
                            {Math.round(weather.hourly[i].temp)}&deg;F
                          </p>
                          <p className="text-center text-sm">
                            {weather.hourly[i].weather[0].main}
                          </p>
                          <p className="text-center text-xs">
                            {weather.hourly[i].weather[0].description}
                          </p>
                          {renderWeatherIcon(
                            weather.hourly[i].weather[0].main,
                            32,
                            weather.hourly[i].weather[0].description
                          )}
                        </div>
                      );
                    }
                    return elements;
                  })()}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center p-2 border-l border-r border-b dark:border-gray-500 dark:bg-white/5 bg-white/95 border-black dark:border-white dark:text-white w-full">
              <div className="flex justify-center">
                <p className="text-center text-xl dark:border-white font-black">
                  5 Day Forecast
                </p>
              </div>
              <div className="flex flex-wrap justify-center">
                {(() => {
                  const elements = [];

                  for (let i = 1; i < 6; i++) {
                    elements.push(
                      <div
                        className="flex flex-col items-center m-1 bg-gray-200/5 border dark:border-gray-500 dark:bg-white/5 bg-white/95  dark:border-white dark:text-white"
                        key={i}
                      >
                        <p className="border-b border-black dark:border-white text-xl text-center">
                          {
                            weekday[
                              new Date(weather.daily[i].dt * 1000).getDay()
                            ]
                          }
                        </p>
                        <p className="text-center m-1 p-1 font-black">
                          {Math.round(weather.daily[i].temp.day)}&deg;F
                        </p>
                        <p className="text-center text-sm">
                          {weather.daily[i].weather[0].main}
                        </p>
                        <p className="text-center text-xs">
                          {weather.daily[i].weather[0].description}
                        </p>
                        {renderWeatherIcon(
                          weather.daily[i].weather[0].main,
                          32,
                          weather.daily[i].weather[0].description
                        )}
                        <div className="flex">
                          <p className="flex text-center text-xs bg-orange-200 p-2 m-1 dark:text-black">
                            Hi: {Math.round(weather.daily[i].temp.max)}&deg;F
                          </p>
                          <p className="flex text-center text-xs bg-blue-200 p-2 m-1 dark:text-black">
                            Low: {Math.round(weather.daily[i].temp.min)}&deg;F
                          </p>
                        </div>
                      </div>
                    );
                  }
                  return elements;
                })()}
              </div>
              <p className="text-center text-xs dark:text-white font-black">
                Last Updated: {normalizeDate_time(weather.lastUpdated)} EST
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const CurrentTemperature = () => {
  const [weather, setWeather] = useState(null);
};

export default Weather;
