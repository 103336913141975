import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getLocations } from "./Utilities/api";

const DisplayLocations = (props) => {
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    getLocations(setLocationData);
  }, [props.flag]);

  return (
    <div className="flex flex-col items-center m-2">
      <div className="flex justify-center m-2">
        <p className="text-sm font-bold uppercase text-black">Locations</p>
      </div>
      <div className="m-4">
        {locationData.length === 0 && <CircularProgress />}
        {locationData.map((item, index) => {
          return (
            <li
              key={index}
              className="rounded-md bg-slate-200 inline-flex m-2 p-2 max-w-sm"
            >
              <p className="text-xl">{item.location}</p>
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default DisplayLocations;
