import React, { useEffect, useState } from "react";
import { selectUser } from "../../../features/userSlice";
import { useSelector } from "react-redux";
import {
  Divider,
  TextField,
  Button,
  Dialog,
  Tooltip,
  Select,
  MenuItem,
} from "@mui/material";
import DatePicker from "react-datepicker";
import {
  applied_fields,
  backset_fields,
  bolt_type_fields,
  casing_type_fields,
  catch_astragal,
  core_fields,
  hinge_finish_fields,
  size_jamb_type_fields,
  stop_applied_fields,
  stop_fields,
} from "../../../components/Forms/SelectValues/values";
import { formAutoSave } from "../../../components/Forms/Misc/utilities";
import { Link, useNavigate } from "react-router-dom";
import DoorBuilder from "../../../components/WindowDoor/DoorBuilder";
import DisplayDoors from "../../../components/Forms/InteriorDoor/DisplayDoors";
import DoorBuilderEdit from "../../../components/WindowDoor/Components/DoorBuilderEdit";
import { getSteps } from "../../../components/WindowDoor/utilities";
import { getWindowDoorUsers } from "../../Admin/Utilities/api";

const InteriorDoor = (props) => {
  //This will have data or be null either is fine.
  const [formData, setFormData] = useState(props.formData);
  const [formID, setFormID] = useState(null);
  const [submitStatus, setStatus] = useState(false);
  const [preSubmit, setPreSubmit] = useState(false);
  const [sendTo, setSendTo] = useState(null);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  //Using existing door data if it exists, if not use a blank row.
  const [doors, setDoors] = useState(props.formData.doorData || []);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [salesTeam, setSalesTeam] = useState(null);

  useEffect(() => {
    getWindowDoorUsers(setSalesTeam);
  }, []);

  const [requiredFields, setRequiredFields] = useState([
    "size_jamb_type",
    "core",
    "casing_type",
    "applied",
    "bolt_type",
    "backset",
    "handing", // Add "handing" to the required fields
    "slab_style", // Add "slab_style" to the required fields
    "width", // Add "width" to the required fields
  ]);

  const handleRequiredRemoval = async (fieldsToRemove) => {
    const updatedRequiredFields = requiredFields.filter(
      (e) => !fieldsToRemove.includes(e)
    );

    await setRequiredFields(updatedRequiredFields);
  };

  const handleRequiredAdd = (fieldToAdd) => {
    var arr = requiredFields;

    arr.push(fieldToAdd);
    setRequiredFields(arr);
  };

  const handleEditRow = (rowData) => {
    setEditRowData(rowData);
    setIsEditDialogOpen(true); // Open the dialog for editing
  };

  //Update formdata with new door data
  useEffect(() => {
    setFormData({ ...formData, doorData: doors });
  }, [doors]);

  // Auto Save Function Run Every time Form Data Changes or Doors Change
  useEffect(() => {
    if (formData) {
      formAutoSave(formData, setFormID);
    }

    var arr = [];

    Object.keys(formData).map((key) => {
      if (formData[`${key}`] !== "") {
        arr.push(key);
      } else {
        if (
          !requiredFields.includes(key) &&
          key !== "instructions" &&
          key !== "stop" &&
          key !== "stop_applied" &&
          key !== "hinge_finish"
        ) {
          handleRequiredAdd(key);
        }
      }
    });

    handleRequiredRemoval(arr);
  }, [formData]);

  useEffect(() => {
    if (formData.door_type === "single") {
      setFormData({
        ...formData,
        casing_type: null,
        hinge_finish: null,
        stop: null,
        stop_applied: null,
        applied: null,
        handing: null,
        bolt_type: null,
        backset: null,
        core: null,
      });
    }
    if (formData.door_type === "double") {
      setFormData({
        ...formData,
        casing_type: null,
        hinge_finish: null,
        stop: null,
        stop_applied: null,
        applied: null,
        handing: null,
        bolt_type: "N/A",
        backset: "N/A",
        core: null,
      });
    }
    if (formData.door_type === "bifold") {
      setFormData({
        ...formData,
        casing_type: null,
        hinge_finish: null,
        stop: null,
        stop_applied: null,
        applied: null,
        core: "Bifold",
        bolt_type: null,
        backset: null,
        handing: "Bifold",
        size_jamb_type: null,
      });
    }
  }, [formData.door_type]);

  useEffect(() => {
    if (formData.size_jamb_type?.includes("SPLIT")) {
      setFormData({
        ...formData,
        hinge_finish: "",
        stop: "N/A",
        stop_applied: "N/A",
      });
    } else if (
      formData.size_jamb_type?.includes("Door Slab Only(null)") ||
      formData.door_type !== "bifold"
    ) {
      handleRequiredRemoval("stop");
      handleRequiredRemoval("stop_applied");
      handleRequiredRemoval("hinge_finish");
      handleRequiredRemoval("handing");
      setFormData({
        ...formData,
        slab_only: 1,
        casing_type: "N/A",
        hinge_finish: "N/A",
        stop: "N/A",
        stop_applied: "N/A",
        applied: "N/A",
        handing: "N/A",
      });
    } else {
      setFormData({
        ...formData,
        slab_only: 0,
        hinge_finish: "",
        stop: "",
        stop_applied: "",
      });
    }
  }, [formData.size_jamb_type]);

  useEffect(() => {
    if (!formData.width) {
      handleRequiredAdd("width");
    }

    if (!formData.slab_style) {
      handleRequiredAdd("slab_style");
    }
  }, [formData.core]);

  useEffect(() => {
    if (formData.door_type === "bifold") {
      handleRequiredRemoval("stop");
      handleRequiredRemoval("stop_applied");
      handleRequiredRemoval("hinge_finish");
      handleRequiredRemoval("handing");
    }
  }, [formData.door_type]);

  useEffect(() => {
    if (sendTo) {
      handleSubmit();

      setSendTo(null);
    }
  }, [sendTo]);

  const handleSaveSpecialInstructions = (rowData, specialInstructions) => {
    const updatedDoors = doors.map((door) =>
      door === rowData
        ? { ...door, special_instructions: specialInstructions }
        : door
    );
    setDoors(updatedDoors);
  };

  const handleSubmit = async () => {
    //Once the form is submitted, stop the auto save.
    var data = formData;

    data.entry_date = new Date();
    data.submit_user = user;
    data.form_type = "order";
    data.submitted = 1;
    data.sendTo = sendTo;

    submitForm(data);
  };

  const submitForm = async (data) => {
    const rawResponse = await fetch("/api/forms/submit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const content = await rawResponse.json();

    if (content.success === 1) {
      setStatus(true);
      await setFormID(content.form_id);
      return;
    } else {
      setStatus(false);
      return;
    }
  };

  useEffect(() => {
    var array = [...doors]; // make a separate copy of the array
    formData.doorData = array;
    setFormData({ ...formData });
  }, [doors]);

  //Get Valid Date for Date Picker
  const validDate = () => {
    if (formData.shipping_date) {
      return new Date(formData.shipping_date);
    } else {
      setFormData({ ...formData, shipping_date: new Date() });
      return new Date();
    }
  };

  const handleSaveRowData = (rowData) => {
    const rowIndex = doors.findIndex((door) => door === editRowData);
    if (rowIndex !== -1) {
      const updatedDoors = [...doors];
      updatedDoors[rowIndex] = rowData;
      setDoors(updatedDoors);
    }

    setEditRowData(null);
    setIsEditDialogOpen(false);
  };

  const [saveSuccessDialogOpen, setSaveSuccessDialogOpen] = useState(false);

  const handleSave = async (openDialog) => {
    var data = formData;

    data.entry_date = new Date();
    data.submit_user = user;
    data.form_type = formData.form_type.toUpperCase();

    // Simply save the data without submitting
    await formAutoSave(formData, setFormID);

    if (openDialog) {
      setSaveSuccessDialogOpen(true); // Open the dialog for save success
    }
  };

  const handleCloseSaveSuccessDialog = () => {
    setSaveSuccessDialogOpen(false); // Close the dialog for save success
    const currentRoute = window.location.pathname;

    if (currentRoute !== "/forms/viewall") {
      navigate("/forms/viewall"); // Navigate to "/forms/viewall" if not already on that page
    } else {
      window.location.reload(); // Refresh the page if already on "/forms/viewall"
    }
  };

  const handlePrint = async () => {
    // Save the data
    handleSave(false);
    window.open(`/api/forms/pdf?id=${formID}`, "_blank");
  };

  return (
    <div className="m-4 items-center p-4 dark:text-white mb-4">
      <Dialog
        open={submitStatus}
        onClose={() => {
          setStatus(false);
          navigate("/forms/viewall");
        }}
        maxWidth="lg"
      >
        <div className="bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 from-gray-200 to-gray-300 border border-green-500 p-4 dark:text-white w-full">
          <p className="text-2xl">
            {formData.form_type.toUpperCase()} Submitted Successfully
          </p>
          <div>
            <div className="flex flex-col justify-center items-center m-1">
              <p className="text-xl">Notification has been sent!</p>
              <div className="m-2">
                <Link to={`/api/forms/pdf?id=${formID}`} target="_blank">
                  <button className="border rounded border-blue-600 text-blue-600 hover:bg-blue-400 p-3 m-1">
                    DOWNLOAD PDF
                  </button>
                </Link>
                <button
                  className="border rounded border-red-600 text-red-600 hover:bg-red-400 p-3 m-1"
                  onClick={() => {
                    setStatus(false);
                    const currentRoute = window.location.pathname;

                    if (currentRoute !== "/forms/viewall") {
                      navigate("/forms/viewall"); // Navigate to "/forms/viewall" if not already on that page
                    } else {
                      window.location.reload(); // Refresh the page if already on "/forms/viewall"
                    }
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={preSubmit}
        onClose={() => {
          setStatus(false);
          navigate("/forms/viewall");
        }}
        maxWidth="lg"
      >
        <div className="bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 from-gray-200 to-gray-300 border p-4 dark:text-white w-full flex flex-col items-center align-center">
          <p className="text-2xl">Send To</p>
          <div>
            <div className="flex justify-center items-center m-1">
              <button
                className="border rounded border-green-600 text-black hover:bg-green-400 p-3 m-1 w-64"
                onClick={() => {
                  setSendTo("tiffiney@bigclumber.com");
                  setPreSubmit(false);
                }}
              >
                Tiffiney Sweezey
              </button>
              <button
                className="border rounded border-green-600 text-black hover:bg-green-400 p-3 m-1 w-64"
                onClick={() => {
                  setSendTo("rong@bigclumber.com");
                  setPreSubmit(false);
                }}
              >
                Ron Goetz
              </button>
            </div>
          </div>
          {/* {salesTeam && (
            <div className="w-full">
              <p>Select Other</p>
              <Select
                placeholder="Select Other"
                onClose={() => {
                  setPreSubmit(false);
                }}
                onChange={(e) => {
                  setSendTo(e.target.value);
                  setPreSubmit(false);
                }}
                className="w-full bg-white"
              >
                {salesTeam.map((item) => {
                  return (
                    <MenuItem value={item.EmailAddress}>{item.Name}</MenuItem>
                  );
                })}
              </Select>
            </div>
          )} */}
        </div>
      </Dialog>

      <Dialog
        open={saveSuccessDialogOpen}
        onClose={handleCloseSaveSuccessDialog}
        className="fixed z-10 inset-0 overflow-hidden flex items-center justify-center"
      >
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-8 max-w-md w-full mx-auto overflow-y-auto">
          <div className="text-center">
            <p className="text-lg font-semibold mb-4 dark:text-white">
              Success!
            </p>
            <p className="text-gray-700 dark:text-gray-300">
              Your form has been successfully saved.
            </p>
          </div>
          <div className="mt-6 flex justify-center">
            <button
              onClick={handleCloseSaveSuccessDialog}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white"
            >
              OK
            </button>
          </div>
        </div>
      </Dialog>

      {/* Delivery Date and Instrutions (2) */}
      <div className="flex flex-col justify-center border p-2 my-1 bg-white dark:bg-white/5 border-black dark:border-white">
        <p
          className="flex justify-center 
      text-xl"
        >
          Interior Door {formData.form_type.toUpperCase()}
        </p>
        <p
          className="flex justify-center 
      text-xl"
        >
          {formData.customer} | Job #{formData.job}
        </p>

        <div className="flex w-full flex-wrap justify-center">
          <div className="w-1/5 m-1">
            <p className="dark:text-white ">Shipping Date</p>
            <DatePicker
              selected={formData.shipping_date ? validDate() : null}
              minDate={new Date()}
              onChange={(date) =>
                setFormData({ ...formData, shipping_date: date })
              }
              className="w-full text-black bg-slate-200"
            />
          </div>
          <div className="w-3/5 m-1">
            <p className="dark:text-white ">Special Instructions</p>
            <TextField
              onChange={(e) => {
                setFormData({
                  ...formData,
                  instructions: e.target.value,
                });
              }}
              className="w-full bg-slate-200"
              multiline={true}
              rows={2}
              value={formData.instructions || ""}
            />
          </div>
          <Divider />

          <div className="w-1/5 m-1">
            <p className="dark:text-white ">Door Type</p>
            <select
              className="w-full bg-slate-200 dark:text-black"
              value={formData.door_type || ""}
              onChange={(e) => {
                switch (e.target.value) {
                  case "double":
                    setFormData({
                      ...formData,
                      door_type: e.target.value,
                    });
                    handleRequiredRemoval("handing");
                    break;
                  case "bifold":
                    setFormData({
                      ...formData,
                      door_type: e.target.value,
                      handing: "Bifold",
                      stop: "N/A",
                      stop_applied: "N/A",
                      hinge_finish: "N/A",
                    });
                    break;

                    setFormData({
                      ...formData,
                      door_type: e.target.value,
                      handing: "Bifold",
                      stop: "N/A",
                      stop_applied: "N/A",
                      hinge_finish: "N/A",
                    });

                    break;

                  default:
                    setFormData({
                      ...formData,
                      door_type: e.target.value,
                      catch_astragal: null,
                    });
                    break;
                }
              }}
            >
              <option key={0} value={`single`}>
                Single
              </option>
              <option key={1} value={`double`}>
                Double
              </option>
              <option key={2} value={`bifold`}>
                Bifold
              </option>
            </select>
          </div>

          {formData.door_type === "double" && (
            <div className="w-1/5 m-1">
              <p className="dark:text-white ">Ball Catch | Astragal</p>
              <select
                className="w-full bg-slate-200 dark:text-black"
                value={formData.catch_astragal || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    catch_astragal: e.target.value,
                  });
                }}
              >
                {catch_astragal.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={`${item.description}(${item.productId})`}
                    >
                      {index === 0
                        ? item.description
                        : `${index}) ${item.description}`}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {formData.door_type !== "bifold" && (
            <div className="w-1/5 m-1">
              <p className="dark:text-white ">Jamb Size and Type</p>
              <select
                className="w-full bg-slate-200 dark:text-black"
                value={formData.size_jamb_type || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    size_jamb_type: e.target.value,
                  });
                  handleRequiredRemoval("size_jamb_type");
                }}
              >
                {size_jamb_type_fields.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={`${item.description}(${item.productId})`}
                    >
                      {index === 0
                        ? item.description
                        : `${index}) ${item.description}`}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          {formData.door_type !== "bifold" &&
            formData.size_jamb_type === "Door Slab Only(null)" && (
              <>
                {/* Render only Core, Bolt Type, and Backset dropdowns */}

                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Core</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.core || ""}
                    onChange={(e) => {
                      //console.log(e.target.value);
                      setFormData({
                        ...formData,
                        core: e.target.value,
                      });
                      handleRequiredRemoval("core");
                    }}
                  >
                    {core_fields.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {index === 0 ? item : `${index}) ${item}`}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Bolt Type</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.bolt_type || ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        bolt_type: e.target.value,
                      });
                      handleRequiredRemoval("bolt_type");
                    }}
                  >
                    {bolt_type_fields(
                      formData.size_jamb_type,
                      formData.door_type
                    ).map((item, index) => {
                      return (
                        <option value={item}>
                          {index === 0 ? item : `${index}) ${item}`}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Backset</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.backset || ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        backset: e.target.value,
                      });
                      handleRequiredRemoval("backset");
                    }}
                  >
                    {backset_fields(
                      formData.size_jamb_type,
                      formData.door_type
                    ).map((item, index) => {
                      return (
                        <option value={item}>
                          {index === 0 ? item : `${index}) ${item}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </>
            )}

          {formData.door_type !== "bifold" &&
            formData.size_jamb_type !== "Door Slab Only(null)" && (
              <>
                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Core</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.core || ""}
                    onChange={(e) => {
                      //console.log(e.target.value);
                      setFormData({
                        ...formData,
                        core: e.target.value,
                      });
                      handleRequiredRemoval("core");
                    }}
                  >
                    {core_fields.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {index === 0 ? item : `${index}) ${item}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Casing Type</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.casing_type || ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        casing_type: e.target.value,
                      });
                      handleRequiredRemoval("casing_type");
                      if (e.target.value === "N/A") {
                        handleRequiredRemoval("applied");
                      }
                    }}
                  >
                    {casing_type_fields.map((item, index) => {
                      return (
                        <option
                          value={`${item.description}(${item.productId})`}
                        >
                          {index === 0
                            ? item.description
                            : `${index}) ${item.description}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {formData.casing_type !== "N/A(null)" && (
                  <div className="w-1/5 m-1">
                    <p className="dark:text-white ">Casing Applied</p>
                    <select
                      className="w-full bg-slate-200 dark:text-black"
                      value={formData.applied || ""}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          applied: e.target.value,
                        });
                        handleRequiredRemoval("applied");
                      }}
                    >
                      {applied_fields.map((item, index) => {
                        return (
                          <option value={item}>
                            {index === 0 ? item : `${index}) ${item}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Hinge Finish</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.hinge_finish || ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hinge_finish: e.target.value,
                      });
                      handleRequiredRemoval("hinge_finish");
                    }}
                  >
                    {hinge_finish_fields.map((item, index) => {
                      return (
                        <option
                          value={`${item.description}(${item.productId})`}
                        >
                          {index === 0
                            ? item.description
                            : `${index}) ${item.description}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Stop</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.stop || ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        stop: e.target.value,
                      });
                      handleRequiredRemoval("stop");
                    }}
                  >
                    {stop_fields(formData.size_jamb_type).map((item, index) => {
                      return (
                        <option
                          value={`${item.description}(${item.productId})`}
                        >
                          {index === 0
                            ? item.description
                            : `${index}) ${item.description}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Stop Applied</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.stop_applied || ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        stop_applied: e.target.value,
                      });
                      handleRequiredRemoval("stop_applied");
                    }}
                  >
                    {stop_applied_fields(formData.size_jamb_type).map(
                      (item, index) => {
                        return (
                          <option value={item}>
                            {index === 0 ? item : `${index}) ${item}`}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>

                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Bolt Type</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.bolt_type || ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        bolt_type: e.target.value,
                      });
                      handleRequiredRemoval("bolt_type");
                    }}
                  >
                    {bolt_type_fields(
                      formData.size_jamb_type,
                      formData.door_type
                    ).map((item, index) => {
                      return (
                        <option value={item}>
                          {index === 0 ? item : `${index}) ${item}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="w-1/5 m-1">
                  <p className="dark:text-white ">Backset</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={formData.backset || ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        backset: e.target.value,
                      });
                      handleRequiredRemoval("backset");
                    }}
                  >
                    {backset_fields(
                      formData.size_jamb_type,
                      formData.door_type
                    ).map((item, index) => {
                      return (
                        <option value={item}>
                          {index === 0 ? item : `${index}) ${item}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </>
            )}
        </div>
      </div>
      {/* Door Configurator */}
      <DoorBuilder
        handleRequiredRemoval={handleRequiredRemoval} // Pass the handleRequiredRemoval function as a prop
        doors={doors}
        setDoors={setDoors}
        formData={formData}
        requiredFields={requiredFields}
        setRequiredFields={setRequiredFields}
        setFormData={setFormData}
      />

      {editRowData && (
        <DoorBuilderEdit
          steps={getSteps(formData?.size_jamb_type)}
          rowData={editRowData}
          setRowData={setEditRowData}
          setIsEditDialogOpen={setIsEditDialogOpen}
          isEditDialogOpen={isEditDialogOpen}
          onSaveRowData={handleSaveRowData}
          onSaveSpecialInstructions={handleSaveSpecialInstructions}
        />
      )}

      {doors.length > 0 && (
        <DisplayDoors
          doors={doors}
          formData={formData}
          setDoors={setDoors}
          onEditRow={handleEditRow}
          setEditRowData={setEditRowData}
          onSaveSpecialInstructions={handleSaveSpecialInstructions}
        />
      )}
      {doors.length > 0 && (
        <>
          <div className="flex justify-center text-xl">
            Total Estimated Price:
            <p className="text-green-500">
              $
              {doors
                .map((door, index) => door.estimated_price)
                .reduce((total, price) => total + price, 0)
                .toFixed(2)}
            </p>
          </div>
          <div className="flex justify-center">
            <Tooltip title="Save Form">
              <button
                onClick={() => handleSave(true)}
                className="bg-green-200/50 p-2 m-1 rounded border"
              >
                Save & Close
              </button>
            </Tooltip>
            {formID && (
              <Tooltip title="Print PDF">
                <button
                  onClick={handlePrint}
                  className="bg-blue-200/50 p-2 m-1 rounded border"
                >
                  Print PDF
                </button>
              </Tooltip>
            )}
            <Tooltip title="Submit Form as Order">
              <button
                onClick={() => {
                  setPreSubmit(true);
                }}
                className="bg-yellow-200/50 p-2 m-1 rounded border"
              >
                Submit as Order
              </button>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default InteriorDoor;
