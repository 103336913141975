import React, { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import { Tooltip as Tooltips } from "chart.js";
import { Pie, getElementAtEvent } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltips, Legend);

const LeadSummaryPie = (props) => {
  const isDarkMode = window.document.documentElement.classList[0] === "dark";
  const [unassigned, setUnassigned] = useState(0);
  const [unassignedList, setUnassignedList] = useState([]);
  const chartRef = useRef();

  const onClick = (event) => {
    var elem = getElementAtEvent(chartRef.current, event);
    if (elem[0].index < 3) {
      props.setValue((elem[0].index + 1).toString());
    } else {
    }
  };

  useEffect(() => {
    if (props.leads) {
      props.leads.map((item) => {
        if (!props.leads.assignedManager && !props.leads.assignedSalesRep) {
          setUnassigned(unassigned + 1);
          setUnassignedList({ ...unassignedList, item });
        }
      });
    }
  }, [props.leads]);

  const backgroundColor = isDarkMode
    ? [
        "rgba(227, 221, 50, 0.3)",
        "rgba(72, 198, 17, 0.3)",
        "rgba(176, 16, 16, 0.3)",
        // "rgba(100, 100, 23, 0.3)",
      ]
    : [
        "rgba(158, 154, 36, 1)",
        "rgba(53, 148, 12, 1)",
        "rgba(117, 9, 9, 1)",
        // "rgba(100, 100, 23, 0.3)",
      ];

  const borderColor = isDarkMode
    ? [
        "rgba(227, 221, 50, 1)",
        "rgba(72, 198, 17, 1)",
        "rgba(176, 16, 16, 1)",
        // "rgba(100, 100, 23, 1)",
      ]
    : [
        "rgba(227, 221, 50, 1)",
        "rgba(72, 198, 17, 1)",
        "rgba(176, 16, 16, 1)",
        // "rgba(100, 100, 23, 1)",
      ];

  const totalLead = {
    labels: ["In Progress", "Complete", "Deleted"],
    datasets: [
      {
        data: [
          props.leadCounts?.totalActive,
          props.leadCounts?.totalComplete,
          props.leadCounts?.totalDeleted,
          // unassigned,
        ],
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
      },
    ],
  };
  return <Pie onClick={onClick} ref={chartRef} data={totalLead} />;
};

export default LeadSummaryPie;
