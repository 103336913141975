import React, { useEffect, useRef, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  TextField,
} from "@mui/material";
import { Dialog } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utilities";
import { VehicleItems } from "./VehicleItems";

const VehicleForm = () => {
  const [open, setOpen] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [notsafeopen, setnotsafeOpen] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedHelpItem, setSelectedHelpItem] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [selectedDefectItems, setSelectedDefectItems] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const stepperRef = useRef(null);
  const { unit, branch } = useParams();
  const navigate = useNavigate();

  const markUnsafe = () => {
    const data = {
      unit: unit,
      branch: branch,
      ...selectedOptions,
    };

    fetch("/api/fleet/forklift/forkNotSafe", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify(data),
    });
    navigate(`/fleet/forks/dashboard/${branch}`);
  };

  useEffect(() => {
    const stepContainers = document.querySelectorAll(".MuiStep-root");

    if (stepContainers[activeStep]) {
      const viewportHeight = window.innerHeight;
      const stepContainerTop =
        stepContainers[activeStep].getBoundingClientRect().top;
      const scrollToPosition =
        stepContainerTop + window.scrollY - viewportHeight / 2;
      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  }, [activeStep]);

  const handleNameChange = (event) => {
    const inputValue = event.target.value;
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      name: inputValue,
    }));
  };

  const handleOdometerChange = (event) => {
    const inputValue = event.target.value;
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      odometer: inputValue,
    }));
  };

  const handleConformation = () => {
    setShowConfirmationDialog(true);
  };

  const handleNotSafe = (item) => {
    handleConformation();
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      notSafe: true,
      notSafeItem: item.title,
    }));
  };

  const handleButtonClick = (item, option) => {
    if (option === item.options[1]) {
      handleDefect(item.field);
    } else {
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [item.field]: option,
      }));

      setInputValues((prevInputValues) => {
        const updatedInputValues = { ...prevInputValues };
        updatedInputValues[item.field] = "";
        return updatedInputValues;
      });

      handleNext(); // Move to the next step after selecting an option

      if (selectedDefectItems.includes(item)) {
        setSelectedDefectItems((prevSelectedDefectItems) =>
          prevSelectedDefectItems.filter((defectItem) => defectItem !== item)
        );
      }
    }
  };

  const handleInputChange = (event, item) => {
    const inputValue = event.target.value;

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [item.field]: inputValue,
    }));

    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [`${item.field}`]: `defect_${inputValue}` || null,
    }));
  };

  const handleDefect = (field) => {
    const item = VehicleItems.find((info) => info.field === field);
    setSelectedItem(item);
    setOpen(true);
    setSelectedDefectItems((prevSelectedDefectItems) => [
      ...prevSelectedDefectItems,
      item,
    ]);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenHelp(false);

    // Keep the defect buttons highlighted
    setSelectedDefectItems((prevSelectedDefectItems) =>
      prevSelectedDefectItems.filter(
        (defectItem) => inputValues[defectItem.field]
      )
    );

    // Proceed to the next step
    handleNext();
  };

  const handlelockoutClose = () => {
    setnotsafeOpen(false);
    markUnsafe();
  };

  const handleSubmit = async () => {
    const requiredFieldsCount = 12;
    const selectedFieldsCount = Object.keys(selectedOptions).length;

    if (selectedFieldsCount < requiredFieldsCount) {
      const missingFieldsCount = requiredFieldsCount - selectedFieldsCount;

      alert(
        `You have not filled in all the fields. You are missing ${missingFieldsCount} fields.`
      );

      return; // Do not proceed with submission
    }

    navigate(`/fleet/forks/dashboard/${branch}`);

    const data = {
      unit: unit,
      branch: branch,
      ...selectedOptions,
    };

    const rawResponse = await fetch("/api/fleet/vehicle/submitEntry", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const name = new URLSearchParams(window.location.search).get("name");

    if (name) {
      setSelectedOptions({
        ...selectedOptions,
        name: name,
      });
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-center m-2 p-2">
        <p className="font-black p-2">Unit #{unit} Checklist</p>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <div>
          <p>Name</p>
          <input
            placeholder="Enter Your Name"
            value={selectedOptions.name}
            className="p-2 bg-white m-2 rounded-md"
            onChange={handleNameChange}
          />
        </div>
        <div>
          <p>Odometer</p>
          <input
            placeholder="Enter Odometer"
            value={selectedOptions.odometer}
            className="p-2 bg-white m-2 rounded-md"
            onChange={handleOdometerChange}
          />
        </div>
      </div>
      <div className="flex flex-col w-full items-center">
        <Stepper
          ref={stepperRef}
          activeStep={activeStep}
          orientation="vertical"
        >
          {VehicleItems.map((item) => {
            return (
              <Step key={item.title}>
                <StepLabel>
                  {item.title} [{selectedOptions[item.field]}]
                </StepLabel>
                <div className="flex w-full">
                  <StepContent>
                    <div className="flex flex-col">
                      {/* Render the options for this step */}
                      <div className="flex w-64">
                        {item.options.map((option, optionIndex) => (
                          <div className="m-1">
                            <button
                              className="m-2 p-2 bg-blue-200/80"
                              key={optionIndex}
                              onClick={() => handleButtonClick(item, option)}
                              // ... button styles ...
                            >
                              {option}
                            </button>
                          </div>
                        ))}
                      </div>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Go Back
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </div>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded p-2 m-2"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"sm"}
          fullWidth={true}
        >
          <p className="m-4 border-b border-black">
            {selectedItem?.description}
          </p>
          <div className="m-2 justify-center flex flex-col items-center">
            <input
              type="text"
              placeholder="Describe Defect"
              value={inputValues[selectedItem?.field] || ""}
              onChange={(event) => handleInputChange(event, selectedItem)}
              className="border border-black rounded-md p-2 m-2 w-full"
            />
            {selectedOptions.notSafe && (
              <div className="flex flex-col items-center text-red-400 font-black">
                <p className="m-2">Vehicle has been marked as not safe.</p>
                <p className="m-2">{selectedOptions.notSafeItem?.title}</p>
              </div>
            )}
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => {
                handleNotSafe(selectedItem);
              }}
              className="bg-red-400 text-white font-black p-2 m-2 rounded"
            >
              Not Safe
            </button>
            <button
              onClick={handleClose}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded p-2 m-2"
            >
              Close
            </button>
          </div>
        </Dialog>
        <Dialog
          open={openHelp}
          onClose={handleClose}
          maxWidth={"sm"}
          fullWidth={true}
        >
          <div>
            <p className="m-4 border-b border-black">
              {selectedHelpItem?.title}
            </p>
            <p className="m-4 border-b border-black">
              {selectedHelpItem?.help}
            </p>
          </div>
        </Dialog>
        <Dialog
          open={showConfirmationDialog}
          onClose={() => setShowConfirmationDialog(false)}
          maxWidth={"sm"}
          fullWidth={true}
        >
          <p className="m-4">Are you sure this vehicle is unsafe?</p>
          <div className="m-2 justify-center flex">
            <button
              onClick={() => {
                setnotsafeOpen(true);
              }}
              className="bg-red-400 text-white font-bold p-2 m-2 rounded"
            >
              Yes
            </button>
            <button
              onClick={() => setShowConfirmationDialog(false)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 m-2 rounded"
            >
              No
            </button>
          </div>
        </Dialog>

        <Dialog
          open={notsafeopen}
          onClose={handleClose}
          maxWidth={"sm"}
          fullWidth={true}
        >
          <div className="m-2 justify-center flex flex-col items-center">
            {/* Display your photo here */}
            <img src={require("../../assets/lockouttagout.jpg")} />

            {/* OK button to navigate back */}
            <button
              onClick={handlelockoutClose}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded p-2 m-2"
            >
              OK
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default VehicleForm;
