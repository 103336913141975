/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.8 Casing13834.gltf
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Casing13834(props) {
  const { nodes, materials } = useGLTF("/Casing13834.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane.geometry}
        material={materials["Material.001"]}
        position={[0, 1.021, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.05, 1, 0.01]}
      />
    </group>
  );
}

useGLTF.preload("/Casing13834.gltf");
