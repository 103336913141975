import React, { useEffect, useState } from "react";
import FRM_QuoteOrder from "../../components/Forms/Misc/FRM_QuoteOrder";
import FRM_SelectCustomer from "../../components/Forms/Misc/FRM_SelectCustomer";
import FRM_FormSelector from "../../components/Forms/Misc/FRM_FormSelector";
import InteriorDoor from "./Door/InteriorDoor";
import ExteriorDoor from "./Door/ExteriorDoor";
import { makeid } from "../../components/Forms/api";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { Tooltip } from "@mui/material";
import Andersen200400 from "./Window/Andersen200400";
import Integrity from "./Window/Integrity";
import MarvinWindow from "./Window/Marvin";
import Andersen100 from "./Window/Andersen100";
import AndersenA from "./Window/AndersenA";

const CloseHandler = (props) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-center w-full">
        <Tooltip title="Close Draft">
          <button
            className="border border-red-600 text-red-600 mt-3 p-2 hover:bg-red-300"
            onClick={() => {
              props.setFormSelected(false);
            }}
          >
            Close Draft
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

const QuoteOrderForm = () => {
  /*
  Form data will store customer info (account Number, job number, etc...), 
  whether it is a quote or an getOrderInfo.  
  */
  const user = useSelector(selectUser);

  const [formData, setFormData] = useState({
    ref_id: makeid(8),
    userID: user.userID,
    form_type: "quote",
    margin: 30,
  });

  const [formSelected, setFormSelected] = useState(null);

  const Section = () => {
    switch (formSelected) {
      case "interior-door":
        return (
          <>
            <CloseHandler setFormSelected={setFormSelected} />
            <InteriorDoor formData={formData} setFormData={setFormData} />
          </>
        );

      case "exterior-door":
        return (
          <>
            <CloseHandler setFormSelected={setFormSelected} />
            <ExteriorDoor formData={formData} setFormData={setFormData} />
          </>
        );

      default:
        return (
          <div className="border border-gray-500 bg-white/5 m-4 rounded-md">
            {/* Select Customer via search or manual entry. */}
            <FRM_SelectCustomer formData={formData} setFormData={setFormData} />
            {/* Get for type quote/order */}
            <FRM_QuoteOrder formData={formData} setFormData={setFormData} />
            {/* Select for and pass customer data and form type to the form */}

            <FRM_FormSelector
              formData={formData}
              setFormData={setFormData}
              setFormSelected={setFormSelected}
            />
          </div>
        );
        break;
    }
  };

  return (
    <>
      {!formSelected ? (
        <div className="m-2">
          {/* Select Customer via search or manual entry. */}
          <FRM_SelectCustomer formData={formData} setFormData={setFormData} />
          {/* Get for type quote/order */}
          <FRM_QuoteOrder formData={formData} setFormData={setFormData} />
          {/* Select for and pass customer data and form type to the form */}

          <FRM_FormSelector
            formData={formData}
            setFormData={setFormData}
            setFormSelected={setFormSelected}
          />
        </div>
      ) : (
        <Section />
      )}
    </>
  );
};

export default QuoteOrderForm;
