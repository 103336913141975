export const getAllWebLeads = (setter) => {
  return fetch(`/api/leads/getall/web`)
    .then((response) => response.json())
    .then((responseJson) => {
      var items = [];
      responseJson.map((item) => {
        if (item.lat != null) {
          var customer = {
            entryDate: item.entryDate,
            customerName: item.customerName,
            customerEmail: item.customerEmail,
            customerPhone: item.customerPhone,
            customerAddress: `${item.customerAddress}`,
            customerHearAbout: `${item.hearAbout} ${item.hearOther}`,
            customerWorkingOn: item.customerWorkingOn,
            lat: item.lat,
            lon: item.lon,
            completed: item.completed,
            deleted: item.deleted,
          };
        }

        items.push(customer);
      });
      setter(items);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const containerStyle = {
  width: "100%",
  height: "80vh",
};

export const center = {
  lat: 41.7534,
  lng: -86.1108,
};

export const storeCoords = {
  roseland: {
    lat: 41.71699490766023,
    lng: -86.25055879842327,
  },

  elkhart: {
    lat: 41.696626921317886,
    lng: -85.97158684920544,
  },

  three_oaks: {
    lat: 41.80064091363884,
    lng: -86.6103532511433,
  },

  coloma: {
    lat: 42.18657234591788,
    lng: -86.29642814303219,
  },

  mishawaka: {
    lat: 41.68029946666671,
    lng: -86.16795521995343,
  },

  adrian: {
    lat: 41.89382871843988,
    lng: -84.03315656900398,
  },

  eau_claire: {
    lat: 41.9853622696042,
    lng: -86.29892063204221,
  },

  granger: {
    lat: 41.75542402583833,
    lng: -86.11566442320111,
  },

  cromwell: {
    lat: 41.405919703360276,
    lng: -85.6112478404758,
  },

  laporte: {
    lat: 41.611963446331025,
    lng: -86.72527748268256,
  },

  shipshewana: {
    lat: 41.677910765500854,
    lng: -85.57610695827266,
  },

  schoolcraft: {
    lat: 42.12494867946055,
    lng: -85.63767800626793,
  },

  fort_wayne: {
    lat: 41.06759383327859,
    lng: -85.10753317829592,
  },

  kendallville: {
    lat: 41.445340779525196,
    lng: -85.26121870263047,
  },

  edgerton: {
    lat: 41.4545428535777,
    lng: -84.74756083355497,
  },

  delton: {
    lat: 42.494860606375056,
    lng: -85.41346688785458,
  },
};
