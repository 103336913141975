import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { Autocomplete, Dialog, TextField } from "@mui/material";
import { getAllUsers, getAllUsersNoSales } from "../../../utilities";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/Other/LoadingSpinner";

const RecognitionForm = () => {
  const [formData, setFormData] = useState({ value: [], files: [] });
  const [status, setStatus] = useState(false);
  const [other, setOther] = useState("");
  const [otherOpen, setOtherOpen] = useState(false);
  const [otherUser, setOtherUser] = useState(false);

  const { name, mobile } = useParams();

  const [team, setTeam] = useState(null);

  const navigate = useNavigate();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (!mobile && user.name !== undefined && user.name !== null) {
      setFormData({ ...formData, submit_user: user.name });
    } else {
      if (mobile) {
        setFormData({ ...formData, submit_user: name });
      } else {
        navigate("/inituser");
      }
    }
  }, [user]);

  useEffect(() => {
    if (formData.employee === "Other") {
      setOtherUser(true);
    }
  }, [formData]);

  useEffect(() => {
    getAllUsersNoSales(setTeam);
  }, []);

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append("employee", data.employee);
    formData.append("explanation", data.explanation);
    formData.append("submit_user", data.submit_user);
    formData.append("email", data.email);
    formData.append("value", data.value);
    data.files.forEach((file) => {
      formData.append("files[]", file);
    });

    console.log(formData);

    const rawResponse = await fetch("/api/forms/submit/recognitionForm", {
      method: "POST",
      body: formData,
    });
    const content = await rawResponse.json();

    if (content.success === 1) {
      setStatus(true);
      return;
    } else {
      setStatus(false);
      return;
    }
  };

  const removeItem = (indexToRemove) => {
    setFormData((prevState) => ({
      ...formData,
      value: prevState.value.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formData, files: [...formData.files, ...files] });
  };

  return (
    <div className="flex flex-col items-center w-full dark:text-white">
      {formData.submit_user ? (
        <div className="flex flex-col w-full items-center">
          <div className="flex w-full justify-center m-2">
            <h1 className="font-black md:text-xl underline mb-2">
              'C'aught in the Act
            </h1>
          </div>
          <div className="flex flex-col w-full items-center">
            <div className="flex md:flex-row flex-col items-center md:items-end mb-1 w-full justify-center">
              I want to recognize{" "}
              {!otherUser && team ? (
                <Autocomplete
                  disablePortal
                  placeholder="Employee Name"
                  className="w-64 m-1 rounded-none"
                  options={team}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Employee..."
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) => {
                    console.log(value);

                    setFormData({
                      ...formData,
                      employee: value?.label,
                      email: value?.email,
                    });
                  }}
                />
              ) : (
                <div className="flex items-center mr-1 ml-1">
                  <input
                    onChange={(e) => {
                      setFormData({ ...formData, employee: e.target.value });
                    }}
                    type="text"
                    placeholder="Enter Name"
                    className="w-64"
                  />
                  <button
                    onClick={() => setOtherUser(false)}
                    className="text-red-500 p-2 border border-black"
                  >
                    X
                  </button>
                </div>
              )}
              for demonstrating
            </div>
            <div className="flex flex-col">
              <div className="flex md:flex-row flex-col items-center justify-center">
                <select
                  onChange={(e) => {
                    if (e.target.value !== "Other") {
                      setFormData({
                        ...formData,
                        value: [...formData.value, e.target.value],
                      });
                    } else {
                      setOtherOpen(true);
                    }
                  }}
                  value={"Select Values"}
                  className="w-64 m-1"
                >
                  <option>[Select One or More]</option>
                  <option value={"Integrity"}>Integrity</option>
                  <option value={"Urgency"}>Urgency</option>
                  <option value={"Safety"}>Safety</option>
                  <option value={"Profitability"}>Profitability</option>
                  <option value={"Respect"}>Respect</option>
                  <option value={"Fun"}>Fun</option>
                  <option value={"Community"}>Community</option>
                  <option value={"Advocate"}>Advocate</option>
                  <option value={"Relationships"}>Relationships</option>
                  <option value={"Impression"}>Impression</option>
                  <option value={"Salesmanship"}>Salesmanship</option>
                  <option value={"Teamwork"}>Teamwork</option>
                  <option value={"Caring"}>Caring</option>
                  <option value={"Other"}>Other</option>
                </select>{" "}
                in the following manner.
              </div>
              <div className="flex">
                {formData.value.map((item, index) => {
                  return (
                    <div>
                      <p className="border p-1 m-1 rounded">
                        {item}
                        <button
                          onClick={() => removeItem(index)}
                          className="ml-1 text-red-500"
                        >
                          X
                        </button>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center mb-2">
            <textarea
              onChange={(e) => {
                setFormData({ ...formData, explanation: e.target.value });
              }}
              placeholder="Explain Here..."
              className="m-1 w-1/2 h-40 p-2"
              style={{ resize: "none", overflow: "auto" }}
            />
          </div>

          <div className="mb-2">
            <input type="file" onChange={handleFileChange} multiple />
          </div>

          <div className="flex flex-col items-center">
            <p>
              Submitting this form as <strong>{formData.submit_user}</strong>
            </p>
            <button
              onClick={() => {
                submitForm(formData);
                //console.log(formData);
              }}
              className="m-1 p-1 bg-blue-500/80 hover:bg-blue-500 rounded text-white"
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full h-screen justify-center items-center">
          <div className="flex flex-col items-center">
            <p className="font-bold text-xl">Loading User Data...</p>
            <LoadingSpinner />
          </div>
        </div>
      )}
      <Dialog
        maxWidth="md"
        className="flex w-full justify-center"
        open={status}
        onClose={() => {
          window.location.reload();
        }}
      >
        <div className="p-6 flex justify-center flex-col items-center">
          <p className="text-xl m-2">Form successfully submitted!</p>
          <button
            className="m-1 p-1 bg-blue-500/80 hover:bg-blue-500 rounded text-white"
            onClick={() => {
              window.location.reload();
            }}
          >
            Close
          </button>
        </div>
      </Dialog>

      <Dialog
        maxWidth="md"
        className="flex w-full justify-center"
        open={otherOpen}
        onClose={() => {
          setOtherOpen(false);
        }}
      >
        <div className="p-6 flex justify-center flex-col items-center">
          <p className="text-xl m-2">Specify Other</p>

          <input
            onChange={(e) => {
              setOther(e.target.value);
            }}
            type="text"
            placeholder="Other"
            className="w-64 m-1"
          />

          <button
            className="m-1 p-1 bg-blue-500/80 hover:bg-blue-500 rounded text-white"
            onClick={() => {
              setFormData({
                ...formData,
                value: [...formData.value, other],
              });

              setOtherOpen(false);
            }}
          >
            Add
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default RecognitionForm;
