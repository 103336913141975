import React from "react";
import { selectUser } from "../../../features/userSlice";
import { useSelector } from "react-redux";
import NavListAuth from "../../../components/Navigation/NavListAuth";
import RequestDataTable from "../../../components/WindowDoor/RequestDataTable";

const DashboardHome = () => {
  return (
    <>
      <div className="flex flex-wrap justify-center">
        <RequestDataTable />
      </div>
    </>
  );
};

export default DashboardHome;
