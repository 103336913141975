import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { storePins } from "../../features/pinnedSlice";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

const PinnedPageHandler = (props) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const handleDelete = async (item) => {
    var data = JSON.stringify({
      id: user.id,
      item: item,
      remove: 1,
    });

    const rawResponse = await fetch("/api/favorites/pin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const pinData = await rawResponse.json();

    dispatch(storePins(pinData));

    window.location.reload(false);
  };

  return (
    <div
      key={props.item}
      className={
        (props.size === "full" ? "w-5/6" : "w-5/12") +
        " flex flex-col overflow-auto max-h-96 mt-4 border dark:border-white border-black p-4"
      }
    >
      <Tooltip title="Remove Pin">
        <ClearIcon
          onClick={() => {
            handleDelete(props.item);
          }}
          className="text-red-600 cursor-pointer"
        />
      </Tooltip>

      <div className="flex justify-center bg-gray-300/50 dark:bg-white/10 p-4 dark:text-white border border-black dark:border-white">
        <p className="font-black">{props.item}</p>
        <Link to={props.link}>
          <ArrowOutwardIcon />
        </Link>
      </div>
      <div className="relative">{props.component}</div>
    </div>
  );
};

export default PinnedPageHandler;
