import * as React from "react";
const DustSVG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 0 64 64" {...props}>
    <defs>
      <filter id="a" width={1.494} height={1.694} x={-0.247} y={-0.271}>
        <feGaussianBlur in="SourceAlpha" stdDeviation={3} />
        <feOffset dy={4} result="offsetblur" />
        <feComponentTransfer>
          <feFuncA slope={0.05} type="linear" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#a)" transform="translate(16 -2)">
      <g
        fill="none"
        stroke="#cd9e73"
        strokeLinecap="round"
        strokeWidth={2}
        className="am-weather-wind"
      >
        <path strokeDasharray="150 10" d="M9.5 15a4 4 0 1 1 3.5 6H-6">
          <animate
            attributeName="stroke-dashoffset"
            begin="0s"
            dur="3s"
            repeatCount="indefinite"
            values="0; 160"
          />
        </path>
        <path strokeDasharray="144 16" d="M23.5 21a4 4 0 1 1 3.5 6h-37">
          <animate
            attributeName="stroke-dashoffset"
            begin="0.5s"
            dur="3s"
            repeatCount="indefinite"
            values="0; 160"
          />
        </path>
        <path strokeDasharray="150 10" d="M12.5 39a4 4 0 1 0 3.5-6H-1">
          <animate
            attributeName="stroke-dashoffset"
            begin="0.25s"
            dur="3s"
            repeatCount="indefinite"
            values="0; 160"
          />
        </path>
      </g>
    </g>
  </svg>
);
export default DustSVG;
