import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function RequestSnackbar(props) {
  return (
    <>
      <Snackbar
        open={props.open}
        autoHideDuration={6000}
        onClose={() => {
          props.setOpen(false);
          //console.log(props.open);
        }}
      >
        <Alert
          onClose={() => {
            props.setOpen(false);
          }}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </>
  );
}
