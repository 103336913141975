import React, { useEffect, useState } from "react";
import { getAllOnlineUsers } from "../../views/Admin/Utilities/api";
import LogoutIcon from "@mui/icons-material/Logout";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { Dialog } from "@mui/material";
import ManageUser from "./ManageUser";

const CurrentlyOnline = () => {
  const [onlineUsers, setOnlineUsers] = useState(null);
  const currentUser = useSelector(selectUser);
  const [redirectDialog, setRedirectDialog] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [redirectUID, setRedirectUID] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchOnlineUsers = () => {
      // Get all users that are currently online
      getAllOnlineUsers(setOnlineUsers);
    };

    // Fetch initially when the component mounts
    fetchOnlineUsers();

    // Set up an interval to fetch online users every 30 seconds
    const intervalId = setInterval(fetchOnlineUsers, 30000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const handleManage = (user) => {
    setSelectedUser(user);
    setUserModal(true);
  };

  const assignUserAction = async (data) => {
    var data = JSON.stringify(data);

    const rawResponse = await fetch("/api/useractions/addAction", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const content = await rawResponse.json();
  };

  //Display all users that are currently online

  return (
    <>
      {selectedUser && (
        <ManageUser
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          userModal={userModal}
          setUserModal={setUserModal}
          // additional props if needed
        />
      )}

      <div className="flex flex-col items-center w-full bg-white dark:bg-white/5 font-black text-black dark:text-white border border-black">
        <p className="items-center p-4 m-2">Currently Online</p>
        <hr className="border border-gray-950 bg-white dark:border-gray-500 dark:bg-white/5 w-full" />

        <div className="flex items-center justify-center  w-full m-2 overflow-auto">
          {onlineUsers &&
            onlineUsers.map((user) => (
              <div className="flex flex-col items-center m-2  text-black dark:text-white dark:border-gray-500 p-1 w-30 h-30 justify-center">
                <div className="w-12 h-12 bg-gray-300 rounded-full overflow-hidden border-green-400 border-2">
                  <img
                    src={`/api/profile/image?id=${user?.id}`}
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="flex">
                  <p className="text-sm">{user?.name}</p>
                </div>

                <p className="text-xs font-light overflow-auto w-48 flex justify-center align-center p-1 bg-blue-200/20 m-1 rounded-md border border-blue-900 dark:border-cyan-200">
                  {user?.last_route}
                </p>

                <div className="flex">
                  <button
                    onClick={() => {
                      setLogoutDialog(true);
                      setRedirectUID(user);
                    }}
                  >
                    <LogoutIcon className="m-1" />
                  </button>

                  <button
                    onClick={() => {
                      setRedirectDialog(true);
                      setRedirectUID(user);
                    }}
                  >
                    <NorthEastIcon className="m-1" />
                  </button>
                  <button
                    onClick={() => {
                      handleManage(user);
                    }}
                  >
                    <ManageAccountsIcon className="m-1" />
                  </button>
                </div>
              </div>
            ))}
        </div>
        <Dialog
          open={redirectDialog}
          onClose={() => setRedirectDialog(false)}
          maxWidth="sm"
          fullWidth={true}
        >
          <div className="flex flex-col items-center justify-center p-4">
            <div>
              <p>
                Send <strong>{redirectUID?.name}</strong> to a different page?
              </p>
            </div>
            <input
              type="text"
              className="border border-gray-950 bg-white dark:border-gray-500 dark:bg-white/5 p-4 text-black dark:text-white rounded-md m-2 w-3/4"
              placeholder="Enter URL"
              onChange={(e) => setRedirect(e.target.value)}
            />
            <div className="flex justify-center">
              <button
                onClick={() => {
                  assignUserAction({
                    uid: redirectUID?.id,
                    redirect: redirect,
                    action_created_by: currentUser?.name,
                  });
                  setRedirectDialog(false);
                }}
                className="border border-gray-950 bg-green-400/80 dark:border-gray-500  p-2 text-black dark:text-white rounded-md m-2"
              >
                Redirect User
              </button>
              <button
                onClick={() => {
                  setRedirectDialog(false);
                }}
                className="border border-gray-950 bg-red-400/80 dark:border-gray-500  p-2 text-black dark:text-white rounded-md m-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={logoutDialog}
          onClose={() => setLogoutDialog(false)}
          maxWidth="sm"
          fullWidth={true}
        >
          <div className="flex flex-col items-center justify-center p-4">
            <div>
              <p>
                Are you sure you want to log{" "}
                <strong>{redirectUID?.name}</strong> off?
              </p>
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => {
                  assignUserAction({
                    uid: redirectUID?.id,
                    logout: 1,
                    action_created_by: currentUser?.name,
                  });
                  setLogoutDialog(false);
                }}
                className="border border-gray-950 bg-green-400/80 dark:border-gray-500  p-2 text-black dark:text-white rounded-md m-2"
              >
                Yes
              </button>

              <button
                onClick={() => {
                  setLogoutDialog(false);
                }}
                className="border border-gray-950 bg-red-400/80 dark:border-gray-500  p-2 text-black dark:text-white rounded-md m-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default CurrentlyOnline;
