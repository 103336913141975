import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import { Tooltip } from "@mui/material";
import { useMsal } from "@azure/msal-react";

const LogoutButton = () => {
  const { instance } = useMsal();

  const dispatch = useDispatch();

  return (
    <>
      <Tooltip title="Logout!">
        <LogoutIcon
          className="dark:text-white text-black hover:text-blue-500"
          onClick={() => {
            dispatch(logout());
            instance.logoutRedirect();
          }}
        />
      </Tooltip>
    </>
  );
};

export default LogoutButton;
