import React, { useEffect, useState } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { useDispatch } from "react-redux";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { savePinned } from "../../views/Admin/Utilities/api";
import { destroyPins, selectPins, storePins } from "../../features/pinnedSlice";

function PinButton(props) {
  const [isPinned, setIsPinned] = useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const pins = useSelector(selectPins);

  const savePinned = async () => {
    var data = JSON.stringify({
      id: user.id,
      item: props.item,
      isPinned: !isPinned,
    });

    const rawResponse = await fetch("/api/favorites/pin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const pin = await rawResponse.json();

    dispatch(storePins(pin));
  };
  const togglePins = () => {
    //dispatch(destroyPins());
    savePinned();
    setIsPinned((prevState) => !prevState);
  };

  const darkIconStyle = {
    color: props.color || "rgba(255, 255, 255, 1)",
  };

  const iconStyle = {
    color: props.color || "rgba(0, 0, 0, 1)",
  };

  useEffect(() => {
    if (pins) {
      pins?.pins?.forEach((pins) => {
        if (pins === props.item) {
          setIsPinned(true);
        }
      });
    }
  }, [pins]);

  return (
    <div onClick={togglePins}>
      {isPinned ? (
        <PushPinIcon
          style={
            window.document.documentElement.classList[0] === "dark"
              ? darkIconStyle
              : iconStyle
          }
        />
      ) : (
        <PushPinOutlinedIcon
          style={
            window.document.documentElement.classList[0] === "dark"
              ? darkIconStyle
              : iconStyle
          }
        />
      )}
    </div>
  );
}

export default PinButton;
