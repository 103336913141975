import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useSelector } from "react-redux";
import { selectEvents } from "../../features/eventSlice";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";

var events = [];

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <button className="flex w-full">
      <div className="flex flex-wrap w-full bg-cyan-900/50 border border-cyan-900 p-2 rounded-md overflow-auto">
        <p className="font-black p-1">{eventInfo.timeText}</p>
        <div>
          <i className="p-1">{eventInfo.event.title}</i>
        </div>
      </div>
    </button>
  );
}

const MyCalendar = () => {
  const event = useSelector(selectEvents);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const getEvents = () => {
    events = [];
    event.value.map((item, index) => {
      events.push({
        title: item.subject,
        start: new Date(item.start.dateTime) - 14400000,
        end: new Date(item.end.dateTime) - 14400000,
        onlineMeetingUrl: item?.onlineMeeting?.joinUrl, // Add the onlineMeeting.joinUrl property
        attendees: item.attendees,
      });
    });

    return events;
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
  };

  const handlePopupClose = () => {
    setSelectedEvent(null);
  };

  useEffect(() => {
    setSelectedEvent(null);
  }, [event.value]);

  return (
    <div className="flex flex-col w-full h-screen bg-white p-2 rounded-md">
      {event.value.length > 0 && (
        <FullCalendar
          height={"100%"}
          width={100}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          weekends={false}
          events={getEvents()}
          eventContent={renderEventContent}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "Short",
          }}
          eventClick={handleEventClick}
          eventMouseEnter={(mouseEnterInfo) => {
            mouseEnterInfo.el.setAttribute("title", "Click for more info");
          }}
        />
      )}
      {selectedEvent && (
        <div className="bg-gray-800 border-b border-gray-500 dark:bg-gray-900 dark:border-gray-700 z-40 p-8 m-4 text-white absolute rounded left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 items-center justify-center flex flex-col drop-shadow-2xl">
          <button>
            <CloseIcon
              sx={{ color: "red" }}
              className="absolute top-2 right-2 text-gray-300 hover:text-gray-100"
              onClick={handlePopupClose}
            />
          </button>

          <h2 className="m-2 font-black text-xl">{selectedEvent.title}</h2>
          <p className="m-2">
            {selectedEvent.start.toLocaleTimeString()} -{" "}
            {selectedEvent.end.toLocaleTimeString()}
          </p>
          <div>
            <p>Attendees</p>
          </div>
          <div className="border border-gray-500 bg-white/5 flex flex-wrap overflow-auto max-w-md max-h-32">
            {selectedEvent.extendedProps.attendees.map((item, index) => {
              return (
                <Tooltip title={item.emailAddress.address}>
                  <div className="flex " key={index}>
                    <p className="m-2 underline">{item.emailAddress.name}</p>
                  </div>
                </Tooltip>
              );
            })}
          </div>
          {selectedEvent.extendedProps.onlineMeetingUrl && (
            <div className="flex border-t w-full mt-4 justify-center">
              <a
                href={selectedEvent.extendedProps.onlineMeetingUrl}
                target="_blank"
              >
                <button className="p-2 bg-white/10 border rounded-md m-2">
                  Join Meeting
                </button>
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyCalendar;
