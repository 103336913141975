import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Alert, AlertTitle } from "@mui/material";

import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const icons = {
  Clear: (props) => <DeleteIcon className="text-black dark:text-white" />,
  Search: (props) => <SearchIcon className="text-black dark:text-white" />,
  ResetSearch: (props) => <DeleteIcon className="text-black dark:text-white" />,
  NextPage: (props) => (
    <NavigateNextIcon className="text-black dark:text-white" />
  ),
  PreviousPage: (props) => (
    <NavigateBeforeIcon className="text-black dark:text-white" />
  ),
  FirstPage: (props) => (
    <SkipPreviousIcon className="text-black dark:text-white" />
  ),
  LastPage: (props) => <SkipNextIcon className="text-black dark:text-white" />,
  SortArrow: (props) => {
    if (props.className.includes("iconDirectionDesc")) {
      return <ArrowDropDownIcon className="text-black dark:text-white" />;
    } else {
      return <ArrowDropUpIcon className="text-black dark:text-white" />;
    }
  },
};

export const tableOptions = {
  pageSize: 45, // make initial page size
  emptyRowsWhenPaging: false, // To avoid of having empty rows
  pageSizeOptions: [45, 75, 150, 250],
  minBodyHeight: "100px",
  maxBodyHeight: "700px",
  headerStyle: {
    search: true,
    backgroundColor:
      window.document.documentElement.classList[0] === "dark"
        ? "rgba(255, 255, 255, 0.05)"
        : "rgba(255, 255, 255, 1)",
    color:
      window.document.documentElement.classList[0] === "dark"
        ? "white"
        : "black",
  },
  searchFieldStyle: {
    color:
      window.document.documentElement.classList[0] === "dark"
        ? "white"
        : "black",
    borderBottom:
      window.document.documentElement.classList[0] === "dark"
        ? "1px solid white"
        : "1px solid black",
  },
  searchFieldStyle: { color: "white", borderBottom: "1px solid white" },
};

export const tableLocalization = {
  body: {
    emptyDataSourceMessage: (
      <div className="flex justify-center">
        <Alert severity="warning">
          <AlertTitle>No Orders Found</AlertTitle>
          If you think this is a mistake please contact{" "}
          <strong>Help Desk</strong>, thank you.
        </Alert>
      </div>
    ),
  },
};

export const setCellStyle = (cellValue) => {
  if (cellValue === "Awaiting Picking") {
    return {
      backgroundColor: "#03C03C",
    };
  } else if (cellValue === "Awaiting Stock") {
    return {
      backgroundColor: "#FFC72C",
    };
  } else if (
    cellValue === "Delivery Request Pending" ||
    cellValue === "Delivery Request Accepted"
  ) {
    return {
      backgroundColor: "#088DA5",
      color: "white",
    };
  } else if (cellValue === "Delivery Request Rejected") {
    return {
      backgroundColor: "#880808",
      color: "white",
    };
  } else {
    return {};
  }
};

export const rowRenderStatus = (rowData, userRequests) => {
  let obj = userRequests.find((request) => request.orderID === rowData.OrderID);

  if (obj) {
    if (obj.requestStatus === 0) {
      rowData.Status = "Delivery Request Pending";
    } else if (obj.requestStatus === 1) {
      rowData.Status = "Delivery Request Accepted";
    } else if (obj.requestStatus === 2) {
      rowData.Status = "Delivery Request Rejected";
    } else {
      rowData.Status = "Delivery Requested";
    }
  }

  return <>{rowData.Status}</>;
};

export const normalizeDate = (rowData) => {
  let rowDate = new Date(rowData["Order Date"]).toLocaleDateString("en-US");

  return <p>{rowDate}</p>;
};

export const checkDeliveryStatus = (rowData) => {
  if (
    rowData["Status"] === "Delivery Request Pending" ||
    rowData["Status"] === "Delivery Request Accepted"
  ) {
    return true;
  } else {
    return false;
  }
};
