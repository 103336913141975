import React, { useState } from "react";
import Webcam from "react-webcam";
import { readPhoneScan } from "../../utilities";
import Tesseract from "tesseract.js";
import { Box, CircularProgress, Typography } from "@mui/material";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        className="w-20 h-20"
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const ManagePhone = () => {
  const [image, setImage] = useState(null);
  const [uploadResults, setUploadResults] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleUpload = async () => {
    if (image) {
      Tesseract.recognize(image, "eng", {
        logger: (m) => {
          setProgress(m.progress * 100);
        },
      }).then(({ data: { text } }) => {
        setUploadResults(text);
      });
    }
  };

  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-col m-2">
        {!image && (
          <Webcam
            className="rounded"
            audio={false}
            screenshotFormat="image/jpeg"
            width={500}
            videoConstraints={videoConstraints}
          >
            {({ getScreenshot }) => (
              <button
                onClick={() => {
                  const imageSrc = getScreenshot();

                  setImage(imageSrc);
                }}
              >
                Capture photo
              </button>
            )}
          </Webcam>
        )}

        {image && (
          <div className="flex flex-col items-center">
            <img className="w-128 m-4 rounded-md" src={image} />
            <div className="flex justify-center">
              <button
                className="m-2 p-2 bg-blue-800/50 rounded"
                onClick={() => {
                  setImage(null);
                }}
              >
                RETAKE
              </button>
              <button
                className="m-2 p-2 bg-green-600/50 rounded"
                onClick={() => {
                  handleUpload();
                }}
              >
                SUBMIT
              </button>
            </div>
            {progress > 1 && <CircularProgressWithLabel value={progress} />}
          </div>
        )}

        {uploadResults && (
          <div className="flex flex-col items-center">{uploadResults}</div>
        )}
      </div>
    </div>
  );
};

export default ManagePhone;
