import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import roleReducer from "../features/roleSlice";
import eventReducer from "../features/eventSlice";
import favoriteReducer from "../features/favoriteSlice";
import pinnedReducer from "../features/pinnedSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const reducer = combineReducers({
  user: userReducer,
  role: roleReducer,
  event: eventReducer,
  favorite: favoriteReducer,
  pins: pinnedReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
