import { TextField } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import React, { useState } from "react";
import { addLocation } from "./Utilities/api";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";

const AddLocation = (props) => {
  const [location, setLocation] = useState("");
  const user = useSelector(selectUser);

  return (
    <div className="flex flex-col items-center mt-2">
      <div className="flex justify-center m-2">
        <p className="text-sm font-bold uppercase text-black">Add Location</p>
      </div>
      <div className="flex justify-center">
        <TextField
          onChange={(e) => {
            setLocation(e.target.value);
          }}
          value={location}
          id="outlined-basic"
          label="Location"
          variant="outlined"
        />
        <button
          onClick={() => {
            addLocation(location, user.name);
            props.setFlag(!props.flag);
            setLocation("");
          }}
        >
          <ControlPointIcon />
        </button>
      </div>
    </div>
  );
};

export default AddLocation;
