import { Button, Divider } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { normalizeDate, stringToColor } from "../../utilities";
import { getAllUsers } from "../../views/Admin/Utilities/api";
import CheckUserAdmin from "./CheckUserAdmin";
import DisplayAllUserRoles from "./DisplayAllUserRoles";
import ManageUser from "./ManageUser";
import { useTable, useSortBy, usePagination } from "react-table";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from "@mui/icons-material";

const DisplayAllUsers = () => {
  const user = useSelector(selectUser);
  const [userData, setUserData] = useState(null);
  const [userDataFiltered, setUserDataFiltered] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [flag, setFlag] = useState(false);

  const handleManage = (user) => {
    setSelectedUser(user);
    setUserModal(true);
  };

  const data = useMemo(() => userDataFiltered || [], [userDataFiltered]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value }, row: { original } }) => (
          <div className="flex flex-col items-center justify-center">
            <p>{value}</p>
            <div className="w-20 h-20 bg-gray-300 rounded-full overflow-hidden m-2 border-cyan-800 border-2">
              <img
                src={`/api/profile/image?id=${original.id}`}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        ),
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Last Seen",
        accessor: "last_seen",
        Cell: ({ cell: { value }, row: { original } }) => {
          return (
            <div className="flex flex-col justify-center items-center flex-wrap">
              <p>{normalizeDate(value)}</p>
              {original.last_route && (
                <div className="flex flex-col">
                  {original.time_spent && (
                    <p className="text-xs m-2 w-20 overflow-auto">
                      Total:{" "}
                      {Math.round(
                        (original.time_spent / 60 / 60 + Number.EPSILON) * 100
                      ) / 100}{" "}
                      Hours
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        },
      },

      {
        Header: "Roles",
        accessor: "roles",
        Cell: ({ value }) => (
          <div className="flex flex-wrap justify-center max-w-sm">
            {value?.map((item) => (
              <p
                style={{ borderColor: stringToColor(item) }}
                key={item}
                className="m-1 p-1 border rounded-md border-gray-500 bg-white/20 p-2 text-black dark:text-white"
              >
                {item}
              </p>
            ))}
          </div>
        ),
      },
      {
        Header: "Manage",
        accessor: "id",

        Cell: ({ cell: { value }, row: { original } }) => (
          <Button
            onClick={() => handleManage(original)}
            size="small"
            className="bg-blue-500 hover:bg-blue-600 text-black dark:text-white rounded-md focus:outline-none"
          >
            Manage
          </Button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useSortBy,
    usePagination
  );

  //Search Users by name
  const handleSearch = (e) => {
    const search = e.target.value;
    if (search) {
      const filtered = userData.filter((user) => {
        return user.name.toLowerCase().includes(search.toLowerCase());
      });
      setUserDataFiltered(filtered);
    } else {
      setUserDataFiltered(userData);
    }
  };

  //Get User on initial page load
  useEffect(() => {
    getAllUsers(setUserData);
  }, []);

  //Set filtered data to all data on initial page load
  useEffect(() => {
    setUserDataFiltered(userData);
  }, [userData]);

  //update user info when role is added/removed
  useEffect(() => {
    getAllUsers(setUserData);
  }, [flag]);

  return (
    <>
      <CheckUserAdmin />
      {selectedUser && (
        <ManageUser
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          userModal={userModal}
          setUserModal={setUserModal}
          flag={flag}
          setFlag={setFlag}
        />
      )}
      <div className="flex justify-center items-center m-2 w-full">
        <div className="flex flex-col w-full">
          <p className="items-center p-4 m-2 border border-gray-950 bg-white dark:border-gray-500 dark:bg-white/5 font-black text-black dark:text-white justify-center flex flex-col">
            All Users
            <input
              className="border border-gray-950 dark:border-gray-500 p-2 text-black flex justify-center m-2 w-full"
              type="text"
              placeholder="Search Users"
              onChange={handleSearch}
            />
          </p>
        </div>
      </div>

      <table
        {...getTableProps()}
        className="table-auto mx-auto text-black dark:text-white bg-white dark:bg-white/5 border border-black border-2 dark:border-white w-full"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-4 py-2"
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="border border-black dark:border-white px-4 py-2"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center items-center mt-2 dark:text-white p-2">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="mr-2"
        >
          <ArrowCircleLeftOutlined style={{ fontSize: "30px" }} />
        </button>

        <span className="ml-2">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>

        <button onClick={() => nextPage()} disabled={!canNextPage}>
          <ArrowCircleRightOutlined
            style={{ fontSize: "30px", marginLeft: "15px" }}
          />
        </button>
      </div>
    </>
  );
};

export default DisplayAllUsers;
