import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { CircularProgress, Modal, TextField, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import UpdateLocationInfoModal from "./UpdateLocationInfoModal";
import { getLocations } from "./Utilities/api";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const LocationManagerInfo = (props) => {
  const [locationData, setLocationData] = useState([]);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getLocations(setLocationData);
  }, [props.flag]);

  useEffect(() => {
    setOpen(true);
    setLoading(false);
  }, [index]);

  return (
    <>
      {!loading && locationData[index] && (
        <Modal
          open={open}
          className="items-center rounded-md flex justify-center"
        >
          <UpdateLocationInfoModal
            open={open}
            setOpen={setOpen}
            item={locationData[index]}
            setFlag={props.setFlag}
            flag={props.flag}
            setIndex={setIndex}
          />
        </Modal>
      )}

      <div className="flex flex-col items-center m-2">
        <div className="flex justify-center m-2">
          <p className="text-sm font-bold uppercase text-black">
            Location Manager
          </p>
        </div>
        <div className="flex flex-col">
          {locationData.length === 0 && <CircularProgress />}
          {locationData.map((item, index) => {
            return (
              <>
                <button
                  onClick={() => {
                    setIndex(index);
                  }}
                >
                  <div className="m-2 md:mt-0 md:col-span-2 ">
                    <div className="drop-shadow hover:drop-shadow-xl sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="grid place-content-center grid-cols-7 gap-6">
                          <div className="col-span-1">
                            <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                              <Tooltip title="Location">
                                <div className="flex justify-center">
                                  <StoreMallDirectoryIcon />
                                  <p className="ml-2">{item.location}</p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="bg-gray-50 p-1 m-1 m-2 border-2 rounded-md">
                              <Tooltip title="Ops Manager">
                                <div className="flex justify-center">
                                  <PersonOutlineIcon />
                                  <p className="ml-2">{item.managerName}</p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                              <Tooltip title="Ops Phone Number">
                                <div className="flex justify-center">
                                  <PhoneIcon />
                                  <p className="ml-2">{item.managerPhone}</p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="col-span-2">
                            <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                              <Tooltip title="Ops Email">
                                <div className="flex flex-wrap justify-center">
                                  <EmailIcon />
                                  <p className="ml-2">{item.managerEmail}</p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                              <Tooltip title="Email Alert Days">
                                <div className="flex justify-center">
                                  <ForwardToInboxIcon />
                                  <p className="ml-2">{item.emailAlert} days</p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                              <Tooltip title="Text Alert Days">
                                <div className="flex justify-center">
                                  <PermPhoneMsgIcon />
                                  <p className="ml-2">{item.textAlert} days</p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="col-span-1"></div>

                          <div className="col-span-1">
                            <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                              <Tooltip title="GM Manager">
                                <div className="flex justify-center">
                                  <PersonOutlineIcon />
                                  <p className="ml-2">{item.generalName}</p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>

                          <div className="col-span-1">
                            <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                              <Tooltip title="GM Phone Number">
                                <div className="flex justify-center">
                                  <PhoneIcon />
                                  <p className="ml-2">{item.generalPhone}</p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>

                          <div className="col-span-2">
                            <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                              <Tooltip title="GM Email">
                                <div className="flex flex-wrap justify-center">
                                  <EmailIcon />
                                  <p className="ml-2">{item.generalEmail}</p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LocationManagerInfo;
