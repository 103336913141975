/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.8 NoSlabSelected.gltf
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function NoSlabSelected(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/NoSlabSelected.gltf");
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Text"
          geometry={nodes.Text.geometry}
          material={materials["Material.001"]}
          rotation={[Math.PI / 2, 0, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/NoSlabSelected.gltf");
