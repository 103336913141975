export const doorDescriptions = [
  {
    productId: 2662,
    code: 5056,
    description: "1/6 X 6/8 H.C. Smooth Colonist",
    size: "1'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2663,
    code: 5058,
    description: "2/0 X 6/8 H.C. Smooth Colonist",
    size: "2'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2664,
    code: 5067,
    description: "2/4 X 6/8 H.C. Smooth Colonist",
    size: "2'4\"",
    type: "Slab",
    core: "H.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2665,
    code: 5071,
    description: "2/6 X 6/8 H.C. Smooth Colonist",
    size: "2'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2666,
    code: 5078,
    description: "2/8 X 6/8 H.C. Smooth Colonist",
    size: "2'8\"",
    type: "Slab",
    core: "H.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2667,
    code: 5081,
    description: "3/0 X 6/8 H.C. Smooth Colonist",
    size: "3'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2674,
    code: 5122,
    description: "1/6 X 6/8 S.C. Smooth Colonist",
    size: "1'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2675,
    code: 5126,
    description: "2/0 X 6/8 S.C. Smooth Colonist",
    size: "2'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2676,
    code: 5131,
    description: "2/4 X 6/8 S.C. Smooth Colonist",
    size: "2'4\"",
    type: "Slab",
    core: "S.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2677,
    code: 5140,
    description: "2/6 X 6/8 S.C. Smooth Colonist",
    size: "2'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2678,
    code: 5150,
    description: "2/8 X 6/8 S.C. Smooth Colonist",
    size: "2'8\"",
    type: "Slab",
    core: "S.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 2679,
    code: 5156,
    description: "3/0 X 6/8 S.C. Smooth Colonist",
    size: "3'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Smooth Colonist",
  },
  {
    productId: 12022,
    code: 627,
    description: '2/0 X 1 3/8" Fr. 10 Wdlite M3911',
    size: "2'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Wood Lite",
  },
  {
    productId: 12023,
    code: 629,
    description: '2/6 X 1 3/8" Fr. 15 Wdlite M3911',
    size: "2'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Wood Lite",
  },
  {
    productId: 12024,
    code: 630,
    description: '2/8 X 1 3/8" Fr. 15 Wdlite M3911',
    size: "2'8\"",
    type: "Slab",
    core: "S.C.",
    style: "Wood Lite",
  },
  {
    productId: 12025,
    code: 632,
    description: '3/0 X 1 3/8" Int Fr. 15 Woodlite',
    size: "3'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Wood Lite",
  },
  {
    productId: 15004,
    code: 1029,
    description: "1/6 X 6/8 X 1 3/8 H.C.Birch",
    size: "1'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Birch",
  },
  {
    productId: 15007,
    code: 1032,
    description: "2/0 X 6/8 X 1 3/8 H.C.Birch",
    size: "2'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Birch",
  },
  {
    productId: 15009,
    code: 1034,
    description: "2/4 X 6/8 X 1 3/8 H.C.Birch",
    size: "2'4\"",
    type: "Slab",
    core: "H.C.",
    style: "Birch",
  },
  {
    productId: 15010,
    code: 1035,
    description: "2/6 X 6/8 X 1 3/8 H.C.Birch",
    size: "2'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Birch",
  },
  {
    productId: 15011,
    code: 1036,
    description: "2/8 X 6/8 X 1 3/8 H.C.Birch",
    size: "2'8\"",
    type: "Slab",
    core: "H.C.",
    style: "Birch",
  },
  {
    productId: 15013,
    code: 1038,
    description: "3/0 X 6/8 X 1 3/8 H.C.Birch",
    size: "3'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Birch",
  },
  {
    productId: 15029,
    code: 1050,
    description: "1/6 X 6/8 X 1 3/8 Prim.Colonist",
    size: "1'6\"",
    type: "Slab",
    style: "Primed Colonist",
  },
  {
    productId: 15032,
    code: 1053,
    description: "2/0 X 6/8 X 1 3/8 Prim.Colonist",
    size: "2'0\"",
    type: "Slab",
    style: "Primed Colonist",
  },
  {
    productId: 15034,
    code: 1055,
    description: "2/4 X 6/8 X 1 3/8 Prim.Colonist",
    size: "2'4\"",
    type: "Slab",
    style: "Primed Colonist",
  },
  {
    productId: 15035,
    code: 1056,
    description: "2/6 X 6/8 X 1 3/8 Prim.Colonist",
    size: "2'6\"",
    type: "Slab",
    style: "Primed Colonist",
  },
  {
    productId: 15036,
    code: 1057,
    description: "2/8 X 6/8 X 1 3/8 Prim.Colonist",
    size: "2'8\"",
    type: "Slab",
    style: "Primed Colonist",
  },
  {
    productId: 15038,
    code: 1059,
    description: "3/0 X 6/8 X 1 3/8 Prim.Colonist",
    size: "3'0\"",
    type: "Slab",
    style: "Primed Colonist",
  },
  {
    productId: 35006,
    code: 10559,
    description: "1/6 Radiata Pine Slab",
    size: "1'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Radiata Pine",
  },
  {
    productId: 35009,
    code: 10563,
    description: "2/0 Radiata Pine Slab",
    size: "2'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Radiata Pine",
  },
  {
    productId: 35011,
    code: 10566,
    description: "2/4 Radiata Pine Slab",
    size: "2'4\"",
    type: "Slab",
    core: "S.C.",
    style: "Radiata Pine",
  },
  {
    productId: 35012,
    code: 10567,
    description: "2/6 Radiata Pine Slab",
    size: "2'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Radiata Pine",
  },
  {
    productId: 35013,
    code: 10569,
    description: "2/8 Radiata Pine Slab",
    size: "2'8\"",
    type: "Slab",
    core: "S.C.",
    style: "Radiata Pine",
  },
  {
    productId: 35015,
    code: 10571,
    description: "3/0 Radiata Pine Slab",
    size: "3'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Radiata Pine",
  },
  {
    productId: 43619,
    code: 869243,
    description: "1/2 x 6/8- 1 3/8 Hollow Core Caiman Slab",
    size: "1'2\"",
    type: "Slab",
    core: "H.C.",
    style: "Caiman",
  },
  {
    productId: 43620,
    code: 869241,
    description: "1/6 x 6/8- 1 3/8 Hollow Core Caiman Slab",
    size: "1'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Caiman",
  },
  {
    productId: 43621,
    code: 869240,
    description: "2/0 x 6/8- 1 3/8 Hollow Core Caiman Slab",
    size: "2'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Caiman",
  },
  {
    productId: 43622,
    code: 869239,
    description: "2/2 x 6/8- 1 3/8 Hollow Core Caiman Slab",
    size: "2'2\"",
    type: "Slab",
    core: "H.C.",
    style: "Caiman",
  },
  {
    productId: 43624,
    code: 869236,
    description: "2/4 x 6/8- 1 3/8 Hollow Core Caiman Slab",
    size: "2'4\"",
    type: "Slab",
    core: "H.C.",
    style: "Caiman",
  },
  {
    productId: 43625,
    code: 869235,
    description: "2/6 x 6/8- 1 3/8 Hollow Core Caiman Slab",
    size: "2'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Caiman",
  },
  {
    productId: 43626,
    code: 869231,
    description: "2/8 x 6/8- 1 3/8 Hollow Core Caiman Slab",
    size: "2'8\"",
    type: "Slab",
    core: "H.C.",
    style: "Caiman",
  },
  {
    productId: 43627,
    code: 869228,
    description: "3/0 x 6/8- 1 3/8 Hollow Core Caiman Slab",
    size: "3'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Caiman",
  },
  {
    productId: 43645,
    code: 876707,
    description: "2/10 x 6/8- 1 3/8 Hollow Core Caiman Slab",
    size: "2'10\"",
    type: "Slab",
    core: "H.C.",
    style: "Caiman",
  },
  {
    productId: 48746,
    code: 23057,
    description: '2/0 X 1 3/8" Primed 10 Lite Slab',
    size: "2'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Primed Lite",
  },
  {
    productId: 48747,
    code: 23058,
    description: '2/6 X 1 3/8" Primed 15 Lite Slab',
    size: "2'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Primed Lite",
  },
  {
    productId: 49207,
    code: 23445,
    description: "1/6 X 6/8 X 1 3/8 Hollow Core Craftsman Primed",
    size: "1'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Craftsman",
  },
  {
    productId: 49208,
    code: 23446,
    description: "2/0 X 6/8 X 1 3/8 Hollow Core Craftsman Primed",
    size: "2'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Craftsman",
  },
  {
    productId: 49209,
    code: 23447,
    description: "2/4 X 6/8 X 1 3/8 Hollow Core Craftsman Primed",
    size: "2'4\"",
    type: "Slab",
    core: "H.C.",
    style: "Craftsman",
  },
  {
    productId: 49210,
    code: 23448,
    description: "2/6 X 6/8 X 1 3/8 Hollow Core Craftsman Primed",
    size: "2'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Craftsman",
  },
  {
    productId: 49211,
    code: 23450,
    description: "2/8 X 6/8 X 1 3/8 Hollow Core Craftsman Primed",
    size: "2'8\"",
    type: "Slab",
    core: "H.C.",
    style: "Craftsman",
  },
  {
    productId: 49212,
    code: 23452,
    description: "3/0 X 6/8 X 1 3/8 Hollow Core Craftsman Primed",
    size: "3'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Craftsman",
  },
  {
    productId: 49216,
    code: 23456,
    description: "1/6 X 6/8 Solid Core Craftsman Slab Only",
    size: "1'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Craftsman",
  },
  {
    productId: 49217,
    code: 23457,
    description: "2/0 X 6/8 Solid Core Craftsman Slab Only",
    size: "2'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Craftsman",
  },
  {
    productId: 49218,
    code: 23458,
    description: "2/4 X 6/8 Solid Core Craftsman Slab Only",
    size: "2'4\"",
    type: "Slab",
    core: "S.C.",
    style: "Craftsman",
  },
  {
    productId: 49219,
    code: 23459,
    description: "2/6 X 6/8 Solid Core Craftsman Slab Only",
    size: "2'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Craftsman",
  },
  {
    productId: 49220,
    code: 23460,
    description: "2/8 X 6/8 Solid Core Craftsman Slab Only",
    size: "2'8\"",
    type: "Slab",
    core: "S.C.",
    style: "Craftsman",
  },
  {
    productId: 49221,
    code: 23461,
    description: "3/0 X 6/8 Solid Core Craftsman Slab Only",
    size: "3'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Craftsman",
  },
  {
    productId: 49222,
    code: 23462,
    description: '1/6 X 6/8 1 3/8" Hollow Core Rockport Slab',
    size: "1'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Rockport",
  },
  {
    productId: 49223,
    code: 23463,
    description: '2/0 X 6/8 1 3/8" Hollow Core Rockport Slab',
    size: "2'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Rockport",
  },
  {
    productId: 49224,
    code: 23464,
    description: '2/4 X 6/8 1 3/8" Hollow Core Rockport Slab',
    size: "2'4\"",
    type: "Slab",
    core: "H.C.",
    style: "Rockport",
  },
  {
    productId: 49225,
    code: 23465,
    description: '2/6 X 6/8 1 3/8" Hollow Core Rockport Slab',
    size: "2'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Rockport",
  },
  {
    productId: 49232,
    code: 23473,
    description: "2/8 Hollow Core Rockport Slabs 1 3/8 Primed",
    size: "2'8\"",
    type: "Slab",
    core: "H.C.",
    style: "Rockport",
  },
  {
    productId: 49233,
    code: 23474,
    description: "3/0 Hollow Core Rockport Slabs 1 3/8 Primed",
    size: "3'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Rockport",
  },
  {
    productId: 49241,
    code: 23482,
    description: "1/6 S.Core. Rockport Slabs 1 3/8 Primed",
    size: "1'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Rockport",
  },
  {
    productId: 49242,
    code: 23483,
    description: "2/0 S.Core. Rockport Slabs 1 3/8 Primed",
    size: "2'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Rockport",
  },
  {
    productId: 49243,
    code: 23484,
    description: "2/4 S.Core. Rockport Slabs 1 3/8 Primed",
    size: "2'4\"",
    type: "Slab",
    core: "S.C.",
    style: "Rockport",
  },
  {
    productId: 49244,
    code: 23485,
    description: "2/6 S.Core. Rockport Slabs 1 3/8 Primed",
    size: "2'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Rockport",
  },
  {
    productId: 49245,
    code: 23486,
    description: "2/8 S.Core. Rockport Slabs 1 3/8 Primed",
    size: "2'8\"",
    type: "Slab",
    core: "S.C.",
    style: "Rockport",
  },
  {
    productId: 49246,
    code: 23487,
    description: "3/0 S.Core. Rockport Slabs 1 3/8 Primed",
    size: "3'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Rockport",
  },
  {
    productId: 49247,
    code: 23488,
    description: "1/6 Hollow Core Cambridge Slabs 1 3/8 Primed",
    size: "1'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Cambridge",
  },
  {
    productId: 49248,
    code: 23489,
    description: "2/0 Hollow Core Cambridge Slabs 1 3/8 Primed",
    size: "2'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Cambridge",
  },
  {
    productId: 49249,
    code: 23490,
    description: "2/4 Hollow Core Cambridge Slabs 1 3/8 Primed",
    size: "2'4\"",
    type: "Slab",
    core: "H.C.",
    style: "Cambridge",
  },
  {
    productId: 49250,
    code: 23491,
    description: "2/6 Hollow Core Cambridge Slabs 1 3/8 Primed",
    size: "2'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Cambridge",
  },
  {
    productId: 49251,
    code: 23492,
    description: "2/8 Hollow Core Cambridge Slabs 1 3/8 Primed",
    size: "2'8\"",
    type: "Slab",
    core: "H.C.",
    style: "Cambridge",
  },
  {
    productId: 49252,
    code: 23493,
    description: "3/0 Hollow Core Cambridge Slabs 1 3/8 Primed",
    size: "3'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Cambridge",
  },
  {
    productId: 49259,
    code: 23500,
    description: "1/6 Solid Core Cambridge Slabs 1 3/8 Primed",
    size: "1'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Cambridge",
  },
  {
    productId: 49260,
    code: 23502,
    description: "2/0 Solid Core Cambridge Slabs 1 3/8 Primed",
    size: "2'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Cambridge",
  },
  {
    productId: 49261,
    code: 23503,
    description: "2/4 Solid Core Cambridge Slabs 1 3/8 Primed",
    size: "2'4\"",
    type: "Slab",
    core: "S.C.",
    style: "Cambridge",
  },
  {
    productId: 49262,
    code: 23505,
    description: "2/6 Solid Core Cambridge Slabs 1 3/8 Primed",
    size: "2'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Cambridge",
  },
  {
    productId: 49263,
    code: 23506,
    description: "2/8 Solid Core Cambridge Slabs 1 3/8 Primed",
    size: "2'8\"",
    type: "Slab",
    core: "S.C.",
    style: "Cambridge",
  },
  {
    productId: 49264,
    code: 23507,
    description: "3/0 Solid Core Cambridge Slabs 1 3/8 Primed",
    size: "3'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Cambridge",
  },
  {
    productId: 49265,
    code: 23508,
    description: "1/6 Solid Core Continental Slab 1 3/8 Primed",
    size: "1'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Continental",
  },
  {
    productId: 49266,
    code: 23509,
    description: "2/0 Solid Core Continental Slab 1 3/8 Primed",
    size: "2'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Continental",
  },
  {
    productId: 49267,
    code: 23511,
    description: "2/4 Solid Core Continental Slab 1 3/8 Primed",
    size: "2'4\"",
    type: "Slab",
    core: "S.C.",
    style: "Continental",
  },
  {
    productId: 49268,
    code: 23512,
    description: "2/6 Solid Core Continental Slab 1 3/8 Primed",
    size: "2'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Continental",
  },
  {
    productId: 49269,
    code: 23513,
    description: "2/8 Solid Core Continental Slab 1 3/8 Primed",
    size: "2'8\"",
    type: "Slab",
    core: "S.C.",
    style: "Continental",
  },
  {
    productId: 49270,
    code: 23515,
    description: "3/0 Solid Core Continental Slab 1 3/8 Primed",
    size: "3'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Continental",
  },
  {
    productId: 49271,
    code: 23516,
    description: "1/6 Solid Core Santa Fe Slabs 1 3/8 Primed",
    size: "1'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Santa Fe",
  },
  {
    productId: 49272,
    code: 23517,
    description: "2/0 Solid Core Santa Fe Slabs 1 3/8 Primed",
    size: "2'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Santa Fe",
  },
  {
    productId: 49273,
    code: 23518,
    description: "2/4 Solid Core Santa Fe Slabs 1 3/8 Primed",
    size: "2'4\"",
    type: "Slab",
    core: "S.C.",
    style: "Santa Fe",
  },
  {
    productId: 49274,
    code: 23519,
    description: "2/6 Solid Core Santa Fe Slabs 1 3/8 Primed",
    size: "2'6\"",
    type: "Slab",
    core: "S.C.",
    style: "Santa Fe",
  },
  {
    productId: 49275,
    code: 23520,
    description: "2/8 Solid Core Santa Fe Slabs 1 3/8 Primed",
    size: "2'8\"",
    type: "Slab",
    core: "S.C.",
    style: "Santa Fe",
  },
  {
    productId: 49276,
    code: 23521,
    description: "3/0 Solid Core Santa Fe Slabs 1 3/8 Primed",
    size: "3'0\"",
    type: "Slab",
    core: "S.C.",
    style: "Santa Fe",
  },
  {
    productId: 60075,
    code: 29330,
    description: '1/6 X 6/8 Hc Cont. Slab, 1 3/8"',
    size: "1'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Continental",
  },
  {
    productId: 60076,
    code: 29331,
    description: '2/0 X 6/8 Hc Cont. Slab 1 3/8"',
    size: "2'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Continental",
  },
  {
    productId: 60077,
    code: 29332,
    description: '2/4 X 6/8 Hc Cont.Slab 1 3/8"',
    size: "2'4\"",
    type: "Slab",
    core: "H.C.",
    style: "Continental",
  },
  {
    productId: 60078,
    code: 29334,
    description: '2/6 X 6/8 Hc Cont.Slab 1 3/8"',
    size: "2'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Continental",
  },
  {
    productId: 60079,
    code: 29335,
    description: '2/8 X 6/8 Hc Cont.Slab 1 3/8"',
    size: "2'8\"",
    type: "Slab",
    core: "H.C.",
    style: "Continental",
  },
  {
    productId: 60080,
    code: 29337,
    description: '3/0 X 6/8 Hc Cont.Slab 1 3/8"',
    size: "3'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Continental",
  },
  {
    productId: 60081,
    code: 29339,
    description: '1/6 X 6/8 Hc Santa Fe Slb 1 3/8"',
    size: "1'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Santa Fe",
  },
  {
    productId: 60082,
    code: 29340,
    description: '2/0 X 6/8 Hc Santa Fe Slb 1 3/8"',
    size: "2'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Santa Fe",
  },
  {
    productId: 60083,
    code: 29342,
    description: '2/4 X 6/8 Hc Santa Fe Slb 1 3/8"',
    size: "2'4\"",
    type: "Slab",
    core: "H.C.",
    style: "Santa Fe",
  },
  {
    productId: 60084,
    code: 29344,
    description: '2/6 X 6/8 Hc Santa Fe Slb 1 3/8"',
    size: "2'6\"",
    type: "Slab",
    core: "H.C.",
    style: "Santa Fe",
  },
  {
    productId: 60085,
    code: 29346,
    description: '2/8 X 6/8 Hc Santa Fe Slb 1 3/8"',
    size: "2'8\"",
    type: "Slab",
    core: "H.C.",
    style: "Santa Fe",
  },
  {
    productId: 60086,
    code: 29347,
    description: '3/0 X 6/8 Hc Santa Fe Slb 1 3/8"',
    size: "3'0\"",
    type: "Slab",
    core: "H.C.",
    style: "Santa Fe",
  },
  {
    productId: 600795,
    code: 29336,
    description: '2/10 X 6/8 Hc Cont.Slab 1 3/8"',
    size: "2'10\"",
    type: "Slab",
    core: "H.C.",
    style: "Continental",
  },
];

const getStopOptions = (jamb) => {
  if (jamb?.toUpperCase().includes("SPLIT")) {
    // Return updated options array
    return [{ productId: null, description: "N/A" }];
  } else {
    return [
      { productId: null, description: "" },
      {
        productId: 19923,

        description: "7' PRIMED FJ ROUND EDGE STOP",
      },
      {
        productId: 19924,

        description: "7' PRIMED FJ COLONIAL DOOR STOP",
      },
      { productId: 49475, description: "POPLAR ROUND EDGE DOOR STOP" },
      {
        productId: 19920,

        description: "7' PINE ROUND EDGE DOOR STOP",
      },
      {
        productId: 19922,

        description: "7' PINE COLONIAL DOOR STOP",
      },
      {
        productId: 41642,

        description: "7' OAK COL DOOR STOP",
      },
      {
        productId: 701,

        description: "LF PINE RANCH DOOR STOP",
      },
    ];
  }
};

const getStopAppliedOptions = (jamb) => {
  if (jamb?.toUpperCase().includes("SPLIT")) {
    // Return updated options array
    return ["N/A"];
  } else {
    // Return default options array
    return ["", "Yes", "No - Loose"];
  }
};

export const door_type_fields = [
  "",
  "HC Birch",
  'SC Birch  1  3/4"',
  "Birch BF",
  "HC Cambridge",
  "SC Cambridge",
  "Cambridge BF",
  "HC Colonist",
  "HC Colonist BF",
  "HC Smooth Colonist",
  "HC Smooth Colonist BF",
  "SC Smooth Colonist",
  "HC Continental",
  "SC Continental",
  "HC Continental BF",
  "HC Craftsman",
  "SC Craftsman",
  "HC Imperial Oak( Legacy )",
  "HC Colonist",
  "HC Colonist BF",
  "HC Smooth Colonist",
  "HC Smooth Colonist BF",
  "SC Smooth Colonist",
  "HC Continental",
  "SC Continental",
  "HC Continental BF",
  "HC Craftsman",
  "SC Craftsman",
  "Craftsman BF",
  "HC Imperial Oak( Legacy )",
  "Imperial Oak( Legacy ) Pre - Hung",
  "Imperial Oak( Legacy ) BF",
  "*** Pine Beveled Glass ***",
  "2/0 10lite BV",
  "2/6 15lite BV",
  "2/8 15lite BV",
  "3/0 15lite BV",
  "*** Pine Divided Lite ***",
  "2/0 10lite",
  "2/6 15lite",
  "2/8 15lite",
  "3/0 15lite",
  "2/0 Primed 10lite",
  "2/6 Primed 15lite",
  "Pine",
  "Pine BF",
  "HC Rockport",
  "SC Rockport",
  "Rockport BF",
  "HC Santa Fe",
  "SC Santa Fe",
  "Santa Fe BF",
  "*** Cased Openings ***",
  'Cased O: 4 - 9/16"',
  'Cased O: 6 - 9/16"',
  "*** Other ***",
  "Other",
];

export const french_fields = [
  "",
  "Ball Catch",
  'Pine "T" Astragal',
  'Pine "T" Astragal w/ Head Bolt',
  'Pine "T" Astragal w/ Ball Catch',
];

export const size_fields = [
  "",
  "1/0",
  "1/2",
  "1/3",
  "1/4",
  "1/6",
  "1/8",
  "1/10",
  "2/0",
  "2/2",
  "2/4",
  "2/6",
  "2/8",
  "2/10",
  "3/0",
  "4/0",
  "5/0",
  "6/0",
  "2/0 Double",
  "2/4 Double",
  "2/6 Double",
  "2/8 Double",
  "3/0 Double",
  "3/4 Double",
  "3/8 Double",
  "4/0 Double",
  "4/4 Double",
  "4/8 Double",
  "5/0 Double",
  "5/4 Double",
  "5/8 Double",
  "6/0 Double",
];

export const handing_fields = ["", "Right Hand", "Left Hand"];

export const size_jamb_type_fields = [
  { productId: null, description: "" },
  { productId: null, description: "Door Slab Only" },
  { productId: 13513, description: "4 9/16 PRIMED SPLIT JAMB" },
  { productId: 13506, description: "4 9/16 PRIMED FLAT JAMB" },
  { productId: 13507, description: "4 9/16 PINE VENEER FLAT JAMB" },
  { productId: 13508, description: "4 9/16  POPLAR SPLIT JAMB" },
  { productId: 13510, description: "4 9/16 SOLID PINE JAMB" },
  { productId: 13511, description: "4 9/16 PINE SPLIT JAMB" },
  { productId: 13515, description: "5 1/4 PINE VENEER JAMB" },
  { productId: 13516, description: "6 9/16 PINE SOLID JAMB" },
  { productId: 13518, description: "6 9/16 PRIMED FLAT JAMB" },
  { productId: 24167, description: "4 9/16 OAK VENEER FLAT JAMB" },
  { productId: 47480, description: "6 9/16 POPLAR SOLID FLAT JAMB" },
  { productId: 49439, description: "4 7/8 PRIMED FLAT JAMB" },
  { productId: 49474, description: "4 9/16 POPLAR SOLID FLAT JAMB" },
];

export const catch_astragal = [
  { productId: null, description: "N/A" },
  { productId: 768, description: "1-3/8 Astragal" },
  { productId: 35038, description: "1-3/8 Astragal w/ Top Route" },
  { productId: 13433, description: "Bright Brass Ball Catch" },
  { productId: 13435, description: "Satin Chrome Ball Catch" },
  { productId: 13533, description: "Bronze Ball Catch" },
  { productId: 13534, description: "Sating Nickel Ball Catch" },
];

export const casing_type_fields = [
  { productId: null, description: "" },
  { productId: null, description: "N/A" },
  { productId: 7997, description: "2 1/4 PRIMED COL CASING" },
  { productId: 7992, description: "3 1/4 PRIMED COL CASING" },
  { productId: 13834, description: "3 1/2 MDF E2E CASING" },
  { productId: 46307, description: "2 3/4 PMD VICTORIAN CASING" },
  { productId: 714, description: "3 1/4 PINE COL CASING" },
  { productId: 45932, description: "3 1/4 MDF 2STEP BACKBAND CASING" },
  { productId: 12184, description: "2 1/4 POP CRAFTSMAN E2E CASING" },
  { productId: 12185, description: "2 3/4 POP CRAFTSMAN E2E CASING" },
  { productId: 41988, description: "2 3/4 BC951 POP FJ CASING" },
  { productId: 48689, description: "2 3/4 FJ POP CASING BC-780" },
  { productId: 787, description: "2 1/4 PINE COL CASING" },
  { productId: 24062, description: "2 1/4  OAK COL CASING" },
  { productId: 779, description: "2 1/4  PINE RANCH CASING" },
];

export const applied_fields = ["", "Yes", "No", "Loose Hooped"];
export const bolt_type_fields = (jamb, door_type) => {
  if (jamb === "Door Slab Only(null)" || door_type === "double") {
    return ["", "N/A", "Mortise", "Drive"];
  } else {
    return ["", "Mortise", "Drive"];
  }
};

export const hinge_finish_fields = [
  { productId: null, description: "" },

  {
    productId: 12592,
    description: '3 1/2" POLISHED CHROME HINGE',
  },
  {
    productId: 12593,
    description: '3 1/2" BRASS HINGE',
  },
  {
    productId: 12594,
    description: '3 1/2" DULL CHROME HINGE',
  },
  {
    productId: 12595,
    description: '3 1/2" ANTIQUE BRASS HINGE',
  },
  {
    productId: 12596,
    description: '3 1/2" ANTIQUE NICKEL HINGE',
  },
  {
    productId: 12597,
    description: '3 1/2" SATIN NICKEL HINGE',
  },
  {
    productId: 12598,
    description: '3 1/2" OIL RUBBED BRONZE HINGE',
  },
  {
    productId: 13780,
    description: '3 1/2" BLACK HINGE',
  },
];

export const stop_fields = (jamb) => {
  return getStopOptions(jamb);
};
export const stop_applied_fields = (jamb) => {
  return getStopAppliedOptions(jamb);
};
export const backset_fields = (jamb, door_type) => {
  if (jamb === "Door Slab Only(null)" || door_type === "double") {
    return ["", "N/A", '2-3/8"', '2-3/4"'];
  } else {
    return ["", '2-3/8"', '2-3/4"'];
  }
};

export const yes_no = ["", "Yes", "No", "N/A"];
export const core_fields = ["", "S.C.", "H.C."];

export const grille_type = ["", "Finelight", "Wood Snap-In", "Plastic Fixed"];

export const hardware_color = [
  "",
  "Satin Nickel",
  "Bright Brass",
  "Oil Rubbed Bronze",
  "Antique Brass",
  "Satin Brass",
];

export const door_size = [
  "",
  "2/4",
  "2/6",
  "2/8",
  "3/0",
  "3/6",
  "5/4 Double",
  "6/0 Double",
];

export const default_door = [
  {
    door_type: "",
    french: "",
    qty: "",
    size: "",
    handing: "",
    other_information: "",
    quoted_price: "",
  },
];

export const default_andersen200400 = [
  {
    door_type: "",
    series: "",
    frame_style: "",
    location: "",
    unit_size: "",
    handing: "",
    glass_type: "",
    grille_pattern: "",
    jamb_apply: "",
    jamb_thickness: "",
  },
];

export const default_a_series = [
  {
    quantity: "",
    frame_style: "",
    location: "",
    call_number: "",
    handing: "",
    glass_type: "",
    grille_pattern: "",
    jamb_apply: "",
    jamb_thickness: "",
  },
];

export const default_marvin_integrity = [
  {
    quantity: "",
    location: "",
    call_number: "",
    hinge: "",
    glass_type: "",
    grille_pattern: "",
    jamb_size: "",
  },
];

export const tax = ["Indiana", "Michigan", "Ohio"];

export const window_exterior_color = [
  "",
  "***200/400/ASW***",
  "White",
  "Sandtone",
  "***400/ASW***",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Dark Bronze",
  "Black",
];

export const window_interior_color = [
  "",
  "***Windows***",
  "Clear Pine",
  "PI White",
  "PI Black",
  "PI Dark Bronze",
  "***Doors***",
  "Clear Pine",
  "Clear Oak",
  "Clear Maple",
  "PI White",
];

export const window_hardware_color = [
  "",
  "White",
  "Stone",
  "Traditional White",
  "Traditional Black",
  "Traditional Distressed Bronze",
  "Traditional Distressed Nickel",
  "Traditional Gold Dust",
  "Traditional Stone",
  "Traditional Bright Brass",
  "Traditional Antique Brass",
  "Traditional Satin Nickel",
  "Traditional Oil Rubbed Bronze",
  "Contemporary White",
  "Contemporary Stone",
  "Contemporary Black",
  "Contemporary Gold Dust",
  "Contemporary Bright Brass",
  "Contemporary Satin Nickel",
  "Contemporary Oil Rubbed Bronze",
  "***Estate Finishes***",
  "Bright Brass",
  "Antique Brass",
  "Polished Chrome",
  "Brushed Chrome",
  "Satin Nickel",
  "Oil Rubbed Bronze",
  "Distressed Nickel",
  "Distressed Bronze",
];

export const interior_screen = [
  "",
  "White",
  "Stone",
  "TruScene White",
  "TruScene Stone",
  "TruScene Wood",
  "TruScene Black",
  "TruScene Dark Bronze",
  "NO SCREEN",
];

export const exterior_screen = [
  "",
  "White",
  "Sandtone",
  "Terratone",
  "Forest Green",
  "NO SCREEN",
  "Canvas",
  "Black",
  "Dark Bronze",
];

export const patio_door_hw_color = [
  "",
  "Tribeca White",
  "Tribeca Stone",
  "Anvers Bright Brass",
  "Anvers Oil Rubbed Bronze",
  "Anvers Satin Nickel",
  "Newbury Bright Brass",
  "Newbury Antique Brass",
  "Newbury Oil Rubbed Bronze",
  "Newbury Satin Nickel",
  "Newbury Polished Chrome",
  "Newbury Brushed Chrome",
  "Whitmore Bright Brass",
  "Whitmore Antique Brass",
  "Whitmore Oil Rubbed Bronze",
  "Whitmore Satin Nickel",
  "Covington Bright Brass",
  "Covington Antique Brass",
  "Covington Oil Rubbed Bronze",
  "Encino Distressed Bronze",
  "Encino Distressed Nickel",
  "Yuma Distressed Bronze",
  "Yuma Distressed Nickel",
  "Albany White",
  "Albany Stone",
  "Albany Gold Dust",
  "Albany Black",
  "FSB 1035 Stainless Steel",
  "FSB 1075 Stainless Steel",
  "FSB 1076 Stainless Steel",
  "FSB 1102 Stainless Steel",
];

export const finelight_profile = ["", '3/4"', '1"'];

export const finelight_interior_color = [
  "",
  "***200/400/ASW***",
  "White",
  "Sandtone",
  "***400/ASW***",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Dark Bronze",
  "Black",
];

export const finelight_exterior_color = [
  "",
  "***200/400/ASW***",
  "White",
  "Sandtone",
  "***400/ASW***",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Dark Bronze",
  "Black",
];

export const removable_profile = ["", '3/4"', '7/8"', '1"'];
export const removable_interior_color = [
  "",
  "***Windows***",
  "Clear Pine",
  "PI White",
  "PI Black",
  "PI Dark Bronze",
  "***Doors***",
  "Clear Pine",
  "Clear Oak",
  "Clear Maple",
  "PI White",
];
export const removable_exterior_color = [
  "",
  "***200/400/ASW***",
  "White",
  "Sandtone",
  "***400/ASW***",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Dark Bronze",
  "Black",
];

export const divided_light_option = [
  "",
  "Removable Interior",
  "Spacer",
  "No Spacer",
];
export const divided_light_interior_finish = [
  "",
  "***Windows***",
  "Clear Pine",
  "PI White",
  "PI Black",
  "PI Dark Bronze",
  "***Doors***",
  "Clear Pine",
  "Clear Oak",
  "Clear Maple",
  "PI White",
];
export const divided_light_interior_profile = [
  "",
  '3/4"',
  '7/8"',
  '1-1/8"',
  '2-1/4"',
];
export const divided_light_exterior_finish = [
  "",
  "***200/400/ASW***",
  "White",
  "Sandtone",
  "***400/ASW***",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Dark Bronze",
  "Black",
];
export const divided_light_exterior_profile = [
  "",
  '3/4"',
  '7/8"',
  '1-1/8"',
  '2-1/4"',
];

export const glass_type = [
  "",
  "***200 Series***",
  "Low E",
  "Low E Temp",
  "Dual Pane",
  "DP Temp",
  "LE Obscure",
  "LE Obs Temp",
  " DP Obscure",
  "BP Obs Temp",
  "***400/ASW***",
  "HP4",
  "HP4 Temp",
  "Smart Sun",
  "Smart Sun Temp",
  "SS Obscure",
  "SS Obscure Temp",
  "Sun Low E",
  "Sun Low E Temp",
  "SLE Obscure",
  "SLE Obscure Temp",
  "Cascade",
  "Cascade Temp",
  "Fern",
  "Fern Temp",
  "Reed",
  "Reed Temp",
];
export const window_series = ["", "200", "400", "ASW"];
export const frame_style = [
  "",
  "Standard Flange",
  "Ext Trim Flange",
  "No Flange",
];
export const jamb_apply = ["", "Yes", "No"];

// Integrity Window Values

export const integrity_ship_screens = ["With Call", "With Windows"];

export const integrity_exterior_color = [
  "***wood/ultrex***",
  "Stone White",
  "Pebble Gray",
  "Cashmere",
  "Evergreen",
  "Bronze",
  "Ebony",
  "***all ultrex***",
  "Stone White",
  "Pebble Gray",
  "Cashmere",
  "Evergreen",
  "Bronze",
  "Ebony",
];

export const integrity_interior_color = [
  "***wood/ultrex***",
  "Bare",
  "White",
  "Black",
  "Clear Coat",
  "***all ultrex***",
  "Stone White",
  "Bronze (w/ext)",
  "Black (w/ext)",
];

export const integrity_hardwre_color = [
  "Almond Frost",
  "White",
  "Brass",
  "Oil Rub Bronze",
  "Satin Nickel",
  "Matte Black",
];

export const integrity_interior_screen = [
  "White",
  "Almond Frost",
  "Black",
  "Wood",
];

export const integrity_exterior_screen = ["Match exterior color", "None"];

export const integrity_patio_door_hw_color = [
  "Cambridge Almond Frost",
  "Cambridge White",
  "Cambridge Brass PVD",
  "Cambridge Oil Rub Bronze PVD",
  "Cambridge Satin Nickel PVD",
  "Cambridge Matte Black",
  "****Northfield****",
  "Northfield Almond Frost",
  "Northfield White",
  "Northfield Matte Black",
  "Northfield Brass PVD",
  "Northfield Oil Rub Bronze PVD",
  "Northfield Satin Nickel PVD",
];

export const integrity_window_type = ["", "Wood-Ultrex", "All Ultrex"];

export const integrity_grille_interior_color = [
  "Bare",
  "White",
  "Black",
  "Clear Coat",
];

export const integrity_grille_exterior_color = [
  "Stone White",
  "Pebble Gray",
  "Cashmere",
  "Evergreen",
  "Bronze",
  "Ebony",
];

export const integrity_wood_removable_interior = [
  "Bare",
  "White",
  "Black",
  "Clear Coat",
];

export const integrity_wood_removable_exterior = [
  "Stone White",
  "Pebble Gray",
  "Cashmere",
  "Evergreen",
  "Bronze",
  "Ebony",
];

export const integrity_divided_light_interior = [
  "Bare",
  "White",
  "Black",
  "Clear Coat",
];

export const integrity_divided_light_exterior = [
  "Stone White",
  "Pebble Gray",
  "Cashmere",
  "Evergreen",
  "Bronze",
  "Ebony",
];

export const integrity_glass_type = [
  "Clear",
  "Low E 2",
  "Low E 2 Temp",
  "Low E 3",
  "Low E 3 Temp",
  "Tempered Obscure",
  "Tripane (Wood Ultrex)",
  "STC/OITC Upgrade Low E 2",
  "STC/OITC Upgrade Low E 3",
  "Other",
];

//Marvin Windows Values
export const marvin_exterior_color = [
  "***aluminum***",
  "Pebble Gray",
  "Bahama Brown",
  "Stone White",
  "Evergreen",
  "Bronze",
  "Ebony",
  "Copper",
  "Wineberry",
  "Bright Silver",
  "Sierra White",
  "Cadet Gray",
  "Coconut Cream",
  "Liberty Bronze",
  "Hampton Sage",
  "Suede",
  "Cascade Blue",
  "Clay",
  "Gun Metal",
  "Cashmere",
  "Custom Color",
  "***wood***",
  "Bare Pine",
  "Primed Pine",
  "Mahogany",
  "VGR Doug Fir",
];
export const marvin_interior_color = [
  "Bare Pine",
  "Primed Pine",
  "PI White",
  "Designer Black",
  "Clear Coat",
  "Honey Stain",
  "Wheat Stain",
  "Hazelnut Stain",
  "Cabernet Stain",
  "Espresso Stain",
  "Leather Stain",
  "Mahogany",
  "VGR Doug Fir",
  "White Oak",
  "Cherry",
  "Douglas Fir",
];
export const marvin_hardware_color = [
  "Satin Taupe",
  "White",
  "Bronze",
  "Brass",
  "Antique Brass",
  "Satin Chrome",
  "Oil Rub Bronze",
  "Satin Nickel",
  "Matte Black",
];
export const marvin_interior_screen_color = [
  "Black",
  "White",
  "Satin Taupe",
  "Bronze",
  "Wood",
];
export const marvin_exterior_screen_color = [
  "***aluminum***",
  "Pebble Gray",
  "Bahama Brown",
  "Stone White",
  "Evergreen",
  "Bronze",
  "Ebony",
  "Copper",
  "Wineberry",
  "Bright Silver",
  "Sierra White",
  "Cadet Gray",
  "Coconut Cream",
  "Liberty Bronze",
  "Hampton Sage",
  "Suede",
  "Cascade Blue",
  "Clay",
  "Gun Metal",
  "Cashmere",
  "***wood***",
  "Bare Pine",
  "Primed Pine",
  "Mahogany",
  "VGR Doug Fir",
];
export const marvin_patio_door_hardware_color = [
  "***standard***",
  "Satin Taupe",
  "White",
  "Dark Bronze",
  "Brass",
  "Antique Brass",
  "Polished Chrome",
  "Satin Chrome",
  "Oil Rub Bronze",
  "Satin Nickel",
  "Matte Black",
  "Oil Ru Bronze PVD",
  "***Gallery***",
  "Ashley Norton",
  "Bouvet",
  "Baldwin",
  "Rocky Mountain",
];
export const marvin_grille_interior_color = [
  "Bronze",
  "Pebble Gray",
  "Sierra",
  "White",
];
export const marvin_grille_exterior_color = [
  "***aluminum***",
  "Pebble Gray",
  "Bahama Brown",
  "Stone White",
  "Evergreen",
  "Bronze",
  "Ebony",
  "Copper",
  "Wineberry",
  "Bright Silver",
  "Sierra White",
  "Cadet Gray",
  "Coconut Cream",
  "Liberty Bronze",
  "Hampton Sage",
  "Suede",
  "Cascade Blue",
  "Clay",
  "Gun Metal",
  "Cashmere",
  "Custom Color",
  "***wood***",
  "Bare Pine",
  "Primed Pine",
  "Mahogany",
  "VGR Doug Fir",
];
export const marvin_divided_light_interior_profile = [
  '7/8" w/ spacer bar',
  '7/8" no spacer bar',
  '1-1/8" w/ spacer bar',
  '1-1/8" no spacer bar',
  '5/8" w/Spacer bar',
  '5/8" no spacer bar',
];
export const marvin_divided_light_exterior_color = [
  "***aluminum***",
  "Pebble Gray",
  "Bahama Brown",
  "Stone White",
  "Evergreen",
  "Bronze",
  "Ebony",
  "Copper",
  "Wineberry",
  "Bright Silver",
  "Sierra White",
  "Cadet Gray",
  "Coconut Cream",
  "Liberty Bronze",
  "Hampton Sage",
  "Suede",
  "Cascade Blue",
  "Clay",
  "Gun Metal",
  "Cashmere",
  "Custom Color",
  "***wood***",
  "Bare Pine",
  "Primed Pine",
  "Mahogany",
  "VGR Doug Fir",
];
export const marvin_glass_type = [
  "Clear",
  "Low E 2",
  "Low E 2 Temp",
  "Obscure",
  "Obscure Temp",
  "Low E 2 Obscure",
  "Low E 2 Obscure Temp",
  "Low E 3",
  "Low E 3 Temp",
  "Low E 3 Obscure",
  "Low E 3 Obscure Temp",
  "Bronze Tint",
  "Green Tint",
  "Triple Glass",
  "Beveled",
  "Flemish",
  "Glue Chip",
  "Reeded",
  "Narrow Reeded",
  "Rain",
  "Aqualite",
  "White Laminated",
  "Sandblasted",
  "Leaded",
  "Frost",
  "V-Groove",
  "Other",
];

export const marvin_divided_light_interior_species = [
  "Bare Pine",
  "Primed Pine",
  "PI White",
  "Designer Black",
  "Clear Coat",
  "Honey Stain",
  "Wheat Stain",
  "Hazelnut Stain",
  "Cabernet Stain",
  "Espresso Stain",
  "Leather Stain",
  "Mahogany",
  "VGR Doug Fir",
  "White Oak",
  "Cherry",
  "Douglas Fir",
];

export const door_kit_cores = ["S.C", "H.C", "Pine"];

export const A100_unit_color = [
  "White Ext / White Int",
  "Sandtone Ext / White Int",
  "Sandtone Ext / Sandtone Int",
  "Terratone Ext / White Int",
  "Dark Bronze Ext / White Int",
  "Dark Bronze Ext / Dark Bronze Int",
  "Black Ext / White Int",
  "Black Ext / Black Int",
];

export const A100_interior_screen = [
  "Standard Screen",
  "TruScene Screen",
  "NO SCREEN",
];

export const A100_exterior_screen = [
  "Standard Screen",
  "TruScene Screen (Windows Only)",
  "NO SCREEN",
];

export const A100_window_hardware_color = [
  "White",
  "Sandtone",
  "Black",
  "Dark Bronze",
  "Antique Brass",
  "Satin Nickel",
  "Slim Line White - Single Hung/Glider",
  "Slim Line Sandtone - Single Hung/Glider",
  "Slim Line Black - Single Hung/Glider",
  "Slim Line Dark Bronze - Single Hung/Glider",
  "Slim Line Antique Brass - Single Hung/Glider",
  "Slim Line Satin Nickel - Single Hung/Glider",
];

export const A100_patio_door_hw_color = [
  "Tulsa - color matches door unit",
  "Afton Antique Brass",
  "Afton Bright Brass",
  "Afton Black",
  "Afton Satin Nickel",
];

export const A100_option = ["Finelight with exterior grille", "Finelight"];

export const A100_divided_light_option = [
  "Removable Interior",
  "Spacer",
  "No Spacer",
];

export const A100_ship_screens_grilles = ["With Windows", "With call"];

export const a_series_exterior_color = [
  "White",
  "Sandtone",
  "Dark Bronze",
  "Black",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Prairie Grass",
  "Dove Gray",
  "Cocoa bean",
  "Red Rock",
];
export const a_series_interior_wood_species = [
  "Pine (unfinished or finished)",
  "Maple (unfinished or finished)",
  "Oak (unfinished or finished)",
  "Cherry (unfinished only)",
  "Mahogany (unfinished only)",
  "Vertical Grain Fir (unfinished only)",
];
export const a_series_interior_finish = [
  "Unfinished",
  "White - painted",
  "Birch Bark - painted",
  "Primed",
  "Sandtone - painted",
  "Canvas - painted",
  "Dark Bronze - painted",
  "Black - painted",
  "Clear Coat - poly finsh",
  "Honey - stained",
  "Cinnamon - stained",
  "Russet - stained",
  "Mocha - stained",
  "Espresso - stained",
];
export const a_series_hardware_color = [
  "White - Double Hung",
  "Stone - Double Hung",
  "Black  - Double Hung",
  "Gold Dust - Double Hung",
  "Traditional White - csmt/ awn",
  "Traditional Black - csmt/ awn",
  "Traditional Distressed Bronze - csmt/ awn",
  "Traditional Distressed Nickel - csmt/ awn",
  "Traditional Gold Dust - csmt/ awn",
  "Traditional Stone - csmt/ awn",
  "Traditional Bright Brass - csmt/ awn",
  "Traditional Antique Brass - csmt/ awn",
  "Traditional Satin Nickel - csmt/ awn",
  "Traditional Oil Rubbed Bronze - csmt/ awn",
  "Contemporary White - csmt/ awn",
  "Contemporary Stone - csmt/ awn",
  "Contemporary Black - csmt/ awn",
  "Contemporary Gold Dust - csmt/ awn",
  "Contemporary Bright Brass - csmt/ awn",
  "Contemporary Satin Nickel - csmt/ awn",
  "Contemporary Oil Rubbed Bronze - csmt/ awn",
  "***Other Finishes For DH***",
  "Bright Brass",
  "Antique Brass",
  "Polished Chrome",
  "Brushed Chrome",
  "Satin Nickel",
  "Oil Rubbed Bronze",
  "Distressed Nickel",
  "Distressed Bronze",
];
export const a_series_interior_screen = [
  "Standard Screen",
  "TruScene Aluminum",
  "TruScene Wood",
  "No Screen",
];
export const a_series_exterior_screen = [
  "Standard Screen",
  "TruScene (Window Only)",
  "No Screen",
];
export const a_series_patio_hw_color = [
  "Tribeca White",
  "Tribeca Stone",
  "Anvers Bright Brass",
  "Anvers Oil Rubbed Bronze",
  "Anvers Satin Nickel",
  "Newbury Bright Brass",
  "Newbury Antique Brass",
  "Newbury Oil Rubbed Bronze",
  "Newbury Satin Nickel",
  "Newbury Polished Chrome",
  "Newbury Brushed Chrome",
  "Whitmore Bright Brass",
  "Whitmore Antique Brass",
  "Whitmore Oil Rubbed Bronze",
  "Whitmore Satin Nickel",
  "Covington Bright Brass",
  "Covington Antique Brass",
  "Covington Oil Rubbed Bronze",
  "Encino Distressed Bronze",
  "Encino Distressed Nickel",
  "Yuma Distressed Bronze",
  "Yuma Distressed Nickel",
  "Albany White",
  "Albany Stone",
  "Albany Gold Dust",
  "Albany Black",
  "FSB 1035 Stainless Steel",
  "FSB 1075 Stainless Steel",
  "FSB 1076 Stainless Steel",
  "FSB 1102 Stainless Steel",
];
export const a_series_finelight_profile = ['3/4"', '1"'];
export const a_series_finelight_interior_color = [
  "White",
  "Sandtone",
  "Dark Bronze",
  "Black",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Prairie Grass",
  "Dove Gray",
  "Cocoa Bean",
  "Red Rock",
];
export const a_series_finelight_exterior_color = [
  "White",
  "Sandtone",
  "Dark Bronze",
  "Black",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Prairie Grass",
  "Dove Gray",
  "Cocoa Bean",
  "Red Rock",
];
export const a_series_removable_profile = ['3/4"', '7/8"', '1"'];
export const a_series_removable_exterior_color = [
  "White",
  "Sandtone",
  "Dark Bronze",
  "Black",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Prairie Grass",
  "Dove Gray",
  "Cocoa Bean",
  "Red Rock",
];
export const a_series_removable_interior_color = [
  "***Windows***",
  "Clear Pine",
  "PI White",
  "PI Black",
  "PI Dark Bronze",
  "***Doors***",
  "Clear Pine",
  "Clear Oak",
  "Clear Maple",
  "PI White",
];
export const a_series_divided_light_option = [
  "Removable Interior",
  "Spacer",
  "No Spacer",
];
export const a_series_divided_light_interior_profile = [
  '3/4"',
  '7/8"',
  '1-1/8"',
  '2-1/4"',
];
export const a_series_divided_light_interior_finish = [
  "White",
  "Sandtone",
  "Dark Bronze",
  "Black",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Prairie Grass",
  "Dove Gray",
  "Cocoa Bean",
  "Red Rock",
];
export const a_series_divided_light_exterior_profile = [
  '3/4"',
  '7/8"',
  '1-1/8"',
  '2-1/4"',
];
export const a_series_divided_light_exterior_finish = [
  "White",
  "Sandtone",
  "Dark Bronze",
  "Black",
  "Terratone",
  "Forest Green",
  "Canvas",
  "Prairie Grass",
  "Dove Gray",
  "Cocoa Bean",
  "Red Rock",
];
export const a_series_frame_style = [
  "Standard",
  "Ext Trim Flange",
  "No Flange",
];
export const a_series_jamb_apply = ["Yes", "No"];
export const a_series_glass_type = [
  "HP4",
  "HP4 Temp",
  "HP4 Obscure",
  "HP4 Obs Temp",
  "Smart Sun",
  "Smart Sun Obscure",
  "Smart Sun Temp",
  "Smart Sun Obscure Temp",
  "Sun Low E",
  "Sun Low E Temp",
  "Sun LE Obscure",
  "Cascade",
  "Cascade Temp",
  "Fern",
  "Reed",
  "Reed Temp",
  "Heatlock",
  "Heatlock Temp",
  "SS Heatlock",
  "SS Heatlock Temp",
  "Sun Heatlock",
  "Sun Heatlock Temp",
];
