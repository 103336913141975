import React, { useEffect, useState } from "react";
import { selectUser } from "../../../features/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MaterialTable from "material-table";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  ThemeProvider,
  createTheme,
  Alert,
  AlertTitle,
  Divider,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getForms } from "../../../components/Forms/api";
import { normalizeDate } from "../../../utilities";
import InteriorDoor from "../Door/InteriorDoor";
import { getDrafts } from "../../../components/Forms/api";

const ViewEntries = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [emailData, setEmailData] = useState({});
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);

  const defaultMaterialTheme = createTheme();
  const [dataStore, setDataStore] = useState([]);
  const [formdataStore, setformDataStore] = useState([]);
  const [draftdataStore, setDraftDataStore] = useState([]);

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    getDrafts(setDraftDataStore, user);
    getForms(setformDataStore, user);
  }, [user]);

  useEffect(() => {
    getDrafts(setDraftDataStore, user);
    getForms(setformDataStore, user);
  }, [flag]);

  useEffect(() => {
    setDataStore([...formdataStore, ...draftdataStore]);
  }, [formdataStore, draftdataStore]);

  useEffect(() => {
    if (emailData.companyName !== undefined || emailData.job) {
      setOpen(true);
    }
  }, [emailData]);

  useEffect(() => {
    setLoading(false);
  }, [dataStore]);

  useEffect(() => {
    if (formData) {
      setModal(true);
    }
  }, [formData]);

  const deleteDraft = async (id) => {
    const data = {
      id: id,
    };

    const rawResponse = await fetch("/api/forms/misc/getForms/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const content = await rawResponse.json();

    setFlag(!flag);
  };

  return (
    <>
      <Link to="/forms/new/rfq">
        <button className="border border-black dark:text-black dark:border-white dark:text-white rounded p-2 mt-3 ml-5 dark:hover:bg-gray-600 hover:bg-gray-200">
          New RFQ Form
        </button>
      </Link>
      {formData && modal && (
        <div className="flex flex-col">
          <div className="flex items-center justify-center w-full">
            <Tooltip title="Close Draft">
              <button
                className="border border-red-600 text-red-600 mt-3 p-2 hover:bg-red-300"
                onClick={() => {
                  setModal(false);
                  setFormData(null);
                  getDrafts(setDraftDataStore, user);
                  getForms(setformDataStore, user);
                }}
              >
                Close Draft
              </button>
            </Tooltip>
          </div>

          {formData.form_id === 6 && (
            <InteriorDoor formData={formData} setFormData={setFormData} />
          )}
        </div>
      )}
      {!loading && !formData && (
        <div style={{ margin: "10px" }} className="App">
          <div style={{ maxWidth: "100%", paddingTop: "12px", margin: "10px" }}>
            {!loading && (
              <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                  columns={[
                    {
                      title: "Entry Date",
                      field: "entry_date",
                      render: (rowData) => {
                        return normalizeDate(new Date(rowData?.entry_date));
                      },
                    },
                    {
                      title: "Form",
                      field: "form_id",
                      render: (rowData) => {
                        switch (rowData?.form_id) {
                          case 2:
                            return <p>Marvin Window</p>;
                          case 8:
                            return <p>Integrity Window</p>;
                          case 6:
                            return <p>Interior Door</p>;
                          case 10:
                            return <p>Andersen 200/400 Series</p>;
                          case 11:
                            return <p>Exterior Door</p>;
                          default:
                            break;
                        }
                      },
                    },
                    {
                      title: "Form Type",
                      field: "form_type",
                      render: (rowData) => {
                        return rowData.form_type.toUpperCase();
                      },
                    },
                    {
                      title: "Customer",
                      field: "customer",
                    },
                    {
                      title: "Job",
                      field: "job",
                    },
                    {
                      title: "Actions",
                      render: (rowData) => (
                        <div className="flex justify-center">
                          <Link
                            target={"_blank"}
                            to={`/api/forms/pdf/?id=${rowData?._id}`}
                          >
                            <button className="border border-black dark:border-white px-3 py-1 rounded dark:hover:text-white hover:text-slate-600 hover:bg-slate-300 m-1">
                              View PDF
                            </button>
                          </Link>
                          {rowData.form_type === "QUOTE" ||
                          rowData.form_type === "quote" ? (
                            <>
                              <button
                                onClick={() => {
                                  // Logic to handle editing the form
                                  setFormData(rowData);
                                }}
                                className="border border-black dark:border-white px-3 py-1 rounded dark:hover:text-white hover:text-slate-600 hover:bg-slate-300 m-1"
                              >
                                Edit Quote
                              </button>
                              <button
                                onClick={() => {
                                  deleteDraft(rowData._id);
                                }}
                                className="border border-black bg-red-500/20 dark:border-white px-3 py-1 rounded dark:hover:text-white hover:text-slate-600 hover:bg-slate-300 m-1"
                              >
                                Delete
                              </button>
                            </>
                          ) : null}
                        </div>
                      ),
                    },
                  ]}
                  data={dataStore.slice().reverse()}
                  title={`My Forms`}
                  icons={{
                    Clear: (props) => (
                      <DeleteIcon className="text-black dark:text-white" />
                    ),
                    Search: (props) => (
                      <SearchIcon className="text-black dark:text-white" />
                    ),
                    ResetSearch: (props) => (
                      <DeleteIcon className="text-black dark:text-white" />
                    ),
                    NextPage: (props) => (
                      <NavigateNextIcon className="text-black dark:text-white" />
                    ),
                    PreviousPage: (props) => (
                      <NavigateBeforeIcon className="text-black dark:text-white" />
                    ),
                    FirstPage: (props) => (
                      <SkipPreviousIcon className="text-black dark:text-white" />
                    ),
                    LastPage: (props) => (
                      <SkipNextIcon className="text-black dark:text-white" />
                    ),
                    SortArrow: (props) => (
                      <ArrowDropDownIcon className="text-black dark:text-white" />
                    ),
                  }}
                  style={{
                    backgroundColor:
                      window.document.documentElement.classList[0] === "dark"
                        ? "rgba(255, 255, 255, 0.05)"
                        : "rgba(255, 255, 255, 1)",
                    color:
                      window.document.documentElement.classList[0] === "dark"
                        ? "white"
                        : "black",
                  }}
                  options={{
                    pageSize: 10, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [5, 10, 15, 25],
                    headerStyle: {
                      search: true,
                      backgroundColor:
                        window.document.documentElement.classList[0] === "dark"
                          ? "rgba(255, 255, 255, 0.05)"
                          : "rgba(255, 255, 255, 1)",
                      color:
                        window.document.documentElement.classList[0] === "dark"
                          ? "white"
                          : "black",
                    },
                    searchFieldStyle: {
                      color:
                        window.document.documentElement.classList[0] === "dark"
                          ? "white"
                          : "black",
                      borderBottom:
                        window.document.documentElement.classList[0] === "dark"
                          ? "1px solid white"
                          : "1px solid black",
                    },
                    rowStyle: (rowData) => {
                      return {
                        fontSize: 14,
                      };
                    },
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: (
                        <div className="flex justify-center m-4">
                          <Alert severity="warning">
                            <div>
                              <AlertTitle>No Forms</AlertTitle>
                              If you think this is a mistake please contact{" "}
                              <strong>
                                <button
                                  onClick={() =>
                                    (window.location =
                                      "mailto:helpdesk@bigclumber.com")
                                  }
                                >
                                  Helpdesk
                                </button>
                              </strong>
                              .
                            </div>
                            <Divider />
                          </Alert>
                        </div>
                      ),
                    },
                  }}
                />
              </ThemeProvider>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ViewEntries;
