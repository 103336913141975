import React, { useEffect, useState } from "react";
import { createDoor, slab_styles } from "./utilities";
import DoorSpecifications from "./Components/DoorSpecifications";
import DoorPreview3D from "./DoorPreview3D";
import { Tooltip } from "@mui/material";
import { Dialog } from "@material-ui/core";

const DoorBuilder = (props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [pricing, setPricing] = useState(0);
  const [isDoubleDoor, setIsDoubleDoor] = useState();
  const [widthOptions, setWidthOptions] = useState([]);
  const [defaultMargin, setDefaultMargin] = useState(props.formData.margin);
  const [marginFlag, setMarginFlag] = useState(false);

  const [data, setData] = useState({
    casing_type: props.formData.casing_type || "",
    core: props.formData.core || "",
    hinge_finish: props.formData.hinge_finish || "",
    size_jamb_type: props.formData.size_jamb_type || "",
    stop: props.formData.stop || "",
    handing: props.formData.handing,
    door_type: props.formData.door_type,
    stop_applied: props.formData.stop_applied || "",
    applied: props.formData.applied || "",
    bolt_type: props.formData.bolt_type || "",
    backset: props.formData.backset || "",
    isDouble: props.formData.door_type === "double" || 0,
    height: `6'8"`,
    qty: 1,
    margin: props.formData.margin,
    estimated_price: props.formData.estimated_price || 0,
  });

  const updateStates = [
    props.formData.casing_type,
    props.formData.hinge_finish,
    props.formData.size_jamb_type,
    props.formData.stop,
    props.formData.core,
    props.formData.applied,
    props.formData.stop_applied,
    props.formData.slab_style,
    props.formData.door_type,
    props.formData.width,
    props.formData.catch_astragal,
    props.formData.bolt_type,
    props.formData.backset,
    data.margin,
    data.qty,
    pricing,
  ];

  // Change the casing type whenever formData changes but keep old data as well
  useEffect(() => {
    //This makes stops impossible to select if Jamb is split
    if (
      props &&
      props.size_jamb_type &&
      props.size_jamb_type.includes("Door Slab Only")
    ) {
      setData({
        ...data,
        casing_type: null,
        hinge_finish: null,
        stop: null,
        stop_applied: null,
        applied: null,
        core: props.formData.core,
      });
    } else {
      setData({
        ...data,
        catch_astragal: props.formData.catch_astragal,
        door_type: props.formData.door_type,
        casing_type: props.formData.casing_type,
        hinge_finish: props.formData.hinge_finish,
        size_jamb_type: props.formData.size_jamb_type,
        stop: props.formData.stop,
        stop_applied: props.formData.stop_applied,
        applied: props.formData.applied,
        isDouble: props.formData.door_type === "double" || 0,
        core: props.formData.core,
        bolt_type: props.formData.bolt_type,
        backset: props.formData.backset,
        estimated_price: pricing,
      });
    }
  }, updateStates);

  const handleDoorAdd = () => {
    //Add door to main Interior Door array
    var array = [...props.doors];
    var newDoor = createDoor(data);

    newDoor.isDouble = props.formData.door_type === "double" || 0;

    if (newDoor.isDouble) {
      newDoor.handing = "Double";
      newDoor.catch_astragal = props.formData.catch_astragal;
    } else {
      newDoor.catch_astragal = null;
    }

    newDoor.backset = props.formData.backset;
    newDoor.bolt_type = props.formData.bolt_type;

    array.push(newDoor);
    props.setDoors(array);

    //Add width back as a required field
    var a = props.requiredFields;
    a.push("width");
    props.setRequiredFields(a);

    //Set Default Data
    setData({
      height: `6'8"`,
      qty: 1,
      slab_style: newDoor.slab_style,
      isDouble: isDoubleDoor || 0,
      handing: isDoubleDoor ? "Double" : newDoor.handing,
      catch_astragal: props.formData.catch_astragal,
      casing_type: props.formData.casing_type,
      core: props.formData.core,
      size_jamb_type: props.formData.size_jamb_type,
      hinge_finish: props.formData.hinge_finish,
      stop: props.formData.stop,
      stop_applied: props.formData.stop_applied,
      applied: props.formData.applied,
      bolt_type: props.formData.bolt_type,
      backset: props.formData.backset,
      margin: defaultMargin,
    });

    setMarginFlag(!marginFlag);
  };

  const handleChange = async (event) => {
    const { name, value, type, checked } = event.currentTarget;

    if (type === "checkbox") {
      await setData({ ...data, [name]: checked });
      setIsDoubleDoor(checked);
    } else if (name === "slab_style") {
      await setData({ ...data, [name]: value, width: null });
    } else if (name === "margin") {
      const newMargin = value; // Parse the value to an integer
      setDefaultMargin(newMargin); // Update the default margin state
      await setData({ ...data, [name]: newMargin }); // Update the margin in the data state
    } else {
      await setData({ ...data, [name]: value });
    }
  };

  const handleReset = () => {
    //reset door data to default
    setData({
      height: `6'8"`,
      qty: 1,
      casing_type: props.formData.casing_type,
      core: props.formData.core,
      size_jamb_type: props.formData.size_jamb_type,
      hinge_finish: props.formData.hinge_finish,
      stop: props.formData.stop,
      stop_applied: props.formData.stop_applied,
      applied: props.formData.applied,
      bolt_type: props.formData.bolt_type,
      backset: props.formData.backset,
      isDouble: isDoubleDoor ? 1 : 0,
      handing: isDoubleDoor ? "Double" : null,
    });
  };

  const getDefaultChecked = (dataLabel, option) => {
    const stepData = data[dataLabel];
    return stepData === option;
  };

  const checkRequiredFields = () => {
    return (
      "Please fill out all fields: " +
      props.requiredFields.map((item) => {
        return ` ${item}`;
      })
    );
  };

  const getWidthOptions = async (slab, core) => {
    try {
      const response = await fetch(
        `/api/doors/kits/getSlabOptions?slabStyle=${slab}&coreType=${core}`
      );
      const data = await response.json();

      if (data.message === "Internal Server Error") {
        await setWidthOptions([]);
        return;
      } else {
        await setWidthOptions(data.options);
        return;
      }
    } catch (error) {
      console.log(error);
      await setWidthOptions([]);
      return;
    }
  };

  useEffect(() => {
    if (data.slab_style !== undefined && data.core !== undefined) {
      getWidthOptions(data.slab_style, data.core);
    }
  }, [data.core, data.slab_style]);

  return (
    <>
      <div className="flex w-full bg-white dark:bg-white/5 dark:border-white mr-1 mt-1 border border-black p-1">
        <div className="flex flex-col w-8/12">
          <div className="flex flex-col items-center">
            {props.formData.door_type !== "double" &&
              props.formData.size_jamb_type !== "Door Slab Only(null)" &&
              props.formData.door_type !== "bifold" && (
                <div className="flex flex-col items-center">
                  <p>Handing</p>
                  <div className="flex m-1 flex-wrap justify-center">
                    <div className="flex m-1 p-2" key="handing">
                      <div className="flex flex-col items-center">
                        <img
                          src={require("../../assets/DoorBuilder/handing_left.jpg")}
                          className="m-1 h-28"
                        />

                        <input
                          type="radio"
                          id={"Left Hand"}
                          name={"handing"}
                          value={"Left Hand"}
                          className="mr-1"
                          checked={data.handing === "Left Hand"}
                          onChange={(e) => {
                            handleChange(e);
                            props.handleRequiredRemoval("handing");
                          }}
                          required={true}
                        />
                        <label htmlFor={"Left Hand"}>{"Left Hand"}</label>
                      </div>
                      <div className="flex flex-col items-center">
                        <img
                          src={require("../../assets/DoorBuilder/handing_right.jpg")}
                          className="m-1 h-28"
                        />

                        <input
                          type="radio"
                          id={"Right Hand"}
                          name={"handing"}
                          value={"Right Hand"}
                          checked={data.handing === "Right Hand"}
                          className="mr-1"
                          onChange={(e) => {
                            handleChange(e);
                            props.handleRequiredRemoval("handing");
                          }}
                          required={true}
                        />
                        <label htmlFor={"Right Hand"}>{"Right Hand"}</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <p>Slab Style</p>
            <div className="flex m-1 justify-center">
              <div
                className="flex m-1 p-2 flex-wrap justify-center"
                key="slab_style"
              >
                {slab_styles(data.core)?.options?.map((item, index) => {
                  return (
                    <div className="flex flex-col items-center w-36">
                      <img
                        src={require(`../../assets/DoorBuilder/${
                          slab_styles(data.core).images[index]
                        }`)}
                        className="m-2 h-28"
                      />

                      <input
                        type="radio"
                        id={item}
                        name={"slab_style"}
                        value={item}
                        className="mr-1"
                        checked={getDefaultChecked("slab_style", item)}
                        onChange={(e) => {
                          handleChange(e);
                          props.handleRequiredRemoval("slab_style");
                        }}
                        required={true}
                      />
                      <p className="flex m-2">{item}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex w-full justify-center items-center">
              {/* Add A Quantity Selector */}
              <div className="flex w-1/5 items-center justify-center m-2">
                {props.formData.custom_margin && (
                  <div className="flex flex-col items-center m-2 ">
                    <p className="text-black dark:text-white">Margin(%)</p>
                    <input
                      type="text"
                      name="margin"
                      className="border border-black dark:text-black rounded w-full"
                      value={data && data.margin ? data.margin : 30}
                      onChange={handleChange}
                      defaultValue="30"
                      max={99}
                      onInput={(e) => {
                        // Allow only numbers and a single decimal point
                        e.target.value = e.target.value.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point is present
                        const parts = e.target.value.split(".");
                        if (parts.length > 2) {
                          parts.pop();
                          e.target.value = parts.join(".");
                        }

                        // Limit to two digits before decimal
                        const integerPart = parts[0];
                        if (integerPart.length > 2) {
                          e.target.value =
                            integerPart.substring(0, 2) +
                            (parts[1] ? "." + parts[1] : "");
                        }

                        // Limit decimal to two numbers after decimal point
                        if (parts[1]) {
                          const decimalPart = parts[1];
                          if (decimalPart.length > 2) {
                            e.target.value =
                              integerPart + "." + decimalPart.substring(0, 2);
                          }
                        }
                      }}
                    />
                  </div>
                )}

                <div className="m-2">
                  <p className="text-black dark:text-white">QTY</p>
                  <input
                    type="number"
                    name="qty"
                    className="border border-black dark:text-black rounded w-16"
                    value={data && data.qty ? data.qty : 1}
                    min="1"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex w-2/5 flex-col items-center m-2">
                <p className="text-black dark:text-white">Width</p>
                {data.slab_style && data.core ? (
                  <select
                    id="width"
                    name="width"
                    value={data.width || ""}
                    className="mr-1 w-full dark:text-black"
                    onChange={(e) => {
                      handleChange(e);
                      props.handleRequiredRemoval("width");
                    }}
                    required={true}
                  >
                    <option value="">Select an option</option>
                    {widthOptions.map((option) => {
                      if (option.productCode) {
                        let displayWidth = option.width;
                        if (data.isDouble) {
                          const [feet, inches] = option.width
                            .split("'")
                            .map((str) => parseInt(str));
                          const doubleTotalInches = (feet * 12 + inches) * 2;
                          const doubleFeet = Math.floor(doubleTotalInches / 12);
                          const doubleInches = doubleTotalInches % 12;
                          displayWidth = `${doubleFeet}'${doubleInches}"`;
                        }
                        return (
                          <option
                            value={`${option.width} - (${option.productCode})`}
                            key={option.width}
                          >
                            {option.width}{" "}
                            {data.isDouble
                              ? `(Actual Size: ${displayWidth})`
                              : ""}
                          </option>
                        );
                      } else {
                        return (
                          <option
                            disabled={true}
                            value={option.width}
                            key={option.width}
                          >
                            {option.width} - Non Stock (Call For Pricing)
                          </option>
                        );
                      }
                    })}
                  </select>
                ) : (
                  <p className="font-black text-red-600">
                    Please Select Slab Style And Core
                  </p>
                )}
              </div>

              {/* Special Instructions */}
              <div className="flex w-2/5 flex-col items-center m-2">
                <div className="flex flex-col items-center w-full">
                  <p className="text-black dark:text-white">
                    Special Instructions
                  </p>
                  <input
                    type="text"
                    name="special_instructions"
                    aria-multiline="true"
                    className="border border-black dark:text-black rounded w-full"
                    value={data?.special_instructions || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              {props.requiredFields.length === 0 ? (
                <button
                  onClick={handleDoorAdd}
                  className="bg-green-200 m-1 p-2 border border-black rounded"
                >
                  Add to Quote
                </button>
              ) : (
                <Tooltip title={checkRequiredFields()}>
                  <button className="bg-green-200/50 m-1 p-2 border border-black rounded cursor-not-allowed	">
                    Add to Quote
                  </button>
                </Tooltip>
              )}

              <button
                onClick={handleReset}
                className="bg-red-200 m-1 p-2 border border-black rounded"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-4/12">
          <div className="flex justify-center mb-2">
            <p className="text-black dark:text-white">Preview</p>
          </div>

          {props.formData.core !== "Pine" && (
            <div onClick={() => setPreviewOpen(true)} className="h-72 relative">
              <p className="absoulute top-10 right-10">
                *From Outside Room Swinging In
              </p>
              <DoorPreview3D
                className="z-10"
                formData={props.formData}
                data={data}
              />
            </div>
          )}

          {/* Render Door Specs currently selected */}
          <DoorSpecifications
            formData={props.formData}
            data={data}
            setEstimatedPrice={setPricing}
            setFormData={props.setFormData}
            marginFlag={marginFlag}
          />
        </div>
      </div>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={() => setPreviewOpen(false)}
        open={previewOpen}
      >
        <div className="h-screen">
          <DoorPreview3D
            className="z-10"
            formData={props.formData}
            data={data}
          />
        </div>
      </Dialog>
    </>
  );
};

export default DoorBuilder;
