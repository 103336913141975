import { CircularProgress, Dialog, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getForklifts } from "./Utilities/api";
import NumbersIcon from "@mui/icons-material/Numbers";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { normalizeDate } from "../../../utilities";

const DisplayForklifts = () => {
  const { location } = useParams();
  const [forkliftData, setForkliftData] = useState([]);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const [flag, setFlag] = useState(false);

  const Locations = [
    "Adrian",
    "Edgerton",
    "Fort Wayne - Store",
    "Fort Wayne - Mill",
    "Kendallville",
    "Cromwell",
    "Delton",
    "Schoolcraft",
    "Shipshewana",
    "Roseland",
    "Elkhart",
    "Three Oaks",
    "Coloma",
    "Mishawaka",
    "Eau Claire",
    "Granger",
    "LaPorte",
  ];

  useEffect(() => {
    getForklifts(setForkliftData, location);
  }, [flag]);

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    setUpdateData({ ...updateData, [name]: value });
  };

  return (
    <>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        {item && (
          <div className="flex flex-col items-center p-4">
            <p>
              Update Forklift Information for Unit <strong>#{item.unit}</strong>
            </p>

            <div className="flex flex-col items-center m-4">
              <p>New Unit Number</p>
              <input
                type="text"
                name="newUnit"
                className="border border-black dark:text-black rounded w-full"
                value={updateData?.newUnit}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col items-center m-4">
              <p>Change Locations</p>
              <select
                id={"newLocation"}
                name={"newLocation"}
                value={updateData?.newLocation || ""}
                className="mr-1 w-full dark:text-black"
                onChange={handleChange}
              >
                <option value={null}>Choose Location (Optional)</option>
                {Locations.map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-center w-full">
              {updateData && (
                <button
                  onClick={() => {
                    fetch("/api/fleet/forklift/updateForklift", {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        location: updateData.newLocation,
                        newUnit: updateData.newUnit,
                        oldLocation: location,
                        oldUnit: item.unit,
                      }),
                    });
                    setFlag(!flag);
                    setUpdateData({});
                    setOpen(false);
                  }}
                  className="p-2 rounded m-2 bg-green-400"
                >
                  Update
                </button>
              )}
              <Link to={`/fleet/forks/entries/${item.unit}`}>
                <button className="p-2 rounded m-2 bg-blue-400">
                  View Entries
                </button>
              </Link>
              <button
                onClick={() => {
                  setOpen(false);
                  setUpdateData({});
                }}
                className="p-2 rounded m-2 bg-red-400"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Dialog>
      <div className="flex flex-col">
        <div className="justify-center flex m-2">
          <p className="text-xl font-black">Forklifts for {location}</p>
        </div>
        <div className="flex flex-col justify-center">
          {forkliftData.length === 0 && <CircularProgress />}
          {forkliftData.map((item, index) => {
            return (
              <button
                onClick={() => {
                  setOpen(true);
                  setItem(item);
                }}
              >
                <div className="m-2 md:mt-0 md:col-span-2 ">
                  <div className="drop-shadow hover:drop-shadow-xl sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <div className="grid place-content-center grid-cols-3 gap-6">
                        <div className="col-span-1">
                          <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                            <Tooltip title="Unit Number">
                              <div className="flex justify-center">
                                <NumbersIcon />
                                <p className="ml-2">{item.unit}</p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="bg-gray-50 p-1 m-1 m-2 border-2 rounded-md">
                            <Tooltip title="Location">
                              <div className="flex justify-center">
                                <LocationOnIcon />
                                <p className="ml-2">{item.location}</p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="bg-gray-50 p-1 m-1 m-2 border-2 rounded-md">
                            <Tooltip title="Last Check In Date">
                              <div className="flex justify-center">
                                <CalendarTodayIcon />
                                <p className="ml-2">
                                  {normalizeDate(item.lastChecked)}
                                </p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        {item.moffett && (
                          <div className="absolute top-0 left-0">
                            <div>
                              <Tooltip title="This Is A Moffett">
                                <StarBorderPurple500Icon />
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DisplayForklifts;
