import { ThemeProvider, createTheme } from "@mui/material";
import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import { icons } from "../../../components/Utilities/UtilitiesWD";
import { getDeliveryRequestAll, normalizeDate } from "../../../utilities";
import ReactLoading from "react-loading";
import { CSVLink, CSVDownload } from "react-csv";

const RequestSummary = (props) => {
  const [requests, setRequests] = useState(null);
  const defaultMaterialTheme = createTheme();

  useEffect(() => {
    getDeliveryRequestAll(setRequests);
  }, []);

  return (
    <div className="text-black dark:text-white">
      <div className="flex justify-center p-2 border border-gray-950 bg-white dark:border-gray-500 dark:bg-white/5 m-2">
        <p className="font-bold text-xl">Exterior Order Request Summary</p>
      </div>

      <div className="m-2 align-end flex">
        <div className="w-3/4">
          {requests ? (
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                style={{
                  backgroundColor:
                    window.document.documentElement.classList[0] === "dark"
                      ? "rgba(255, 255, 255, 0.05)"
                      : "rgba(255, 255, 255, 1)",
                  color:
                    window.document.documentElement.classList[0] === "dark"
                      ? "white"
                      : "black",
                }}
                options={{
                  pageSize: 10, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [5, 10, 15, 25],
                  headerStyle: {
                    search: true,
                    backgroundColor:
                      window.document.documentElement.classList[0] === "dark"
                        ? "rgba(255, 255, 255, 0.05)"
                        : "rgba(255, 255, 255, 1)",
                    color:
                      window.document.documentElement.classList[0] === "dark"
                        ? "white"
                        : "black",
                  },
                  searchFieldStyle: {
                    color:
                      window.document.documentElement.classList[0] === "dark"
                        ? "white"
                        : "black",
                    borderBottom:
                      window.document.documentElement.classList[0] === "dark"
                        ? "1px solid white"
                        : "1px solid black",
                  },
                }}
                columns={[
                  {
                    title: "Date",
                    field: "requestSubmitted",
                    render: (rowData) => {
                      return normalizeDate(new Date(rowData?.requestSubmitted));
                    },
                  },
                  {
                    title: "From",
                    field: "requestName",
                  },
                  {
                    title: "Status",
                    field: "requestStatus",
                    render: (rowData) => {
                      switch (rowData.requestStatus) {
                        case 0:
                          return <p>Pending</p>;
                        case 1:
                          return (
                            <p>
                              Scheduled for{" "}
                              {rowData.earliestDateScheduled
                                ? rowData.earliestDateScheduled
                                : rowData.requestDate}
                            </p>
                          );
                        case 2:
                          return <p>Rejected</p>;
                        case 3:
                          return <p>Deleted</p>;
                        default:
                          return <p>Unknown Status</p>;
                          break;
                      }
                    },
                  },
                  {
                    title: "Customer",
                    field: "customer",
                  },
                ]}
                data={requests}
                title={"All Exterior Delivery Requests"}
                icons={icons}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No records to display",
                  },
                }}
              />
            </ThemeProvider>
          ) : (
            <div className="flex justify-center">
              <ReactLoading
                type={"bars"}
                color={"white"}
                height={200}
                width={250}
              />
            </div>
          )}
        </div>
        <div className="w-1/4">
          <div className="flex">
            <div className="flex flex-col items-center ml-2 border border-gray-950 bg-white dark:border-gray-500 dark:bg-white/5 p-2 w-full">
              <p>Requests Made</p>

              <div className="flex w-full p-2 justify-center">
                <p className="text-lg font-black">
                  {requests?.length ? (
                    requests.length
                  ) : (
                    <div className="flex justify-center">
                      <ReactLoading
                        type={"bars"}
                        color={"white"}
                        height={10}
                        width={25}
                      />
                    </div>
                  )}
                </p>
              </div>
              {requests?.length && (
                <CSVLink target="_blank" data={requests}>
                  <p className="text-xs underline">Download CSV</p>
                </CSVLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestSummary;
