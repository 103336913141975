import React, { useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CreateIcon from "@mui/icons-material/Create";

const Messages = () => {
  const [messages, setMessages] = useState([]);

  const messageData = [
    {
      id: 1,
      name: "John Green",
      last_message: "Hello, how are you?",
      last_message_time: "12:00 PM",
      unread_messages: 0,
    },
    {
      id: 2,
      name: "Jane Doe",
      last_message: "Hello, how are you?",
      last_message_time: "12:00 PM",
      unread_messages: 1,
    },
    {
      id: 3,
      name: "aslk;dmaskdm",
      last_message: "lorem10",
      last_message_time: "12:00 PM",
      unread_messages: 1,
    },
  ];

  return (
    <div className="flex p-2 m-4">
      <div className="flex flex-col w-1/5 p-2 border border-gray-500 bg-white/5 text-white items-center">
        <div className="flex w-full">
          <p className="border-b-2 w-full flex justify-center">Messages List</p>
          <button>
            <CreateIcon
              className="text-green-500 m-2"
              sx={{ color: "green" }}
            />
          </button>
        </div>

        <div className="w-full">
          {messageData.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-col m-2 border-b border-gray-500 hover:bg-white/5"
              >
                {item.unread_messages > 0 && (
                  <CircleIcon className="text-green-500 absolute" />
                )}
                <p className="flex font-black justify-center">{item.name}</p>
                <p>{item.last_message}</p>
                <p>{item.last_message_time}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex w-4/5 p-2 border border-gray-500 bg-white/5">
        Message Screen
      </div>
    </div>
  );
};

export default Messages;
