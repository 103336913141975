/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.8 TexturedColonist2.gltf
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function TexturedColonist2(props) {
  const { nodes, materials } = useGLTF("/TexturedColonist2.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane.geometry}
        material={materials["Material.001"]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload("/TexturedColonist2.gltf");
