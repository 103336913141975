import React, { useEffect, useState, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Dialog, MenuItem, Select, Tooltip } from "@mui/material";
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from "@mui/icons-material";
import { branches, getAdcUsers } from "./Utilities/api";
import LoadingSpinner from "../../components/Other/LoadingSpinner";
import IdleTimerRedirect from "../../components/Other/IdleTimerRedirect";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const UserPortal = () => {
  const [addUserModal, setAddModal] = useState(false);
  const [userDataFiltered, setUserDataFiltered] = useState(null);
  const [addSuccess, setAddSuccess] = useState(false);
  const [userData, setUserData] = useState([]);

  // New state for managing edit dialog
  const [editUser, setEditUser] = useState(null);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    getAdcUsers(setUserData);
  }, []);

  const [newUser, setNewUser] = useState({
    name: "",
    jobTitle: "",
    password: "",
    branch: "",
    login: "",
    operatorNumber: null,
  });

  const data = useMemo(
    () => userDataFiltered || userData,
    [userDataFiltered, userData]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Operator #",
        accessor: "operatorNumber",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Title",
        accessor: "jobTitle",
      },
      {
        Header: "Login",
        accessor: "login",
      },
      {
        Header: "Group",
        accessor: "adcGroup",
      },
      {
        Header: "Branch",
        accessor: "branch",
      },
      {
        Header: "Password",
        accessor: "password",
        Cell: ({ value }) => <PasswordCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <button
            onClick={() => {
              setEditUser(row.original);
              setEditModal(true);
            }}
            className="bg-blue-300 p-2 m-2 hover:bg-blue-500 rounded"
          >
            Edit
          </button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useSortBy,
    usePagination
  );

  //Search Users by name
  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase();

    if (search) {
      const filtered = userData.filter((user) => {
        return Object.values(user).some((value) =>
          value.toString().toLowerCase().includes(search)
        );
      });
      setUserDataFiltered(filtered);
    } else {
      setUserDataFiltered(userData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Assuming your API endpoint is '/api/users'
    const response = await fetch("/api/profile/portal/addUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newUser),
    });

    if (response.status === 200) {
      getAdcUsers(setUserData);
      setAddModal(false);
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    // Assuming your API endpoint is '/api/profile/portal/editUser'
    const response = await fetch("/api/profile/portal/updateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editUser),
    });

    if (response.status === 200) {
      getAdcUsers(setUserData);
      setEditModal(false);
    }
  };

  return (
    <div className="m-2">
      <IdleTimerRedirect time={10} route={"/"} />
      {userData.length > 0 ? (
        <>
          <button
            onClick={() => {
              setAddModal(true);
            }}
            className="bg-green-200 hover:bg-green-300 m-2 p-2 rounded"
          >
            Add User
          </button>

          <div className="flex justify-center items-center w-full ">
            <div className="flex flex-col w-full">
              <p className="items-center p-1 m-2 border rounded border-gray-950 bg-white dark:border-gray-500 dark:bg-white/5 text-black dark:text-white justify-center flex flex-col">
                All Users
                <input
                  className="border border-gray-950 dark:border-gray-500 flex justify-center m-2 w-full"
                  type="text"
                  placeholder="Search Users"
                  onChange={handleSearch}
                />
              </p>
            </div>
          </div>

          <table
            {...getTableProps()}
            className="table-auto mx-auto text-black dark:text-white bg-white dark:bg-white/5 border border-black dark:border-white w-full"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-4 py-2 bg-blue-200 dark:bg-blue-200/30"
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    onDoubleClick={() => {
                      setNewUser({
                        name: "",
                        jobTitle: row.original.jobTitle,
                        password: "",
                        branch: row.original.branch,
                        login: "",
                        operatorNumber: null,
                        adcGroup: row.original.adcGroup,
                      });
                      setAddSuccess(false);
                      setAddModal(true);
                    }}
                    className="hover:bg-gray-100 dark:hover:bg-gray-100/10 "
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="border border-black dark:border-white px-4 py-2 w-1/8"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-center items-center mt-2 dark:text-white p-2">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="mr-2"
            >
              <ArrowCircleLeftOutlined style={{ fontSize: "30px" }} />
            </button>

            <span className="ml-2">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>

            <button onClick={() => nextPage()} disabled={!canNextPage}>
              <ArrowCircleRightOutlined
                style={{ fontSize: "30px", marginLeft: "15px" }}
              />
            </button>
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}

      {addUserModal && (
        <Dialog
          open={addUserModal}
          onClose={() => {
            setAddModal(false);
          }}
          maxWidth={"lg"}
          key={addUserModal ? "open" : "closed"}
        >
          <div className="flex m-2 justify-center">
            {!addSuccess ? (
              <form
                className="flex flex-col items-center"
                onSubmit={handleSubmit}
              >
                <label className="m-2 flex flex-col w-full">
                  Name:
                  <input
                    type="text"
                    className="w-full border bg-white"
                    name="name"
                    value={newUser.name}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="m-2 flex flex-col w-full">
                  Operator Number:
                  <input
                    type="text"
                    className="w-full border bg-white"
                    name="operatorNumber"
                    value={newUser.operatorNumber}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="m-2 flex flex-col w-full">
                  Job Title:
                  <input
                    type="text"
                    className="w-full border bg-white"
                    name="jobTitle"
                    value={newUser.jobTitle}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="m-2 flex flex-col w-full">
                  ADC Login:
                  <input
                    type="text"
                    className="w-full border bg-white"
                    name="login"
                    value={newUser.login}
                    onChange={handleInputChange}
                  />
                </label>

                <label className="m-2 flex flex-col w-full">
                  ADC Group:
                  <input
                    type="text"
                    className="w-full border bg-white"
                    name="adcGroup"
                    value={newUser.adcGroup}
                    onChange={handleInputChange}
                  />
                </label>

                <label className="m-2 flex flex-col w-full">
                  Password:
                  <input
                    type="text"
                    className="w-full border bg-white"
                    name="password"
                    value={newUser.password}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="m-2 flex flex-col w-full">
                  Branch Location:
                  <Select
                    value={newUser.branch}
                    onChange={handleInputChange}
                    className="w-full bg-white"
                    name="branch"
                  >
                    {branches.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </label>
                <button
                  className="bg-green-200 hover:bg-green-300 m-2 p-2 rounded"
                  type="submit"
                >
                  Add User
                </button>
              </form>
            ) : (
              <div className="flex w-full justify-center items-center flex-col p-2">
                <CheckCircleIcon className="text-green-400 w-64" />
                <p>User Successfully Added!</p>

                <div className="flex m-2">
                  <button
                    onClick={() => {
                      setAddSuccess(false);
                      setNewUser({
                        name: "",
                        jobTitle: "",
                        password: "",
                        branch: "",
                        login: "",
                        operatorNumber: null,
                        adcGroup: "",
                      });
                    }}
                    className="bg-green-100 hover:bg-green-200 p-2 m-2 rounded"
                  >
                    Add Another
                  </button>
                  <button
                    onClick={() => {
                      setAddSuccess(false);
                      setAddModal(false);
                      setNewUser({
                        name: "",
                        jobTitle: "",
                        password: "",
                        branch: "",
                        login: "",
                        operatorNumber: null,
                        adcGroup: "",
                      });
                    }}
                    className="bg-red-100 hover:bg-red-200 p-2 m-2 rounded"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </Dialog>
      )}

      {editUser && (
        <Dialog
          open={editModal}
          onClose={() => {
            setEditModal(false);
          }}
          maxWidth={"lg"}
          key={editModal ? "open" : "closed"}
        >
          <div className="flex m-2 justify-center">
            <form
              className="flex flex-col items-center"
              onSubmit={handleEditSubmit}
            >
              {/* Add input fields for editing user information */}
              <label className="m-2 flex flex-col w-full">
                Operator Number:
                <input
                  type="text"
                  className="w-full border bg-white"
                  name="operatorNumber"
                  value={editUser.operatorNumber}
                  onChange={(e) =>
                    setEditUser((prevUser) => ({
                      ...prevUser,
                      operatorNumber: e.target.value,
                    }))
                  }
                />
              </label>

              <label className="m-2 flex flex-col w-full">
                Name:
                <input
                  type="text"
                  className="w-full border bg-white"
                  name="name"
                  value={editUser.name}
                  onChange={(e) =>
                    setEditUser((prevUser) => ({
                      ...prevUser,
                      name: e.target.value,
                    }))
                  }
                />
              </label>

              <label className="m-2 flex flex-col w-full">
                Title:
                <input
                  type="text"
                  className="w-full border bg-white"
                  name="jobTitle"
                  value={editUser.jobTitle}
                  onChange={(e) =>
                    setEditUser((prevUser) => ({
                      ...prevUser,
                      jobTitle: e.target.value,
                    }))
                  }
                />
              </label>

              <label className="m-2 flex flex-col w-full">
                Login:
                <input
                  type="text"
                  className="w-full border bg-white"
                  name="login"
                  value={editUser.login}
                  onChange={(e) =>
                    setEditUser((prevUser) => ({
                      ...prevUser,
                      login: e.target.value,
                    }))
                  }
                />
              </label>

              <label className="m-2 flex flex-col w-full">
                ADC Group:
                <input
                  type="text"
                  className="w-full border bg-white"
                  name="adcGroup"
                  value={editUser.adcGroup}
                  onChange={(e) =>
                    setEditUser((prevUser) => ({
                      ...prevUser,
                      adcGroup: e.target.value,
                    }))
                  }
                />
              </label>

              <label className="m-2 flex flex-col w-full">
                Branch:
                <Select
                  value={editUser.branch}
                  onChange={(e) =>
                    setEditUser((prevUser) => ({
                      ...prevUser,
                      branch: e.target.value,
                    }))
                  }
                  className="w-full bg-white"
                  name="branch"
                >
                  {branches.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </label>

              <label className="m-2 flex flex-col w-full">
                Password:
                <input
                  type="text"
                  className="w-full border bg-white"
                  name="password"
                  value={editUser.password}
                  onChange={(e) =>
                    setEditUser((prevUser) => ({
                      ...prevUser,
                      password: e.target.value,
                    }))
                  }
                />
              </label>

              <button type="submit">Save Changes</button>
            </form>
          </div>
        </Dialog>
      )}
    </div>
  );
};

// Custom cell component for rendering password with show/hide functionality
const PasswordCell = ({ value }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="flex flex-col">
      {showPassword ? <span>{value}</span> : <span>********</span>}
      <div className="flex">
        <Tooltip title="Copy Password">
          <button className="m-1" onClick={() => copyToClipboard(value)}>
            <ContentCopyIcon />
          </button>
        </Tooltip>
        <Tooltip title="Show/Hide Password">
          <button className="m-1" onClick={togglePasswordVisibility}>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default UserPortal;
