export const getCustomerSearch = async (setter, term) => {
  return fetch(`/api/forms/misc/getCustomerSearch?term=${term}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson.recordset);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getCustomerAddress = async (setter, id) => {
  return fetch(`/api/forms/misc/getCustomerAddress?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson.recordset);
      setter(responseJson.recordset);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const formAutoSave = async (data, setter) => {
  //Delete ID so Mongo is happy. (Prevents trying to overwrite the existing _id which will crash Mongo)
  delete data._id;

  var data = JSON.stringify(data);

  const rawResponse = await fetch("/api/forms/misc/autosave", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();

  setter(content.formID);
};

export const getPricing = async (data, isDouble, jamb, setter) => {
  return fetch(
    `/api/doors/kits/quotePricing?priceList=${data}&isDouble=${isDouble}&jamb=${jamb}`
  )
    .then((response) => response.json())
    .then(async (responseJson) => {
      //console.log(responseJson.recordset);
      await setter(
        Math.round((responseJson.pricing + Number.EPSILON) * 100) / 100
      );
    })
    .catch((error) => {
      //console.error(error);
    });
};
