import { Routes, Route, useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { loginRequest } from "./authConfig";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import NavListAuth from "./components/Navigation/NavListAuth";
import { routes } from "./components/Routes/RouteHandler";
import { Helmet } from "react-helmet";
import Footer from "./components/Navigation/Footer";
import { useEffect, useState } from "react";
import MfaHandler from "./components/Routes/MfaHandler";
import { selectUser } from "./features/userSlice";
import { useSelector } from "react-redux";
import io from "socket.io-client";

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Pages />
    </MsalProvider>
  );
}

function Pages() {
  const authRequest = {
    ...loginRequest,
  };

  const [mfaVerify, setVerify] = useState(false);
  const [prevAuth, setPrevAuth] = useState(false);
  const user = useSelector(selectUser);

  return (
    <>
      <Routes>
        {routes.map((route, index) => {
          if (!route.requiresMFA) {
            return (
              <Route
                path={route.path}
                key={route.path}
                element={
                  route.authHeader ? (
                    <MsalAuthenticationTemplate
                      interactionType={InteractionType.Redirect}
                      authenticationRequest={authRequest}
                    >
                      <Helmet>
                        <title>{route.name}</title>
                      </Helmet>
                      <div
                        className={
                          "flex flex-col h-screen justify-between dark:contrast-150"
                        }
                      >
                        {route.authHeader && <NavListAuth />}
                        <div className="mb-auto"> {route.component}</div>
                        {route.footer && <Footer />}
                      </div>
                    </MsalAuthenticationTemplate>
                  ) : (
                    <>
                      <Helmet>
                        <title>{route.name}</title>
                      </Helmet>
                      {route.component}
                    </>
                  )
                }
              />
            );
          } else {
            return (
              <Route
                path={route.path}
                key={route.path}
                element={
                  route.authHeader ? (
                    <MsalAuthenticationTemplate
                      interactionType={InteractionType.Redirect}
                      authenticationRequest={authRequest}
                    >
                      <Helmet>
                        <title>{route.name}</title>
                      </Helmet>
                      {mfaVerify || prevAuth ? (
                        <div className="flex flex-col h-screen justify-between">
                          {route.authHeader && <NavListAuth />}
                          <div className="mb-auto"> {route.component}</div>
                          {route.footer && <Footer />}
                        </div>
                      ) : (
                        <div className="flex flex-col h-screen justify-between">
                          {route.authHeader && <NavListAuth />}
                          <MfaHandler
                            setPrevAuth={setPrevAuth}
                            setVerify={setVerify}
                            mfaVerify={mfaVerify}
                            prevAuth={prevAuth}
                          />
                          {route.footer && <Footer />}
                        </div>
                      )}
                    </MsalAuthenticationTemplate>
                  ) : (
                    <>
                      <Helmet>
                        <title>{route.name}</title>
                      </Helmet>
                      {route.component}
                    </>
                  )
                }
              />
            );
          }
        })}
      </Routes>
    </>
  );
}

export default App;
