import React, { useState } from "react";
import AddLocation from "../../../components/Fleet/Forklifts/AddLocation";
import DisplayLocations from "../../../components/Fleet/Forklifts/DisplayLocations";
import LocationManagerInfo from "../../../components/Fleet/Forklifts/LocationManagerInfo";
import NavListAuth from "../../../components/Navigation/NavListAuth";

const LocationManager = () => {
  const [flag, setFlag] = useState(false);

  return (
    <>
      <div className="flex flex-col">
        <div>
          <AddLocation setFlag={setFlag} flag={flag} />
        </div>
        <div>
          <DisplayLocations flag={flag} />
        </div>

        <div>
          <LocationManagerInfo setFlag={setFlag} flag={flag} />
        </div>
      </div>
    </>
  );
};

export default LocationManager;
