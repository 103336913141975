import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../components/Other/LoadingSpinner";
import { useParams } from "react-router-dom";
import { normalizeDate } from "../../../utilities";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Dialog } from "@mui/material";

const DisplayEntries = () => {
  const [forkliftEntries, setForkliftEntries] = useState([]);
  const { unit } = useParams();
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});

  useEffect(() => {
    getEntries();
  }, []);

  const getEntries = () => {
    return fetch(`/api/fleet/forklift/getEntries?unit=${unit}`)
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson);
        setForkliftEntries(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Submit Date",
        accessor: "submitDate",
        Cell: ({ value }) => <p>{normalizeDate(value)}</p>,
      },
      {
        Header: "Submit By",
        accessor: "name",
      },
      {
        Header: "Safe?",
        accessor: "notSafe",
        Cell: ({ value }) =>
          value ? (
            <p className="text-red-500">Not Safe</p>
          ) : (
            <p className="text-green-500">Safe</p>
          ),
      },
      {
        Header: "View",
        Cell: ({ row }) => (
          <button
            onClick={() => {
              setRow(row.original);
              setOpen(true);
            }}
          >
            View
          </button>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => forkliftEntries, [forkliftEntries]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  return (
    <div className="flex flex-col m-2">
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <div className="m-2 p-2">
          {Object.keys(row).map((key) => (
            <div className="flex flex-col items-center overflow-auto" key={key}>
              <div className="flex text-black dark:text-white hover:text-slate-500 dark:hover:text-slate-500">
                <p className="flex">{key.toUpperCase()}:</p>

                <p className="ml-1">{row[key]}</p>
              </div>
            </div>
          ))}
        </div>
      </Dialog>
      {forkliftEntries?.length > 0 ? (
        <div className="flex flex-col items-center">
          <div className="flex m-2 w-1/2">
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              value={globalFilter || ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </div>
          <table
            className="w-full m-4 dark:text-white rounded-md"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="dark:bg-gray-800 bg-gray-500"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="p-4 text-center"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={
                      row.index % 2 === 0
                        ? "dark:bg-gray-700 bg-gray-300"
                        : "dark:bg-gray-800 bg-white"
                    }
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="p-4 text-center w-1/6"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex flex-col items-center">
            <div className="flex">
              <button
                className="m-2 font-black"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>
              <button
                className="m-2 font-black"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>
              <button
                className="m-2 font-black"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>
              <button
                className="m-2 font-black"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
            <span>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
        </div>
      ) : (
        <p>
          <LoadingSpinner />
        </p>
      )}
    </div>
  );
};

export default DisplayEntries;
