import React from "react";
import AddForklift from "../../../components/Fleet/Forklifts/AddForklift";
import NavListAuth from "../../../components/Navigation/NavListAuth";

const ForkManager = () => {
  return (
    <>
      <div>
        <AddForklift />
      </div>
    </>
  );
};

export default ForkManager;
