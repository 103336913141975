import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import {
  getUserFavorites,
  getUserPins,
  getUserRoles,
} from "../../views/Admin/Utilities/api";
import { useDispatch } from "react-redux";
import { PageSearch } from "./PageSearch";
import { SideNavigation } from "./SideNavigation";
import { update } from "../../features/roleSlice";
import { store } from "../../features/eventSlice";
import { callMsCalendar } from "../../utils/MsGraphApiCall";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import { storeFavorites } from "../../features/favoriteSlice";
import NotificationButton from "../Other/NotificationButton";
import ToggleDarkTheme from "./ToggleDarkTheme";
import { storePins } from "../../features/pinnedSlice";

const NavListAuth = () => {
  const user = useSelector(selectUser);
  const [roles, setRoles] = useState(false);
  const [favorites, setFavorites] = useState(false);
  const [pins, setPins] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const { instance } = useMsal();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUserActions = () => {
    return fetch(`/api/useractions/getActions?id=${user.id}`)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[0]?.logout == 1) {
          navigate("/logout");
        } else if (responseJson[0]?.redirect != null) {
          window.location.href = responseJson[0].redirect;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //use effect to get user actions every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (user) {
        getUserActions();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/inituser");
    } else {
      getUserRoles(setRoles, user.id);
      getUserFavorites(setFavorites, user.id);
      getUserPins(setPins, user.id);
    }
  }, [user]);

  useEffect(() => {
    callMsCalendar()
      .then((response) => {
        dispatch(store(response));
      })
      .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount(),
          });
        }
      });
  }, []);

  useEffect(() => {
    if (roles.length > 0) {
      dispatch(update({ roles: roles }));
    }
  }, [roles]);

  useEffect(() => {
    if (favorites.length > 0) {
      //console.log(favorites);
      dispatch(storeFavorites({ favorite: favorites }));
    }
  }, [favorites]);

  useEffect(() => {
    if (pins.length > 0) {
      //console.log(favorites);
      dispatch(storePins({ pins: pins }));
    }
  }, [pins]);

  return (
    <>
      <nav className="bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 border-b-2 dark:border-gray-500 border-black dark:from-gray-800 from-gray-400/50 to-gray-300/50 border-black">
        <div className="flex flex-wrap items-center justify-between mx-auto p-4">
          <SideNavigation
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />

          <div className="flex ">
            <div className="flex w-1/5 justify-start items-center">
              <button
                onClick={() => setShowSidebar(!showSidebar)}
                className="flex text-black dark:text-white justify-start cursor-pointer w-2"
              >
                <MenuIcon className="animate-pulse" />
              </button>
            </div>

            <div className="flex w-4/5 items-center pl-2">
              <Link to="/">
                <img
                  src={
                    window.document.documentElement.classList[0] === "dark"
                      ? require("../../assets/NodeLogo2.png")
                      : require("../../assets/NodeLogoDark.png")
                  }
                  style={{ width: "150px" }}
                />
              </Link>
            </div>
          </div>

          <button
            data-collapse-toggle="navbar-multi-level"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2  dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-multi-level"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <HomeIcon className="text-black" />
          </button>
          <div className="w-full md:flex md:w-auto" id="navbar-multi-level">
            <ul className="flex sm:flex-col items-center justify-center font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0">
              {/* <li>
                <ContextProvider>
                  <ScreenShareNavBar />
                </ContextProvider>
              </li> */}
              <li>
                <PageSearch />
              </li>

              <li>
                <div className="flex justify-center items-center border rounded-full dark:border-white border-black">
                  <ToggleDarkTheme />
                </div>
              </li>
              <li>
                <button>
                  <NotificationButton />
                </button>
              </li>
              <li>
                <Link to="/profile">
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-10 h-10 bg-gray-300 rounded-full overflow-hidden border-cyan-800 border-2">
                      <img
                        src={`/api/profile/image?id=${user?.id}`}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavListAuth;
