import React, { useState } from "react";
import { addChange, addchange } from "./Utilities/api";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { useNavigate } from "react-router-dom";

const Changes = () => {
  const [change, setChange] = useState("");
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setChange(event.target.value);
  };

  const handleSubmit = () => {
    const data = {
      change: change,
      submittedBy: user,
      date: new Date(),
    };
    addChange(data);
    setChange("");
  };

  return (
    <div className="flex flex-col items-center">
      <p className="dark:text-white text-2xl mb-4 text-center">
        Input Node changes here.
      </p>
      <textarea
        className="text-black p-2 rounded"
        rows="4"
        cols="50"
        value={change}
        onChange={handleChange}
        placeholder="Enter your changes here..."
      ></textarea>

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-4 rounded"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default Changes;
