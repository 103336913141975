import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  sideNav,
  sideNavAdminLinks,
  sideNavFleetForklifts,
  sideNavMyDashboard,
  sideNavRFQForms,
  sideNavSBCF,
  sideNavWebLeads,
  sideNavWindowDoor,
} from "./components/Navigation/SideNav";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FavoriteButton from "./components/Navigation/FavoriteButton";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import DoorBackIcon from "@mui/icons-material/DoorBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useSelector } from "react-redux";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { selectRoles } from "./features/roleSlice";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LanguageIcon from "@mui/icons-material/Language";
import PinButton from "./components/Navigation/PinButton";
import FoundationIcon from "@mui/icons-material/Foundation";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

function SideNav(props) {
  const [open, setOpen] = useState(false);
  const [openRFQ, setOpenRFQ] = useState(false);
  const [openSBCF, setOpenSBCF] = useState(false);

  const [openDashboard, setOpenDashboard] = useState(false);
  const [openFleet, setOpenFleet] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openWebLead, setOpenWebLead] = useState(false);
  const role = useSelector(selectRoles);

  const navigate = useNavigate();

  const handleNavigation = () => {
    props.setShowSidebar(!props.showSidebar);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const checkRoles = (neededRoles, myRoles) => {
    if (neededRoles[0] === "*") {
      return true;
    }

    // Convert both arrays to sets for easier comparison
    const neededRolesSet = new Set(neededRoles);
    const myRolesSet = new Set(myRoles);

    // Check if any role in neededRolesSet is present in myRolesSet
    for (const role of neededRolesSet) {
      if (myRolesSet.has(role)) {
        return true; // Role found in myRoles
      }
    }

    return false; // No role found in myRoles
  };
  return (
    <>
      <List className="border-b border-black dark:border-white dark:text-white">
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <DoorBackIcon className="text-black dark:text-white" />
          </ListItemIcon>
          <ListItemText primary="Window Door" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sideNavWindowDoor.map((item, index) => {
              if (checkRoles(item.roles, role?.roles)) {
                return (
                  <div key={index} className="flex">
                    <div className="flex w-11/12">
                      <Link
                        onClick={() => handleNavigation(item.path)}
                        to={item.path}
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary={item.name} />
                        </ListItemButton>
                      </Link>
                    </div>

                    <div className="flex justify-center items-center w-1/12">
                      <button>
                        <FavoriteButton item={item.name} />
                      </button>
                    </div>
                    {item.pin && (
                      <div className="flex justify-center items-center w-1/12">
                        <button>
                          <PinButton item={item.name} />
                        </button>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </List>
        </Collapse>
      </List>

      <List className="border-b border-black dark:border-white dark:text-white">
        <ListItemButton onClick={() => setOpenSBCF(!openSBCF)}>
          <ListItemIcon>
            <FoundationIcon className="text-black dark:text-white" />
          </ListItemIcon>
          <ListItemText primary="SBCF" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSBCF} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sideNavSBCF.map((item, index) => {
              return (
                <div key={index} className="flex">
                  <div className="flex w-11/12">
                    <Link
                      onClick={() => handleNavigation(item.path)}
                      to={item.path}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </Link>
                  </div>

                  <div className="flex justify-center items-center w-1/12">
                    <button>
                      <FavoriteButton item={item.name} />
                    </button>
                  </div>
                  {item.pin && (
                    <div className="flex justify-center items-center w-1/12">
                      <button>
                        <PinButton item={item.name} />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </List>
        </Collapse>
      </List>

      <List className="w-full border-b border-black dark:border-white dark:text-white">
        <ListItemButton onClick={() => setOpenRFQ(!openRFQ)}>
          <ListItemIcon>
            <AssignmentIcon className="text-black dark:text-white" />
          </ListItemIcon>
          <ListItemText primary="RFQ Forms" />
          {openRFQ ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openRFQ} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sideNavRFQForms.map((item, index) => {
              if (checkRoles(item.roles, role?.roles)) {
                return (
                  <div key={index} className="flex">
                    <div className="flex w-11/12">
                      <Link
                        onClick={() => handleNavigation(item.path)}
                        to={item.path}
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary={item.name} />
                        </ListItemButton>
                      </Link>
                    </div>
                    <div className="flex justify-center items-center w-1/12">
                      <button>
                        <FavoriteButton item={item.name} />
                      </button>
                    </div>
                    {item.pin && (
                      <div className="flex justify-center items-center w-1/12">
                        <button>
                          <PinButton item={item.name} />
                        </button>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </List>
        </Collapse>
      </List>

      {role?.roles?.includes("Admin") && (
        <List className="w-full border-b border-black dark:border-white dark:text-white">
          <ListItemButton onClick={() => setOpenAdmin(!openAdmin)}>
            <ListItemIcon>
              <AdminPanelSettingsIcon className="text-black dark:text-white" />
            </ListItemIcon>
            <ListItemText primary="Admin Links" />
            {openAdmin ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openAdmin} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {sideNavAdminLinks.map((item, index) => {
                if (checkRoles(item.roles, role?.roles)) {
                  return (
                    <div key={index} className="flex">
                      <div className="flex w-11/12">
                        <Link
                          onClick={() => handleNavigation(item.path)}
                          to={item.path}
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary={item.name} />
                          </ListItemButton>
                        </Link>
                      </div>
                      <div className="flex justify-center items-center w-1/12">
                        <button>
                          <FavoriteButton item={item.name} />
                        </button>
                      </div>
                      {item.pin && (
                        <div className="flex justify-center items-center w-1/12">
                          <button>
                            <PinButton item={item.name} />
                          </button>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </List>
          </Collapse>
        </List>
      )}

      <List className="w-full border-b border-black dark:border-white dark:text-white">
        <ListItemButton onClick={() => setOpenDashboard(!openDashboard)}>
          <ListItemIcon>
            <DashboardIcon className="text-black dark:text-white" />
          </ListItemIcon>
          <ListItemText primary="My Dashboard" />
          {openDashboard ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openDashboard} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sideNavMyDashboard.map((item, index) => {
              if (checkRoles(item.roles, role?.roles)) {
                return (
                  <div key={index} className="flex">
                    <div className="flex w-11/12">
                      <Link
                        onClick={() => handleNavigation(item.path)}
                        to={item.path}
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary={item.name} />
                        </ListItemButton>
                      </Link>
                    </div>
                    <div className="flex justify-center items-center w-1/12">
                      <button>
                        <FavoriteButton item={item.name} />
                      </button>
                    </div>
                    {item.pin && (
                      <div className="flex justify-center items-center w-1/12">
                        <button>
                          <PinButton item={item.name} />
                        </button>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </List>
        </Collapse>
      </List>

      {role?.roles?.includes("Admin") && (
        <List className="w-full border-b border-black dark:border-white dark:text-white">
          <ListItemButton onClick={() => setOpenFleet(!openFleet)}>
            <ListItemIcon>
              <LocalShippingIcon className="text-black dark:text-white" />
            </ListItemIcon>
            <ListItemText primary="Fleet / Forklifts" />
            {openFleet ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openFleet} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {sideNavFleetForklifts.map((item, index) => {
                if (checkRoles(item.roles, role?.roles)) {
                  return (
                    <div key={index} className="flex">
                      <div className="flex w-11/12">
                        <Link
                          onClick={() => handleNavigation(item.path)}
                          to={item.path}
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary={item.name} />
                          </ListItemButton>
                        </Link>
                      </div>
                      <div className="flex justify-center items-center w-1/12">
                        <button>
                          <FavoriteButton item={item.name} />
                        </button>
                      </div>
                      {item.pin && (
                        <div className="flex justify-center items-center w-1/12">
                          <button>
                            <PinButton item={item.name} />
                          </button>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </List>
          </Collapse>
        </List>
      )}
      <List className="w-full border-b border-black dark:border-white dark:text-white">
        <ListItemButton onClick={() => setOpenWebLead(!openWebLead)}>
          <ListItemIcon>
            <LanguageIcon className="text-black dark:text-white" />
          </ListItemIcon>
          <ListItemText primary="Web Leads" />
          {openWebLead ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openWebLead} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sideNavWebLeads.map((item, index) => {
              if (checkRoles(item.roles, role?.roles)) {
                return (
                  <div key={index} className="flex">
                    <div className="flex w-11/12">
                      <Link
                        onClick={() => handleNavigation(item.path)}
                        to={item.path}
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary={item.name} />
                        </ListItemButton>
                      </Link>
                    </div>
                    <div className="flex justify-center items-center w-1/12">
                      <button>
                        <FavoriteButton item={item.name} />
                      </button>
                    </div>
                    {item.pin && (
                      <div className="flex justify-center items-center w-1/12">
                        <button>
                          <PinButton item={item.name} />
                        </button>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </List>
        </Collapse>
      </List>
      <div className="flex w-full items-center dark:text-white">
        <button
          onClick={() => {
            handleNavigation("/forms/recognition");
            navigate("/forms/recognition");
          }}
          className="flex pl-4 mt-2"
        >
          <div className="flex w-full">
            <LocalActivityIcon />

            <p className="pl-8 hover:text-cyan-800">Caught in the Act </p>
          </div>
        </button>

        <button
          className="items-center flex justify-center ml-2 mt-2 dark:text-white"
          onClick={() => {
            handleNavigation("/forms/recognition/entries");
            navigate("/forms/recognition/entries");
          }}
        >
          <p className="hover:text-cyan-800">[View Entries]</p>
        </button>
      </div>

      <button
        onClick={() => {
          handleNavigation("/forms/recognition");
          navigate("/forms/issue/delivery");
        }}
        className="flex pl-4 mt-2 border-t w-full pt-2 border-black dark:text-white"
      >
        <div className="flex w-full">
          <ReportProblemIcon />

          <p className="pl-8">Delivery Problem</p>
        </div>
      </button>
    </>
  );
}

export default SideNav;
