import DaySVG from "../../assets/Weather/animated/Day";
import CloudySVG from "../../assets/Weather/animated/Cloudy";
import DrizzleSVG from "../../assets/Weather/animated/Drizzle";
import HeavyRainSVG from "../../assets/Weather/animated/HeavyRain";
import SnowSVG from "../../assets/Weather/animated/Snow";
import ThunderSVG from "../../assets/Weather/animated/Thunder";
import MistSVG from "../../assets/Weather/animated/Mist";
import DustSVG from "../../assets/Weather/animated/Dust";
import TornadoSVG from "../../assets/Weather/animated/Tornado";
import HazeSVG from "../../assets/Weather/animated/Haze";
import SmokeSVG from "../../assets/Weather/Smoke";

const renderWeatherIcon = (main, size, description) => {
  switch (main) {
    case "Clear":
      return <DaySVG className={`h-${size} drop-shadow-xl`} />;
    case "Clouds":
      return (
        <CloudySVG
          description={description}
          className={`h-${size} drop-shadow-xl`}
        />
      );
    case "Thunderstorm":
      return <ThunderSVG className={`h-${size} drop-shadow-xl`} />;
    case "Rain":
      return (
        <HeavyRainSVG
          description={description}
          className={`h-${size} drop-shadow-xl`}
        />
      );
    case "Drizzle":
      return <DrizzleSVG className={`h-${size} drop-shadow-xl`} />;
    case "Snow":
      return <SnowSVG className={`h-${size} drop-shadow-xl`} />;
    case "Mist":
      return <MistSVG className={`h-${size} drop-shadow-xl`} />;
    case "Fog":
      return <MistSVG className={`h-${size} drop-shadow-xl`} />;
    case "Tornado":
      return <TornadoSVG className={`h-${size} drop-shadow-xl`} />;
    case "Dust":
      return <DustSVG className={`h-${size} drop-shadow-xl`} />;
    case "Haze":
      return <HazeSVG className={`h-${size} drop-shadow-xl`} />;
    case "Smoke":
      return <SmokeSVG className={`h-${size} drop-shadow-xl`} />;
    default:
      return null;
  }
};

export default renderWeatherIcon;
