import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
  name: "event",
  initialState: {
    event: null,
  },
  reducers: {
    store: (state, action) => {
      //console.log(state);
      state.event = action.payload;
    },
  },
});

export const { store } = eventSlice.actions;

export const selectEvents = (state) => state.event.event;

export default eventSlice.reducer;
