/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.8 Continental.gltf
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Continental(props) {
  const { nodes, materials } = useGLTF("/Continental.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane.geometry}
        material={materials["default"]}
        position={[1.212, 0.89, 0.143]}
      />
    </group>
  );
}

useGLTF.preload("/Continental.gltf");
