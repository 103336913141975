import { createSlice } from "@reduxjs/toolkit";

export const pinnedSlice = createSlice({
  name: "pins",
  initialState: {
    pins: [],
  },
  reducers: {
    storePins: (state, action) => {
      //console.log(state);
      state.pins = action.payload;
    },
    destroyPins: (state, action) => {
      //console.log(state);
      state.pins = null;
    },
  },
});

export const { storePins, destroyPins } = pinnedSlice.actions;

export const selectPins = (state) => state.pins.pins;

export default pinnedSlice.reducer;
