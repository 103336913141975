import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { getAllInteriorDoorEntries } from "../../../components/Forms/api";
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from "@mui/icons-material";

const InteriorDoorFormSummary = () => {
  const [entryData, setEntryData] = useState([]);

  useEffect(() => {
    getAllInteriorDoorEntries(setEntryData);
  }, []);

  const formatPrice = (price) => {
    return Number(price).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "customer",
        Cell: ({ value }) => <div className="text-left">{value}</div>,
      },
      {
        Header: "Account #",
        accessor: "account",
        Cell: ({ value }) => <div className="text-left">{value}</div>,
      },
      {
        Header: "Job #",
        accessor: "job",
        Cell: ({ value }) => <div className="text-left">{value}</div>,
      },
      {
        Header: "Submitted by",
        accessor: "submitUser",
        Cell: ({ value }) => <div className="text-left">{value}</div>,
      },
      {
        Header: "Total Estimated Price",
        accessor: "totalEstimatedPrice",
        Cell: ({ value }) => (
          <div className="text-left text-green-600">{formatPrice(value)}</div>
        ),
      },
      {
        Header: "PDF Link",
        accessor: "pdfLink",
        Cell: ({ row }) => (
          <a
            href={`https://node.bigclumber.com/api/forms/pdf/?id=${row.original._id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            View PDF
          </a>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: entryData,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;

  return (
    <div className="flex flex-col items-center mx-auto p-4 w-full dark:text-white h-full">
      <h2 className="text-lg font-bold mb-2">Interior Door Form Summary</h2>
      <div className="mb-2 flex w-full justify-end">
        <input
          type="text"
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="px-2 py-1 border border-gray-400 rounded-lg dark:text-black"
        />
      </div>
      <div className="h-full w-full">
        <table
          {...getTableProps()}
          className="w-full border-collapse border border-gray-400"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`px-4 py-2 bg-gray-200 dark:bg-gray-200/5 border border-gray-400 text-left w-64 ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? "desc"
                          : "asc"
                        : ""
                    }`}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="ml-1">&#x25BC;</span>
                      ) : (
                        <span className="ml-1">&#x25B2;</span>
                      )
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="h-20">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="px-4 py-2 border border-gray-400"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-center h-1/4">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          style={{
            padding: "5px",
            margin: "5px",
          }}
        >
          <ArrowCircleLeftOutlined style={{ fontSize: "30px" }} />
        </button>
        <p>
          {state.pageIndex + 1} of {pageOptions.length}
        </p>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          style={{
            padding: "5px",
            margin: "5px",
          }}
        >
          <ArrowCircleRightOutlined style={{ fontSize: "30px" }} />
        </button>
      </div>
    </div>
  );
};

export default InteriorDoorFormSummary;
