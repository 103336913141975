import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getOrderInfo,
  getRequestInfo,
  rejectRequest,
  sendUserNotification,
} from "../../../../utilities";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, Tooltip } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import NumbersIcon from "@mui/icons-material/Numbers";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import ErrorID from "../../../../components/Other/ErrorID";

const RejectRequest = () => {
  const { id } = useParams();
  const [notes, setNotes] = useState("");
  const navigate = useNavigate();
  const [altDate, setAltDate] = useState([]);
  const [dateSelected, setDate] = useState(new Date());
  const [data, setData] = useState("");
  const [requestData, setReqData] = useState("");

  useEffect(() => {
    getOrderInfo(id, setData);
    getRequestInfo(id, setReqData);
  }, []);

  const handleRemoveItem = (removeTarget) => {
    const date = removeTarget;
    setAltDate(altDate.filter((item) => item !== date));
  };

  return (
    <>
      {data.error && (
        <div>
          <ErrorID id={id} />
        </div>
      )}
      <div className="w-full h-full flex justify-center p-8">
        <div className="flex justify-center items-stretch m-4 w-full">
          <div className="mt-5 md:mt-0 md:col-span-2 w-full">
            <div className="shadow sm:rounded-md sm:overflow-hidden border border-gray-950 bg-white text-black dark:border-gray-500 dark:bg-white/5 dark:text-white">
              <div className="px-4 py-5 space-y-6 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <label className="block text-sm font-medium text-red-400">
                      Reject Request - ID {id}
                    </label>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 border rounded-md">
                      <Tooltip title="Customer">
                        <div>
                          <PersonOutlineIcon />
                          {data.Customer}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 border rounded-md">
                      <Tooltip title="Order Number">
                        <div>
                          <NumbersIcon />
                          {data.OrderNumber}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 border rounded-md">
                      <Tooltip title="Job Ref">
                        <div>
                          <WorkIcon />
                          {data["Job Ref"]}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="p-2 border rounded-md">
                      <Tooltip title="Deliver To Address">
                        <div>
                          <HomeIcon />
                          {data["Deliver To"]}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <label className="block text-sm font-medium text-black dark:text-white m-2">
                      Add Alternative Dates
                    </label>

                    <div className="flex">
                      <DatePicker
                        selected={dateSelected}
                        minDate={new Date()}
                        onChange={(date) => {
                          setAltDate((altDate) => [
                            ...altDate,
                            date.toLocaleDateString("en-US"),
                          ]);
                          setDate(date);
                        }}
                        className="border border-gray-950 bg-white text-black dark:border-gray-500 dark:bg-white/5 dark:text-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <ul className="max-w-md">
                      {altDate.map((item, index) => {
                        return (
                          <li className="border border-gray-950 bg-white text-black dark:border-gray-500 dark:bg-white/5 dark:text-white inline-flex m-2 p-2 max-w-sm">
                            <p>{item}</p>
                            <button
                              onClick={() => {
                                //console.log(`${item} -> Deleted`);
                                handleRemoveItem(item);
                              }}
                            >
                              <DeleteForeverIcon style={{ color: "#f44336" }} />
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black dark:text-white">
                    Notes
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="notes"
                      name="notes"
                      rows="3"
                      className="shadow-sm border border-gray-500 bg-white/5 dark:border-gray-500 dark:bg-white/5 dark:text-white p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="enter optional notes"
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                      value={notes}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="flex justify-center px-4 py-3 text-right sm:px-6">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    rejectRequest(id, notes, altDate);
                    sendUserNotification(
                      requestData?.requestEmail,
                      "System",
                      `Your Request For Order #${requestData.salesOrder} Has Been Rejected, please check email to accept alternative date.`,
                      "Delivery Request Rejected"
                    );
                    navigate("/windowdoor/admin/requestlist");
                  }}
                >
                  Reject
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RejectRequest;
