export const getVehicles = async (setter, location) => {
  return fetch(`/api/fleet/vehicle/getVehicles?location=${location}`)
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};
