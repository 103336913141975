import React, { useState, useEffect } from "react";

const SlabOptions = () => {
  const slabStyles = [
    "Birch",
    "Carrara",
    "Caiman",
    "Craftsman",
    "Rockport",
    "Santa Fe",
    "Smooth Colonist",
    "Textured Colonist",
    "6 Panel",
    "Wood Lite",
    "Primed Lite",
  ];
  const [coreTypes, setCoreTypes] = useState([
    "H.C.",
    "S.C.",
    "Pine",
    "Bifold",
  ]);
  const [selectedSlabStyle, setSelectedSlabStyle] = useState("");
  const [selectedCoreType, setSelectedCoreType] = useState("");
  const [options, setOptions] = useState([]);

  const handleSlabStyleChange = (e) => {
    setSelectedSlabStyle(e.target.value);
    fetchData(e.target.value, selectedCoreType);
  };

  const handleCoreTypeChange = (e) => {
    setSelectedCoreType(e.target.value);
    fetchData(selectedSlabStyle, e.target.value);
  };

  const fetchData = async (slabStyle, coreType) => {
    try {
      const response = await fetch(
        `/api/doors/kits/getSlabOptions?slabStyle=${slabStyle}&coreType=${coreType}`
      );
      const data = await response.json();

      // Create a map to quickly look up product codes by width
      const productCodeMap = {};

      if (data.message === "Internal Server Error") {
        // Set all textbox values to empty when there's an error
        setOptions(widths.map((width) => ({ width, productCode: "" })));
      } else {
        data.options.forEach((option) => {
          if (option.productCode) {
            productCodeMap[option.width] = option.productCode;
          } else {
            productCodeMap[option.width] = "NS";
          }
        });

        // Update options state, keeping existing widths and filling with product codes if available
        const updatedOptions = widths.map((width) => ({
          width,
          productCode: productCodeMap[width] || "",
        }));

        setOptions(updatedOptions);
      }
    } catch (error) {
      console.error("Error fetching slab options:", error);
    }
  };

  useEffect(() => {
    fetchData(selectedSlabStyle, selectedCoreType);
  }, [selectedSlabStyle, selectedCoreType]);

  const handleProductCodeChange = (width, value) => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) => {
        if (option.width === width) {
          return { ...option, productCode: value };
        }
        return option;
      });
      return updatedOptions;
    });
  };

  const handleSave = () => {
    const slabOptionsData = {
      slabStyle: selectedSlabStyle,
      coreType: selectedCoreType,
      options: options,
    };

    // Example URL - Replace with your actual server endpoint
    const serverEndpoint = "/api/doors/kits/saveSlabOptions";

    // Sending data to the server using fetch
    fetch(serverEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(slabOptionsData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle server response if needed
        console.log("Server response:", data);
      })
      .catch((error) => {
        // Handle error if request fails
        console.error("Error sending data to the server:", error);
      });
  };

  const widths = [
    "1'0\"",
    "1'2\"",
    "1'3\"",
    "1'4\"",
    "1'6\"",
    "1'8\"",
    "1'10\"",
    "2'0\"",
    "2'2\"",
    "2'4\"",
    "2'6\"",
    "2'8\"",
    "2'10\"",
    "3'0\"",
    "4'0\"",
    "5'0\"",
    "6'0\"",
  ];

  // Initialize options array with default productCode values
  const initialOptions = widths.map((width) => ({ width, productCode: "" }));
  useEffect(() => {
    setOptions(initialOptions);
  }, []);

  return (
    <div className="flex justify-center items-center flex-col">
      <div className="m-1">
        <label htmlFor="slabStyle">Slab Style:</label>
        <select
          id="slabStyle"
          onChange={handleSlabStyleChange}
          value={selectedSlabStyle}
        >
          <option value="">Select Slab Style</option>
          {slabStyles.map((style, index) => (
            <option key={index} value={style}>
              {style}
            </option>
          ))}
        </select>
      </div>
      <div className="m-1">
        <label htmlFor="coreType">Core Type:</label>
        <select
          id="coreType"
          onChange={handleCoreTypeChange}
          value={selectedCoreType}
        >
          <option value="">Select Core Type</option>
          {coreTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>Width</th>
            <th>Product Code</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option, index) => (
            <tr key={index}>
              <td>{option.width}</td>
              <td>
                <input
                  type="text"
                  placeholder="Enter product code"
                  value={option.productCode}
                  onChange={(e) =>
                    handleProductCodeChange(option.width, e.target.value)
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="m-2 p-2 bg-blue-200/50" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default SlabOptions;
