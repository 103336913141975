import React from "react";
import ExteriorOrdersFaq from "../../components/Help/ExteriorOrdersFaq";
import WebLeadsFaq from "../../components/Help/WebLeadsFaq";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const Help = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col m-4 rounded-2xl items-center p-4 border border-gray-500 bg-white dark:bg-white/5">
        <div className="mb-4">
          <p className="font-black text-2xl dark:text-white">Help</p>
        </div>
        <div className="mb-4 flex flex-wrap flex-col items-center">
          <p className="dark:text-white text-center">
            Welcome to the Frequently Asked Questions (FAQ) page! This page is
            designed to provide you with answers to some of the most common
            questions we receive from our users. If you're new to Node, this is
            a great place to start to get a better understanding of how things
            work. We've compiled a list of questions and answers that we believe
            will be helpful to you. To navigate through the questions, simply
            click the questions you wish to know the answer to. If you don't see
            the answer you're looking for, please feel free to contact HelpDesk
            and we'll be happy to assist you.
          </p>
        </div>
        <div>
          <a
            className="p-2 border border-black dark:border-white bg-cyan-600/20 dark:bg-white/5 hover:bg-slate-200 flex justify-between items-center rounded-md"
            href={`mailto:helpdesk@bigclumber.com?subject=Node%20Web%20Help%20Desk%20Ticket%20`}
          >
            <div className="flex">
              <div className="w-11/12 flex flex-col">
                <p className="font-black dark:text-white">Submit a Ticket</p>
              </div>
              <div className="w-1/12 flex justify-center items-center">
                <ArrowOutwardIcon />
              </div>
            </div>
          </a>
        </div>
      </div>

      <div className="flex">
        <div className="w-1/2">
          <ExteriorOrdersFaq />
        </div>
        <div className="w-1/2">
          <WebLeadsFaq />
        </div>
      </div>
    </div>
  );
};

export default Help;
