/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.8 DoorHandleRH.gltf
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function DoorHandleRH(props) {
  const { nodes, materials } = useGLTF("/DoorHandleRH.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Base_4.geometry}
        material={materials.Aluminium}
        position={[0, 3.272, -0.116]}
        scale={[0.238, 1, 1]}
      />
      <group position={[0, 3.272, -0.116]} scale={[0.238, 1, 1]}>
        <mesh
          geometry={nodes.Cube003.geometry}
          material={materials.Aluminium}
        />
        <mesh
          geometry={nodes.Cube003_1.geometry}
          material={materials["Matt Black"]}
        />
        <mesh geometry={nodes.Cube003_2.geometry} material={materials.Fill} />
      </group>
    </group>
  );
}

useGLTF.preload("/DoorHandleRH.gltf");
