import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectRoles } from "../../features/roleSlice";

const CheckUserAdmin = () => {
  const navigate = useNavigate();

  const role = useSelector(selectRoles);

  useEffect(() => {
    if (!role?.roles?.includes("Admin")) {
      navigate("/");
    }
  }, [role]);

  return <></>;
};

export default CheckUserAdmin;
