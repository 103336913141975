import { Dialog, Select } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  size_jamb_type_fields,
  applied_fields,
  backset_fields,
  bolt_type_fields,
  casing_type_fields,
  core_fields,
  hinge_finish_fields,
  stop_applied_fields,
  stop_fields,
  handing_fields,
} from "../../Forms/SelectValues/values";
import { getPricing } from "../../Forms/Misc/utilities";
import { extractNumbers, slab_styles, width_options } from "../utilities";

const DoorBuilderEdit = (props) => {
  const [selectedOptions, setSelectedOptions] = useState({ ...props.rowData });
  const [productIDs, setProductIDs] = useState(extractNumbers(selectedOptions));
  const [pricing, setPricing] = useState(0);
  const [isDoubleDoor, setIsDoubleDoor] = useState(props.rowData.isDouble);
  const [widthOptions, setWidthOptions] = useState([]);

  useEffect(() => {
    if (props.rowData) {
      setSelectedOptions({ ...props.rowData });

      if (isDoubleDoor) {
        getPricing(productIDs, 1, selectedOptions.size_jamb_type, setPricing);
      } else {
        getPricing(productIDs, 0, selectedOptions.size_jamb_type, setPricing);
      }
    }
  }, [props.rowData]);
  useEffect(() => {
    const extractedNumbers = extractNumbers(selectedOptions);
    setProductIDs((prevProductIDs) => [...prevProductIDs, ...extractedNumbers]);

    // Clean up function (runs on component unmount)

    return () => {
      setProductIDs([]);
    };
  }, [selectedOptions]);

  useEffect(() => {
    if (isDoubleDoor) {
      getPricing(productIDs, 1, selectedOptions.size_jamb_type, setPricing);
    } else {
      getPricing(productIDs, 0, selectedOptions.size_jamb_type, setPricing);
    }
  }, [
    productIDs,
    selectedOptions.quantity,
    selectedOptions.margin,
    selectedOptions.slab_style,
    isDoubleDoor,
  ]);

  const handleDoubleDoorChange = (e) => {
    if (e.target.checked) {
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        isDouble: 1,
        handing: "Double",
      }));
      setIsDoubleDoor(e.target.checked);
    } else {
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        isDouble: 0,
      }));
      setIsDoubleDoor(e.target.checked);
    }
  };

  useEffect(() => {
    if (props.rowData) {
      setSelectedOptions({ ...props.rowData });
    }
  }, [props.rowData]);

  const handleQuantityChange = (event) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      quantity: event.target.value,
    }));
  };

  const handleMarginChange = (event) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      margin: event.target.value,
    }));
  };

  useEffect(() => {
    getWidthOptions(selectedOptions.slab_style, selectedOptions.core);
    setSelectedOptions({ ...selectedOptions, width: null });
  }, [selectedOptions.slab_style, selectedOptions.core]);

  const handleSave = () => {
    props.onSaveRowData({
      ...props.rowData,
      ...selectedOptions,
      special_instructions: selectedOptions.specialInstructions, // Add this line
      estimated_price:
        Math.round(
          (pricing *
            selectedOptions.quantity *
            (100 / (100 - parseFloat(selectedOptions.margin))) +
            Number.EPSILON) *
            100
        ) / 100,
    });
  };

  const getWidthOptions = async (slab, core) => {
    try {
      const response = await fetch(
        `/api/doors/kits/getSlabOptions?slabStyle=${slab}&coreType=${core}`
      );
      const data = await response.json();

      if (data.message === "Internal Server Error") {
        await setWidthOptions([]);
        return;
      } else {
        await setWidthOptions(data.options);
        return;
      }
    } catch (error) {
      console.log(error);
      await setWidthOptions([]);
      return;
    }
  };

  return (
    props.rowData && (
      <Dialog
        open={props.isEditDialogOpen}
        onClose={() => {
          props.setIsEditDialogOpen(false);
          props.setRowData(null);
        }}
        maxWidth={"md"}
        fullWidth={true}
      >
        <div className="flex flex-col m-4 dark:text-black">
          <p className="flex text-black font-bold">Edit Door</p>
          <div className="flex flex-wrap">
            {selectedOptions.core !== "Bifold" && (
              <div className="w-1/5 m-1">
                <p className="">Jamb Size and Type</p>
                <select
                  className="w-full bg-slate-200 dark:text-black"
                  value={selectedOptions.size_jamb_type || ""}
                  onChange={(e) => {
                    //console.log(e.target.value);
                    setSelectedOptions({
                      ...selectedOptions,
                      size_jamb_type: e.target.value,
                    });
                  }}
                >
                  {size_jamb_type_fields.map((item, index) => {
                    return (
                      <option value={`${item.description}(${item.productId})`}>
                        {item.description}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {selectedOptions.size_jamb_type === "Door Slab Only(null)" &&
              selectedOptions.core !== "Bifold" && (
                <>
                  {/* Render only Core, Bolt Type, and Backset dropdowns */}
                  <div className="w-1/5 m-1">
                    <p className="dark:text-black ">Core</p>
                    <select
                      className="w-full bg-slate-200 dark:text-black"
                      value={selectedOptions.core || ""}
                      onChange={(e) => {
                        //console.log(e.target.value);
                        setSelectedOptions({
                          ...selectedOptions,
                          core: e.target.value,
                        });
                      }}
                    >
                      {core_fields.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {index === 0 ? item : `${index}) ${item}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </>
              )}
            {selectedOptions.size_jamb_type !== "Door Slab Only(null)" &&
              selectedOptions.core !== "Bifold" && (
                <>
                  <div className="w-1/5 m-1">
                    <p className="dark:text-black ">Bolt Type</p>
                    <select
                      className="w-full bg-slate-200 dark:text-black"
                      value={selectedOptions.bolt_type || ""}
                      onChange={(e) => {
                        setSelectedOptions({
                          ...selectedOptions,
                          bolt_type: e.target.value,
                        });
                      }}
                    >
                      {bolt_type_fields(selectedOptions.size_jamb_type).map(
                        (item, index) => {
                          return (
                            <option value={item}>
                              {index === 0 ? item : `${index}) ${item}`}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="w-1/5 m-1">
                    <p className="dark:text-black">Backset</p>
                    <select
                      className="w-full bg-slate-200 dark:text-black"
                      value={selectedOptions.backset || ""}
                      onChange={(e) => {
                        setSelectedOptions({
                          ...selectedOptions,
                          backset: e.target.value,
                        });
                      }}
                    >
                      {backset_fields(selectedOptions.size_jamb_type).map(
                        (item, index) => {
                          return (
                            <option value={item}>
                              {index === 0 ? item : `${index}) ${item}`}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div className="w-1/5 m-1">
                    <p className="">Casing Type</p>
                    <select
                      className="w-full bg-slate-200 dark:text-black"
                      value={selectedOptions.casing_type || ""}
                      onChange={(e) => {
                        setSelectedOptions({
                          ...selectedOptions,
                          casing_type: e.target.value,
                        });
                      }}
                    >
                      {casing_type_fields.map((item, index) => {
                        return (
                          <option
                            value={`${item.description}(${item.productId})`}
                          >
                            {item.description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {selectedOptions.casing_type !== "N/A" && (
                    <div className="w-1/5 m-1">
                      <p className="">Applied</p>
                      <select
                        className="w-full bg-slate-200 dark:text-black"
                        value={selectedOptions.applied || ""}
                        onChange={(e) => {
                          setSelectedOptions({
                            ...selectedOptions,
                            applied: e.target.value,
                          });
                        }}
                      >
                        {applied_fields.map((item, index) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </select>
                    </div>
                  )}

                  <div className="w-1/5 m-1">
                    <p className="">Hinge Finish</p>
                    <select
                      className="w-full bg-slate-200 dark:text-black"
                      value={selectedOptions.hinge_finish || ""}
                      onChange={(e) => {
                        setSelectedOptions({
                          ...selectedOptions,
                          hinge_finish: e.target.value,
                        });
                      }}
                    >
                      {hinge_finish_fields.map((item, index) => {
                        return (
                          <option
                            value={`${item.description}(${item.productId})`}
                          >
                            {item.description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="w-1/5 m-1">
                    <p className="">Stop</p>
                    <select
                      className="w-full bg-slate-200 dark:text-black"
                      value={selectedOptions.stop || ""}
                      onChange={(e) => {
                        setSelectedOptions({
                          ...selectedOptions,
                          stop: e.target.value,
                        });
                      }}
                    >
                      {stop_fields(selectedOptions.size_jamb_type).map(
                        (item, index) => {
                          return (
                            <option
                              value={`${item.description}(${item.productId})`}
                            >
                              {item.description}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div className="w-1/5 m-1">
                    <p className="">Stop Applied</p>
                    <select
                      className="w-full bg-slate-200 dark:text-black"
                      value={selectedOptions.stop_applied || ""}
                      onChange={(e) => {
                        setSelectedOptions({
                          ...selectedOptions,
                          stop_applied: e.target.value,
                        });
                      }}
                    >
                      {stop_applied_fields(selectedOptions.size_jamb_type).map(
                        (item, index) => {
                          return <option value={item}>{item}</option>;
                        }
                      )}
                    </select>
                  </div>
                </>
              )}

            {selectedOptions.size_jamb_type !== "Door Slab Only(null)" &&
              selectedOptions.core !== "Bifold" && (
                <div className="w-1/5 m-1">
                  <p className="">Handing</p>
                  <select
                    className="w-full bg-slate-200 dark:text-black"
                    value={selectedOptions.handing || ""}
                    onChange={(e) => {
                      setSelectedOptions({
                        ...selectedOptions,
                        handing: e.target.value,
                      });
                    }}
                  >
                    {handing_fields.map((item, index) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                </div>
              )}
            <div className="w-1/5 m-1">
              <p className="">Slab Style</p>
              <select
                className="w-full bg-slate-200 dark:text-black"
                value={selectedOptions.slab_style || ""}
                onChange={(e) => {
                  setSelectedOptions({
                    ...selectedOptions,
                    slab_style: e.target.value,
                  });
                }}
              >
                {slab_styles(selectedOptions.core).options.map(
                  (item, index) => {
                    return <option value={item}>{item}</option>;
                  }
                )}
              </select>
            </div>
            <div className="flex w-2/5 flex-col items-center m-2">
              <p className="text-black dark:text-black">Width</p>
              {selectedOptions.slab_style && props.rowData.core ? (
                <select
                  id="width"
                  name="width"
                  value={selectedOptions.width || ""}
                  className="mr-1 w-full dark:text-black"
                  onChange={(e) => {
                    setSelectedOptions({
                      ...selectedOptions,
                      width: e.target.value,
                    });
                  }}
                  required={true}
                >
                  <option value="">Select an option</option>
                  {widthOptions.map((option) => {
                    if (option.productCode) {
                      let displayWidth = option.width;
                      if (selectedOptions.isDouble) {
                        const [feet, inches] = option.width
                          .split("'")
                          .map((str) => parseInt(str));
                        const doubleTotalInches = (feet * 12 + inches) * 2;
                        const doubleFeet = Math.floor(doubleTotalInches / 12);
                        const doubleInches = doubleTotalInches % 12;
                        displayWidth = `${doubleFeet}'${doubleInches}"`;
                      }
                      return (
                        <option
                          value={`${option.width} - (${option.productCode})`}
                          key={option.width}
                        >
                          {option.width}{" "}
                          {selectedOptions.isDouble
                            ? `(Actual Size: ${displayWidth})`
                            : ""}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          disabled={true}
                          value={option.width}
                          key={option.width}
                        >
                          {option.width} - Non Stock (Call For Pricing)
                        </option>
                      );
                    }
                  })}
                </select>
              ) : (
                <p>Please Select Slab Style</p>
              )}
            </div>
            {selectedOptions.core !== "Bifold" && (
              <div className="flex m-1 p-2 flex-wrap justify-center">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="doubleDoor"
                    name="doubleDoor"
                    checked={isDoubleDoor}
                    onChange={handleDoubleDoorChange}
                  />
                  <p className="flex p-2">Double Door</p>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-center">
            <div className="m-2 flex flex-col w-1/2">
              <p>Special Instructions</p>
              <input
                type="text"
                value={selectedOptions.specialInstructions || ""}
                onChange={(event) =>
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions,
                    specialInstructions: event.target.value,
                  }))
                }
              />
            </div>
            <div className="m-2 flex flex-col w-1/2">
              <p>Quantity</p>
              <input
                type="number"
                value={selectedOptions.quantity || ""}
                onChange={handleQuantityChange}
                min={1}
              />
            </div>
            <div className="m-2 flex flex-col w-1/2">
              <p className="text-black">Margin (%)</p>
              <input
                type="number"
                name="margin"
                className="border border-black dark:text-black rounded w-16"
                value={selectedOptions.margin}
                onChange={handleMarginChange}
                inputMode="numeric"
                defaultValue="30"
                max={99}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
              />
            </div>
          </div>
          {selectedOptions.width && (
            <div className="flex text-xl justify-center items-center font-bold">
              Estimated Price:{" "}
              <p className="text-green-500">
                $
                {Math.round(
                  (pricing *
                    selectedOptions.quantity *
                    (100 / (100 - parseFloat(selectedOptions.margin))) +
                    Number.EPSILON) *
                    100
                ) / 100}
              </p>
            </div>
          )}

          <button
            disabled={selectedOptions.width === null && "true"}
            className={
              selectedOptions.width === null
                ? "bg-gray-300/80 m-2 p-2 cursor-not-allowed"
                : "bg-green-300/80 m-2 p-2"
            }
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </Dialog>
    )
  );
};

export default DoorBuilderEdit;
