import React, { useState } from "react";

const WindowServiceRequestForm = () => {
  // State to manage form inputs
  const [formData, setFormData] = useState({
    manufacturer: "",
    isHomeOccupied: "",
    homeownersName: "",
    lot: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    preferred: "",
    customerPhone: "",
    customerEmail: "",
    contractor: "",
    ageOfProduct: "",
    contractorOfficePhone: "",
    contractorCellPhone: "",
    contractorEmail: "",
    describeIssue: "",
    cigNum: "",
    productID: "",
    billTo: "",
    location: "",
    accountNumber: "",
    jobNumber: "",
  });

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can perform further actions like sending data to a server or processing the form data here
  };

  // Function to update form data
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <div className="dark:text-white flex items-center justify-center min-h-screen">
      <div className="bg-white/5 p-8 shadow-md rounded-lg max-w-md w-full">
        <h1 className="text-2xl font-semibold mb-6">
          Window Service Request Form
        </h1>
        <form className="m-4" onSubmit={handleSubmit}>
          <div>
            <label>Manufacturer: </label>
            <div>
              <label>
                <input
                  type="radio"
                  value="Andersen"
                  checked={formData.manufacturer === "Andersen"}
                  onChange={() => handleInputChange("manufacturer", "Andersen")}
                />
                Andersen
              </label>
              <label>
                <input
                  type="radio"
                  value="Jeld-Wen"
                  checked={formData.manufacturer === "Jeld-Wen"}
                  onChange={() => handleInputChange("manufacturer", "Jeld-Wen")}
                />
                Jeld-Wen
              </label>
              <label>
                <input
                  type="radio"
                  value="Marvin"
                  checked={formData.manufacturer === "Marvin"}
                  onChange={() => handleInputChange("manufacturer", "Marvin")}
                />
                Marvin
              </label>
              <label>
                <input
                  type="radio"
                  value="Silverline"
                  checked={formData.manufacturer === "Silverline"}
                  onChange={() =>
                    handleInputChange("manufacturer", "Silverline")
                  }
                />
                Silverline
              </label>
              <label>
                <input
                  type="radio"
                  value="Simonton"
                  checked={formData.manufacturer === "Simonton"}
                  onChange={() => handleInputChange("manufacturer", "Simonton")}
                />
                Simonton
              </label>
              <label>
                <input
                  type="radio"
                  value="ThermaTru"
                  checked={formData.manufacturer === "ThermaTru"}
                  onChange={() =>
                    handleInputChange("manufacturer", "ThermaTru")
                  }
                />
                ThermaTru
              </label>
              <label>
                <input
                  type="radio"
                  value="Other"
                  checked={formData.manufacturer === "Other"}
                  onChange={() => handleInputChange("manufacturer", "Other")}
                />
                Other:
                <input
                  className="dark:text-black"
                  type="text"
                  value={
                    formData.manufacturer === "Other"
                      ? formData.manufacturerOtherText
                      : ""
                  }
                  onChange={(e) =>
                    handleInputChange("manufacturerOtherText", e.target.value)
                  }
                />
              </label>
            </div>
          </div>

          <div>
            <label>Is Home Occupied?:</label>
            <div>
              <label>
                <input
                  type="radio"
                  value="yes"
                  checked={formData.isHomeOccupied === "yes"}
                  onChange={() => handleInputChange("isHomeOccupied", "yes")}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  value="no"
                  checked={formData.isHomeOccupied === "no"}
                  onChange={() => handleInputChange("isHomeOccupied", "no")}
                />
                No
              </label>
            </div>
          </div>

          <div>
            <label>Homeowners Name:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.homeownersName}
              onChange={(e) =>
                handleInputChange("homeownersName", e.target.value)
              }
            />
          </div>

          <div>
            <label>Lot:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.lot}
              onChange={(e) => handleInputChange("lot", e.target.value)}
            />
          </div>

          <div>
            <label>Address:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.address}
              onChange={(e) => handleInputChange("address", e.target.value)}
            />
          </div>

          <div>
            <label>City:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.city}
              onChange={(e) => handleInputChange("city", e.target.value)}
            />
          </div>

          <div>
            <label>State:</label>
            <select
              className="dark:text-black"
              value={formData.state}
              onChange={(e) => handleInputChange("state", e.target.value)}
            >
              <option value="">Select a state</option>
              <option value="illinois">Illinois</option>
              <option value="indiana">Indiana</option>
              <option value="michigan">Michigan</option>
              <option value="ohio">Ohio</option>
            </select>
          </div>

          <div>
            <label>Zip Code:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.zipcode}
              onChange={(e) => handleInputChange("zipcode", e.target.value)}
            />
          </div>

          <div>
            <label>Preferred:</label>
            <div>
              <label>
                <input
                  type="radio"
                  value="phone"
                  checked={formData.preferred === "phone"}
                  onChange={() => handleInputChange("preferred", "phone")}
                />
                Phone
              </label>
              <label>
                <input
                  type="radio"
                  value="email"
                  checked={formData.preferred === "email"}
                  onChange={() => handleInputChange("preferred", "email")}
                />
                Email
              </label>
            </div>
          </div>

          <div>
            <label>Customer Phone:</label>
            <input
              className="dark:text-black"
              type="tel"
              value={formData.customerPhone || ""}
              onChange={(e) =>
                handleInputChange("customerPhone", e.target.value)
              }
              placeholder="(123)456-7890"
              maxLength="13"
            />
            <small>Will be used to contact the customer.</small>
          </div>

          <div>
            <label>Customer Email:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.customerEmail}
              onChange={(e) =>
                handleInputChange("customerEmail", e.target.value)
              }
            />
          </div>

          <div>
            <label>Contractor:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.contractor}
              onChange={(e) => handleInputChange("contractor", e.target.value)}
            />
          </div>

          <div>
            <label>Age Of Product:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.ageOfProduct}
              onChange={(e) =>
                handleInputChange("ageOfProduct", e.target.value)
              }
            />
          </div>

          <div>
            <label>Contractor Office Phone:</label>
            <input
              className="dark:text-black"
              type="tel"
              value={formData.contractorOfficePhone || ""}
              onChange={(e) =>
                handleInputChange("contractorOfficePhone", e.target.value)
              }
              placeholder="(123)456-7890"
              maxLength="13"
            />
          </div>

          <div>
            <label>Contractor Cell Phone:</label>
            <input
              className="dark:text-black"
              type="tel"
              value={formData.contractorCellPhone || ""}
              onChange={(e) =>
                handleInputChange("contractorCellPhone", e.target.value)
              }
              placeholder="(123)456-7890"
              maxLength="13"
            />
          </div>

          <div>
            <label>Contractor Email:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.contractorEmail}
              onChange={(e) =>
                handleInputChange("contractorEmail", e.target.value)
              }
            />
          </div>

          <div>
            <label>Describe Issue:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.describeIssue}
              onChange={(e) =>
                handleInputChange("describeIssue", e.target.value)
              }
            />
          </div>

          <div>
            <label>CIG#</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.cigNum}
              onChange={(e) => handleInputChange("cigNum", e.target.value)}
            />
          </div>

          <div>
            <label>Product ID (Anderson Only)</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.productID}
              onChange={(e) => handleInputChange("productID", e.target.value)}
            />
          </div>

          <div>
            <label>Bill To:</label>
            <select
              className="dark:text-black"
              value={formData.billTo}
              onChange={(e) => handleInputChange("billTo", e.target.value)}
            >
              <option value=""></option>
              <option value="Contractor">Contractor</option>
              <option value="Salesperson">Salesperson</option>
              <option value="Homeowner">Homeowner</option>
              <option value="Manufacturer">Manufacturer</option>
              <option value="Window Shop">Window Shop</option>
              <option value="Store">Store</option>
            </select>
          </div>

          <div>
            <label>Location:</label>
            <select
              className="dark:text-black"
              value={formData.location}
              onChange={(e) => handleInputChange("location", e.target.value)}
            >
              <option value=""></option>
              <option value="01 - Roseland">01 - Roseland</option>
              <option value="02 - Elkhart">02 - Elkhart</option>
              <option value="03 - Three Oaks">03 - Three Oaks</option>
              <option value="04 - Coloma">04 - Coloma</option>
              <option value="05 - Mishawaka">05 - Mishawaka</option>
              <option value="06 - Adrian">06 - Adrian</option>
              <option value="07 - Eau Claire">07 - Eau Claire</option>
              <option value="08 - Granger">08 - Granger</option>
              <option value="09 - Cromwell">09 - Cromwell</option>
              <option value="10 - LaPorte">10 - LaPorte</option>
              <option value="11 - Door/Window">11 - Door/Window</option>
              <option value="12 - Shipshewana">12 - Shipshewana</option>
              <option value="13 - Schoolcraft">13 - Schoolcraft</option>
              <option value="14 - Fort Wayne">14 - Fort Wayne</option>
              <option value="15 - Delton">15 - Delton</option>
              <option value="16 - Kendallville">16 - Kendallville</option>
              <option value="17 - Edgerton">17 - Edgerton</option>
              <option value="18 - SBC">18 - SBC</option>
            </select>
          </div>

          <div>
            <label>Account Number:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.accountNumber}
              onChange={(e) =>
                handleInputChange("accountNumber", e.target.value)
              }
            />
          </div>

          <div>
            <label>Job Number:</label>
            <input
              className="dark:text-black"
              type="text"
              value={formData.jobNumber}
              onChange={(e) => handleInputChange("jobNumber", e.target.value)}
            />
          </div>

          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default WindowServiceRequestForm;
