import React, { useState, useEffect } from "react";
import { getChange } from "./Utilities/api";
import LoadingSpinner from "../../components/Other/LoadingSpinner";
import CircleIcon from "@mui/icons-material/Circle";

const ChangeLog = () => {
  const [changeEntries, setChangeEntries] = useState([]);

  useEffect(() => {
    getChange(setChangeEntries);
  }, []);

  // const groupedChanges = changeEntries.reduce((grouped, entry) => {
  //   const date = new Date(entry.date).toLocaleDateString();
  //   grouped[date] = [...(grouped[date] || []), entry];
  //   return grouped;
  // }, {});
  const groupedChanges = {};
  changeEntries.forEach((entry) => {
    const date = new Date(entry.date).toLocaleDateString();
    groupedChanges[date] = [...(groupedChanges[date] || []), entry];
  });

  return (
    <div className="flex justify-center max-h-screen overflow-auto">
      {changeEntries.length > 0 ? (
        <div className="flex flex-col items-center m-4 dark:text-white w-1/2">
          <p className="font-black text-2xl">Changelog</p>
          {Object.entries(groupedChanges).map(([date, entries], index) => (
            <div
              key={index}
              className="bg-white dark:bg-white/5 dark:border-white border border-black rounded-md p-4 m-2 w-full"
            >
              <div>
                <h2 className="text-lg font-bold">{date}</h2>
                {entries.map((entry, subIndex) => (
                  <div key={subIndex} className="flex items-center my-2">
                    <CircleIcon className="m-1" style={{ width: "7px" }} />
                    <div style={{ wordWrap: "break-word" }}>{entry.change}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>
          <LoadingSpinner />
        </p>
      )}
    </div>
  );
};

export default ChangeLog;
