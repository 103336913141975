import React, { useEffect, useState, useRef } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  getUserNotifications,
  markAllNotificationRead,
  markNotificationRead,
  normalizeDate,
} from "../../utilities";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Divider } from "@mui/material";
import { makeid } from "../Forms/api";

// const useAudio = (url) => {
//   const [audio] = useState(new Audio(url));
//   const [playing, setPlaying] = useState(false);

//   const toggle = () => setPlaying(!playing);

//   useEffect(() => {
//     playing ? audio.play() : audio.pause();
//   }, [playing]);

//   useEffect(() => {
//     audio.addEventListener("ended", () => setPlaying(false));
//     return () => {
//       audio.removeEventListener("ended", () => setPlaying(false));
//     };
//   }, []);

//   return [playing, toggle];
// };

const NotificationButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const popupRef = useRef(null);
  // const [notificationCount, setNotificationCount] = useState(0);
  // const [oldNotificationCount, setOldNotificationCount] = useState(0);
  const user = useSelector(selectUser);
  // const [playing, toggle] = useAudio(require("../../assets/NewMessage.mp3"));

  useEffect(() => {
    if (user) {
      getUserNotifications(setNotifications, user.email);
    }
  }, [user]);

  // useEffect(() => {
  //   //Check if there are any new notifications
  //   if (notifications.length > 0) {
  //     setOldNotificationCount(notifications.length);
  //     //Check if the notification count has changed
  //     if (notificationCount !== notifications.length) {
  //       //Play the sound
  //       toggle();

  //       //Update the notification count
  //       setNotificationCount(notifications.length);
  //       setOldNotificationCount(notificationCount);
  //     }
  //   }
  // }, [notifications]);

  useEffect(() => {
    if (user) {
      const interval = setInterval(() => {
        getUserNotifications(
          setNotifications,
          user.email,
          user.id,
          window.location.pathname
        );
      }, 2500);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  const handleClick = () => {
    setShowPopup(!showPopup);
  };

  const handleDeleteClick = (index) => {
    const updatedNotifications = [...notifications];
    updatedNotifications.slice(0).reverse();
    updatedNotifications.splice(index, 1);
    setNotifications(updatedNotifications);

    markNotificationRead(notifications[index]._id);
  };

  useEffect(() => {
    // Add event listener to listen for clicks on the document
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      event.target.parentElement?.classList[0] != "notificationwindow" &&
      event.target.parentElement?.classList[0] != "MuiSvgIcon-root"
    ) {
      setShowPopup(false);
    }
  };

  return (
    <div className="notificationwindow relative inline-block">
      <div className="notificationwindow  relative">
        <NotificationsIcon
          className="bell-icon text-black dark:text-white hover:text-blue-500"
          style={{ fontSize: "2rem" }}
          onClick={handleClick}
        />

        {showPopup && (
          <div className="notificationwindow overflow-auto max-h-72 absolute top-10 right-0 bg-white p-2 rounded-sm shadow-md w-96 cursor-auto z-50">
            {notifications.length > 0 ? (
              notifications.map((item, index) => {
                return (
                  <div
                    className="notificationwindow  mb-2 p-2 border border-gray-300 hover:bg-gray-100 rounded-md cursor-auto"
                    key={makeid(10)}
                  >
                    <div className="notificationwindow  flex justify-between">
                      <p className="text-xs bg-yellow-200/50 p-1 rounded-md">
                        {item.from}
                      </p>
                      <p className="text-xs bg-green-200/50 p-1 rounded-md">
                        {normalizeDate(item.date)}
                      </p>
                    </div>
                    <div
                      className="notificationwindow flex items-center justify-start"
                      key={index}
                    >
                      <div className="notificationwindow flex flex-col w-full">
                        <span className="underline">{item.subject}</span>
                        <span>{item.message}</span>
                      </div>

                      <div>
                        <button
                          className="notificationwindow icon-button hover:text-red-500"
                          onClick={() => handleDeleteClick(index)}
                        >
                          <DeleteForeverIcon
                            className="notificationwindow delete-icon"
                            style={{
                              color: "red",
                              fontSize: "26px",
                            }}
                            onClick={() => handleDeleteClick(index)}
                          />
                        </button>

                        <div>
                          {item.redirect && (
                            <Link to={item.redirect} target="_blank">
                              <LaunchIcon
                                className="launch-icon hover:text-gray-500"
                                style={{
                                  color: "black",
                                  fontSize: "26px",
                                }}
                                onClick={() => {
                                  setShowPopup(false);
                                  handleDeleteClick(index);
                                }}
                              />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No new notifications</div>
            )}
            {notifications.length > 0 && (
              <button
                onClick={() => {
                  markAllNotificationRead(user.email);
                  setShowPopup(false);
                  setNotifications([]);
                }}
              >
                <p className="notificationwindow flex justify-center p-2 bg-gradient-to-r from-gray-800 to-gray-900 border-gray-500 text-white m-4">
                  Mark All as Read
                </p>
              </button>
            )}
          </div>
        )}
        {notifications.length > 0 && (
          <div
            className="notificationwindow absolute top-0 left-4 flex items-center justify-center w-4 h-4 bg-red-700 rounded-full"
            onClick={handleClick}
          >
            <p className="notificationwindow text-white text-xs">
              {notifications.length}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationButton;
