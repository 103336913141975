import React, { useContext, useEffect, useState } from "react";
import { SocketContext } from "./Utilities/Context";
import CancelIcon from "@mui/icons-material/Cancel";
import CallIcon from "@mui/icons-material/Call";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const VideoPlayer = () => {
  const {
    name,
    callAccepted,
    myVideo,
    userVideo,
    callEnded,
    stream,
    call,
    startScreenShare,
    stopScreenShare,
  } = useContext(SocketContext);

  return (
    <div className="justify-center" templateColumns="repeat(2, 1fr)" mt="12">
      {/* my video */}
      {stream ? (
        <div>
          <div colSpan={1}>
            <p className="text-xl">{name || "Name"}</p>
            <video playsInline muted ref={myVideo} autoPlay width="0" />
          </div>
        </div>
      ) : (
        <div className="flex justify-center m-2">
          <button
            onClick={() => startScreenShare()}
            className="bg-green-400 p-2 m-2 rounded"
          >
            Start ScreenShare
          </button>
          <button
            onClick={() => stopScreenShare()}
            className="bg-red-400 p-2 m-2 rounded"
          >
            Stop ScreenShare
          </button>
        </div>
      )}
      {/* user's video */}
      {callAccepted && !callEnded && (
        <div>
          <div className="w-full">
            <p className="text-xl">{call.name || "Name"}</p>
            <video playsInline ref={userVideo} autoPlay width="1000" />
          </div>
        </div>
      )}
    </div>
  );
};

const Options = () => {
  const { me, callAccepted, name, setName, callEnded, leaveCall, callUser } =
    useContext(SocketContext);
  const [idToCall, setIdToCall] = useState("");

  return (
    <div className="m-4 justify-center flex items-center">
      <div className="p-2 border-2">
        <div templateColumns="repeat(2, 1fr)" mt="12">
          <div colSpan={1} p="6">
            <p className="text-xl"> Account Info </p>
            <div className="flex">
              <p>Connection ID</p>
            </div>
            <input type="text" value={me} disabled width="100%" />
            <ContentCopyIcon
              className="cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(me);
              }}
              text={me}
              mt="20"
            >
              <button
                leftIcon={<ContentCopyIcon />}
                colorScheme="teal"
                variant="solid"
              >
                Copy ID
              </button>
            </ContentCopyIcon>
          </div>
          <div colSpan={1} p="6">
            <p className="text-xl"> Make a Call </p>
            <p> User id to call </p>
            <input
              type="text"
              value={idToCall}
              onChange={(e) => setIdToCall(e.target.value)}
              width="100%"
            />
            {callAccepted && !callEnded ? (
              <button
                leftIcon={<CancelIcon />}
                onClick={leaveCall}
                mt="20"
                colorScheme="teal"
                variant="info"
              >
                Hang up
              </button>
            ) : (
              <button
                leftIcon={<CallIcon />}
                onClick={() => callUser(idToCall)}
                mt="20"
                colorScheme="teal"
                variant="solid"
              >
                Call
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Notifications = () => {
  const { answerCall, call, callAccepted } = useContext(SocketContext);

  return (
    <>
      {call.isReceivingCall && !callAccepted && (
        <div display="flex" justifyContent="space-around" mb="20">
          <p className="text-xl"> {call.name} is calling </p>
          <button
            variant="outline"
            onClick={answerCall}
            border="1px"
            borderStyle="solid"
            borderColor="black"
          >
            Answer Call
          </button>
        </div>
      )}
    </>
  );
};

const ScreenShare = () => {
  return (
    <div className="flex justify-center flex-col items-center">
      <VideoPlayer />
      <Options />
      <Notifications />
    </div>
  );
};

export default ScreenShare;
