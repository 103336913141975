export const addLocation = async (location, user) => {
  var data = JSON.stringify({
    location: location,
    addedBy: user,
    dateAdded: Date(),
    deleted: 0,
  });

  //console.log(data);

  const rawResponse = await fetch("/api/fleet/forklift/addLocation", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const addForklift = async (location, unit, moffett, user) => {
  var data = JSON.stringify({
    unit: unit,
    location: location,
    moffett: moffett,
    addedBy: user,
    dateAdded: Date(),
    deleted: 0,
  });

  //console.log(data);

  const rawResponse = await fetch("/api/fleet/forklift/addForklift", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const updateLocationManager = async (
  location,
  user,
  managerName,
  managerEmail,
  managerPhone,
  generalEmail,
  generalName,
  generalPhone,
  emailAlert,
  textAlert
) => {
  var data = JSON.stringify({
    location: location,
    updatedBy: user,
    datedUpdated: Date(),
    managerName: managerName,
    managerEmail: managerEmail,
    managerPhone: managerPhone,
    generalEmail: generalEmail,
    generalName: generalName,
    generalPhone: generalPhone,
    emailAlert: emailAlert,
    textAlert: textAlert,
  });

  const rawResponse = await fetch("/api/fleet/forklift/updateLocation", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const getLocations = async (setter) => {
  return fetch(`/api/fleet/forklift/getLocations`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getForklifts = async (setter, location) => {
  return fetch(`/api/fleet/forklift/getForklifts?location=${location}`)
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getForkliftManager = async (setter, location) => {
  return fetch(
    `/api/fleet/forklift/getLocationForkliftManager?location=${location}`
  )
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};
