import { Alert, AlertTitle, Divider, Switch } from "@mui/material";
import React from "react";

const WDNoDataDisplay = (props) => {
  return (
    <div className="flex justify-center m-2">
      <Alert severity="warning">
        <div>
          <p className="text-black">No Orders Found</p>
          {props.branchOrders && (
            <>
              If you think this is a mistake please contact{" "}
              <strong>Help Desk</strong>.
            </>
          )}
          {!props.branchOrders && (
            <>
              If you think this is a mistake please contact{" "}
              <strong>Help Desk</strong> or check <strong>Branch Orders</strong>
              .
            </>
          )}
        </div>
        <Divider />
        <div>
          <Switch
            checked={props.branchOrders}
            color="warning"
            onChange={() => {
              props.setBranchOrders(!props.branchOrders);
              props.setLoading(true);
            }}
          />
          <p className="text-xs">My Branch Orders</p>
        </div>
      </Alert>
    </div>
  );
};

export default WDNoDataDisplay;
