/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.8 Casing714.gltf
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Casing714(props) {
  const { nodes, materials } = useGLTF("/Casing714.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane001.geometry}
        material={materials["Material.001"]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.1, 1, 0.1]}
      />
    </group>
  );
}

useGLTF.preload("/Casing714.gltf");
