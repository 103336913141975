export const pages = [
  {
    name: "Exterior Orders",
    description: "Create a new delivery request for exterior products.",
    path: "/windowdoor/exteriororders",
    roles: ["*"],
  },
  {
    name: "My Requests - Exterior Orders ",
    description: "View your delivery requests for exterior products.",
    path: "/windowdoor/requests",
    roles: ["*"],
  },
  {
    name: "Request Manager - Exterior Orders",
    description: "View and manage all delivery requests for exterior products.",
    path: "/windowdoor/admin/requestlist",
    roles: ["Window Door Manager", "Admin"],
  },
  {
    name: "Request Summary - Exterior Orders",
    description: "View all delivery requests for exterior products.",
    path: "/windowdoor/summary",
    roles: ["Window Door Manager", "Admin"],
  },
  {
    name: "Web Leads",
    description: "View and manage web leads.",
    path: "/webleads/home",
    roles: ["Web Lead Manager", "Admin"],
  },
  {
    name: "My Web Leads",
    description: "View and manage your web leads.",
    path: "/webleads/manager",
    roles: ["Manager", "Admin"],
  },
  {
    name: "My Web Leads",
    description: "View and manage your web leads.",
    path: "/webleads/sales",
    roles: ["Inside Sales", "Outside Sales"],
  },
  {
    name: "Web Lead Summary",
    description: "View all web leads.",
    path: "/webleads/summary",
    roles: ["Web Lead Manager", "Admin", "Executive Team"],
  },
  {
    name: "Help",
    description: "View help documentation.",
    path: "/help",
    roles: ["*"],
  },
  {
    name: "New RFQ",
    description: "Create a new RFQ.",
    path: "/forms/new/rfq",
    roles: ["Admin"],
  },
  {
    name: "My Forms",
    description: "View all the forms you have submitted.",
    path: "/forms/viewall",
    roles: ["Admin"],
  },
  {
    name: "User Management",
    description: "Manage users and roles.",
    path: "/admin/user/userManager",
    roles: ["Admin", "IT"],
  },
  {
    name: "Submit A Ticket",
    description: "Need some help from HelpDesk? Create a ticket here.",
    path: "ticket",
    roles: ["*"],
  },
];
