import React, { useState, useEffect } from "react";
import { roles } from "../../utils/NodeRoles";
import { getUser, updateUserRole } from "../../views/Admin/Utilities/api";
import { Dialog, DialogTitle } from "@mui/material";
import { stringToColor } from "../../utilities";

const ManageUser = (props) => {
  const [user, setUser] = useState(null);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    getUser(setUser, props.selectedUser.id);
  }, []);

  useEffect(() => {
    getUser(setUser, props.selectedUser.id);
  }, [flag]);

  const handleRoleAdd = (role) => {
    updateUserRole(props.selectedUser.id, 1, role);
    setFlag(!flag);
  };

  const handleRoleRemove = (role) => {
    updateUserRole(props.selectedUser.id, 2, role);
    setFlag(!flag);
  };

  return (
    <>
      {user && (
        <Dialog
          open={props.userModal}
          onClose={() => {
            props.setUserModal(false);
            props.setSelectedUser(null);
          }}
        >
          <div className="flex flex-col justify-center bg-gradient-to-r from-gray-300 to-gray-400 text-black dark:from-gray-800 dark:to-gray-900 dark:text-white p-4 rounded-md">
            <p>Manage {user?.name}</p>
            <div className="flex flex-col flex-wrap items-center m-2">
              <div className="m-2">Add Roles</div>

              <div className="flex flex-wrap justify-center">
                {roles
                  ?.filter((el) => !user.roles?.includes(el))
                  .map((item) => {
                    return (
                      <button
                        onClick={() => handleRoleAdd(item)}
                        style={{ borderColor: stringToColor(item) }}
                        key={item}
                        className="m-1 p-1 border rounded-md"
                      >
                        {item}
                      </button>
                    );
                  })}
              </div>
            </div>

            <div className="flex flex-col flex-wrap items-center m-2">
              <div className="m-2">Remove Roles</div>

              <div className="flex flex-wrap justify-center">
                {user.roles?.map((item) => {
                  return (
                    <button
                      onClick={() => handleRoleRemove(item)}
                      style={{ borderColor: stringToColor(item) }}
                      key={item}
                      className="m-1 p-1 border rounded-md"
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ManageUser;
