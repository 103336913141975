export const branches = [
  "Granger",
  "Mishawaka",
  "Elkhart",
  "Coloma",
  "Fort Wayne - Store",
  "Three Oaks",
  "Edgerton",
  "Kendallville",
  "Cromwell",
  "Delton",
  "LaPorte",
  "Roseland",
  "Adrian",
  "Shipshewana",
  "Eau Claire",
  "Schoolcraft",
];

export const getCheckedAddress = (setter, userSelected) => {
  return fetch(`/api/cloud/getAddressValidate?user=${userSelected}`)
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAdcUsers = (setter) => {
  return fetch(`/api/profile/portal/getUsers?key=0a9kdk0dsa3912j3812jada9821`)
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const sendShareCode = (code) => {
  return fetch(`/api/profile/send/sharecode?code=${code}&key=33213`)
    .then((response) => response.json())
    .then((responseJson) => {
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const countInvalidAll = (setter, userSelected) => {
  return fetch(`/api/cloud/countInvalidAll?user=${userSelected}`)
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const countValidAll = (setter, userSelected) => {
  return fetch(`/api/cloud/countValidAll?user=${userSelected}`)
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllUsers = (setter) => {
  return fetch(`/api/user/getAllUsers`)
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllOnlineUsers = (setter) => {
  return fetch(`/api/user/getAllUsers/online`)
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getUser = (setter, id) => {
  return fetch(`/api/user/getUser?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson[0]);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getUserRoles = (setter, id) => {
  return fetch(`/api/user/getUser/roles?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getUserFavorites = (setter, id) => {
  return fetch(`/api/user/getUser/favorites?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getUserPins = (setter, id) => {
  return fetch(`/api/user/getUser/pins?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //if nothing set to empty array
      setter([]);
      //console.error(error);
    });
};

export const updateUserRole = (id, type, role) => {
  fetch(`/api/user/updateUserRole?id=${id}&type=${type}&role=${role}`).catch(
    (error) => {
      //console.error(error);
    }
  );

  return;
};

export const checkAdminRole = (user) => {
  return user.roles?.includes("Admin");
};

export const getUnassignedLeads = (set) => {
  return fetch(`/api/leads/getunassigned`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllLeadsBranch = (set, branch) => {
  return fetch(`/api/leads/getall?branch=${branch}`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllLeads = (set) => {
  return fetch(`/api/leads/getall`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getLeadCounts = (set) => {
  return fetch(`/api/leads/getall/leadSummaryCount`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getLeadNoContact = (set) => {
  return fetch(`/api/leads/nocontact`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getLeadNoContactBranch = (set, branch) => {
  return fetch(`/api/leads/nocontact?branch=$branch}`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson.length);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAverageLeadCompletionTime = (set) => {
  return fetch(`/api/leads/averagecompletion`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAverageLeadCompletionTimeBranch = (set, branch) => {
  return fetch(`/api/leads/averagecompletion?branch=${branch}`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getLeadConvertedSale = (set) => {
  return fetch(`/api/leads/data/tosale`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getLeadConvertedSaleBranch = (set, branch) => {
  return fetch(`/api/leads/data/tosale?branch=${branch}`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getCompletedLeadsBranch = (set, branch) => {
  return fetch(`/api/leads/getall?completed=1&branch=${branch}`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getCompletedUsersLeads = (set, userID) => {
  return fetch(`/api/leads/data/per/sales?userID=${userID}&type=2`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getDeletedUsersLeads = (set, userID) => {
  return fetch(`/api/leads/data/per/sales?userID=${userID}&type=3`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getOpenUsersLeads = (set, userID) => {
  return fetch(`/api/leads/data/per/sales?userID=${userID}&type=1`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

// /api/leads/data

export const getCompletedLeads = (set) => {
  return fetch(`/api/leads/getall?completed=1`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getDeletedLeadsBranch = (set, branch) => {
  return fetch(`/api/leads/getall?deleted=1&branch=${branch}`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getDeletedLeads = (set) => {
  return fetch(`/api/leads/getall?deleted=1`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getManagerLeads = (set, id) => {
  return fetch(`/api/leads/getmanager?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getSalesLeads = (set, id) => {
  return fetch(`/api/leads/getsales?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getFeedback = (set) => {
  return fetch(`/api/feedback/get`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson);
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getChange = (set) => {
  return fetch(`/api/changelog/get`)
    .then((response) => response.json())
    .then((responseJson) => {
      set(responseJson.splice(1).reverse());
      return;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getManagers = (setter) => {
  return fetch("/api/getUserID")
    .then((response) => response.json())
    .then(async (responseJson) => {
      await responseJson.recordset.push({
        UserID: 395,
        SalesRepCode: null,
        Name: "BLAKE UPHAM",
        EmailAddress: "blake.upham@bigclumber.com",
        JobTitle: "IT",
        Mobile: "574-218-5051",
        Branch: "Granger",
      });

      await responseJson.recordset.push({
        UserID: 1406,
        SalesRepCode: null,
        Name: "JOHN ROY",
        EmailAddress: "john.roy@bigclumber.com",
        JobTitle: "General Manager",
        Mobile: "260-215-7670",
        Branch: "Fort Wayne - Store",
      });
      var array = [];
      var arrayLength = responseJson.recordset.length;
      for (var i = 0; i < arrayLength; i++) {
        if (
          responseJson.recordset[i].JobTitle === "General Manager" ||
          responseJson.recordset[i].Name === "STEVE WALDRON" ||
          responseJson.recordset[i].Name === "RYAN BECK" ||
          responseJson.recordset[i].Name === "SCOTT BUNKLEMAN" ||
          responseJson.recordset[i].Name === "BLAKE UPHAM" ||
          responseJson.recordset[i].Name === "NATHAN BILANCIO" ||
          responseJson.recordset[i].Name === "ALEC EVANS" ||
          responseJson.recordset[i].Name === "SCOTT FOLEY" ||
          responseJson.recordset[i].Name === "BEN CHAMBERS" ||
          responseJson.recordset[i].Name === "MIKE SENESAC JR"
        ) {
          if (responseJson.recordset[i].Name === "SCOTT FOLEY") {
            responseJson.recordset[i].Branch = "Western";
          }
          if (responseJson.recordset[i].Name === "BEN CHAMBERS") {
            responseJson.recordset[i].Branch = "Eastern";
          }
          if (responseJson.recordset[i].Name !== "ANDY TOLCH") {
            array.push(responseJson.recordset[i]);
          }
        }
      }
      setter(array);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getWindowDoorUsers = (setter) => {
  return fetch("/api/getUserID")
    .then((response) => response.json())
    .then((responseJson) => {
      var array = [];
      var arrayLength = responseJson.recordset.length;
      for (var i = 0; i < arrayLength; i++) {
        if (
          responseJson.recordset[i].Name === "AMANDA ABEYTA" ||
          responseJson.recordset[i].Name === "CHRISTY CIULA" ||
          responseJson.recordset[i].Name === "GAIE LYNNE LEER" ||
          responseJson.recordset[i].Name === "KORY SHEDD" ||
          responseJson.recordset[i].Name === "KURT GIBSON" ||
          responseJson.recordset[i].Name === "JOE LEICHTMAN"
        ) {
          array.push(responseJson.recordset[i]);
        }
      }
      setter(array);
    })
    .catch((error) => {
      //console.error(error);
    });
};

// export const getUserBranch = (user) => {
//   return fetch("/api/getUserID")
//     .then((response) => response.json())
//     .then((responseJson) => {
//       var array = [];
//       var arrayLength = responseJson.recordset.length;
//       for (var i = 0; i < arrayLength; i++) {
//         if (responseJson.recordset[i].Name === user.name.toUpperCase()) {
//           return responseJson.recordset[i].Branch;
//         }
//       }
//       return "No Branch";
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// };

export const getSalesTeam = (setter) => {
  return fetch("/api/getUserID")
    .then((response) => response.json())
    .then((responseJson) => {
      var array = [];
      var arrayLength = responseJson.recordset.length;
      for (var i = 0; i < arrayLength; i++) {
        if (
          responseJson.recordset[i].JobTitle === "Inside Sales" ||
          responseJson.recordset[i].JobTitle === "Outside Sales" ||
          responseJson.recordset[i].Name === "BLAKE UPHAM" ||
          responseJson.recordset[i].Name === "ALEC EVANS"
        ) {
          if (responseJson.recordset[i].Name !== "JACK EARLEY") {
            array.push(responseJson.recordset[i]);
          }
        }
      }
      setter(array);
    })
    .catch((error) => {
      //console.error(error);
    });
};

const checkJobTitlesWebLeads = (jobTitle, name) => {
  if (
    jobTitle === "Inside Sales" ||
    jobTitle === "Outside Sales" ||
    jobTitle === "Kitchen Department Coordinator" ||
    jobTitle === "Cabinet Sales" ||
    jobTitle === "Installed Sales" ||
    jobTitle === "Installed Service Tech" ||
    jobTitle === "Estimating" ||
    jobTitle === "General Manager" ||
    name === "BLAKE UPHAM"
  ) {
    return true;
  }
  return false;
};

export const getSalesTeamForLocation = (setter, branch) => {
  const easternBranches = [
    "Adrian",
    "Edgerton",
    "Fort Wayne - Store",
    "Kendallville",
    "Cromwell",
    "Delton",
    "Schoolcraft",
    "Shipshewana",
  ];
  const westernBranches = [
    "Roseland",
    "Elkhart",
    "Three Oaks",
    "Coloma",
    "Mishawaka",
    "Eau Claire",
    "Granger",
    "LaPorte",
  ];

  return fetch("/api/getUserID")
    .then((response) => response.json())
    .then((responseJson) => {
      var array = [];
      var arrayLength = responseJson.recordset.length;

      for (var i = 0; i < arrayLength; i++) {
        switch (branch) {
          case "Eastern":
            if (
              checkJobTitlesWebLeads(
                responseJson.recordset[i].JobTitle,
                responseJson.recordset[i].Name
              ) &&
              easternBranches.includes(responseJson.recordset[i].Branch)
            ) {
              array.push(responseJson.recordset[i]);
            }
            break;
          case "Western":
            if (
              checkJobTitlesWebLeads(
                responseJson.recordset[i].JobTitle,
                responseJson.recordset[i].Name
              ) &&
              westernBranches.includes(responseJson.recordset[i].Branch)
            ) {
              array.push(responseJson.recordset[i]);
            }
            break;
          default:
            if (
              (checkJobTitlesWebLeads(
                responseJson.recordset[i].JobTitle,
                responseJson.recordset[i].Name
              ) &&
                responseJson.recordset[i].Branch === branch) ||
              responseJson.recordset[i].Name === "SCOTT BUNKELMAN"
            ) {
              array.push(responseJson.recordset[i]);
            }
            break;
        }
      }
      setter(array);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getNodeProfileSalesTeamForLocation = (setter, branch) => {
  const easternBranches = [
    "Adrian",
    "Edgerton",
    "Fort Wayne - Store",
    "Kendallville",
    "Cromwell",
    "Delton",
    "Schoolcraft",
    "Shipshewana",
  ];
  const westernBranches = [
    "Roseland",
    "Elkhart",
    "Three Oaks",
    "Coloma",
    "Mishawaka",
    "Eau Claire",
    "Granger",
    "LaPorte",
  ];

  fetch("/api/getUserID")
    .then((response) => response.json())
    .then((responseJson) => {
      var array = [];
      var arrayLength = responseJson.recordset.length;
      for (var i = 0; i < arrayLength; i++) {
        switch (branch) {
          case "Eastern":
            if (
              checkJobTitlesWebLeads(
                responseJson.recordset[i].JobTitle,
                responseJson.recordset[i].Name
              ) &&
              easternBranches.includes(responseJson.recordset[i].Branch)
            ) {
              array.push(responseJson.recordset[i].UserID);
            }
            break;
          case "Western":
            if (
              checkJobTitlesWebLeads(
                responseJson.recordset[i].JobTitle,
                responseJson.recordset[i].Name
              ) &&
              westernBranches.includes(responseJson.recordset[i].Branch)
            ) {
              array.push(responseJson.recordset[i].UserID);
            }
            break;
          default:
            if (
              (checkJobTitlesWebLeads(
                responseJson.recordset[i].JobTitle,
                responseJson.recordset[i].Name
              ) &&
                responseJson.recordset[i].Branch === branch) ||
              responseJson.recordset[i].Name === "SCOTT BUNKELMAN"
            ) {
              array.push(responseJson.recordset[i].UserID);
            }
            break;
        }
      }

      const keyString = array.join(","); // Convert array to comma-separated string

      return fetch(`/api/profile/node/msauth2node?users=${keyString}`)
        .then((response) => response.json())
        .then((responseJson) => {
          let salesTeam = [{ userID: "ALL", name: "ALL", id: "None" }];

          responseJson.map((item) => {
            salesTeam.push(item);
          });
          setter(salesTeam);
        })
        .catch((error) => {
          console.error(error);
        });
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getUserBranch = (user, setter) => {
  return fetch("/api/getUserID")
    .then((response) => response.json())

    .then((responseJson) => {
      var array = [];

      var arrayLength = responseJson.recordset.length;

      for (var i = 0; i < arrayLength; i++) {
        if (responseJson.recordset[i].Name === user.name.toUpperCase()) {
          setter(responseJson.recordset[i].Branch);
          return;
        }
      }

      return "No Branch";
    })

    .catch((error) => {
      //console.error(error);
    });
};

export const assignWebLeadManager = async (data) => {
  //Delete ID so Mongo is happy. (Prevents trying to overwrite the existing _id which will crash Mongo)

  var data = JSON.stringify(data);

  const rawResponse = await fetch("/api/leads/assignManager", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const assignWebLeadSalesRep = async (data) => {
  //Delete ID so Mongo is happy. (Prevents trying to overwrite the existing _id which will crash Mongo)

  var data = JSON.stringify(data);

  const rawResponse = await fetch("/api/leads/assignSalesRep", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const sendWebLeadNotification = async (id, from, to) => {
  //Delete ID so Mongo is happy. (Prevents trying to overwrite the existing _id which will crash Mongo)

  var data = JSON.stringify({
    id: id,
    from: from,
    to: to,
  });

  const rawResponse = await fetch("/api/leads/sendNotification", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const addFeedback = async (data) => {
  var data = JSON.stringify(data);

  const rawResponse = await fetch("/api/feedback/add", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const deleteFeedback = async (data) => {
  var data = JSON.stringify(data);

  const rawResponse = await fetch("/api/feedback/delete", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const addChange = async (data) => {
  var data = JSON.stringify(data);

  const rawResponse = await fetch("/api/changelog/add", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};
