import React, { useState, useEffect } from "react";
import {
  getAllLeads,
  getCompletedLeads,
  getDeletedLeads,
  getLeadConvertedSale,
  getLeadCounts,
  getLeadNoContact,
} from "../Admin/Utilities/api";

const SummaryNew = () => {
  const [leads, setLeads] = useState(null);
  const [leadCounts, setLeadCounts] = useState({});
  const [completedLeads, setCompletedLeads] = useState(null);
  const [deletedLeads, setDeletedLeads] = useState(null);
  const [noContact, setNoContact] = useState(null);
  const [toSale, setToSale] = useState(null);

  useEffect(() => {
    getLeadConvertedSale(setToSale);
    getLeadNoContact(setNoContact);
    getLeadCounts(setLeadCounts);
    getAllLeads(setLeads);
    getCompletedLeads(setCompletedLeads);
    getDeletedLeads(setDeletedLeads);
  }, []);

  return <div className="flex"></div>;
};

export default SummaryNew;
