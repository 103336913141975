import { Switch } from "@mui/material";
import React from "react";

const WDCustomTitle = (props) => {
  return (
    <div className="flex items-center min-w-full ">
      <div>{props.name}</div>
      <div className="flex flex-col items-center absolute top-2 right-72">
        <Switch
          checked={props.branchOrders}
          onChange={() => {
            props.setLoading(true);
            props.setBranchOrders(!props.branchOrders);
          }}
        />
        <p className="text-xs">My Branch Orders</p>
      </div>
    </div>
  );
};

export default WDCustomTitle;
