import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";

import { useMsal } from "@azure/msal-react";

const Logout = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    const logoutUser = async () => {
      // Dispatch your logout action to clear user state
      dispatch(logout());

      try {
        // Call the MSAL logout method
        await instance.logoutPopup(); // or instance.logoutRedirect();
      } catch (error) {
        console.error("Logout error:", error);
      }
    };

    logoutUser();
  }, [dispatch, instance]);

  return <></>;
};

export default Logout;
