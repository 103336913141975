import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import MenuIcon from "@mui/icons-material/Menu";

const WebLeadsFaq = () => {
  const [open, setOpen] = React.useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
  });

  const handleClick = (x) => {
    setOpen({ ...open, [x]: !open[x] });
  };

  return (
    <div className="m-4">
      <p className="p-4 w-full dark:text-red-200 text-red-500 text-3xl text-center font-black">
        Web Leads FAQ
      </p>

      <List>
        <ListItemButton
          onClick={() => {
            handleClick("one");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              How do I know I have been assigned a web lead?
            </p>
          </ListItemText>
          {open.one ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.one} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  You will receive an email notifying you of the new web lead
                  that you have been assigned.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md h-4/5 w-4/5"
                      src={require("../../assets/Help/Help13.png")}
                    />
                  </div>
                </li>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("two");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              Where can I view my web leads?
            </p>
          </ListItemText>
          {open.two ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.two} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  Click the <MenuIcon /> icon on the top left of the page.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md "
                      src={require("../../assets/Help/Help1.png")}
                    />
                  </div>
                </li>

                <li className="m-2">
                  Click the Web Leads drop down.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md "
                      src={require("../../assets/Help/Help11.png")}
                    />
                  </div>
                </li>

                <li className="m-2">
                  Click the My Web Leads.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md "
                      src={require("../../assets/Help/Help12.png")}
                    />
                  </div>
                </li>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("three");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              How do I update a Web Lead?
            </p>
          </ListItemText>
          {open.three ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.three} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  To update the web lead click the Update button here.
                  <div className="flex justify-center m-4 ">
                    <img
                      className="justify-center rounded-md h-4/5 w-4/5"
                      src={require("../../assets/Help/Help14.png")}
                    />
                  </div>
                </li>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("four");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              How do I complete a Web Lead?
            </p>
          </ListItemText>
          {open.four ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.four} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  Fill in the appropriate fields and select complete.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md h-4/5 w-4/5"
                      src={require("../../assets/Help/Help15.png")}
                    />
                  </div>
                </li>

                <li className="m-2">
                  Click yes to complete the web lead.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md h-4/5 w-4/5"
                      src={require("../../assets/Help/Help16.png")}
                    />
                  </div>
                </li>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
    </div>
  );
};

export default WebLeadsFaq;
