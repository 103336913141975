import React, { Suspense, useEffect, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";

import { TexturedColonist } from "../Models/TexturedColonist";
import { DoorHandleRH } from "../Models/DoorHandleRH";
import { DoorHandleLH } from "../Models/DoorHandleLH";
import { Cambridge } from "../Models/Cambridge";
import { Rockport } from "../Models/Rockport";
import { Craftsman } from "../Models/Craftsman";
import { Continental } from "../Models/Continental";
import { TexturedColonist2 } from "../Models/TexturedColonist2";
import { SantaFe } from "../Models/SantaFe";
import { Birch } from "../Models/Birch";
import { NoSlabSelected } from "../Models/NoSlabSelected";
import { Casing779 } from "../Models/Casing779";
import { Casing787 } from "../Models/Casing787";
import { Casing7997 } from "../Models/Casing7997";
import { Casing24062 } from "../Models/Casing24062";
import { Casing7992 } from "../Models/Casing7992";
import { Casing714 } from "../Models/Casing714";
import { Casing12184 } from "../Models/Casing12184";
import { Casing12185 } from "../Models/Casing12185";
import { Casing13834 } from "../Models/Casing13834";
import { Casing41988 } from "../Models/Casing41988";
import { Casing46307 } from "../Models/Casing46307";
import { Casing45932 } from "../Models/Casing45932";

const DoorPreview3D = (props) => {
  const getSlabStyleImage = (slab_style) => {
    switch (slab_style) {
      case "Textured Colonist":
        return <TexturedColonist2 scale={5} position={[0, -1, 0]} />;
        break;
      case "Smooth Colonist":
        return <TexturedColonist2 scale={5} position={[0, -1, 0]} />;
        break;
      case "Carrara":
        return <Cambridge scale={5} position={[0.13, -1, -0.2]} />;
        break;
      case "Craftsman":
        return <Craftsman scale={5} position={[0, -1, 0]} />;
        break;
      case "Rockport":
        return <Rockport scale={5} position={[0.18, -1, -0.2]} />;
        break;
      case "Caiman":
        return <Continental scale={5} position={[0, -1, 0]} />;
        break;
      case "Santa Fe":
        return <SantaFe scale={5} position={[0, -1, 0]} />;
        break;
      case "Birch":
        return <Birch scale={5} position={[0, -1, 0]} />;
        break;

      default:
        return <NoSlabSelected scale={size} position={[-2, 1, 1]} />;
        break;
    }
  };
  const getCasing = (casing) => {
    if (casing?.includes("779")) {
      return <Casing779 scale={5} position={[0.2, -1, -0.2]} />;
    } else if (casing?.includes("787")) {
      return <Casing787 scale={5} position={[0.2, -1, -0.2]} />;
    } else if (casing?.includes("7997")) {
      return <Casing7997 scale={5} position={[0.2, -1, -0.2]} />;
    } else if (casing?.includes("24062")) {
      return <Casing24062 scale={5} position={[0.2, -1, -0.2]} />;
    } else if (casing?.includes("7992")) {
      return <Casing7992 scale={5} position={[0.2, -1, -0.2]} />;
    } else if (casing?.includes("714")) {
      return <Casing714 scale={5} position={[0.2, -1, -0.2]} />;
    } else if (casing?.includes("12184")) {
      return <Casing12184 scale={5} position={[0, -1, 0]} />;
    } else if (casing?.includes("12185")) {
      return <Casing12185 scale={5} position={[0, -1, 0]} />;
    } else if (casing?.includes("13834")) {
      return <Casing13834 scale={5} position={[0, -1, 0]} />;
    } else if (casing?.includes("41988")) {
      return <Casing41988 scale={5} position={[0.2, -1, -0.2]} />;
    } else if (casing?.includes("46307")) {
      return <Casing46307 scale={5} position={[0.2, -1, -0.2]} />;
    } else if (casing?.includes("45932")) {
      return <Casing45932 scale={5} position={[0.2, -1, -0.2]} />;
    } else {
      return <></>;
    }
  };

  const getHanding = (handing) => {
    switch (handing) {
      case "Right Hand":
        return (
          <>
            <DoorHandleRH
              rotation={[0, 11, 0]}
              scale={0.2}
              position={[-2.1, -2.1, 0.04]}
              color="Black"
            />
            <DoorHandleLH
              rotation={[3.145, -11, 0]}
              scale={0.2}
              position={[-2.1, -0.8, -0.35]}
            />
          </>
        );
        break;

      case "Left Hand":
        return (
          <>
            <DoorHandleRH
              rotation={[3.145, -11, 0]}
              scale={0.2}
              position={[2.1, -0.8, 0.04]}
            />
            <DoorHandleLH
              rotation={[0, 11, 0]}
              scale={0.2}
              position={[2, -2.12, -0.35]}
            />
          </>
        );
        break;
      default:
        return <></>;
        break;
    }
  };

  const [size, setSize] = useState(4);

  return (
    <Suspense>
      <div className="flex h-full relative">
        <Canvas
          camera={{ position: [0, 0, 7], fov: 90 }}
          className="cursor-pointer"
          shadows
        >
          <ambientLight intensity={0.14} />
          <spotLight position={[10, 0, 10]} angle={0.9} penumbra={0.1} />
          <pointLight position={[-10, -10, -10]} />
          {getSlabStyleImage(props.data.slab_style)}
          {getHanding(props.data.handing)}
          {getCasing(props.formData.casing_type)}
        </Canvas>
      </div>
    </Suspense>
  );
};

export default DoorPreview3D;
