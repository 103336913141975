import React, { useEffect, useState } from "react";
import { selectUser } from "../../../features/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NavListAuth from "../../../components/Navigation/NavListAuth";
import { getDeliveryRequest } from "../../../utilities";
import MaterialTable from "material-table";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  ThemeProvider,
  createTheme,
  Tooltip,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";
import RequestSnackbar from "../../../components/Other/RequestSnackbar";
import { icons } from "../../../components/Utilities/UtilitiesWD";

const Requests = () => {
  const user = useSelector(selectUser);
  const [dataStore, setDataStore] = useState([]);
  const defaultMaterialTheme = createTheme();
  const [reminderFlag, setreminderFlag] = useState(false);

  const sendReminder = async (rowData) => {
    var data = JSON.stringify(rowData);

    const rawResponse = await fetch("/api/request/reminder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const content = await rawResponse.json();
    setreminderFlag(true);
    getDeliveryRequest(user.email, setDataStore);
  };

  useEffect(() => {
    if (user) {
      getDeliveryRequest(user.email, setDataStore);
    }
  }, [user]);

  return (
    <>
      <div style={{ margin: "10px" }}>
        <div>
          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              style={{
                backgroundColor:
                  window.document.documentElement.classList[0] === "dark"
                    ? "rgba(255, 255, 255, 0.05)"
                    : "rgba(255, 255, 255, 1)",
                color:
                  window.document.documentElement.classList[0] === "dark"
                    ? "white"
                    : "black",
              }}
              columns={[
                {
                  title: "Order#",
                  field: "salesOrder",
                },
                {
                  title: "Submit Date",
                  field: "requestSubmitted",
                  render: (rowData) => {
                    let rowDate = new Date(
                      rowData["requestSubmitted"]
                    ).toLocaleDateString("en-US");

                    return <p>{rowDate}</p>;
                  },
                },
                {
                  title: "Customer",
                  field: "customer",
                },
                {
                  title: "Job",
                  field: "job",
                },
                {
                  title: "Delivery Date Requested",
                  field: "requestDate",
                  render: (rowData) => {
                    if (rowData.earliestAvailable) {
                      return <p>Earliest</p>;
                    } else {
                      return <p>{rowData.requestDate}</p>;
                    }
                  },
                },
                {
                  title: "Status",
                  field: "requestStatus",
                  render: (rowData) => {
                    //console.log(rowData.requestSent);

                    if (rowData.requestStatus === 0) {
                      if (typeof rowData.requestSent === "undefined") {
                        if (
                          (new Date() - new Date(rowData.createdAt)) /
                            1000 /
                            60 >
                          30
                        ) {
                          return (
                            <div>
                              <p>Pending</p>

                              <Tooltip title="Send reminder">
                                <button
                                  onClick={() => {
                                    sendReminder(rowData);
                                  }}
                                  className="text-red-600 hover:text-red-400"
                                >
                                  <NotificationsActiveIcon />
                                </button>
                              </Tooltip>
                            </div>
                          );
                        } else {
                          return <p>Pending</p>;
                        }
                      } else {
                        return <p>Pending</p>;
                      }
                    } else if (rowData.requestStatus === 1) {
                      if (rowData.earliestAvailable) {
                        return (
                          <div className="flex flex-col">
                            <div>
                              <p>
                                Scheduled For: {rowData?.earliestDateScheduled}
                              </p>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="flex flex-col">
                            <div>
                              <p>Accepted</p>
                            </div>
                          </div>
                        );
                      }
                    } else if (rowData.requestStatus === 2) {
                      return <p>Rejected</p>;
                    } else {
                      return <p>Unknown</p>;
                    }
                  },
                },
              ]}
              data={dataStore.slice().reverse()}
              title={`Requests - ${user?.name}`}
              icons={icons}
              components={{
                Action: (props) => (
                  <button>
                    <LocalShippingIcon
                      onClick={(event) => {
                        props.action.onClick(event, props.data);
                      }}
                      color="primary"
                      variant="text"
                      style={{ textTransform: "none" }}
                      size="small"
                    />
                  </button>
                ),
              }}
              options={{
                pageSize: 5, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [10, 15, 20, 25],
                headerStyle: {
                  search: true,
                  backgroundColor:
                    window.document.documentElement.classList[0] === "dark"
                      ? "rgba(255, 255, 255, 0.05)"
                      : "rgba(255, 255, 255, 1)",
                  color:
                    window.document.documentElement.classList[0] === "dark"
                      ? "white"
                      : "black",
                },
                searchFieldStyle: {
                  color:
                    window.document.documentElement.classList[0] === "dark"
                      ? "white"
                      : "black",
                  borderBottom:
                    window.document.documentElement.classList[0] === "dark"
                      ? "1px solid white"
                      : "1px solid black",
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: 14,
                  };
                },
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <p className="text-black dark:text-white">
                      Please Contact Helpdesk With Assistance In Retrieving
                      Records
                    </p>
                  ),
                },
              }}
            />
          </ThemeProvider>
        </div>
        <RequestSnackbar
          open={reminderFlag}
          setOpen={setreminderFlag}
          message={`Reminder has been sent!`}
        />
      </div>
    </>
  );
};

export default Requests;
