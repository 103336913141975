import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";

const ScheduleOrders = () => {
  const getOrders = (setter) => {
    return fetch(`/api/orders`)
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson);
        setter(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    dropBranch
  ) => {
    var sourceCopy = source;
    var destinationCopy = destination;

    // console.log(dropBranch);
    // console.log(destinationCopy);

    destinationCopy.splice(
      destinationCopy.index,
      0,
      sourceCopy[droppableSource.index]
    );

    sourceCopy.splice(droppableSource.index, 1);

    setOrders(sourceCopy);
    //setBranch({ ...branch, dropBranch: destinationCopy });
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver, includeskey, closestBranches) => {
    if (includeskey) {
      return {
        background: isDraggingOver ? "lightblue" : "white",
        borderWidth: "3px",
        padding: grid,
        borderRadius: "2px",
        width: isDraggingOver ? "33%" : "25%",
        height: isDraggingOver ? "33%" : "25%",
        borderColor: "green",
      };
    } else if (closestBranches.length === 0) {
      return {
        background: isDraggingOver ? "lightblue" : "white",
        padding: grid,
        borderRadius: "2px",
        width: isDraggingOver ? "33%" : "25%",
        height: isDraggingOver ? "33%" : "25%",
      };
    } else {
      return {
        background: "lightgray",
        padding: grid,
        borderRadius: "2px",
        width: "25%",
        height: "25%",
      };
    }
  };

  const getOrderListStyle = (isDraggingOver) => {
    return {
      background: isDraggingOver ? "lightblue" : "white",
      display: "flex",
      padding: grid,
      overflow: "auto",
      borderRadius: "5px",
    };
  };

  const [orders, setOrders] = useState([]);
  const [closestBranches, setClosest] = useState([]);
  const [branch, setBranch] = useState({
    edgerton: [],
    kendallville: [],
    shipshewana: [],
    adrian: [],
    cromwell: [],
    fort_wayne: [],
    schoolcraft: [],
    elkhart: [],
    delton: [],
    granger: [],
    mishawaka: [],
    roseland: [],
    eau_claire: [],
    coloma: [],
    three_oaks: [],
    laporte: [],
  });

  const [modal, setModal] = useState(false);

  useEffect(() => {
    getOrders(setOrders);
  }, []);

  const displayBranchRank = (rank) => {
    switch (rank) {
      case 0:
        return <LooksOneIcon style={{ color: "green" }} />;
        break;
      case 1:
        return <LooksTwoIcon style={{ color: "green" }} />;
        break;
      case 2:
        return <Looks3Icon style={{ color: "orange" }} />;
        break;
      case 3:
        return <Looks4Icon style={{ color: "orange" }} />;
        break;
      case 4:
        return <Looks5Icon style={{ color: "red" }} />;
        break;
      case -1:
        break;

      default:
        break;
    }
  };

  const getList = (id) => {
    switch (id) {
      case "edgerton":
        return branch.edgerton;
        break;
      case "kendallville":
        return branch.kendallville;
        break;
      case "shipshewana":
        return branch.shipshewana;
        break;
      case "adrian":
        return branch.adrian;
        break;
      case "cromwell":
        return branch.cromwell;
        break;
      case "fort_wayne":
        return branch.fort_wayne;
        break;
      case "schoolcraft":
        return branch.schoolcraft;
        break;
      case "elkhart":
        return branch.elkhart;
        break;
      case "delton":
        return branch.delton;
        break;
      case "granger":
        return branch.granger;
        break;
      case "mishawaka":
        return branch.mishawaka;
        break;
      case "roseland":
        return branch.roseland;
        break;
      case "eau_claire":
        return branch.eau_claire;
        break;
      case "coloma":
        return branch.coloma;
        break;
      case "three_oaks":
        return branch.three_oaks;
        break;
      case "laporte":
        return branch.laporte;
        break;
      case "orders":
        return orders;

      default:
        break;
    }
  };

  const getClosest5Branches = (id) => {
    var closestBranches = [];
    Object.entries(orders).map((key, value) => {
      if (key[1]._id === id) {
        Object.entries(key[1].distance_from_branch).map((key, value) => {
          if (closestBranches.length < 5) {
            closestBranches.push(key[0]);
          }
        });
      }
    });

    setClosest(closestBranches);
  };

  const onDragStart = (result) => {
    getClosest5Branches(result.draggableId);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      //reorder "Orders" list
    } else {
      move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
        destination.droppableId
      );
    }
    setClosest([]);
  };

  return (
    <div className="w-full">
      <div className="flex justify-center dark:text-white border border-black bg-white dark:border-gray-500 dark:bg-white/5 m-4">
        <p className="font-black text-lg m-2">My Orders</p>
      </div>
      {orders && (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <div className="m-2">
            <Droppable droppableId="orders" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getOrderListStyle(snapshot.isDraggingOver)}
                  className="flex"
                >
                  {orders.map((item, index) => {
                    return (
                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="flex flex-col border shadow-lg"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className="w-64">
                              <p className="text-sm m-2">
                                Order #{item.orderNumber}
                              </p>
                              <p className="text-sm m-2">{item.address}</p>
                              <p className="text-sm m-2">
                                Closest Branch:{" "}
                                {Object.keys(item.distance_from_branch)[0]}
                              </p>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className="flex justify-center dark:text-white border border-black bg-white dark:border-gray-500 dark:bg-white/5 m-4">
            <p className="font-black text-lg m-2">Branches</p>
          </div>

          <div className="flex m-4 overflow-x-auto border border-gray-500 bg-white/5 p-4">
            {Object.entries(branch).map((key, value) => {
              var bRank = closestBranches.findIndex(function (item) {
                return item.indexOf(key[0]) !== -1;
              });
              return (
                <Droppable
                  isDropDisabled={
                    closestBranches.includes(key[0]) ? false : true
                  }
                  visible={false}
                  droppableId={key[0]}
                  key={value}
                  index={value}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(
                        snapshot.isDraggingOver,
                        closestBranches.includes(key[0]),
                        closestBranches
                      )}
                      className="flex m-2"
                    >
                      <div className="flex flex-col items-center">
                        <div className="flex flex-col">
                          <div className="flex justify-center">
                            <p className="w-7/8">{key[0].toUpperCase()}</p>
                            {displayBranchRank(bRank)}
                          </div>

                          {key[1].map((item, index) => (
                            <div className="flex flex-col border shadow-lg w-64 m-2">
                              <p className="text-sm m-2">
                                Order #{item.orderNumber}
                              </p>
                              <p className="text-sm m-2">{item.address}</p>
                              <p className="text-sm m-2">
                                Closest Branch:{" "}
                                {Object.keys(item.distance_from_branch)[0]}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
        </DragDropContext>
      )}
    </div>
  );
};

export default ScheduleOrders;
