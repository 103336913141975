/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.8 Rockport.gltf
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Rockport(props) {
  const { nodes, materials } = useGLTF("/Rockport.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane.geometry}
        material={materials["default"]}
        rotation={[0, 0, -Math.PI / 2]}
      />
    </group>
  );
}

useGLTF.preload("/Rockport.gltf");
