import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";
import renderWeatherIcon from "./GetWeatherIcon";
import { capitalizeFirstLetter } from "../../utilities";
import LoadingSpinner from "./LoadingSpinner";

const HeaderWeather = () => {
  const user = useSelector(selectUser);
  const [weather, setWeather] = useState(null);

  const getWeather = async () => {
    var link;

    switch (user.branch) {
      case "Corporate":
        link = " /api/weather/getWeatherData?branch=Granger";
        break;
      case "Window/Door - Mishawaka":
        link = " /api/weather/getWeatherData?branch=Mishawaka";
        break;
      case "Mill - Fort Wayne":
        link = " /api/weather/getWeatherData?branch=Fort Wayne";
        break;
      case null:
        link = " /api/weather/getWeatherData?branch=Granger";
        break;
      default:
        link = " /api/weather/getWeatherData?branch=" + user.branch;
        break;
    }

    const res = await fetch(link);
    const data = await res.json();
    setWeather(data[0]);
  };

  useEffect(() => {
    if (user) {
      getWeather();
    }
  }, [user]);

  return (
    <>
      {weather ? (
        <div className="flex flex-col items-center justify-center text-center">
          <p className="text-sm border-b border-black">
            {capitalizeFirstLetter(weather.branch)}
          </p>
          <p className="text-xs">
            {capitalizeFirstLetter(weather.current.weather[0].description)}
          </p>
          <p className="-translate-y-">
            {renderWeatherIcon(
              weather.current.weather[0].main,
              28,
              weather.current.weather[0].description
            )}
          </p>

          <p className="-translate-y-5">
            {Math.round(weather.current.feels_like)}&deg;F
          </p>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default HeaderWeather;
