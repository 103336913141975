import {
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import LocationNavigationVehicles from "../../components/Fleet/LocationNavigationVehicles";
import { getLocations } from "../../components/Fleet/Forklifts/Utilities/api";

const VehicleManager = () => {
  const user = useSelector(selectUser);
  const [UnitNumber, setUnitNumber] = useState("");
  const [location, setLocation] = useState("");
  const [vinNumber, setVinNumber] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    getLocations(setLocationData);
  }, []);

  const handleChange = (event) => {
    setLocation(event.target.value);
  };

  const handleAdd = async () => {
    var data = JSON.stringify({
      vin: vinNumber,
      branch: location,
      make: make,
      model: model,
      unit: UnitNumber,
      deleted: 0,
      inService: true,
      addedBy: user.user,
      dateAdded: new Date(),
    });

    await fetch("/api/fleet/vehicle/addVehicle", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });

    setUnitNumber("");
    setLocation("");
    setVinNumber("");
    setMake("");
    setModel("");
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="mt-2 md:mt-0 md:col-span-2 ">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white space-y-2 sm:p-6">
              <p className="text-xl font-black">Add Vehicle</p>
              <div className="grid grid-cols-3 gap-2">
                <div className="col-span-1">
                  <div className="bg-gray-50 p-2 border-2 rounded-md">
                    <InputLabel id="unit-number-label">Unit Number</InputLabel>
                    <TextField
                      onChange={(e) => {
                        setUnitNumber(e.target.value);
                      }}
                      value={UnitNumber}
                      id="unit-number"
                      label="#"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="bg-gray-50 p-2 border-2 rounded-md">
                    <InputLabel id="location-label">Location</InputLabel>
                    <Select
                      className="w-full"
                      labelId="location-label"
                      id="location"
                      value={location}
                      label="Location"
                      onChange={handleChange}
                    >
                      {locationData.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.location}>
                            {item.location}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2 mt-4">
                <div className="col-span-1">
                  <div className="bg-gray-50 p-2 border-2 rounded-md">
                    <InputLabel id="vin-number-label">VIN Number</InputLabel>
                    <TextField
                      onChange={(e) => {
                        setVinNumber(e.target.value);
                      }}
                      value={vinNumber}
                      id="vin-number"
                      label="#"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="bg-gray-50 p-2 border-2 rounded-md">
                    <InputLabel id="make-label">Make</InputLabel>
                    <TextField
                      onChange={(e) => {
                        setMake(e.target.value);
                      }}
                      value={make}
                      id="make"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="bg-gray-50 p-2 border-2 rounded-md">
                    <InputLabel id="model-label">Model</InputLabel>
                    <TextField
                      onChange={(e) => {
                        setModel(e.target.value);
                      }}
                      value={model}
                      id="model"
                      variant="outlined"
                    />
                  </div>
                </div>
                <button className="m-2 p-2 bg-green-200/50" onClick={handleAdd}>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LocationNavigationVehicles locationData={locationData} />
    </>
  );
};

export default VehicleManager;
