export const getWindowOrders = (userid, setter, branchorders) => {
  return fetch(
    `/api/windowOrders?userid=${userid}&branchorders=${branchorders}`
  )
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson.recordset);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getUserID = (email, setter) => {
  return fetch("/api/getUserID")
    .then((response) => response.json())
    .then((responseJson) => {
      var arrayLength = responseJson.recordset.length;
      for (var i = 0; i < arrayLength; i++) {
        if (responseJson.recordset[i].EmailAddress === email) {
          //console.log(responseJson.recordset[i]);
          if (responseJson.recordset[i].Branch === "Corporate") {
            //console.log(responseJson.recordset[i]);
            responseJson.recordset[i].Branch = "Granger";
          }
          setter(responseJson.recordset[i]);
        }
      }
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const readPhoneScan = (setter) => {
  return fetch("/api/phone/read")
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getCertData = (setter) => {
  return fetch("/api/taxcert")
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson.recordset);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const sendDeliveryRequest = async (
  orderID,
  customer,
  job,
  SalesOrderNumber,
  notes,
  date,
  name,
  email,
  checked
) => {
  var data = JSON.stringify({
    orderID: orderID,
    customer: customer,
    job: job,
    salesOrder: SalesOrderNumber,
    notes: notes,
    date: date,
    email: email,
    requestBy: name,
    earliestAvailable: checked,
  });

  const rawResponse = await fetch("/smtp/test", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();

  //console.log(content);
};

export const getDeliveryRequest = async (email, setter, branchorders) => {
  return fetch(`/api/getRequests?key=98duas98dja9&email=${email}`)
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllDeliveryRequest = async (setter) => {
  return fetch("/api/getAllRequests?key=98duas98dja9&")
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getDeliveryRequestAll = async (setter) => {
  return fetch("/api/getAllRequests/all?key=98duas98dja9&")
    .then((response) => response.json())
    .then((responseJson) => {
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getUserNotifications = async (setter, email, id, route) => {
  return fetch(
    `/api/notifications/getUserNotifications?email=${email}&id=${id}&route=${route}`
  )
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log(responseJson);
      setter(responseJson.slice(0).reverse());
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const markNotificationRead = async (id) => {
  return fetch(`/api/notifications/markNotificationRead?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const markAllNotificationRead = async (user) => {
  return fetch(`/api/notifications/markAllNotificationRead?user=${user}`)
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const sendUserNotification = async (
  to,
  from,
  message,
  subject,
  redirect
) => {
  var data = JSON.stringify({
    to: to,
    from: from,
    redirect: redirect || null,
    message: message,
    subject: subject,
    redirect: redirect,
  });

  const rawResponse = await fetch("/api/notifications", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const acceptRequest = async (id, notes) => {
  var data = JSON.stringify({
    id: id,
    notes: notes,
    request: "accept",
  });

  const rawResponse = await fetch("/api/response", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const addToJourney = async (date, orderid) => {
  //Add To Journey Planner
  await fetch(`/api/addJourney?orderid=${orderid}&date=${date}`);
};

export const rejectRequest = async (id, notes, altDates) => {
  var data = JSON.stringify({
    id: id,
    notes: notes,
    request: "reject",
    altDates: altDates,
  });

  const rawResponse = await fetch("/api/response", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();

  //console.log(content);
};

export const acceptAltDate = async (id, date) => {
  var data = JSON.stringify({
    id: id,
    date: date,
  });

  const rawResponse = await fetch("/api/acceptAltDate", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();
};

export const getOrderInfo = async (id, setter) => {
  return fetch(`/api/getOrderInfo?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson.recordset[0]);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getRequestInfo = async (id, setter) => {
  return fetch(`/api/getRequestInfo?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getRequestInfo_PrevCheck = async (id, setter) => {
  return fetch(`/api/getRequestInfo/submitcheck?id=${id}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson.prev_submit);
      setter(responseJson.prev_submit);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const countWDRequest = async (email, setter) => {
  return fetch(`/api/countWDRequest?email=${email}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const scheduleEarliestDate = async (id, notes, date) => {
  var data = JSON.stringify({
    id: id,
    notes: notes,
    date: date,
    request: "accept-earliest",
  });

  const rawResponse = await fetch("/api/response", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data,
  });
  const content = await rawResponse.json();

  //console.log(content);
};

//Search OCR PDF QUERY API CALL

export const searchPDF = (query, setter) => {
  return fetch(`/api/cloud/findOCR?query=${query}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getPDFData = (filename, setter) => {
  return fetch(`/api/cloud/getPDFData?filename=${filename}`)
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      setter(responseJson[0]);
    })
    .catch((error) => {
      //console.error(error);
    });
};

//Update User Data Call
export const updateUser = async (user) => {
  const rawResponse = await fetch("/api/user/updateUser", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  const content = await rawResponse.json();
};

//Normalize Date
export const normalizeDate = (date) => {
  return new Date(date).toLocaleTimeString([], {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const normalizeDate_notime = (date) => {
  return new Date(date).toLocaleDateString([], {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

//Normalize Date
export const normalizeDate_time = (date) => {
  return new Date(date).toLocaleTimeString("en-US", {
    timeStyle: "short",
  });
};

export const daysSince = (date) => {
  const diffTime = Math.abs(new Date(date) - new Date());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

//String to Color
export const stringToColor = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var color = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }

  // console.log(color);
  return color;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getAllUsers = (setter) => {
  return fetch("/api/getUserID")
    .then((response) => response.json())
    .then((responseJson) => {
      let users = [];

      console.log(responseJson);

      for (let index = 0; index < responseJson.recordset.length; index++) {
        if (
          responseJson.recordset[index].EmailAddress !==
          "noreply@bigclumber.com"
        ) {
          users.push({ label: responseJson.recordset[index].Name, id: index });
        }
      }

      setter(users);
    })
    .catch((error) => {
      //console.error(error);
    });
};

export const getAllUsersNoSales = (setter) => {
  return fetch("/api/getUserID/users")
    .then((response) => response.json())
    .then((responseJson) => {
      let users = [];

      users.push({ label: "Other", id: 0 });

      for (let index = 0; index < responseJson.recordset.length; index++) {
        if (
          responseJson.recordset[index].EmailAddress !==
          "noreply@bigclumber.com"
        ) {
          users.push({
            label: responseJson.recordset[index].Name,
            email: responseJson.recordset[index].Email,
            id: index + 1,
          });
        }
      }

      setter(users);
    })
    .catch((error) => {
      //console.error(error);
    });
};
