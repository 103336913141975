import React from "react";
import ReactLoading from "react-loading";

const LoadingSpinner = () => {
  return (
    <div className="w-full h-full flex justify-center items-center m-2">
      <ReactLoading
        type={"spin"}
        color={"gray"}
        className="w-full h-screen dark:text-white text-black"
      />
    </div>
  );
};

export default LoadingSpinner;
