import { LogLevel } from "@azure/msal-browser";
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "7968b20e-407e-47cb-aa93-b828331bdd86",
    authority:
      "https://login.microsoftonline.com/bigclumberinc.onmicrosoft.com",
    redirectUri: "https://node.bigclumber.com/inituser",
    //redirectUri: "http://localhost:3000/inituser",
    //Dev server
    //redirectUri: "https://dev.bigclumber.com/inituser",
    postLogoutRedirectUri: "https://bigclumber.com/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [
    "User.Read",
    "Group.Read.All",
    "Calendars.Read",
    "Files.Read.All",
    "Files.ReadWrite.All",
    "Sites.Read.All",
    "Sites.ReadWrite.All",
  ],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  groupsEndpoint: "https://graph.microsoft.com/v1.0/groups/",
  calanderMeEndpoint: "https://graph.microsoft.com/v1.0/me/calendar/events",
};
