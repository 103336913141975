import React, { useEffect, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

function UserChecklist() {
  const [checkboxSections, setCheckboxSections] = useState({
    Add: [],
    Edit: [],
    Remove: [],
  });
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [newFieldName, setNewFieldName] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  const handleOpenAddDialog = (section) => {
    setOpenAddDialog(true);
    setSelectedSection(section);
    setNewFieldName("");
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddField = (section) => {
    if (newFieldName.trim() !== "") {
      const updatedSections = { ...checkboxSections };
      updatedSections[section] = [
        ...updatedSections[section],
        { name: newFieldName, checked: false },
      ];
      setCheckboxSections(updatedSections);
      setOpenAddDialog(false);
    }
  };

  const handleOpenRemoveDialog = (section) => {
    setOpenRemoveDialog(true);
    setSelectedSection(section);
    setSelectedField("");
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const handleRemoveField = (section) => {
    if (selectedField !== "") {
      const updatedSections = { ...checkboxSections };
      updatedSections[section] = updatedSections[section].filter(
        (field) => field.name !== selectedField
      );
      setCheckboxSections(updatedSections);
      setOpenRemoveDialog(false);
    }
  };

  const handleCheckboxChange = (fieldName, section) => {
    const updatedSections = { ...checkboxSections };
    updatedSections[section] = updatedSections[section].map((field) => {
      if (field.name === fieldName) {
        return { ...field, checked: !field.checked };
      }
      return field;
    });
    setCheckboxSections(updatedSections);
  };

  const renderFields = (section) => {
    return checkboxSections[section].map((field, index) => (
      <div key={index} className="flex items-center">
        <input
          type="checkbox"
          checked={field.checked}
          onChange={() => handleCheckboxChange(field.name, section)}
        />
        <label className="ml-2">{field.name}</label>
      </div>
    ));
  };

  const renderDropdownOptions = () => {
    const sectionFields = checkboxSections[selectedSection];
    if (!sectionFields) return null; // Guard against undefined section
    return sectionFields.map((field) => (
      <MenuItem key={field.name} value={field.name}>
        {field.name}
      </MenuItem>
    ));
  };

  return (
    <div className="flex flex-col text-black dark:text-white">
      {/* Add Section */}
      <div className="border border-black p-4 relative">
        <h2 className="text-lg font-bold">Add</h2>
        <button
          className="absolute bottom-4 right-14 px-4 py-2"
          onClick={() => handleOpenAddDialog("Add")}
        >
          <AddBoxIcon sx={{ fontSize: 32 }} />
        </button>
        <button
          className="absolute bottom-4 right-4 px-4 py-2"
          onClick={() => handleOpenRemoveDialog("Add")}
        >
          <IndeterminateCheckBoxIcon sx={{ fontSize: 32 }} />
        </button>
        {renderFields("Add")}
      </div>
      {/* Edit Section */}
      <div className="border border-black p-4 relative">
        <h2 className="text-lg font-bold">Edit</h2>
        <button
          className="absolute bottom-4 right-14 px-4 py-2"
          onClick={() => handleOpenAddDialog("Edit")}
        >
          <AddBoxIcon sx={{ fontSize: 32 }} />
        </button>
        <button
          className="absolute bottom-4 right-4 px-4 py-2"
          onClick={() => handleOpenRemoveDialog("Edit")}
        >
          <IndeterminateCheckBoxIcon sx={{ fontSize: 32 }} />
        </button>
        {renderFields("Edit")}
      </div>
      {/* Remove Section */}
      <div className="border border-black p-4 relative">
        <h2 className="text-lg font-bold">Remove</h2>
        <button
          className="absolute bottom-4 right-14 px-4 py-2"
          onClick={() => handleOpenAddDialog("Remove")}
        >
          <AddBoxIcon sx={{ fontSize: 32 }} />
        </button>
        <button
          className="absolute bottom-4 right-4 px-4 py-2"
          onClick={() => handleOpenRemoveDialog("Remove")}
        >
          <IndeterminateCheckBoxIcon sx={{ fontSize: 32 }} />
        </button>
        {renderFields("Remove")}
      </div>
      {/* Dialog for Adding Checkbox Field */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <div className="p-4">
          <TextField
            label="Add Checkbox Field"
            variant="outlined"
            fullWidth
            value={newFieldName}
            onChange={(e) => setNewFieldName(e.target.value)}
          />
          <div className="mt-4 flex justify-end">
            <button
              className="px-4 py-2 bg-blue-500"
              onClick={() => handleAddField(selectedSection)}
            >
              Add
            </button>
            <button
              className="px-4 py-2 bg-gray-500 ml-2"
              onClick={handleCloseAddDialog}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      {/* Dialog for Removing Checkbox Field */}
      <Dialog open={openRemoveDialog} onClose={handleCloseRemoveDialog}>
        <div className="p-4">
          <h2>Select Field to Remove:</h2>
          <TextField
            select
            fullWidth
            label="Field"
            value={selectedField}
            onChange={(e) => setSelectedField(e.target.value)}
            variant="outlined"
            className="mt-2"
          >
            {renderDropdownOptions()}
          </TextField>
          <div className="mt-4 flex justify-end">
            <button
              className="px-4 py-2 bg-red-500"
              onClick={() => handleRemoveField(selectedSection)}
            >
              Remove
            </button>
            <button
              className="px-4 py-2 bg-gray-500 ml-2"
              onClick={handleCloseRemoveDialog}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default UserChecklist;
