export const VehicleItems = [
  {
    title: "Parking Brake",
    field: "parkingBrake",
    options: ["Working", "Not Working"],
    description: "Describe Problem With Fluid Levels",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Service Brakes",
    field: "serviceBrake",
    options: ["Working", "Not Working"],
    description: "Describe Leak Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Horn",
    field: "horn",
    options: ["Working", "Not Working"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Steering",
    field: "steering",
    options: ["Working", "Not Working"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Tires",
    field: "tires",
    options: ["Optimal Tread", "Worn Tread"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Wheels",
    field: "wheels",
    options: ["Good Condition", "Bad Condition"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Mirrors",
    field: "mirrors",
    options: ["Good Condition", "Bad Condition"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Lights",
    field: "lights",
    options: ["Working", "Not Working"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Reflectors",
    field: "reflectors",
    options: ["Present", "Not Present"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Wipers",
    field: "wipers",
    options: ["Working Condition", "Bad Condition"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Fire Extinguisher",
    field: "fireExtinguisher",
    options: ["Present", "Not Present"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Reflective Triangles",
    field: "reflectiveTriangles",
    options: ["Present", "Not Present"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Engine Fluids",
    field: "engineFluids",
    options: ["Optimal Levels", "Insufficient Levels"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
  {
    title: "Engine Function",
    field: "engineFunction",
    options: ["Optimal Performance", "Suboptimal Performance"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
  },
];
