import { Button, DialogTitle, Dialog, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { getCustomerAddress, getCustomerSearch } from "./utilities";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const FRM_SelectCustomer = (props) => {
  const [customerSearch, setCustomer] = useState("");
  const [searchResults, setResults] = useState(null);
  const [customerSearchModal, setSearchModal] = useState(false);
  const [customerAddress, setCustomerAddress] = useState(null);
  const [customerAddressModal, setAddressModal] = useState(false);

  function openModal() {
    setSearchModal(true);
  }

  function closeModal() {
    setSearchModal(false);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (customerSearch !== "") {
      openModal();
      getCustomerSearch(setResults, customerSearch);
    }
  };

  return (
    <>
      <Dialog
        open={customerSearchModal}
        onClose={() => {
          closeModal();
        }}
      >
        <div className="bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 from-gray-200 to-gray-300 border-b dark:border-gray-500 border-black p-4 dark:text-white">
          <div className="flex justify-center">
            <p className="text-xl font-black">
              Search Results - "{customerSearch}"
            </p>
          </div>

          {searchResults?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-center items-center m-2 border dark:border-gray-500 border-black bg-white dark:bg-white/5 rounded-md"
              >
                <div className="w-4/5">
                  <div className="flex justify-center">
                    <p className="font-m">{item.Name}</p>
                  </div>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setAddressModal(true);
                      getCustomerAddress(setCustomerAddress, item.CustomerID);

                      var tempData = props.formData;

                      tempData.account = item.CustomerID;
                      tempData.customer = item.Name;

                      props.setFormData(tempData);

                      closeModal();
                    }}
                  >
                    <div className="flex justify-center">
                      <ArrowForwardIcon />
                    </div>
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </Dialog>

      <Dialog
        open={customerAddressModal}
        onClose={() => {
          setAddressModal(false);
        }}
      >
        <div className="bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 from-gray-200 to-gray-300 border-b dark:border-gray-500 border-black p-4 dark:text-white">
          <p>{props.formData.customer} Addresses</p>
          {customerAddress?.length === 0 && (
            <div className="flex justify-center items-center m-2 border-b dark:border-gray-500 border-black dark:hover:bg-white/5 hover:bg-black/5">
              <div className="w-4/5">
                <div className="flex justify-center">
                  <p className="font-m">No Addresses Found</p>
                </div>
              </div>
            </div>
          )}
          {customerAddress?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-center items-center m-2 border-b dark:border-gray-500 border-black dark:hover:bg-white/5 hover:bg-black/5"
              >
                <div className="w-4/5">
                  <div className="flex justify-center">
                    <p className="font-m">{item.CustomerAddress}</p>
                  </div>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      var tempData = props.formData;
                      tempData.address = item.FormatedAddress;
                      tempData.job = item.CustomerAddress.split(" ")[0];
                      tempData.imported_customer = true;
                      props.setFormData(tempData);

                      setAddressModal(false);
                    }}
                  >
                    <div className="flex justify-center">
                      <ArrowForwardIcon />
                    </div>
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </Dialog>

      <div className="flex justify-center dark:text-white">
        <p className="font-black">Customer Information</p>
      </div>

      <div className="flex flex-wrap justify-center items-center m-2 dark:text-white">
        <div className="w-1/3 m-2">
          <p>Customer Name</p>
          <TextField
            disabled={props.formData.imported_customer ? true : false}
            onChange={(e) => {
              props.setFormData({
                ...props.formData,
                customer: e.target.value,
              });
              setCustomer(e.target.value);
              // props.setFormData(...props.formData);
            }}
            className="shadow-sm border border-gray-500 bg-white dark:text-white p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
            value={props.formData.customer}
            variant="outlined"
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <div className="flex hover:bg-blue-200/20">
                  <Tooltip title="Search Bistrack for Customer">
                    <Button
                      onClick={() => {
                        handleSearch();
                      }}
                    >
                      <SearchIcon className="text-black dark:text-white" />
                    </Button>
                  </Tooltip>
                </div>
              ),
            }}
          />
        </div>
        <div className="w-1/3 m-2">
          <p>Account Number</p>
          <TextField
            onChange={(e) => {
              props.setFormData({ ...props.formData, account: e.target.value });
            }}
            className="shadow-sm border border-gray-500 bg-white dark:text-white p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
            value={props.formData.account}
            variant="outlined"
          />
        </div>
        <div className="w-1/3 m-2">
          <p>Job Number</p>
          <TextField
            onChange={(e) => {
              props.setFormData({
                ...props.formData,
                job: e.target.value,
              });
            }}
            className="shadow-sm border border-gray-500 bg-white dark:text-white p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
            value={props.formData.job}
            variant="outlined"
          />
        </div>
        <div className="w-1/3 m-2">
          <p>Delivery Address</p>
          <TextField
            onChange={(e) => {
              props.setFormData({ ...props.formData, address: e.target.value });
            }}
            className="shadow-sm border border-gray-500 bg-white dark:text-white p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
            value={props.formData.address}
            variant="outlined"
          />
        </div>
      </div>
    </>
  );
};

export default FRM_SelectCustomer;
