import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import SideNav from "../../SideNav";

export const SideNavigation = (props) => {
  return (
    <div
      className={`flex flex-col top-0 left-0 w-full sm:w-1/4 bg-gradient-to-b from-gray-200 to-gray-300 border-gray-900 dark:from-gray-800 dark:to-gray-900 dark:border-gray-500 border p-4 dark:text-white fixed h-full z-40 ease-in-out duration-300 z-50 ${
        props.showSidebar ? "-translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="flex flex-col overflow-auto">
        <div className="flex pb-2 m-2">
          <div className="flex w-1/4">
            <button
              className="flex text-4xl text-black dark:text-white items-center cursor-pointer"
              onClick={() => props.setShowSidebar(!props.showSidebar)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex w-3/4 justify-between items-center">
            <p className="text-xl font-bold">Node Navigation</p>
          </div>
        </div>

        <div className="flex flex-col items-start p-1 text-black">
          <div className="w-full text-black">
            <div>
              <SideNav
                setShowSidebar={props.setShowSidebar}
                showSidebar={props.showSidebar}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
