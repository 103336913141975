import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import MyCalendar from "../../components/Home/MyCalendar";
import { ButtonBase, Dialog, Divider, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import CalendarSVG from "../../components/Home/svgs/CalendarSVG";
import HelpSVG from "../../components/Home/svgs/HelpSVG";
import QuickLinks from "../../components/Home/QuickLinks";
import FeedbackSVG from "../../components/Home/svgs/FeedbackSVG";
import PinnedPages from "../../components/Home/PinnedPages";
import { selectPins } from "../../features/pinnedSlice";
import HeaderWeather from "../../components/Other/HeaderWeather";
import { NearMeDisabled } from "@mui/icons-material";
import NeedAQuote from "../../components/Home/NeedAQuote";

const Home = () => {
  const user = useSelector(selectUser);
  const pins = useSelector(selectPins);
  const [arrowClass, setClass] = useState("text-black");
  const [showHelper, setHelper] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);

  const handleClose = () => {
    setOpenCalendar(false);
  };

  const handleHelper = () => {
    setHelper(true);
  };

  useEffect(() => {
    if (showHelper) {
      const timer = setTimeout(() => {
        setHelper(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [showHelper]);

  return (
    <div className="flex flex-col items-center m-2">
      <div className="flex w-full">
        <div className="flex w-1/2 justify-center items-center flex-wrap">
          <div className="flex m-2">
            <button onClick={() => setOpenCalendar(true)}>
              <div className="flex flex-col items-center text-xl min-w-44 max-w-44 h-48 max-h-48 w-44  max-h-48 font-black hover:bg-yellow-300/20 text-black dark:text-white border-black border bg-white dark:bg-white/5 text-yellow dark:border-white dark:hover:bg-yellow-300/10">
                <p>Calendar</p>
                <Tooltip title="View Calendar">
                  <CalendarSVG />
                </Tooltip>
              </div>
            </button>
          </div>

          <div className="flex m-2">
            <Link to="/help">
              <div className="flex flex-col items-center text-xl min-w-44 max-w-44 h-48 max-h-48 w-44  max-h-48 font-black text-black dark:text-white border-black bg-white hover:bg-red-600/20 border dark:border-white dark:bg-white/5 dark:hover:bg-red-300/10">
                <p>Help</p>
                <Tooltip title="Need Help?">
                  <HelpSVG />
                </Tooltip>
              </div>
            </Link>
          </div>

          <div className="flex m-2">
            <Link to="/dashboard/weather">
              <div className="flex flex-col items-center text-xl min-w-44 max-w-44 h-48 max-h-48 w-44   max-h-48 border font-black text-black dark:text-white border-black bg-white hover:bg-sky-300/10 dark:text-sky-300 dark:hover:bg-sky-300/10 dark:bg-white/5 dark:border-white">
                <p>Weather</p>

                <HeaderWeather />
              </div>
            </Link>
          </div>
        </div>

        <div className="flex w-1/2 justify-center">
          <NeedAQuote />
        </div>
      </div>

      <div className="hidden lg:flex lg:w-full">
        <PinnedPages />
      </div>

      <Dialog
        maxWidth={"xl"}
        fullWidth={true}
        open={openCalendar}
        onClose={handleClose}
      >
        <MyCalendar />
      </Dialog>
    </div>
  );
};

export default Home;
