import React from "react";
import { Alert } from "@mui/material";

const ErrorID = (props) => {
  return (
    <div>
      <Alert severity="error">Error with ID: {props.id}</Alert>
    </div>
  );
};

export default ErrorID;
