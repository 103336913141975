import * as React from "react";
const HeavyRainSVG = (props) => {
  if (props.description === "light rain") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
        <defs>
          <filter
            id="blur"
            x={-0.24684}
            y={-0.24132}
            width={1.4937}
            height={1.5878}
          >
            <feGaussianBlur in="SourceAlpha" stdDeviation={3} />
            <feOffset dx={0} dy={4} result="offsetblur" />
            <feComponentTransfer>
              <feFuncA slope={0.05} type="linear" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <style type="text/css" />
        </defs>
        <g transform="translate(16,-2)" filter="url(#blur)">
          <g
            className="am-weather-cloud-3"
            style={{
              MozAnimationDuration: "3s",
              MozAnimationIterationCount: "infinite",
              MozAnimationName: "am-weather-cloud-2",
              MozAnimationTimingFunction: "linear",
              WebkitAnimationDuration: "3s",
              WebkitAnimationIterationCount: "infinite",
              WebkitAnimationName: "am-weather-cloud-2",
              WebkitAnimationTimingFunction: "linear",
            }}
          >
            <path
              transform="translate(-20,-11)"
              d="m47.7 35.4c0-4.6-3.7-8.2-8.2-8.2-1 0-1.9 0.2-2.8 0.5-0.3-3.4-3.1-6.2-6.6-6.2-3.7 0-6.7 3-6.7 6.7 0 0.8 0.2 1.6 0.4 2.3-0.3-0.1-0.7-0.1-1-0.1-3.7 0-6.7 3-6.7 6.7 0 3.6 2.9 6.6 6.5 6.7h17.2c4.4-0.5 7.9-4 7.9-8.4z"
              fill="#57a0ee"
              stroke="#fff"
              strokeLinejoin="round"
              strokeWidth={1.2}
            />
          </g>
          <g transform="translate(-20,-10) rotate(10,-238.68,233.96)">
            <line
              className="am-weather-rain-1"
              transform="translate(-6,1)"
              y2={8}
              fill="none"
              stroke="#91c0f8"
              strokeDasharray="4, 7"
              strokeLinecap="round"
              strokeWidth={2}
              style={{
                MozAnimationDuration: "8s",
                MozAnimationIterationCount: "infinite",
                MozAnimationName: "am-weather-rain",
                MozAnimationTimingFunction: "linear",
                msAnimationDuration: "8s",
                msAnimationIterationCount: "infinite",
                msAnimationName: "am-weather-rain",
                msAnimationTimingFunction: "linear",
                WebkitAnimationDuration: "8s",
                WebkitAnimationIterationCount: "infinite",
                WebkitAnimationName: "am-weather-rain",
                WebkitAnimationTimingFunction: "linear",
              }}
            />
          </g>
        </g>
      </svg>
    );
  } else if (
    props.description === "moderate rain" ||
    props.description === ""
  ) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
        <defs>
          <filter
            id="blur"
            x={-0.24684}
            y={-0.23409}
            width={1.4937}
            height={1.5702}
          >
            <feGaussianBlur in="SourceAlpha" stdDeviation={3} />
            <feOffset dx={0} dy={4} result="offsetblur" />
            <feComponentTransfer>
              <feFuncA slope={0.05} type="linear" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <style type="text/css" />
        </defs>
        <g transform="translate(16,-2)" filter="url(#blur)">
          <g
            className="am-weather-cloud-3"
            style={{
              MozAnimationDuration: "3s",
              MozAnimationIterationCount: "infinite",
              MozAnimationName: "am-weather-cloud-2",
              MozAnimationTimingFunction: "linear",
              WebkitAnimationDuration: "3s",
              WebkitAnimationIterationCount: "infinite",
              WebkitAnimationName: "am-weather-cloud-2",
              WebkitAnimationTimingFunction: "linear",
            }}
          >
            <path
              transform="translate(-20,-11)"
              d="m47.7 35.4c0-4.6-3.7-8.2-8.2-8.2-1 0-1.9 0.2-2.8 0.5-0.3-3.4-3.1-6.2-6.6-6.2-3.7 0-6.7 3-6.7 6.7 0 0.8 0.2 1.6 0.4 2.3-0.3-0.1-0.7-0.1-1-0.1-3.7 0-6.7 3-6.7 6.7 0 3.6 2.9 6.6 6.5 6.7h17.2c4.4-0.5 7.9-4 7.9-8.4z"
              fill="#57a0ee"
              stroke="#fff"
              strokeLinejoin="round"
              strokeWidth={1.2}
            />
          </g>
          <g
            transform="translate(-20,-10) rotate(10,-245.89,217.31)"
            fill="none"
            stroke="#91c0f8"
            strokeDasharray="4, 7"
            strokeLinecap="round"
            strokeWidth={2}
          >
            <line
              className="am-weather-rain-1"
              transform="translate(-6,1)"
              y2={8}
              style={{
                MozAnimationDuration: "8s",
                MozAnimationIterationCount: "infinite",
                MozAnimationName: "am-weather-rain",
                MozAnimationTimingFunction: "linear",
                msAnimationDuration: "8s",
                msAnimationIterationCount: "infinite",
                msAnimationName: "am-weather-rain",
                msAnimationTimingFunction: "linear",
                WebkitAnimationDuration: "8s",
                WebkitAnimationIterationCount: "infinite",
                WebkitAnimationName: "am-weather-rain",
                WebkitAnimationTimingFunction: "linear",
              }}
            />
            <line
              className="am-weather-rain-2"
              transform="translate(0,-1)"
              y2={8}
              style={{
                MozAnimationDelay: "0.25s",
                MozAnimationDuration: "8s",
                MozAnimationIterationCount: "infinite",
                MozAnimationName: "am-weather-rain",
                MozAnimationTimingFunction: "linear",
                msAnimationDelay: "0.25s",
                msAnimationDuration: "8s",
                msAnimationIterationCount: "infinite",
                msAnimationName: "am-weather-rain",
                msAnimationTimingFunction: "linear",
                WebkitAnimationDelay: "0.25s",
                WebkitAnimationDuration: "8s",
                WebkitAnimationIterationCount: "infinite",
                WebkitAnimationName: "am-weather-rain",
                WebkitAnimationTimingFunction: "linear",
              }}
            />
          </g>
        </g>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
        <defs>
          <filter
            id="blur"
            x={-0.24684}
            y={-0.22892}
            width={1.4937}
            height={1.5576}
          >
            <feGaussianBlur in="SourceAlpha" stdDeviation={3} />
            <feOffset dx={0} dy={4} result="offsetblur" />
            <feComponentTransfer>
              <feFuncA slope={0.05} type="linear" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <style>
            {
              "@keyframes am-weather-rain{0%{stroke-dashoffset:0}to{stroke-dashoffset:-100}}@keyframes am-weather-cloud-2{0%,to{-webkit-transform:translate(0,0);-moz-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0)}50%{-webkit-transform:translate(2px,0);-moz-transform:translate(2px,0);-ms-transform:translate(2px,0);transform:translate(2px,0)}}"
            }
          </style>
        </defs>
        <g transform="translate(16,-2)" filter="url(#blur)">
          <g
            className="am-weather-cloud-3"
            style={{
              MozAnimationDuration: "3s",
              MozAnimationIterationCount: "infinite",
              MozAnimationName: "am-weather-cloud-2",
              MozAnimationTimingFunction: "linear",
              WebkitAnimationDuration: "3s",
              WebkitAnimationIterationCount: "infinite",
              WebkitAnimationName: "am-weather-cloud-2",
              WebkitAnimationTimingFunction: "linear",
            }}
          >
            <path
              transform="translate(-20,-11)"
              d="m47.7 35.4c0-4.6-3.7-8.2-8.2-8.2-1 0-1.9 0.2-2.8 0.5-0.3-3.4-3.1-6.2-6.6-6.2-3.7 0-6.7 3-6.7 6.7 0 0.8 0.2 1.6 0.4 2.3-0.3-0.1-0.7-0.1-1-0.1-3.7 0-6.7 3-6.7 6.7 0 3.6 2.9 6.6 6.5 6.7h17.2c4.4-0.5 7.9-4 7.9-8.4z"
              fill="#57a0ee"
              stroke="#fff"
              strokeLinejoin="round"
              strokeWidth={1.2}
            />
          </g>
          <g
            transform="translate(-20,-10) rotate(10,-247.39,200.17)"
            fill="none"
            stroke="#91c0f8"
            strokeDasharray="4, 4"
            strokeLinecap="round"
            strokeWidth={2}
          >
            <line
              className="am-weather-rain-1"
              transform="translate(-4,1)"
              y2={8}
              style={{
                MozAnimationDuration: "8s",
                MozAnimationIterationCount: "infinite",
                MozAnimationName: "am-weather-rain",
                MozAnimationTimingFunction: "linear",
                msAnimationDuration: "8s",
                msAnimationIterationCount: "infinite",
                msAnimationName: "am-weather-rain",
                msAnimationTimingFunction: "linear",
                WebkitAnimationDuration: "8s",
                WebkitAnimationIterationCount: "infinite",
                WebkitAnimationName: "am-weather-rain",
                WebkitAnimationTimingFunction: "linear",
              }}
            />
            <line
              className="am-weather-rain-2"
              transform="translate(0,-1)"
              y2={8}
              style={{
                MozAnimationDelay: "0.25s",
                MozAnimationDuration: "8s",
                MozAnimationIterationCount: "infinite",
                MozAnimationName: "am-weather-rain",
                MozAnimationTimingFunction: "linear",
                msAnimationDelay: "0.25s",
                msAnimationDuration: "8s",
                msAnimationIterationCount: "infinite",
                msAnimationName: "am-weather-rain",
                msAnimationTimingFunction: "linear",
                WebkitAnimationDelay: "0.25s",
                WebkitAnimationDuration: "8s",
                WebkitAnimationIterationCount: "infinite",
                WebkitAnimationName: "am-weather-rain",
                WebkitAnimationTimingFunction: "linear",
              }}
            />
            <line
              className="am-weather-rain-1"
              transform="translate(4)"
              y2={8}
              style={{
                MozAnimationDuration: "8s",
                MozAnimationIterationCount: "infinite",
                MozAnimationName: "am-weather-rain",
                MozAnimationTimingFunction: "linear",
                msAnimationDuration: "8s",
                msAnimationIterationCount: "infinite",
                msAnimationName: "am-weather-rain",
                msAnimationTimingFunction: "linear",
                WebkitAnimationDuration: "8s",
                WebkitAnimationIterationCount: "infinite",
                WebkitAnimationName: "am-weather-rain",
                WebkitAnimationTimingFunction: "linear",
              }}
            />
          </g>
        </g>
      </svg>
    );
  }
};
export default HeavyRainSVG;
