import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { getLeadNoContact } from "../Admin/Utilities/api";
import { Button } from "@mui/material";
import { normalizeDate } from "../../utilities";
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from "@mui/icons-material";

const WithoutContact = () => {
  const [leads, setLeads] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "customerName",
      },
      {
        Header: "Email",
        accessor: "customerEmail",
      },
      {
        Header: "Phone",
        accessor: "customerPhone",
      },
      {
        Header: "Address",
        accessor: "customerAddress",
      },
      // Add "Date Added" column
      {
        Header: "Date Added",
        accessor: "entryDate",
        Cell: ({ value }) => normalizeDate(value),
      },
      // Add "Assigned To" column
      {
        Header: "Assigned To",
        accessor: "assignedSalesRep.Name",
        Cell: ({ cell: { value }, row: { original } }) => {
          if (value) {
            return value;
          } else if (
            original.assignedManager &&
            original.assignedManager.Name
          ) {
            return original.assignedManager.Name;
          } else {
            return "Not Assigned";
          }
        },
      },
    ],
    []
  );

  useEffect(() => {
    getLeadNoContact(setLeads); // Call getLeadNoContact with setLeads
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
    prepareRow,
  } = useTable(
    {
      columns,
      data: leads,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="m-2 dark:text-white">
      <p>Webleads Without Contact</p>
      <table
        {...getTableProps()}
        className="table-auto mx-auto"
        style={{
          borderCollapse: "collapse",
          width: "100%",
          alignItems: "center",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="dark:bg-blue-200/20"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="dark:bg-blue-200/10">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "8px",
                      border: "1px solid black",
                      textAlign: "left",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center items-center mt-2 dark:text-white p-2">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="mr-2"
        >
          <ArrowCircleLeftOutlined style={{ fontSize: "30px" }} />
        </button>

        <span className="ml-2">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>

        <button onClick={() => nextPage()} disabled={!canNextPage}>
          <ArrowCircleRightOutlined
            style={{ fontSize: "30px", marginLeft: "15px" }}
          />
        </button>
      </div>
    </div>
  );
};

export default WithoutContact;
