import React from "react";
import { stringToColor } from "../../utilities";

const DisplayUserRoles = (props) => {
  return (
    <div className="flex flex-wrap justify-center max-w-sm">
      {props.role?.roles?.map((item) => {
        return (
          <p
            style={{ borderColor: stringToColor(item) }}
            key={item}
            className="m-1 p-1 border rounded-md border-2 border-gray-500 bg-white/20 p-2 text-black dark:text-white"
          >
            {item}
          </p>
        );
      })}
    </div>
  );
};

export default DisplayUserRoles;
