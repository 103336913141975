import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../../components/WindowDoor/DataTable";

const ExteriorOrders = () => {
  return (
    <Fragment>
      <DataTable />
    </Fragment>
  );
};

export default ExteriorOrders;
