import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import useDarkSide from "./utility";
import DarkModeToggle from "react-dark-mode-toggle";

const ToggleDarkTheme = () => {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(colorTheme === "dark");

  useEffect(() => {
    document.body.classList.toggle("dark", darkSide);
    setTheme(darkSide ? "dark" : "light");
  }, [darkSide, setTheme]);

  const toggleDarkMode = () => {
    setDarkSide(!darkSide);
    window.location.reload(false);
  };

  const [isDarkMode, setIsDarkMode] = useState(() => false);
  return (
    <DarkModeToggle onChange={toggleDarkMode} checked={darkSide} size={45} />
  );
};

export default ToggleDarkTheme;
