import * as React from "react";
const ThunderSVG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 10 64 64" {...props}>
    <defs>
      <filter id="blur">
        <feGaussianBlur in="SourceAlpha" stdDeviation={3} />
        <feOffset dx={0} dy={4} result="offsetblur" />
        <feComponentTransfer>
          <feFuncA slope={0.05} type="linear" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <style>
        {
          "@keyframes am-weather-cloud-3{0%,to{-webkit-transform:translate(-5px,0);-moz-transform:translate(-5px,0);-ms-transform:translate(-5px,0);transform:translate(-5px,0)}50%{-webkit-transform:translate(10px,0);-moz-transform:translate(10px,0);-ms-transform:translate(10px,0);transform:translate(10px,0)}}@keyframes am-weather-cloud-2{0%,to{-webkit-transform:translate(0,0);-moz-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0)}50%{-webkit-transform:translate(2px,0);-moz-transform:translate(2px,0);-ms-transform:translate(2px,0);transform:translate(2px,0)}}@keyframes am-weather-stroke{0%,12%,16%,20%,24%,28%,4%,8%,to{-webkit-transform:translate(0,0);-moz-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0)}10%,14%,18%,2%{-webkit-transform:translate(.3px,0);-moz-transform:translate(.3px,0);-ms-transform:translate(.3px,0);transform:translate(.3px,0)}6%{-webkit-transform:translate(.5px,.4px);-moz-transform:translate(.5px,.4px);-ms-transform:translate(.5px,.4px);transform:translate(.5px,.4px)}22%{-webkit-transform:translate(1px,0);-moz-transform:translate(1px,0);-ms-transform:translate(1px,0);transform:translate(1px,0)}26%{-webkit-transform:translate(-1px,0);-moz-transform:translate(-1px,0);-ms-transform:translate(-1px,0);transform:translate(-1px,0)}40%{fill:orange;-webkit-transform:translate(0,0);-moz-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0)}65%{fill:#fff;-webkit-transform:translate(-1px,5px);-moz-transform:translate(-1px,5px);-ms-transform:translate(-1px,5px);transform:translate(-1px,5px)}61%{fill:orange}}"
        }
      </style>
    </defs>
    <g transform="translate(16,2)" filter="url(#blur)">
      <g className="am-weather-cloud-3" transform="translate(-16,-2)">
        <path
          fill="#57a0ee"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth={1.2}
          d="M47.7 35.4c0-4.6-3.7-8.2-8.2-8.2-1 0-1.9.2-2.8.5-.3-3.4-3.1-6.2-6.6-6.2-3.7 0-6.7 3-6.7 6.7 0 .8.2 1.6.4 2.3-.3-.1-.7-.1-1-.1-3.7 0-6.7 3-6.7 6.7 0 3.6 2.9 6.6 6.5 6.7h17.2c4.4-.5 7.9-4 7.9-8.4z"
          style={{
            WebkitAnimationName: "am-weather-cloud-3",
            MozAnimationName: "am-weather-cloud-3",
            animationName: "am-weather-cloud-3",
            WebkitAnimationDuration: "7s",
            MozAnimationDuration: "7s",
            animationDuration: "7s",
            WebkitAnimationTimingFunction: "linear",
            MozAnimationTimingFunction: "linear",
            animationTimingFunction: "linear",
            WebkitAnimationIterationCount: "infinite",
            MozAnimationIterationCount: "infinite",
            animationIterationCount: "infinite",
          }}
        />
      </g>
      <g
        className="am-weather-lightning"
        transform="matrix(1.2,0,0,1.2,-4,28) translate(0,7)"
      >
        <polygon
          className="am-weather-stroke"
          points="11.1 6.9 14.3 -2.9 20.5 -2.9 16.4 4.3 20.3 4.3 11.5 14.6 14.9 6.9"
          fill="#ffa500"
          stroke="#fff"
          style={{
            MozAnimationDuration: "1.11s",
            MozAnimationIterationCount: "infinite",
            MozAnimationName: "am-weather-stroke",
            MozAnimationTimingFunction: "linear",
            WebkitAnimationDuration: "1.11s",
            WebkitAnimationIterationCount: "infinite",
            WebkitAnimationName: "am-weather-stroke",
            WebkitAnimationTimingFunction: "linear",
            animationName: "am-weather-stroke",
            animationDuration: "1.11s",
            animationTimingFunction: "linear",
            animationIterationCount: "infinite",
          }}
        />
      </g>
    </g>
  </svg>
);
export default ThunderSVG;
