import React from "react";
import { Link } from "react-router-dom";

const NavItem = (props) => {
  return (
    <div className="flex items-center">
      <Link to={props.nav} className="text-sm font-bold text-white m-1">
        <div className="bg-cyan-700 rounded-sm flex items-center justify-center p-2">
          {props.title}
        </div>
      </Link>
    </div>
  );
};

export default NavItem;
