import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import MenuIcon from "@mui/icons-material/Menu";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const ExteriorOrdersFaq = () => {
  const [open, setOpen] = React.useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
  });

  const handleClick = (x) => {
    setOpen({ ...open, [x]: !open[x] });
  };

  return (
    <div className="m-4 ">
      <p className="p-4 w-full dark:text-red-200 text-red-500 text-3xl text-center font-black">
        Exterior Orders FAQ
      </p>

      <List>
        <ListItemButton
          onClick={() => {
            handleClick("one");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              Where can I see my exterior orders?
            </p>
          </ListItemText>
          {open.one ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.one} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  Click the <MenuIcon /> icon on the top left of the page.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md"
                      src={require("../../assets/Help/Help1.png")}
                    />
                  </div>
                </li>

                <li className="m-2">
                  Click the Window Door drop down.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md"
                      src={require("../../assets/Help/Help2.png")}
                    />
                  </div>
                </li>

                <li className="m-2">
                  Click My Exterior Orders.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md"
                      src={require("../../assets/Help/Help3.png")}
                    />
                  </div>
                </li>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("two");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              How can I schedule a delivery request?
            </p>
          </ListItemText>
          {open.two ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.two} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  Click the <LocalShippingIcon className="text-white" /> icon on
                  the far left of the order.
                </li>
                <li>
                  Click the Request Delivery button
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md"
                      src={require("../../assets/Help/Help4.png")}
                    />
                  </div>
                </li>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("three");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              I cannot find a specific order on the table, where is it?
            </p>
          </ListItemText>
          {open.three ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.three} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  All orders with Exterior items only are listed on the table,
                  also if you are not the TakenBy or the SalesRep you will need
                  to toggle "Branch Orders"
                </li>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("four");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              How can I view Branch orders?
            </p>
          </ListItemText>
          {open.four ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.four} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">Toggle "Branch Orders"</li>
                <div className="flex justify-center m-4">
                  <img
                    className="justify-center rounded-md"
                    src={require("../../assets/Help/Help5.png")}
                  />
                </div>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("five");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              How do I know if my request has been accepted?
            </p>
          </ListItemText>
          {open.five ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.five} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">View the status of the order here"</li>
                <div className="flex justify-center m-4">
                  <img
                    className="justify-center rounded-md"
                    src={require("../../assets/Help/Help7.png")}
                  />
                </div>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("six");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              What happens if my request is denied?
            </p>
          </ListItemText>
          {open.six ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.six} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  If request is denied you will receive an email prompting you
                  to select a new date for the delivery.
                </li>
                <div className="flex justify-center m-4">
                  <img
                    className="justify-center rounded-md"
                    src={require("../../assets/Help/Help8.png")}
                  />
                </div>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("seven");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              Where can I view my requests?
            </p>
          </ListItemText>
          {open.seven ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.seven} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  Click the <MenuIcon /> icon on the top left of the page.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md"
                      src={require("../../assets/Help/Help1.png")}
                    />
                  </div>
                </li>

                <li className="m-2">
                  Click the Window Door drop down.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md"
                      src={require("../../assets/Help/Help2.png")}
                    />
                  </div>
                </li>

                <li className="m-2">
                  Click My Requests.
                  <div className="flex justify-center m-4">
                    <img
                      className="justify-center rounded-md"
                      src={require("../../assets/Help/Help6.png")}
                    />
                  </div>
                </li>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={() => {
            handleClick("eight");
          }}
        >
          <ListItemText>
            <p className="text-xl text-center border-b">
              My request hasn't been accepted or rejected, what now?
            </p>
          </ListItemText>
          {open.eight ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.eight} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div className="flex justify-center font-bold dark:text-green-200 text-green-600 text-xl m-4">
              <ol className="text-center list-decimal">
                <li className="m-2">
                  If the delivery is still pending after 30 minutes a bell icon
                  will appear. Click the icon to send a reminder to the delivery
                  scheduler.
                </li>
                <div className="flex justify-center m-4">
                  <img
                    className="justify-center rounded-md"
                    src={require("../../assets/Help/Help9.png")}
                  />
                </div>

                <li className="m-2">
                  Once clicked you will receive a notification that the reminder
                  has been sent.
                </li>
                <div className="flex justify-center m-4">
                  <img
                    className="justify-center rounded-md"
                    src={require("../../assets/Help/Help10.png")}
                  />
                </div>
              </ol>
            </div>
          </List>
        </Collapse>
      </List>
    </div>
  );
};

export default ExteriorOrdersFaq;
