import { Tooltip } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import React from "react";

const RequestDelivery = (props) => {
  return (
    <>
      <div className="flex">
        <Tooltip title="Request Delivery">
          <button
            onClick={() => {
              props.setOrderID(props.rowData.OrderID);
              props.setCustomer(props.rowData.Customer);
              props.setJob(props.rowData.Job);
              props.setSO(props.rowData["SO #"]);
              props.setSelectedRowData(props.rowData);
              props.setIsOpen(true);
            }}
          >
            <LocalShippingIcon color="white" variant="text" />
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default RequestDelivery;
