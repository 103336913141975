import React from "react";
import door from "../../assets/DoorConfig.png";
import PaidIcon from "@mui/icons-material/Paid";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import GroupIcon from "@mui/icons-material/Group";
import VisibilityIcon from "@mui/icons-material/Visibility";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

const NeedAQuote = () => {
  return (
    <div className="flex flex-col items-center bg-blue-100 p-2 m-2 rounded-md shadow-md w-full justify-center">
      <div className="flex flex-col justify-center w-full">
        <div className="text-center">
          <h2 className="text-2xl font-bold">Interior Door Configurator</h2>
          <p className="text-lg">Need a quick quote?</p>
        </div>
        <div className="flex lg:flex-col flex-row">
          <p className="flex flex-wrap space-x-4 justify-center items-center">
            <span className="flex items-center space-x-2">
              <PaidIcon />
              <span>Accurate Pricing in Real-Time</span>
            </span>
            <span className="flex items-center space-x-2">
              <MeetingRoomIcon />
              <span>Comprehensive Stock Options</span>
            </span>
            <span className="flex items-center space-x-2">
              <GroupIcon />
              <span>User-Friendly Interface</span>
            </span>
            <span className="flex items-center space-x-2">
              <QueryBuilderIcon />
              <span>Time-Saving</span>
            </span>
            <span className="flex items-center space-x-2">
              <VisibilityIcon />
              <span>Visual Representation</span>
            </span>
            <span className="flex items-center space-x-2">
              <UploadFileIcon />
              <span>Quick Import Customer From Bistrack</span>
            </span>
            <span className="flex items-center space-x-2">
              <SaveIcon />
              <span>Automatic Saving</span>
            </span>
            <span className="flex items-center space-x-2">
              <EditIcon />
              <span>Return to Edit Anytime</span>
            </span>
          </p>
          {/* <div className="rounded-md justify-center flex-col items-center hidden lg:flex">
            <img
              src={door}
              alt="Configurator"
              className="max-h-64 max-w-96 justify-center rounded"
            />
          </div> */}
        </div>
      </div>

      <a
        href="/forms/new/rfq"
        className="mt-2 bg-blue-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-700"
      >
        Try Now!
      </a>
    </div>
  );
};

export default NeedAQuote;
