import { createSlice } from "@reduxjs/toolkit";

export const roleSlice = createSlice({
  name: "role",
  initialState: {
    roles: [],
  },
  reducers: {
    update: (state, action) => {
      //console.log(state.role);
      state.role = action.payload;
    },
    destroy: (state) => {
      state.role = null;
    },
  },
});

export const { update, destroy } = roleSlice.actions;

export const selectRoles = (state) => state.role.role;

export default roleSlice.reducer;
