import React, { useEffect, useState } from "react";
import { getVehicles } from "./utilities";
import NumbersIcon from "@mui/icons-material/Numbers";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { CircularProgress, Tooltip } from "@mui/material";
import { normalizeDate } from "../../utilities";
import { useParams } from "react-router-dom";

const DisplayVehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const { location } = useParams();

  useEffect(() => {
    getVehicles(setVehicles, location);
  }, []);

  return (
    <div>
      <div className="flex flex-col">
        <div className="justify-center flex m-2">
          <p className="text-xl font-black">Vehicles for {location}</p>
        </div>
        <div className="flex flex-col justify-center">
          {vehicles.length === 0 && <CircularProgress />}
          {vehicles.map((item, index) => {
            return (
              <button onClick={() => {}}>
                <div className="m-2 md:mt-0 md:col-span-2 ">
                  <div className="drop-shadow hover:drop-shadow-xl sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <div className="grid place-content-center grid-cols-3 gap-6">
                        <div className="col-span-1">
                          <div className="bg-gray-50 p-1 m-1 border-2 rounded-md">
                            <Tooltip title="Unit Number">
                              <div className="flex justify-center">
                                <NumbersIcon />
                                <p className="ml-2">{item.unit}</p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="bg-gray-50 p-1 m-1 m-2 border-2 rounded-md">
                            <Tooltip title="Location">
                              <div className="flex justify-center">
                                <LocationOnIcon />
                                <p className="ml-2">{item.branch}</p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="bg-gray-50 p-1 m-1 m-2 border-2 rounded-md">
                            <Tooltip title="Last Check In Date">
                              <div className="flex justify-center">
                                <CalendarTodayIcon />
                                <p className="ml-2">
                                  {normalizeDate(item?.lastChecked)}
                                </p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="bg-gray-50 p-1 m-1 m-2 border-2 rounded-md">
                            <Tooltip title="VIN">
                              <div className="flex justify-center">
                                <p className="ml-2">{item.vin}</p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="bg-gray-50 p-1 m-1 m-2 border-2 rounded-md">
                            <Tooltip title="MAKE">
                              <div className="flex justify-center">
                                <p className="ml-2">{item.make}</p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="bg-gray-50 p-1 m-1 m-2 border-2 rounded-md">
                            <Tooltip title="MODEL">
                              <div className="flex justify-center">
                                <p className="ml-2">{item.model}</p>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DisplayVehicles;
