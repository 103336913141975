import {
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { addForklift, getLocations } from "./Utilities/api";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import LocationNavigation from "./LocationNavigation";

const AddForklift = () => {
  const user = useSelector(selectUser);
  const [UnitNumber, setUnitNumber] = useState("");
  const [location, setLocation] = useState(``);
  const [moffett, setMoffett] = useState(false);

  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    getLocations(setLocationData);
  }, []);

  const handleChange = (event, child) => {
    setLocation(event.target.value);
  };
  return (
    <>
      <div className="flex justify-center">
        <div className="mt-2 md:mt-0 md:col-span-2 ">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white space-y-2 sm:p-6">
              <p className="text-xl font-black">Add Forklift</p>
              <div className="grid grid-cols-3 gap-2">
                <div className="col-span-1">
                  <div className="bg-gray-50 p-2 border-2 rounded-md">
                    <InputLabel id="demo-simple-select-label">
                      Unit Number
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setUnitNumber(e.target.value);
                      }}
                      value={UnitNumber}
                      id="outlined-basic"
                      label="#"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="bg-gray-50 p-2 border-2 rounded-md">
                    <InputLabel id="demo-simple-select-label">
                      Location
                    </InputLabel>
                    <Select
                      className="w-full"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={location}
                      label="Location"
                      onChange={handleChange}
                    >
                      {locationData.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.location}>
                            {item.location}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div className="col-span-1">
                  <div className="bg-gray-50 p-2 border-2 rounded-md">
                    <InputLabel id="demo-simple-select-label">
                      Moffett?
                    </InputLabel>
                    <Checkbox
                      checked={moffett}
                      onChange={(e) => {
                        setMoffett(e.target.checked);
                        //console.log(moffett);
                      }}
                    />
                  </div>
                </div>

                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    addForklift(location, UnitNumber, moffett, user.name);
                    setLocation("");
                    setUnitNumber("");
                    setMoffett(false);
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LocationNavigation locationData={locationData} />
    </>
  );
};

export default AddForklift;
