export const ForkInfo = [
  {
    title: "All Fluid Levels",
    field: "FluidLevel",
    options: ["Optimal Fluid Levels", "Low Fluid Levels"],
    description: "Describe Problem With Fluid Levels",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Check For Leaks",
    field: "Leaks",
    options: ["No Leaks", "Leaks"],
    description: "Describe Leak Problem",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Hydraulic Hoses, Mast & Chains",
    field: "Hydraulic",
    options: ["Good Condition", "Bad Condition"],
    description: "Describe Hydraulic Hoses, Mast & Chains Problem",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Fork Condition",
    field: "ForkCondition",
    options: ["Good Condition", "Worn Forks"],
    description: "Describe Fork Condition",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Load Backrest",
    field: "Backrest",
    options: ["Good Condition", "Bad Condition"],
    description: "Describe Backrest Condition",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Tire Condition",
    field: "TireCondition",
    options: ["Optimal Tires", "Worn Tires"],
    description: "Describe Tire Problem",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Roll Over Cage",
    field: "RollOverCage",
    options: ["Good Condition", "Bad Condition"],
    description: "Describe Roll Over Cage Condition",
    help: "This is example help text where you can find what you need",
    moffett: false,
    forklift: true,
  },
  {
    title: "LP Gas Leaks (ODOR)",
    field: "GasLeak",
    options: ["No Odor", "Oder"],
    description: "Describe Leak",
    help: "This is example help text where you can find what you need",
    moffett: false,
    forklift: true,
  },
  {
    title: "LP Cylinder Securely Mounted",
    field: "LPCylinderMounted",
    options: ["Securely Mounted", "Not Securely Mounted"],
    description: "Describe why the cylinder is not securely mounted",
    help: "This is example help text where you can find what you need",
    moffett: false,
    forklift: true,
  },
  {
    title: "Data Plate & Decals",
    field: "DataPlate",
    options: ["Readable", "Not Readable"],
    description: "Describe Data Plates & Decals",
    help: "This is example help text where you can find what you need",
    moffett: false,
    forklift: true,
  },
  {
    title: "Instruments Gauges & Controls",
    field: "Instruments",
    options: ["Working", "Not Working"],
    description: "Describe Instrument Gauges & Controls",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Lights & Turn Signal",
    field: "Lights",
    options: ["Working", "Not Working", "N/A"],
    description: "Describe Light and Turn Signal Condition",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Seat & Seat Belt",
    field: "Seat",
    options: ["Good Condition", "Bad Condition", "N/A"],
    description: "Describe Seat & Seat Belt",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Air Clean Radiator",
    field: "CleanRadiator",
    options: ["Complete", "Not Complete", "N/A"],
    description: "Reason for not Cleaning Radiator",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Air Clean Forklift",
    field: "CleanForklift",
    options: ["Complete", "Not Complete", "N/A"],
    description: "Reason for not Cleaning Forklift",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Back-Up Alarm",
    field: "BackUpAlarm",
    options: ["Working", "Not Working", "N/A"],
    description: "Describe Back-Up Alarm",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Warning Strobe",
    field: "Strobe",
    options: ["Working", "Not Working", "N/A"],
    description: "Describe Warning Strobe",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Horn",
    field: "Horn",
    options: ["Working", "Not Working"],
    description: "Describe Horn Problem",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Gauges",
    field: "Gauges",
    options: ["Good Condition", "Bad Condition"],
    description: "Describe Gauge Condition",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Steering & Drive Controls",
    field: "Steering",
    options: ["Good Condition", "Bad Condition"],
    description: "Describe Steering & Drive Controls",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Brakes",
    field: "Brakes",
    options: ["Good Condition", "Bad Condition", "N/A"],
    description: "Describe Brakes",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: true,
  },
  {
    title: "Mounting Pins / Locks / Chains",
    field: "MountingPins",
    options: ["Good Condition", "Bad Condition"],
    description: "Describe Mounting Pins / Locks / Chains",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: false,
  },
  {
    title: "Operators Manual",
    field: "OperatorManual",
    options: ["Present", "Not Present"],
    description: "Why is it not present",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: false,
  },
  {
    title: "Hydraulic Control Operations",
    field: "HydraulicControl",
    options: ["Working", "Not Working"],
    description: "Describe why its not working",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: false,
  },
  {
    title: "Air Filter Indicator",
    field: "AirFilterIndicator",
    options: ["Working", "Not Working"],
    description: "Describe why its not working",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: false,
  },
  {
    title: "Parking Brake",
    field: "ParkingBrake",
    options: ["Working", "Not Working"],
    description: "Describe why its not working",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: false,
  },
  {
    title: "Fire Extinguisher",
    field: "FireExtinguisher",
    options: ["Present", "Not Present"],
    description: "Why is it not present",
    help: "This is example help text where you can find what you need",
    moffett: true,
    forklift: false,
  },
];
