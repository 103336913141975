import React, { useEffect, useState } from "react";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import { Dialog, ThemeProvider, createTheme, Tooltip } from "@mui/material";
import { getDeliveryRequest, getWindowOrders } from "../../utilities";
import Modal from "react-modal";
import ReactLoading from "react-loading";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import {
  checkDeliveryStatus,
  normalizeDate,
  rowRenderStatus,
  setCellStyle,
  tableIcons,
} from "../Utilities/UtilitiesWD";
import RequestSnackbar from "../Other/RequestSnackbar";
import RequestDeliveryModal from "./RequestDeliveryModal";
import WDCustomTitle from "../Other/MaterialTable/WDCustomTitle";
import WDNoDataDisplay from "../Other/MaterialTable/WDNoDataDisplay";
import OutstandingPayment from "./TableComponents/OutstandingPayment";
import RequestDelivery from "./TableComponents/RequestDelivery";

const DataTable = () => {
  const user = useSelector(selectUser);
  const defaultMaterialTheme = createTheme();
  const [selectedRowData, setSelectedRowData] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [orderLineOpen, setOrderLineOpen] = useState(false);
  const [orderLineItems, setOrderLineItems] = useState([]);

  const [branchOrders, setBranchOrders] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderID, setOrderID] = useState("");
  const [orderIDLN, setOrderIDLN] = useState(null);

  const [customer, setCustomer] = useState("");
  const [job, setJob] = useState("");
  const [SalesOrderNumber, setSO] = useState("");
  const [userRequests, setUserRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataStore, setDataStore] = useState([]);

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (user) {
      getDeliveryRequest(user?.email, setUserRequests);
      getWindowOrders(user?.userID, setDataStore, 0);
    }
  }, [user]);

  const getOrderLineItems = async (id) => {
    return fetch(`/api/getOrderInfo/orderline?id=${id}`)
      .then((response) => response.json())
      .then((responseJson) => {
        setOrderLineItems(responseJson.recordset);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (orderIDLN) {
      getOrderLineItems(orderIDLN);
    }
  }, [orderIDLN]);

  useEffect(() => {
    if (user?.userID) {
      getWindowOrders(user?.userID, setDataStore, +branchOrders);
    }
  }, [branchOrders]);

  useEffect(() => {
    if (dataStore) {
      setLoading(false);
    }
  }, [dataStore]);

  return (
    <>
      {loading ? (
        <div className="w-full h-full flex justify-center m-2">
          <ReactLoading
            type={"bars"}
            color={"white"}
            height={200}
            width={250}
          />
        </div>
      ) : (
        <>
          <Dialog maxWidth={"lg"} open={modalIsOpen} onClose={closeModal}>
            <RequestDeliveryModal
              setIsOpen={setIsOpen}
              modalIsOpen={modalIsOpen}
              selectedRowData={selectedRowData}
              SalesOrderNumber={SalesOrderNumber}
              job={job}
              customer={customer}
              orderID={orderID}
              setOpen={setOpen}
              setLoading={setLoading}
            />
          </Dialog>

          {orderLineItems.length > 0 && orderLineOpen && (
            <Dialog
              maxWidth={"lg"}
              open={orderLineOpen}
              onClose={() => {
                setOrderLineOpen(false);
              }}
            >
              <div className="flex flex-col m-4">
                <button
                  className="justify-end"
                  onClick={() => {
                    setOrderLineOpen(false);
                  }}
                >
                  <p className="text-red-700 ">Close</p>
                </button>
                <table className="m-2">
                  <thead>
                    <tr>
                      <th className="p-2 border">QTY</th>
                      <th className="p-2 border">Description</th>
                      <th className="p-2 border">Product Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderLineItems.map((item) => {
                      return (
                        <tr>
                          <td className="p-2 border">{item.Quantity}</td>
                          <td className="p-2 border">{item.Description}</td>
                          <td className="p-2 border">{item.ProductCode}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Dialog>
          )}

          {!loading && (
            <div style={{ margin: "5px" }} className="App">
              <div
                style={{
                  maxWidth: "100%",
                  paddingTop: "12px",
                }}
              >
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    style={{
                      backgroundColor:
                        window.document.documentElement.classList[0] === "dark"
                          ? "rgba(255, 255, 255, 0.05)"
                          : "rgba(255, 255, 255, 1)",
                      color:
                        window.document.documentElement.classList[0] === "dark"
                          ? "white"
                          : "black",
                    }}
                    columns={[
                      {
                        title: "Action",
                        field: "Action",
                        render: (rowData) => {
                          if (rowData["Payment Outstanding"]) {
                            return <OutstandingPayment />;
                          } else {
                            if (checkDeliveryStatus(rowData)) {
                              return <></>;
                            } else {
                              return (
                                <div className="flex">
                                  <RequestDelivery
                                    setOrderID={setOrderID}
                                    setCustomer={setCustomer}
                                    setJob={setJob}
                                    setSO={setSO}
                                    setSelectedRowData={setSelectedRowData}
                                    setIsOpen={setIsOpen}
                                    rowData={rowData}
                                  />

                                  <Tooltip title="View Order Items">
                                    <button
                                      onClick={() => {
                                        setOrderIDLN(rowData.OrderID);
                                        setOrderLineOpen(true);
                                      }}
                                    >
                                      <ContentPasteSearchIcon
                                        color="white"
                                        variant="text"
                                      />
                                    </button>
                                  </Tooltip>
                                </div>
                              );
                            }
                          }
                        },
                      },
                      {
                        title: "Customer",
                        field: "Customer",
                      },
                      {
                        title: "Job",
                        field: "Job",
                      },
                      {
                        title: "SO#",
                        field: "SO #",
                      },
                      {
                        title: "PO#",
                        field: "PO #",
                      },
                      {
                        title: "Supplier",
                        field: "Supplier",
                      },
                      {
                        title: "Order Date",
                        field: "Order Date",
                        render: (rowData) => {
                          return normalizeDate(rowData);
                        },
                      },

                      {
                        title: "Sales Rep",
                        field: "Sales Rep",
                      },
                      {
                        title: "Status",
                        field: "Status",
                        render: (rowData) => {
                          return rowRenderStatus(rowData, userRequests);
                        },
                        cellStyle: (cellValue, rowData) => {
                          return setCellStyle(cellValue);
                        },
                      },
                      {
                        title: "Amount",
                        field: "Total Amount",
                        type: "currency",
                      },
                      {
                        title: "Cost",
                        field: "Total Cost",
                        type: "currency",
                      },
                    ]}
                    data={dataStore?.slice()?.reverse()}
                    title={
                      <WDCustomTitle
                        name={`${user?.name}`}
                        branchOrders={branchOrders}
                        setBranchOrders={setBranchOrders}
                        setLoading={setLoading}
                      />
                    }
                    icons={tableIcons}
                    options={{
                      pageSize: 10, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [5, 10, 15, 25],
                      sorting: true,
                      headerStyle: {
                        search: true,
                        backgroundColor:
                          window.document.documentElement.classList[0] ===
                          "dark"
                            ? "rgba(255, 255, 255, 0.05)"
                            : "rgba(255, 255, 255, 1)",
                        color:
                          window.document.documentElement.classList[0] ===
                          "dark"
                            ? "white"
                            : "black",
                      },
                      searchFieldStyle: {
                        color:
                          window.document.documentElement.classList[0] ===
                          "dark"
                            ? "white"
                            : "black",
                        borderBottom:
                          window.document.documentElement.classList[0] ===
                          "dark"
                            ? "1px solid white"
                            : "1px solid black",
                      },
                      rowStyle: (rowData) => {
                        return {
                          fontSize: 14,
                        };
                      },
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <WDNoDataDisplay
                            branchOrders={branchOrders}
                            setBranchOrders={setBranchOrders}
                            setLoading={setLoading}
                          />
                        ),
                      },
                    }}
                  />
                </ThemeProvider>
              </div>
              {open && (
                <RequestSnackbar
                  open={open}
                  setOpen={setOpen}
                  message={`Thy deliv'ry requesteth hast been hath sent | Customer: ${customer} | Job: ${job}`}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DataTable;
