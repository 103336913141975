import React from "react";

const SmokeSVG = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-250 -200 1024 1024"
      {...props}
    >
      <g>
        <path
          d="M21.9,213.9c0-25.9,8.3-49,24.5-69.3c16.2-20.4,36.8-33.3,61.9-38.8c6.9-30.8,22.5-56.4,47.3-76.2
		C180.2,9.9,208.3,0,240.2,0c30.8,0,58.6,9.6,83,28.9c24.5,19.2,40.1,44,47.6,74h7.4c30.8,0,57.2,10.7,79.2,32.4
		c21.7,21.5,32.7,47.9,32.7,78.4c0,16.5-3.3,32.2-10.2,46.8c-6.9,14.6-16.2,27.2-28.3,37.7v0.8c0,16.2-5.2,30.8-15.4,43.7
		c-10.2,12.9-23.1,20.9-38.5,24.5c-3.8,17.1-12.4,31.6-25,43.4c-12.6,11.8-27.8,19.2-44.8,22c8,9.3,11.8,19.8,11.8,31.1
		c0,13.2-4.7,24.5-14,34.1c-9.4,9.3-20.6,14.3-33.8,14.3s-24.5-4.7-33.8-14.3c-9.4-9.3-14-20.9-14-34.1c0-5.2,0.8-10.4,2.8-15.7H244
		c-16,0-29.7-5.8-41.2-17.3c-11.5-11.5-17.3-25.3-17.3-41.2c0-11,2.8-20.9,8.2-29.4c-14.3-8-24.5-19.2-30.8-34.4h-35.2v-0.3
		c-29.4-1.9-54.4-13.5-75.1-34.9S21.9,243.6,21.9,213.9z M60.4,207.1c0,22,7.7,40.7,23.1,56.1c15.4,15.4,34.1,23.1,55.8,23.1
		c13.5,0,26.1-3,37.7-9.4c3.3,20.4,12.9,37.4,28.6,51.2c15.7,13.7,34.4,20.3,55.5,20.3c23.9,0,44.3-8.5,61-25.3
		c11.3,13.2,25.3,19.5,42.3,19.5c15.7,0,28.9-5.5,40.1-16.5c11-11,16.5-24.5,16.5-40.1c11-7.4,19.8-16.8,26.1-28.6
		c6.3-11.5,9.6-24.2,9.6-37.7c0-21.7-7.7-40.4-23.4-55.5c-15.7-15.1-34.4-22.8-56.4-22.8c-15.4,0-29.4,4.1-42.1,12.1
		c1.7-6.6,2.2-14,2.2-21.7c0-26.4-9.3-48.9-28.3-67.6c-19-18.7-41.8-27.8-68.5-27.8c-25.8,0-48.1,9.1-66.8,26.7
		c-18.7,17.6-28.6,39.6-29.4,65.2H142h-2.5c-21.7,0-40.1,7.7-55.8,23.1C68.1,166.6,60.4,185.6,60.4,207.1z"
        />
      </g>
    </svg>
  );
};

export default SmokeSVG;
