import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
  Dialog,
  ThemeProvider,
  Tooltip,
  createTheme,
  Checkbox,
  TextField,
  Button,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { icons } from "../../Utilities/UtilitiesWD";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CheckBox } from "@mui/icons-material";

const DisplayDoors = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isFindReplaceModalOpen, setFindReplaceModalOpen] = useState(false);
  const [findText, setFindText] = useState("");
  const [replaceText, setReplaceText] = useState("");
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedRowOptions, setSelectedRowOptions] = useState({}); // State to store the options for each selected row

  const defaultMaterialTheme = createTheme();

  const DeleteRow = (row) => {
    //get index of row data
    const index = row.tableData.id;

    //remove row from data array
    const temp = [...props.doors];
    temp.splice(index, 1);
    props.setDoors(temp);
  };

  const editRow = (row) => {
    props.onEditRow(row);
  };

  const copyRow = (row) => {
    const copiedRow = { ...row }; // Create a copy of the row data
    props.setDoors((prevDoors) => [...prevDoors, copiedRow]); // Add the copied row to the doors array
  };

  const openFindReplaceModal = () => {
    setFindReplaceModalOpen(true);
  };

  const closeFindReplaceModal = () => {
    setFindReplaceModalOpen(false);
  };

  const handleDeleteRows = () => {
    // Remove selected rows from the doors array
    const updatedDoors = props.doors.filter(
      (door, index) => selectedRows.indexOf(index) === -1
    );
    props.setDoors(updatedDoors);

    // Clear selected rows
    setSelectedRows([]);

    // Close the modal after deleting rows
    closeFindReplaceModal();
  };

  const handleFindReplace = () => {
    // Iterate over selected rows
    selectedRows.forEach((selectedIndex) => {
      // Get the corresponding row data
      const rowData = props.doors[selectedIndex];

      // Replace occurrences in selected columns
      ["QTY", "handing", "stop", "special_instructions"].forEach((key) => {
        if (
          selectedColumns[key] &&
          typeof rowData[key] === "string" &&
          rowData[key] === findText
        ) {
          // Replace only if the field is selected, its value is an exact match to findText
          rowData[key] = replaceText;
        }
      });
    });

    // Update the doors array with the modified data
    props.setDoors([...props.doors]);

    // Close the modal after performing find and replace
    closeFindReplaceModal();
  };

  const customLabels = {
    quantity: "QTY",
    handing: "Handing",
    casing_type: "Casing",
    width: "Dimensions",
    height: "Dimensions",
    slab_style: "Slab Style",
    size_jamb_type: "Jamb",
    hinge_finish: "Hinge Finish",
    stop: "Stop",
    estimated_price: "Estimated Price",
    margin: "Margin %",
    // Add more custom labels as needed
  };

  const getCasingAppliedStatus = (rowData) => {
    if (rowData.applied === "Yes") {
      return " (Applied)";
    } else if (rowData.applied === "No") {
      return " (Not Applied)";
    }
    // Add more conditions if needed
    return "";
  };

  const renderFindOptions = () => {
    const commonFields = [
      "quantity",
      "handing",
      "casing_type",
      "slab_style",
      "width",
      "size_jamb_type",
      "hinge_finish",
      "stop",
      "estimated_price",
      "catch_astragal",
      // Add more common fields as needed
    ];

    const options = commonFields.flatMap((field) => {
      const values = new Set(); // Use a Set to store unique values for each field
      selectedRows.forEach((selectedIndex) => {
        const rowData = props.doors[selectedIndex];
        if (field === "width" || field === "height") {
          values.add(`${rowData.width}x${rowData.height}`);
        } else if (field === "casing_type") {
          values.add(
            `${rowData.casing_type}${getCasingAppliedStatus(rowData)}`
          );
        } else {
          values.add(rowData[field]);
        }
      });

      return Array.from(values).map((value, index) => (
        <option key={`${field}-${index}`} value={`${field}: ${value}`}>
          {`${customLabels[field] || field}: ${value}`}
        </option>
      ));
    });

    return options;
  };

  const handleColumnCheckboxChange = (event, columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: event.target.checked,
    }));
  };

  const renderColumnCheckboxes = () => {
    const allowedColumns = ["QTY", "handing", "stop", "special_instructions"];

    return props.doors.length > 0 ? (
      <div style={{ overflowX: "auto", marginBottom: "10px" }}>
        <div style={{ display: "flex" }}>
          {allowedColumns.map((columnName) => (
            <div
              key={columnName}
              style={{
                marginRight: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={selectedColumns[columnName]}
                onChange={(event) =>
                  handleColumnCheckboxChange(event, columnName)
                }
              />
              <span style={{ marginLeft: "5px" }}>{columnName}</span>
            </div>
          ))}
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="relative mt-1 border border-black">
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          style={{
            backgroundColor:
              window.document.documentElement.classList[0] === "dark"
                ? "rgba(255, 255, 255, 0.05)"
                : "rgba(255, 255, 255, 1)",
            color:
              window.document.documentElement.classList[0] === "dark"
                ? "white"
                : "black",
          }}
          columns={[
            {
              title: "QTY",
              field: "quantity",
            },
            {
              title: "Handing",
              field: "handing",
              render: (rowData) => {
                if (rowData.door_type === "bifold") {
                  return "Bifold";
                } else if (rowData.door_type === "double") {
                  return "Double";
                } else {
                  return rowData.handing;
                }
              },
            },
            {
              title: "Casing",
              field: "casing_type",
              render: (rowData) => {
                if (rowData.casing_type === "N/A") {
                  return <div className="flex flex-col">N/A</div>;
                } else if (rowData.applied === "Yes") {
                  return (
                    <div className="flex flex-col">
                      <div>{rowData.casing_type}</div>
                      <div className="bg-green-200/20 border flex justify-center p-1 rounded-md">
                        Applied
                      </div>
                    </div>
                  );
                } else if (rowData.applied === "No") {
                  return (
                    <div className="flex flex-col overflow-auto max-h-16">
                      <div>{rowData.casing_type}</div>
                      <div className="bg-green-200/20 border flex justify-center p-1 rounded-md">
                        Not Applied
                      </div>
                    </div>
                  );
                } else if (rowData.applied === "Loose") {
                  return (
                    <div className="flex flex-col overflow-auto max-h-16">
                      <div>{rowData.casing_type}</div>
                      <div className="bg-green-200/20 border flex justify-center p-1 rounded-md">
                        Applied (Loose)
                      </div>
                    </div>
                  );
                } else if (rowData.applied === "Loose Hooped") {
                  return (
                    <div className="flex flex-col overflow-auto max-h-16">
                      <div>{rowData.casing_type}</div>
                      <div className="bg-green-200/20 border flex justify-center p-1 rounded-md">
                        Applied (Loose Hooped)
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="flex flex-col overflow-auto max-h-16">
                      <div>{rowData.casing_type}</div>
                    </div>
                  );
                }
              },
            },

            {
              title: "Slab Information",
              field: "slab_style",
              render: (rowData) => {
                const slabInfo = `${rowData.width} x ${rowData.height} ${rowData.core} ${rowData.slab_style}`;
                return <div>{slabInfo}</div>;
              },
            },
            {
              title: "Jamb",
              field: "size_jamb_type",
            },
            {
              title: "Hinge Finish",
              field: "hinge_finish",
            },
            {
              title: "Stop",
              field: "stop",
              render: (rowData) => {
                if (rowData.stop === "N/A") {
                  return <div className="flex flex-col">N/A</div>;
                } else if (rowData.stop_applied === "Yes") {
                  return (
                    <div className="flex flex-col">
                      <div>{rowData.stop}</div>
                      <div className="bg-green-200/20 border flex justify-center p-1 rounded-md">
                        Applied
                      </div>
                    </div>
                  );
                } else if (rowData.stop_applied === "No - Loose") {
                  return (
                    <div className="flex flex-col">
                      <div>{rowData.stop}</div>
                      <div className="bg-green-200/20 border flex justify-center p-1 rounded-md">
                        Loose
                      </div>
                    </div>
                  );
                }
              },
            },
            {
              title: "Estimated Price",
              field: "estimated_price",
              render: (rowData) => (
                <p className="flex items-center justify-center text-green-500">
                  ${rowData.estimated_price}
                </p>
              ),
            },
            {
              title: "Special Instructions",
              field: "special_instructions",
            },
            {
              title: "Action",
              field: "action",
              render: (rowData, index) => (
                <div>
                  <Tooltip title="Delete">
                    <button>
                      <DeleteForeverIcon
                        className="text-red-500"
                        onClick={() => {
                          DeleteRow(rowData);
                        }}
                      />
                    </button>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <button>
                      <EditIcon
                        className="text-yellow-400"
                        onClick={() => {
                          editRow(rowData);
                        }}
                      />
                    </button>
                  </Tooltip>
                  <Tooltip title="Copy">
                    <button>
                      <ContentCopyIcon
                        onClick={() => {
                          copyRow(rowData);
                        }}
                      />
                    </button>
                  </Tooltip>
                </div>
              ),
            },
          ]}
          components={{
            Toolbar: (props) => {
              if (selectedRows.length > 0) {
                return (
                  <div className="flex">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <Tooltip title="Find and Replace">
                        <button
                          className="m-1 p-2 bg-blue-200 rounded-md"
                          onClick={openFindReplaceModal}
                        >
                          Find & Replace
                        </button>
                      </Tooltip>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <Tooltip title="Find and Replace">
                        <button
                          className="m-1 p-2 bg-red-200 rounded-md"
                          onClick={handleDeleteRows}
                        >
                          Delete Selected
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                );
              }
            },
          }}
          data={props.doors}
          title={"Doors"}
          icons={icons}
          options={{
            pageSize: 10, // make initial page size
            emptyRowsWhenPaging: false, // To avoid of having empty rows
            pageSizeOptions: [5, 10, 15, 25],
            headerStyle: {
              search: true,
              backgroundColor:
                window.document.documentElement.classList[0] === "dark"
                  ? "rgba(255, 255, 255, 0.05)"
                  : "rgba(255, 255, 255, 1)",
              color:
                window.document.documentElement.classList[0] === "dark"
                  ? "white"
                  : "black",
            },
            searchFieldStyle: {
              color:
                window.document.documentElement.classList[0] === "dark"
                  ? "white"
                  : "black",
              borderBottom:
                window.document.documentElement.classList[0] === "dark"
                  ? "1px solid white"
                  : "1px solid black",
            },
            rowStyle: (rowData) => {
              return {
                fontSize: 14,
              };
            },
          }}
        />
      </ThemeProvider>
      <Dialog open={isFindReplaceModalOpen} onClose={closeFindReplaceModal}>
        {renderColumnCheckboxes()}
        <div style={{ padding: "20px" }}>
          {selectedRows.length > 0 && (
            <div>
              <label>Find:</label>
              <select
                value={findText}
                onChange={(e) => setFindText(e.target.value)}
              >
                {renderFindOptions()}
              </select>
            </div>
          )}

          <TextField
            label="Replace"
            variant="outlined"
            fullWidth
            margin="normal"
            value={replaceText}
            onChange={(e) => setReplaceText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleFindReplace}
          >
            Find & Replace
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DisplayDoors;
