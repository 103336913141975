import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";

import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from "@mui/icons-material";
import { normalizeDate } from "../../../utilities";
import { getAllRecognitions } from "../../../components/Forms/api";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { selectRoles } from "../../../features/roleSlice";

const RecognitionEntries = () => {
  const [entries, setEntries] = useState([]);
  const [flag, setFlag] = useState(false);

  const user = useSelector(selectUser);
  const role = useSelector(selectRoles);

  useEffect(() => {
    getAllRecognitions(setEntries);
  }, [flag]);

  const deleteForm = async (data) => {
    const formData = new FormData();
    formData.append("id", data._id);
    formData.append("key", "9a8dj98j89JSDn12jmoiamsdp");
    formData.append("deleted_by", user.name);

    await fetch("/api/forms/submit/recognitionForm/delete", {
      method: "POST",
      body: formData,
    });
    setFlag(!flag);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Entry Date",
        accessor: "date_entered",
        Cell: ({ cell: { value }, row: { original } }) => (
          <div className="flex flex-col w-32 items-center">
            <p className="text-xs">{normalizeDate(value)}</p>
            {role?.roles?.includes("Admin") && (
              <button
                onClick={() => deleteForm(original)}
                className="dark:text-white hover:text-red-400"
              >
                <p className="text-xs">[Delete Entry]</p>
              </button>
            )}
          </div>
        ),
      },
      {
        Header: "From",
        accessor: "submit_user",
      },
      {
        Header: "For",
        accessor: "employee",
      },
      {
        Header: "Values",
        accessor: "value",
        Cell: ({ cell: { value }, row: { original } }) => {
          return (
            <div className="flex w-32 flex-wrap">
              {value.map((item, index) => (
                <React.Fragment key={index}>
                  <p>{item}</p>
                  {index !== value.length - 1 && <span>,</span>}
                </React.Fragment>
              ))}
            </div>
          );
        },
      },
      {
        Header: "Files",
        accessor: "files",
        Cell: ({ cell: { value }, row: { original } }) => {
          return (
            <div className="flex flex-col">
              {value && value.length > 0 ? (
                value.map((file, index) => (
                  <div key={index}>
                    <a
                      href={`https://node.bigclumber.com/api/forms/submit/recognitionForm/files/${original._id}/${file.filename}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      {file.filename}
                    </a>
                  </div>
                ))
              ) : (
                <p>No files uploaded</p>
              )}
            </div>
          );
        },
      },
      {
        Header: "Explanation",
        accessor: "explanation",
      },
    ],
    []
  );

  useEffect(() => {
    getAllRecognitions(setEntries); // Call getLeadNoContact with setLeads
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
    prepareRow,
  } = useTable(
    {
      columns,
      data: entries.reverse(),
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="m-2 dark:text-white p-2">
      <div className="flex w-full justify-center">
        <p className="text-2xl font-bold">Caught in the Act Entries</p>

        <CSVLink target="_blank" data={entries}>
          <p className="ml-2 text-xs underline">Download CSV</p>
        </CSVLink>
      </div>
      <table
        {...getTableProps()}
        className="table-auto mx-auto"
        style={{
          borderCollapse: "collapse",
          width: "100%",
          alignItems: "center",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="dark:bg-blue-200/20 bg-slate-200 border border-black"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="dark:bg-blue-200/10">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "8px",
                      border: "1px solid black",
                      textAlign: "left",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center items-center mt-2 dark:text-white p-2">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="mr-2"
        >
          <ArrowCircleLeftOutlined style={{ fontSize: "30px" }} />
        </button>

        <span className="ml-2">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>

        <button onClick={() => nextPage()} disabled={!canNextPage}>
          <ArrowCircleRightOutlined
            style={{ fontSize: "30px", marginLeft: "15px" }}
          />
        </button>
      </div>
    </div>
  );
};

export default RecognitionEntries;
