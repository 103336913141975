import React, { useEffect, useState } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import NumbersIcon from "@mui/icons-material/Numbers";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import { Button, Modal, Tooltip } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getRequestInfo_PrevCheck,
  sendDeliveryRequest,
  sendUserNotification,
} from "../../utilities";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";

const RequestDeliveryModal = (props) => {
  const [checked, setChecked] = useState(true);
  const [prevSubmit, setPrevSubmit] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const user = useSelector(selectUser);
  const [notes, setNotes] = useState("");

  const handleChange = () => {
    setChecked(!checked);
  };

  function closeModal() {
    props.setIsOpen(false);
  }

  const requestDelivery = (orderID, customer, job, SalesOrderNumber) => {
    sendUserNotification(
      "amanda.abeyta@bigclumber.com",
      "System",
      `${user.name} has requested a delivery for ${customer} | ${job} | ${SalesOrderNumber}`,
      "New Delivery Request",
      "/windowdoor/admin/requestlist"
    );
    sendUserNotification(
      "blake.upham@bigclumber.com",
      "System",
      `${user.name} has requested a delivery for ${customer} | ${job} | ${SalesOrderNumber}`,
      "New Delivery Request",
      "/windowdoor/admin/requestlist"
    );
    sendUserNotification(
      "nathan.bilancio@bigclumber.com",
      "System",
      `${user.name} has requested a delivery for ${customer} | ${job} | ${SalesOrderNumber}`,
      "New Delivery Request",
      "/windowdoor/admin/requestlist"
    );
    sendDeliveryRequest(
      orderID,
      customer,
      job,
      SalesOrderNumber,
      notes,
      startDate,
      user.name,
      user.email,
      checked
    );
  };

  useEffect(() => {
    getRequestInfo_PrevCheck(props.orderID, setPrevSubmit);
  }, []);

  return (
    <>
      <div className="md:mt-0 md:col-span-2 bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 border-b dark:border-gray-500 border-black">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-5 space-y-6 sm:p-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-1">
                <div className="border border-gray-500 dark:bg-white/5 p-2 border rounded-md">
                  <Tooltip title="Customer">
                    <div className="dark:text-white">
                      <PersonOutlineIcon />
                      {props.selectedRowData.Customer}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="col-span-1">
                <div className="border border-gray-500 bg-white/5 p-2 border rounded-md">
                  <Tooltip title="Order Number">
                    <div className="dark:text-white">
                      <NumbersIcon />
                      {props.selectedRowData["SO #"]}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="col-span-1">
                <div className="border border-gray-500 bg-white/5 p-2 border rounded-md">
                  <Tooltip title="Job Ref">
                    <div className="dark:text-white">
                      <WorkIcon />
                      {props.selectedRowData.Job}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="col-span-3">
                <div className="border border-gray-500 bg-white/5 p-2 border rounded-md">
                  <Tooltip title="Deliver To Address">
                    <div className="dark:text-white">
                      <HomeIcon />
                      {props.selectedRowData["Deliver To"]}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="col-span-1">
                <div className="border border-gray-500 bg-white/5 p-2 border rounded-md flex">
                  <h1 className="font-medium dark:text-white">
                    Earliest Available Date?
                  </h1>
                  <input
                    className="ml-2"
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {!checked && (
                <>
                  <div className="col-span-2">
                    <div className="border border-gray-500 bg-white/5 p-2 border rounded-md flex items-center">
                      <p className="font-bold dark:text-white">
                        Select Delivery Date:
                      </p>
                      <DatePicker
                        selected={startDate}
                        minDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                        className="border rounded-full p-2 border-gray-500 bg-white/5 dark:text-white"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="col-span-2">
              <label className="block font-bold font-medium dark:text-white">
                Notes
              </label>
              <div className="mt-1">
                <textarea
                  name="notes"
                  rows="3"
                  className="shadow-sm border border-gray-500 bg-white/5 dark:text-white p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="enter optional notes"
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  value={notes}
                ></textarea>
              </div>
            </div>
          </div>

          {!prevSubmit ? (
            <div className="flex justify-center px-4 py-3 text-right sm:px-6">
              <div className="m-2">
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => {
                    closeModal();
                    props.setOpen(true);
                    requestDelivery(
                      props.orderID,
                      props.customer,
                      props.job,
                      props.SalesOrderNumber
                    );
                  }}
                >
                  Request Delivery
                </Button>
              </div>
              <div className="m-2">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-center px-4 py-3 text-right sm:px-6">
                <p className="text-red-400 font-black text-lg">
                  You have already requested a delivery of this order, please
                  contact Window Door for further assistance!
                </p>
              </div>
              <div className="flex justify-center px-4 py-3 text-right sm:px-6">
                <div className="m-2">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      closeModal();
                      setPrevSubmit(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestDeliveryModal;
